import AddIcon from '@mui/icons-material/Add';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { IconButton } from "@mui/material";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import React from 'react';
import { config } from '../../../config';
import { AlertContext } from '../../../contexts';
import { NetworkCall } from '../../../networkcall';
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from '../../../utils';
import { useStyles } from "./styles";


const CustomInput = styled('input')({
    position: 'absolute',
    opacity: 0,
    inset: 0,
    zIndex: 1,
    cursor: 'pointer'
})

const UploadButton = styled(Button)(({ theme }) => ({
    padding: "20px",
    color: "#5078E1",
    background: "E4EFFF",
    fontSize: 12,
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,

}))

export const UploadBtn = (props) => {

    const {
        accept = "image/*",
        fileUploadUrl = true,
        handleChange = null,
        isReadonly = false,
        iconButton = false
    } = props;


    const alert = React.useContext(AlertContext);
    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);

    const handleUpload = async (data) => {
        //only allow if file selected
        if (data?.length) {
            if (fileUploadUrl) {
                executeImageData(data)
            }
            else {
                handleChange && handleChange(data)
            }

        }
    }

    const executeImageData = async (data) => {
        setLoading(true);
        let bytes = data?.size
        let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
        if (finalSize <= 2.00) {
            const formData = new FormData();
            formData.append("files", data?.[0]);
            formData.append("tenantId", `${config.tenantId}`);

            await NetworkCall(
                `${config.api_url}/file-upload`,
                NetWorkCallMethods.post,
                formData,
                null,
                true,
                false
            )
                .then((response) => {
                    handleChange && handleChange({
                        url: response.data.fileUrls[0].url,
                        is_active: true,
                        file_meta: {
                            name: data?.[0]?.name,
                            size: data?.[0]?.size,
                            type: data[0]?.type
                        }


                    });
                    setLoading(false);
                })
                .catch((err) => {
                    handleChange(null);
                    setLoading(false);
                    alert.setSnack({
                        open: true,
                        horizontal: AlertProps.horizontal.center,
                        vertical: AlertProps.vertical.top,
                        msg: `Unable to upload`,
                        severity: AlertProps.severity.error,
                    });
                });
        }
        else {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please upload less then 2MB File",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    }

    return (
        <div>
            <Stack
                alignItems={"center"}
            >
                {
                    !isReadonly &&
                    <>
                        {
                            iconButton ?
                                <IconButton className={classes.hoverIcon}>
                                    <AttachFileIcon />
                                    <CustomInput
                                        type="file"
                                        onChange={(e) => handleUpload(e.target.files)}
                                        accept={accept}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        className={classes.hoverIcon}
                                    />
                                </IconButton>
                                :
                                <UploadButton
                                    fullWidth
                                    variant={"outlined"}
                                    disabled={loading}
                                    onClick={(event) => {
                                        event.target.value = null
                                    }}
                                // startIcon={<AddIcon />}
                                >
                                    <AddIcon />
                                    <CustomInput
                                        type="file"
                                        onChange={(e) => handleUpload(e.target.files)}
                                        accept={accept}
                                    />
                                </UploadButton>
                        }
                    </>
                }

            </Stack>
        </div>
    )
}