
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Password, TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext, FirebaseContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, Message, ValidateEmail } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ForgetPassword from "./forgetPassword";
import { silentOTP } from "./silentOTP";
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  forgetPassword: {
    float: "right",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "12px",
    color: theme.palette.primary.main,
    marginTop: 7,
    cursor: "pointer",
  },
  forgetPasswordTextStyle: {
    textAlign: "right",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "12px",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",

  },
  contentBottom: {
    padding: "8px",
  },
  dialogStyle: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  pa: {
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  version: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "12px",
    color: "#091B29"
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: (props) => (props.multiline ? "unset" : 50),
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (props) => (props.multiline ? "0px" : "11px 14px"),
    },
    ":-webkit-autofill": {
      WebkitBoxShadow: "none !important",
      backgroundColor: "red !important",
    },
  },
}));


const LoginDetails = ({ t }) => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [decoded, setDecoded] = React.useState({
    decode: null,
    isDecode: false
  });
  const InitialState = {
    email: decoded?.isDecode > 0
      ? decoded?.decode?.email_id
      : "",
    password: "",
    error: {
      email: "",
      password: "",
    },
  };
  const [login, setLogin] = React.useState({ ...InitialState });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const search = useLocation().search;
  const firebaseContext = React.useContext(FirebaseContext);
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );

  const [showDrawer, setShowDrawer] = React.useState(false);
  const [disable, setDisable] = React.useState(false)

  React.useEffect(() => {

    if (localStorage.getItem("device_token")) {
    } else {
      firebaseContext.requestPermission();
    }
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded({
        decode: jwt_decode(welcomePageAuthToken),
        isDecode: true
      })
      const email_id = jwt_decode(welcomePageAuthToken)
      setLogin({ ...login, email: email_id?.email_id })
    } else if (authToken !== null && authToken !== "") {
      history.push(Routes.dashboard);
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [history]);

  const updateState = (key, value) => {
    if (key === "email") {
      ValidateEmail(value);
    }
    let error = login.error;
    error[key] = "";
    setLogin({ ...login, [key]: value, error });
  };

  const isIamValideToLogin = () => {
    let isValid = true;
    let error = login.error;
    // if (decoded != null && decoded.email_id.length === 0) {
    //   isValid = false;
    //   error.email = Message.English.requiredMessage("Email");
    // }
    if (login?.email?.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email");
    }
    if (login?.password?.length === 0) {
      isValid = false;
      error.password = Message.English.requiredMessage("Password");
    }
    // if (
    //   ((decoded != null && decoded.email_id !== "") || login.email !== "") &&
    //   login.password !== ""
    // ) {
    //   isValid = true;
    // }
    setLogin({ ...login, error });
    return isValid;
  };
  // eslint-disable-next-line
  const onSendLoginBtnClicked = (e) => {
    e.preventDefault();
    let data = {
      //tenantId: "8e9c54d2-45ef-4fac-bb37-6bc7d35bb199",
      password: login.password,
    };
    if (isIamValideToLogin()) {
      // Check phone number

      //eslint-disable-next-line
      var isphone = /^\d+$/.test(login.email)

      // Check email id

      //eslint-disable-next-line
      var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        login.email
      );


      if (isphone) {
        data["mobile_no"] = login.email;
      } else if (email) {
        data["email_id"] = login.email;
      } else {
        data["ucid"] = login.email;
      }

      NetworkCall(
        `${config.auth_api_url}/auth/login`,
        NetWorkCallMethods.post,
        data,
        null,
        false,
        false
      )
        .then((response) => {
          if (response?.status === 200) {
            const email_ID = jwt_decode(response?.data?.token)
            if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
              history.push(
                Routes.otpPage + "?welcomeAuthToken=" + welcomePageAuthToken
              );

            } else {
              history.push(
                Routes.otpPage +
                "?data=" +
                email_ID?.email_id
              );
            }

            localStorage.setItem(LocalStorageKeys.authToken, response.data.token)
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("InvalidCredientials"),
          });
        });
    } else {
      return false;
    }
  };

  const onDrawerClose = () => {
    setShowDrawer(false);
  };

  return (
    <div>
      <Container maxWidth="sm" style={{ padding: 0 }}>
        <form
          onSubmit={(e) => {
            setDisable(true)
            silentOTP(e, login, alert, history, isIamValideToLogin, setDisable, t, welcomePageAuthToken)
          }}
          autoComplete="on">
          <Grid container>
            <Grid
              item
              className={classes.content}
              style={{ height: size.height > 620 ? size.height - 94 : 527 }}
            >
              <Grid item xs={12} className={classes.backgroundImage} />
              <Grid style={{ padding: "0px 16px 0px 16px" }}>
                <Box height={"24px"} />
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent={"space-between"}
                  alignContent={"center"}
                >
                  {/* {logo?.logo_url && (
                    <img
                      src={logo?.logo_url}
                      alt="logo"
                      className={classes.img}
                    />
                  )} */}

                  <img
                    src="/images/rafallogo.svg"
                    alt="logo"
                    className={classes.img}
                  />
                  <Typography className={classes.version}>{"Ver " + localStorage.getItem(LocalStorageKeys.version)}</Typography>
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Typography className={classes.text}>{t("Login")}</Typography>
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <TextBox
                    type="text"
                    value={login?.email ?? ""}
                    onChange={(e) => updateState("email", e.target.value)}
                    label={Message.English.login.email.label}
                    placeholder={Message.English.login.email.placeholder}
                    isError={login.error.email.length > 0}
                    errorMessage={login.error.email}
                    isReadonly={decoded && decoded.email_id ? true : false}
                    isRequired
                    className={classes.textbox}
                  />
                </Grid>
                <Box height={"16px"} />
                <Grid item xs={12}>
                  <Password
                    value={login?.password}
                    onChange={(e) => updateState("password", e.target.value)}
                    label={Message.English.login.password.label}
                    placeholder={Message.English.login.password.placeholder}
                    isError={login.error.password.length > 0}
                    errorMessage={login.error.password}
                    isRequired
                  />
                  <Box height={"20px"} />
                  <Grid
                    item
                    xs={12}
                    justifyContent={"end"}
                    alignItems={"center"}
                  >
                    <Typography
                      onClick={() => setShowDrawer(true)}
                      className={classes.forgetPasswordTextStyle}
                    >
                      {t("Forgetpassword")} ?
                    </Typography>
                  </Grid>
                  {/* <span
                  className={classes.forgetPassword}
                  onClick={() => setShowDrawer(true)}
                >
                  Forget password ?
                </span> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ padding: "16px 16px 25px 16px" }}>
              <Button
                fullWidth
                disabled={disable}
                variant="contained"
                className={classes.btn}
                // onClick={onSendLoginBtnClicked}
                type="submit"
              >
                <Typography className={classes.buttonTextStyle}>
                  {t("LogIn")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* Drawer */}
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            open={showDrawer}
            anchor="bottom"
            onClose={() => setShowDrawer(false)}
          >
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {t("ForgetPassword")}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => setShowDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.contentBottom}>
              <ForgetPassword onDrawerClose={onDrawerClose} />
            </div>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
            className={classes.dialogStyle}
          >
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {t("ForgetPassword")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setShowDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.contentBottom}>


              <ForgetPassword onDrawerClose={onDrawerClose} />
            </div>

          </Dialog>
        </Hidden>
      </Container>
    </div>
  );
};
export default withNamespaces("welcomePage")(LoginDetails)