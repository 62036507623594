import React from "react";
import General from './general';

class GeneralParent extends React.Component {
    render() {
        return <General />;
    }
}

export default GeneralParent;
