import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  DocumentViewer,
  Filter,
  HomepagePayCard,
  InvoiceList,
  PayCard,
  TitleBar
} from "../../components";
import { InvoiceSimmer } from "../../components/simmer/invoiceSimmer";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  DASHBOARDWIDGET,
  FILTERINVOICE,
  GET_AGREEMENT_PDF,
  GETALLINVOICE,
  INVOICE
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import InvoicePaymentScreen from "./invoicePaymentScreen";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
    // overflow: "hidden",
  },
  widgetsCard: {
    backgroundColor: "#FBE1BA",
    boxShadow: "0px 2px 6px #c1c1c1",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  text: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
  },
  amount: {
    fontSize: "20px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.palette.secondary.main,
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  bottomScreen: {
    backgroundColor: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 8px 24px #1C1C1C40",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 26px",
    },
  },
  paynow: {
    backgroundColor: "#091B29",
    color: "white",
    borderRadius: "4px",
    fontSize: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    paddingTop: "2px",
    paddingRight: "3px",
    paddingLeft: "4px",
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center",
    cursor: "pointer",
  },
  arrow: {
    marginLeft: "8px",

    padding: "8px",
    backgroundColor: "#55BC7E",
    borderRadius: "4px",
    cursor: "pointer",
  },
  btn: {
    borderRadius: "4px",
    width: "93px",
    height: "40px",
  },
  content: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    boxShadow: "0px 3px 24px #00000014",
    padding: "16px 16px 0px 16px",
    backgroundColor: "white",
  },
  bottombar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    left: "0px",
    right: "0px",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",

  },
  contentBottom: {
    padding: "12px",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      width: "-webkit-fill-available",
    },
    "& .MuiContainer-root": {
      padding: "0px",
      width: "-webkit-fill-available",
    },
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "40px",
      paddingRight: "40px",
    },
    overflow: "hidden",
    backgroundColor: "transparent",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  cardsub: {
    color: "#703D00",
    fontSize: "12px",
  },
  cardsub2: {
    color: theme.typography.color.primary,
    fontSize: "10px",
  },
  cardtitle: {
    color: theme.typography.color.primary,
    fontSize: "20px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  dashboardCard: {
    padding: "12px",
    //backgroundImage: `url("/images/cardbgdashboard.png")`,
    //backgroundRepeat: "no-repeat",
    //backgroundSize: "100% 100%",
    backgroundColor: "#FBE1BA",
    boxShadow: "0px 2px 6px #c1c1c1",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
  },
  paymant: {
    backgroundColor: theme.palette.dashboard.primary,
    padding: "12px",
  },
}));

const InvoiceScreen = (props) => {
  const { t } = props
  const classes = useStyles();
  const defaultData = {};
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { state } = useLocation();
  const [drawer, setDrawer] = React.useState(null);
  const [invoicedata, setInvoicedata] = React.useState([]);
  const [due, setDue] = React.useState(0);
  const [dues, setDues] = React.useState(0);
  const [agreementli, setagreementli] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [activeinvoice, setActiveinvoice] = React.useState(0);
  const [invoicepdf, setInvoicepdf] = React.useState([]);
  const [showpdf, setShowpdf] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState(defaultData);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [payall, setPayall] = React.useState(true);
  const searchURL = useLocation().search;
  const jwt = new URLSearchParams(searchURL).get("inv_data");
  const token = jwt && jwt_decode(jwt)
  const invoiceid = jwt ? token?.id : new URLSearchParams(searchURL).get("id")
  const agreementid = jwt ? token?.agreement_id : new URLSearchParams(searchURL).get("agreementid")
  const totalamount = jwt ? token?.amount : new URLSearchParams(searchURL).get("amt")
  const urlType = jwt ? token?.type : new URLSearchParams(searchURL).get("type")
  const openSuccess = () => {
    history.push({
      pathname: Routes.success,
      state: {
        msg: "Payment Successful",
      },
    });
  };
  moment.defaultFormat = "YYYY-MM-DD";
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = yyyy + "-" + mm + "-" + dd;
  const history = useHistory();
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const goBack = () => {
    window.history.go(-1);
  };

  const filterData = [
    {
      id: "1",
      title: props?.t("agreement"),
      key: "agreement",
      filterType: "CHECKBOX",

      values: agreementli,
    },
    {
      id: "2",
      title: props?.t("status"),
      key: "status",
      filterType: "CHECKBOX",
      values: [
        {
          label: props?.t("paid"),
          value: "no-due",
          key: "status",
        },
        {
          label: props?.t("pending"),
          value: "due",
          key: "status",
        },
      ],
    },
  ];
  //Get All Invoice if coming from dashboard
  const checkallinvoice = (agreement_id = [agreementid]) => {
    const params = {
      query: (state?.outstanding || invoiceid) ? DASHBOARDWIDGET : GETALLINVOICE,
      variables: {
        id: state?.agreemntlist ? state?.agreemntlist?.map((val) => val.id) : agreement_id,
      },
    };

    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        const fetch = response?.data?.data?.invoice?.map((val, index) => {
          let _d;
          try {
            _d = {
              description: val?.description,
              id: val?.id,
              invoice_due_amount: val?.invoice_due_amount,
              invoice_no: val?.invoice_no,
              payment_due_date: val?.payment_due_date,
              payment_status: val?.payment_status,
              active: false,
              currency_master: {
                name: val?.currency_master?.name,
                symbol: val?.currency_master?.symbol,
              },
              invoice_type: val?.invoice_master?.type,
              company_id: val?.company?.id,
              currency_id: val?.company?.currency_id,
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong"),
            });
          }
          return _d;
        });
        setInvoicedata(fetch);
        if (invoiceid && agreementid) {
          handlepay(true, { id: invoiceid }, fetch)
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  //Get All Invoice if coming from agreement
  const getInvoiceByallagreementId = (id = props?.id) => {
    const params = {
      query: INVOICE,
      variables: {
        id: id,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        const fetch = response?.data?.data?.invoice?.map((val, index) => {
          let _d;
          try {
            _d = {
              description: val?.description,
              id: val?.id,
              invoice_due_amount: val?.invoice_due_amount,
              invoice_no: val?.invoice_no,
              payment_due_date: val?.payment_due_date,
              payment_status: val?.payment_status,
              active: false,
              currency_master: {
                name: val?.currency_master?.name,
                symbol: val?.currency_master?.symbol,
              },
              invoice_type: val?.invoice_master?.type,
              company_id: val?.company?.id,
              currency_id: val?.company?.currency_id,
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong"),
            });
          }
          return _d;
        });
        setInvoicedata(fetch);
        setLoading(false);
      })
      .catch((error) => {
        console.log("errror",error)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  // Outstanding amount
  const calculateoutstandingamount = () => {
    let text2 = 0;
    if (invoicedata.length > 0) {
      for (let i = 0; i < invoicedata.length; i++) {
        if (
          moment(
            moment(invoicedata[i].payment_due_date)
              .tz(moment.tz.guess())
              .format()
          ).isBefore(today)
        ) {
          if (invoicedata[i].payment_status === "due") {
            text2 = text2 + invoicedata[i].invoice_due_amount;
          }
        }
      }
    }

    setDues(text2);
  };
  const calculatedueamount = () => {
    let text = 0;
    if (invoicedata.length > 0) {
      for (let i = 0; i < invoicedata.length; i++) {
        if (invoicedata[i].payment_status === "due") {
          text = text + invoicedata[i].invoice_due_amount;
        }
      }
    }

    setDue(text);
  };
  //Filter Apply
  const applyFilter = (value) => {
    if (value.agreement) {
      if (value.status) {
        const params = {
          query: FILTERINVOICE(value.agreement, value.status).loc.source.body,
          variables: {},
        };
        NetworkCall(
          `${config.graphql_url}`,
          NetWorkCallMethods.post,
          params,
          null,
          true,
          false
        )
          .then((response) => {
            setInvoicedata(response?.data?.data?.invoice);
            setDrawer(false);
          })
          .catch((error) => {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong"),
            });
          });
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please Select one status",
        });
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Select one agreement",
      });
    }
    setSelectedFilter(value);

  };
  //Initial Start
  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      if (props.agreement) {
        getInvoiceByallagreementId();
      } else {
        if (state || (invoiceid && agreementid) || jwt) {
          checkallinvoice();
        } else {
          history.push(Routes.dashboard);
        }
      }
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, [props.id, invoiceid]);

  //Calculate total Outstanding amount
  React.useEffect(() => {
    calculateoutstandingamount();
    calculatedueamount();
    // eslint-disable-next-line
  }, [invoicedata]);
  //Get Agreement Filter Data
  React.useEffect(() => {
    if (props.agreement) {
      const fetchAgreemntlist = props?.agreemntlist?.map((val, index) => {
        let _d;
        try {
          _d = {
            value: val.agreement_no,
            label: val.agreement_no,
            key: "agreement",
            all: "true" + index,
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }
        return _d;
      });
      //setdefaukt

      let tempAgreementi = [];
      tempAgreementi = agreementli.concat(fetchAgreemntlist);
      setagreementli(tempAgreementi);
      defaultData.agreement = [props.agreement_no];
      defaultData.status = ["no-due", "due"];
    } else {
      const fetchAgreemntlist = state?.agreemntlist?.map((val, index) => {
        let _d;
        try {
          _d = {
            value: val.agreement_no,
            label: val.agreement_no,
            key: "agreement",
            all: "true" + index,
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }
        return _d;
      });
      let tempAgreementi = [];
      tempAgreementi = agreementli.concat(fetchAgreemntlist);
      setagreementli(tempAgreementi);

      defaultData.agreement = state?.agreemntlist?.map(
        (val) => val.agreement_no
      );
      if (state?.outstanding) {
        defaultData.status = ["due"];
      } else {
        defaultData.status = ["no-due", "due"];
      }
    }
    // eslint-disable-next-line
  }, []);

  //Delete Invoice
  const handleDelete = (data, e) => {
    const edited = invoicedata.map((val) =>
      val.id === data.id
        ? {
          description: data.description,
          id: data.id,
          invoice_due_amount: data.invoice_due_amount,
          invoice_no: data.invoice_no,
          payment_due_date: data.payment_due_date,
          payment_status: data.payment_status,
          active: e,
          currency_master: {
            name: data.currency_master.name,
            symbol: data.currency_master.symbol,
          },
          invoice_type: val.invoice_type,
          company_id: val?.company_id,
          currency_id: val?.currency_id,
        }
        : val
    );
    setInvoicedata(edited);
    setTotal(total - data.invoice_due_amount);
    setActiveinvoice(activeinvoice - 1);
    if (total === 0) {
      setShowDrawer(false);
    }
  };
  //Edit Invoice
  const handlepay = (e, data, fetch) => {
    const invoice_data = fetch ? fetch : invoicedata
    const edited = invoice_data.map((val) =>
      val.id === data.id
        ? {
          description: val?.description,
          id: val?.id,
          invoice_due_amount: val?.invoice_due_amount,
          invoice_no: val?.invoice_no,
          payment_due_date: val?.payment_due_date,
          payment_status: val?.payment_status,
          active: e,
          currency_master: {
            name: val?.currency_master.name,
            symbol: val?.currency_master.symbol,
          },
          invoice_type: val.invoice_type,
          company_id: val?.company_id,
          currency_id: val?.currency_id,
        }
        : val
    );
    setInvoicedata(edited);

    if (e) {
      if (invoiceid && agreementid) {
        setTotal(totalamount)
        setActiveinvoice(1)
        setShowDrawer(!showDrawer)
        if (urlType !== "Invoice Due") {
          viewinvoice(invoiceid)
        }
      } else {
        setTotal(total + data?.invoice_due_amount);
        setActiveinvoice(activeinvoice + 1);
      }
    } else if (!e) {
      setTotal(total - data?.invoice_due_amount);
      setActiveinvoice(activeinvoice - 1);
    }
  };
  //View Invoice
  const viewinvoice = (data) => {
    const params = {
      query: GET_AGREEMENT_PDF,
      variables: {
        id: data,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setInvoicepdf(response?.data?.data);
        setShowpdf(true);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  let amount = 0;
  let totalInvoice = 0;
  const payNow = async (data) => {
    if (payall) {
      const edited = await invoicedata?.map((val, index) => {
        let _d;
        try {
          if (val.payment_status === "due") {
            amount += val.invoice_due_amount;
            totalInvoice += 1;
            setTotal(amount);
            setActiveinvoice(totalInvoice);
            _d = {
              description: val.description,
              id: val.id,
              invoice_due_amount: val.invoice_due_amount,
              invoice_no: val.invoice_no,
              payment_due_date: val.payment_due_date,
              payment_status: val.payment_status,
              active: true,
              currency_master: {
                name: val.currency_master.name,
                symbol: val.currency_master.symbol,
              },
              invoice_type: val.invoice_type,
              company_id: val?.company_id,
              currency_id: val?.currency_id,
            };
          } else {
            return val;
          }
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }
        return _d;
      });
      setInvoicedata(edited);
      setShowDrawer(true);
    } else {
      setTotal(0);
      setActiveinvoice(0);
      const edited = invoicedata?.map((val, index) => {
        let _d;
        try {
          if (val.payment_status === "due") {
            _d = {
              description: val.description,
              id: val.id,
              invoice_due_amount: val.invoice_due_amount,
              invoice_no: val.invoice_no,
              payment_due_date: val.payment_due_date,
              payment_status: val.payment_status,
              active: false,
              currency_master: {
                name: val.currency_master.name,
                symbol: val.currency_master.symbol,
              },
              invoice_type: val.invoice_type,
            };
          } else {
            return val;
          }
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }
        return _d;
      });
      setInvoicedata(edited);
    }
  };
  return (
    <div className={classes.root}>
      {loading ? (
        <InvoiceSimmer />
      ) : (
        <>
          <Container maxWidth="sm" style={{ padding: "0px" }}>
            <Grid
              className={classes.screen}
              style={{
                // height: props.agreement ? "auto" : size?.height,
                backgroundColor: "white",
              }}
              item
              xs={12}
            >
              <Grid className={classes.topNavBarStyle}>
                {props.titlebar ? (
                  ""
                ) : (
                  <TitleBar text="Invoice" goBack={goBack} />
                )}
              </Grid>
              <Grid
                className={classes.screenPadding}
                style={{ height: size?.height - 60, overflow: "auto" }}
              >
                <div style={{ padding: "12px" }}>
                  <HomepagePayCard
                    Outstanding_amount={due}
                    amount={dues}
                    currency_symbol={invoicedata?.[0]?.currency_master?.symbol}
                    onClick={() => {
                      setPayall(!payall);
                      payNow("all");
                    }}
                  />
                </div>
                <div className={classes.content}>
                  <Box display="flex" alignItems="center" marginBottom="10px">
                    {/* Total Invoice */}
                    <Box flexGrow={1}>
                      <Typography className={classes.total}>
                        {props?.t("invoices")}
                        {"(" +
                          (invoicedata.length < 10
                            ? "0" + invoicedata.length
                            : invoicedata.length) +
                          ")"}
                      </Typography>
                    </Box>
                    <Box>
                      {/* Filter*/}
                      <IconButton
                        className={classes.filter}
                        onClick={draweropen}
                      >
                        {selectedFilter?.agreement?.length ||
                          selectedFilter?.status?.length ? (
                          <Badge variant="dot" color="primary">
                            <img src="/images/icons8-funnel.svg" alt="filter" />
                          </Badge>
                        ) : (
                          <img src="/images/icons8-funnel.svg" alt="filter" />
                        )}
                      </IconButton>
                    </Box>
                  </Box>
                  <Grid container>
                    {invoicedata?.map((item) => {
                      return (
                        <>
                          <React.Fragment key={item.id}>
                            <Grid item xs={12}>
                              <InvoiceList
                                invoiceData={item}
                                onCheck={handlepay}
                                viewinvoice={viewinvoice}
                              />
                            </Grid>
                          </React.Fragment>
                        </>
                      );
                    })}
                    <Hidden smUp>
                      <br />
                      <br />
                      <br />
                      <br />
                    </Hidden>
                  </Grid>

                  {total > 0 && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        className={classes.containers}
                      >
                        <Paper
                          sx={{
                            position: "fixed",
                            bottom: props.agreement ? "65px" : "0px",
                            left: 0,
                            right: 0,
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            padding: "12px",
                          }}
                          elevation={3}
                        >
                          <Container maxWidth="sm" sx={{ padding: 0 }}>
                            <div className={classes.bottomScreen}>
                              <PayCard
                                currencySymbol={
                                  invoicedata?.[0]?.currency_master.symbol
                                }
                                currency_id={invoicedata?.[0]?.currency_id}
                                type="invoice"
                                active={activeinvoice}
                                total={total}
                                handleOnClick={() => setShowDrawer(true)}
                              />
                            </div>
                          </Container>
                        </Paper>
                      </Grid>
                    </Grid>
                  )}

                  {/* Drawer */}
                  <Hidden smUp>
                    <Drawer
                      className={classes.drawer}
                      open={showDrawer && total > 0}
                      anchor="bottom"
                      onClose={() => setShowDrawer(false)}
                    >
                      <Container maxWidth="sm" >
                        <Box
                          display="flex"
                          p={1}
                          alignItems="center"
                          className={classes.titleroot}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                              {props?.t("payment")}
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => setShowDrawer(false)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <div className={classes.contentBottom}>

                          <InvoicePaymentScreen
                            total={total}
                            data={invoicedata}
                            onDelete={handleDelete}
                            viewinvoice={viewinvoice}
                            onPay={openSuccess}
                            activeinvoice={activeinvoice}
                          />
                        </div>
                      </Container>
                    </Drawer>
                  </Hidden>
                  <Hidden smDown>
                    <Dialog
                      fullWidth={true}
                      maxWidth="sm"
                      open={showDrawer && total !== 0}
                      className={classes.dialog}
                      sx={{ padding: "0px" }}
                    >
                      <Container
                        maxWidth="sm"
                        style={{
                          overflow: "hidden",
                          padding: "2px 8px 8px 8px",
                        }}
                      >
                        <Box
                          display="flex"
                          p={1}
                          alignItems="center"
                          className={classes.titleroot}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                              {props?.t("payment")}
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => setShowDrawer(false)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <div style={{ maxHeight: size?.height - 115 }}>
                          <InvoicePaymentScreen
                            total={total}
                            data={invoicedata}
                            onDelete={handleDelete}
                            viewinvoice={viewinvoice}
                            onPay={openSuccess}
                            activeinvoice={activeinvoice}
                          />
                        </div>
                      </Container>
                    </Dialog>
                  </Hidden>
                  {/* invoice */}
                  <Hidden smDown>
                    <Dialog
                      maxWidth="sm"
                      fullWidth
                      open={showpdf}
                      className={classes.dialog}
                      sx={{ padding: "0px" }}
                    >
                      <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                      >
                        <Box flexGrow={1}>
                          <Typography className={classes.bottomTitle}>
                            {props?.t("invoice")}
                          </Typography>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            onClick={() => setShowpdf(false)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      <div style={{ height: size?.height - 115 }}>
                        <DocumentViewer url={invoicepdf?.assets?.[0]?.url ?? invoicepdf?.invoice?.[0]?.asset_url} />
                      </div>
                    </Dialog>
                  </Hidden>
                  <Hidden smUp>
                    <Drawer
                      maxWidth="sm"
                      anchor="bottom"
                      fullWidth
                      open={showpdf}
                      className={classes.drawer}
                    >
                      <div>
                        <Box
                          display="flex"
                          p={1}
                          alignItems="center"
                          className={classes.titleroot}
                        >
                          <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                              {props?.t("invoice")}
                            </Typography>
                          </Box>
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => setShowpdf(false)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        </Box>
                        <div style={{ height: size?.height - 115 }}>
                          <DocumentViewer url={invoicepdf?.invoice?.[0]?.asset_url} />
                        </div>
                      </div>
                    </Drawer>
                  </Hidden>
                </div>
              </Grid>
            </Grid>

            {/* filter drawer */}
            <Hidden smDown>
              <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={drawer}
                onClose={drawerclose}
                className={classes.dialog}
              >
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Filter
                        filterData={filterData}
                        onGoBack={drawerclose}
                        selectedList={filterData[0]}
                        filters={{
                          agreement: selectedFilter.agreement,
                          status: selectedFilter.status,
                        }}
                        allFilter={true}
                        check={true}
                        onApply={applyFilter}
                        onClose={drawerclose}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.filterDrawer}
                docked
                open={drawer}
                onClose={drawerclose}
              >
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Filter
                        filterData={filterData}
                        onGoBack={drawerclose}
                        selectedList={filterData[0]}
                        filters={{
                          agreement: selectedFilter.agreement,
                          status: selectedFilter.status,
                        }}
                        allFilter={true}
                        onApply={applyFilter}
                        onClose={drawerclose}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Drawer>
            </Hidden>
          </Container>
        </>
      )}
    </div>
  );
};
export default withNamespaces("invoice")(InvoiceScreen);
