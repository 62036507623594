/* eslint-disable array-callback-return */
import {
  Box,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  // Tab,
  TextField,
  Typography
} from "@mui/material";

// import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { CarouselSliders, ServiceCard, TopNavBars } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { NetWorkCallMethods } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
// import PollsAndSurveyIcon from "../../assets/pollsAndSurvey";
// import TownshipTabIcon from "../../assets/townshipTab";
import YourTownshipIcon from "../../assets/yourTownship";
import ContactMeIcon from "../../assets/contactMe";
import PlaceOfInterestIcon from "../../assets/placeOfInterest";
import CommitteeIcon from "../../assets/commiteeIcon";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dashboard.primary,
    padding: "0px",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "4px 8px 10px 12px",
    backgroundColor: theme.palette.background.secondary,
    marginTop: "8px"
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    // borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      // borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
    // backgroundColor: "white",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "4px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },

  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  info: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
    textAlign: "center",
  },
  title: {
    padding: "8px 14px",
    // background: "#5078E1",
    color: "#071741",
    display: "flex",
    alignItems: "center",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "4px",
    backgroundColor: "#071741",
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  titles: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().extraBold,
    margin: "2px 8px 8px 8px",
  },
  box: {
    padding: "16px 0px"
  }
}));

const Service = ({ t }) => {
  const size = useWindowDimensions();
  const [banners, setBanners] = React.useState([]);
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const [search, setSearch] = React.useState("");
  const logo = JSON.parse(localStorage.getItem("accessType"))?.filter(
    (val) => val?.name === "Tenant"
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const getBanners = () => {
    let data = {
      type: value === "1" ? "Community" : "Services",
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let bannerss = response.data.data.map((item) => item?.assets).flat();
      setBanners(bannerss);
    });
  };

  const homeCard = [
    {
      id: "1",
      img: "/images/homeCleanIMG.svg",
      title: "Home",
      sub: "Cleaning",
      link: "/service",
      filter: "Cleaning",
      noavailable: true,
      borderColor: "#8C20E5"

    },
    {
      id: "2",
      img: "/images/homeSanitization.svg",
      title: "Home",
      sub: "Sanitization",
      link: "/service",
      noavailable: true,
      filter: "Home Sanitization",
      borderColor: "#F7A72F"

    },
    {
      id: "3",
      img: "/images/bathrommCleanIMG.svg",
      noavailable: true,
      title: "Bathroom",
      sub: "Cleaning",
      link: "/service",
      filter: "Bathroom Cleaning",
      borderColor: "#50E6FF"

    },
    {
      id: "4",
      img: "/images/tempIMG.svg",
      title: "Home ",
      noavailable: true,
      sub: "Repair",
      link: "/service",
      filter: "Home Repair",
      borderColor: "#199BE2"

    },
    {
      id: "5",
      img: "/images/homePaintingIMG.svg",
      noavailable: true,
      title: "Home",
      sub: "Painting",
      link: "/service",
      filter: "Home Painting",
      borderColor: "#8C20E5"

    },
  ];

  const requsetData = [
    {
      id: "1",
      img: "/images/visiterRequset.svg",
      title: t("visitor"),
      link: "/worker_gate_pass",

      search: "Visitor",
      borderColor: "#F5CF64"
    },
    {
      id: "2",
      img: "/images/worketIMG.svg",
      title: t("worker"),
      link: "/worker_gate_pass",

      search: "Worker",
      borderColor: "#EE6B01"

    },
    {
      id: "3",
      img: "/images/parkingLink.svg",
      title: t("parking"),
      link: "/parking_pass",

      search: "Parking",
      borderColor: "#50E6FF"

    },
    {
      id: "4",
      img: "/images/domesticHelperIMG.svg",
      title: t("domestic"),
      link: "/domestic_help",
      sub: t("help"),
      search: "Domestic Help",
      borderColor: "#707B85"

    },
    {
      id: "5",
      img: "/images/aminitiesAgree.svg",
      title: t("amenities"),
      noavailable: true,
      noService: false,
      link: "/amenitiesBookingNew",
      sub: t("booking"),
      search: "Amenities Booking",
      borderColor: "#C966E9"

    },
    {
      id: "6",
      img: "/images/deliveryCollectionIMG.svg",
      title: t("delivery"),
      link: "/delivery",
      sub: t("collection"),
      search: "Delivery Collection",
      borderColor: "#F5CF64"

    },
  ];
  const serviceData = [
    {
      id: "1",
      img: "/images/discussion.svg",
      link: "/community_discussion",
      title: t("discussion"),
      sub: t("group"),
      search: "Discussion Group",
      borderColor: "#707B85"

    },
    {
      id: "2",
      img: "/images/neighborhood.svg",
      link: "/my_neighbours",
      title: t("my"),
      sub: t("neighbours"),
      search: "My Neighbours",
      borderColor: "#707B85"

    },
    {
      id: "3",
      img: "/images/usefulContactIMG.svg",
      link: "/contactdetails",
      title: t("my"),
      sub: t("contacts"),
      search: "My Contacts",
      borderColor: "#199BE2"

    },
    {
      id: "4",
      img: "/images/communityResidentIMG.svg",
      title: t("management"),
      sub: t("team"),
      link: "/managment",
      search: "Management team",
      borderColor: "#20AFB5"

    },
    {
      id: "5",
      img: "/images/announcementIMG.svg",
      title: t("announcement"),
      link: "/announcement",

      search: "Announcement",
      borderColor: "#199BE2"

    },
    // {
    //   id: "6",
    //   img: "/images/feedBackIMG.svg",
    //   title: "Feedback",
    //   link: "/service",
    //   noavailable: true,
    //   search: "Feedback",
    // },
    {
      id: "7",
      img: "/images/emergencyNumberIMG.svg",
      title: t("emergency"),
      link: "/emergency_contact",
      sub: t("number"),
      search: "Emergency Number",
      borderColor: "#2881E7"

    },
    // {
    //   id: "8",
    //   img: "/images/pollsIMG.svg",
    //   title: "Polls & ",
    //   link: "/service",
    //   sub: " Surveys",
    //   noavailable: true,
    //   search: "Polls & Surveys",
    // },

    // {
    //   id: "9",
    //   img: "/images/garage.svg",
    //   title: t("parking"),
    //   link: "/service",
    //   sub: t("alarm"),
    //   search: "Parking Alarm",
    //   noavailable: true,
    //   borderColor: "#2881E7"

    // },
    // {
    //   id: "10",
    //   img: "/images/reception.svg",
    //   title: t("walk_ins"),
    //   link: "/service",
    //   search: "Walk-ins",
    //   noavailable: true,
    //   borderColor: "#F5CF64",
    //   noService: true
    // },
  ];

  // essentials data
  const EssentialsData = [
    {
      id: "1",
      img: "/images/carRentalIMG.svg",
      title: "Car",
      sub: "Rental",
      link: "/service",
      filter: "Car Rental",
      noavailable: true,
      borderColor: "#388FE2"

    },
    {
      id: "2",
      img: "/images/postalResitentIMG.svg",
      title: "Postal",
      sub: " Assistance",
      link: "/service",
      filter: "Postal Assistance",
      noavailable: true,
      borderColor: "#007AD9"

    },
    {
      id: "3",
      img: "/images/mediaclResidentIMG.svg",
      title: "Medical",
      sub: "Supplies",
      link: "/service",
      filter: "Medical Supplies",
      noavailable: true,
      borderColor: "#D4943B"

    },
    {
      id: "4",
      img: "/images/pastIMG.svg",
      title: "Pest",
      sub: "Control",
      link: "/service",
      filter: "Pest Control",
      noavailable: true,
      borderColor: "#F09600"

    },
    {
      id: "5",
      img: "/images/packerIMG.svg",
      title: "Packer &",
      sub: "Movers",
      link: "/service",
      noavailable: true,
      filter: "Packer Movers",
      borderColor: "#FAD500"

    },
    {
      id: "6",
      img: "/images/legalIMG.svg",
      noavailable: true,
      title: "Legal",
      sub: "Assistance",
      link: "/service",
      filter: "Legal Assistance",
      borderColor: "#50E6FF"

    },
    {
      id: "7",
      img: "/images/carWashIMG.svg",
      title: "Car",
      sub: "Wash",
      link: "/service",
      noavailable: true,
      filter: "Car Wash",
      borderColor: "#388FE2"

    },
  ];

  //homeproduct data
  const Homeproduct = [
    {
      id: "1",
      img: "/images/sweetIMG.svg",
      title: "Sweets &",
      link: "/service",
      sub: "Savories",
      search: "Sweets Savories",
      noavailable: true,
      borderColor: "#F09600"

    },
    {
      id: "2",
      img: "/images/orgaincIMG.svg",
      title: "Organic",
      link: "/service",
      sub: "Products",
      search: "Organic Products",
      noavailable: true,
      borderColor: "#FAD500"

    },
    {
      id: "3",
      img: "/images/foodIMG.svg",
      title: "Food",
      noavailable: true,
      link: "/service",
      sub: "Supplies",
      search: "Food Supplies",
      borderColor: "#C966E9"

    },
  ];

  //survery card data
  // const surveyData = [
  //   {
  //     name: t("polls_and_survey"),
  //     type: "Marketplace",
  //     icon: <PollsAndSurveyIcon />
  //   },
  // ]
  //township data tab 3
  const TownshipData = [
    {
      id: "1",
      icon: <YourTownshipIcon />,
      title: "Your",
      sub: "Township",
      link: "/service",
      filter: "Your Township",
      noavailable: true,
      borderColor: "#388FE2"

    },
    {
      id: "2",
      img: "/images/announcementIMG.svg",
      title: "Public",
      sub: "Announcements",
      link: "/service",
      filter: "Public Announcements",
      noavailable: true,
      borderColor: "#199BE2"

    },
    {
      id: "3",
      icon: <ContactMeIcon />,
      title: "Contact",
      sub: "Township",
      link: "/service",
      filter: "Contact Township",
      noavailable: true,
      borderColor: "#388FE2"

    },
    {
      id: "4",
      img: "/images/aminitiesAgree.svg",
      title: "Public",
      sub: "Amenities",
      link: "/service",
      filter: "Public Amenities ",
      noavailable: true,
      borderColor: "#C966E9"

    },
    {
      id: "5",
      icon: <PlaceOfInterestIcon />,
      title: "Places Of",
      sub: "Interest",
      link: "/service",
      noavailable: true,
      filter: "Places Of Interest",
      borderColor: "#388FE2"

    },
    // {
    //   id: "6",
    //   img: "/images/legalIMG.svg",
    //   noavailable: true,
    //   title: "Enroll",
    //   sub: "Residents",
    //   link: "/service",
    //   filter: "Enroll Residents",
    //   borderColor: "#50E6FF"

    // },
    {
      id: "7",
      icon: <CommitteeIcon />,
      title: "Township",
      sub: "Committee",
      link: "/service",
      noavailable: true,
      filter: "Township Committee",
      borderColor: "#388FE2"

    },
  ];

  React.useEffect(() => {
    getBanners();
    // eslint-disable-next-line
  }, [value]);
  return (
    <Container className={classes.root} maxWidth="sm">
      <TopNavBars />
      {/* Tabs section */}
      {/* <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="none"
        textColor="secondary"
        className={classes.tabroot}
        variant="scrollable"
        visibleScrollbar={false}
        scrollButtons={false}
      >
        <Tab
          className={value === "1" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span
              className={value === "1" ? classes.titleselect : classes.title}
            >
              {value === "1" && <img src="/images/Group 93829.svg" alt=" " />}
              &nbsp;{t("Community")}
            </span>
          }
          value="1"
        />
        <Tab
          className={value === "2" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span
              className={value === "2" ? classes.titleselect : classes.title}
            >
              {value === "2" && (
                <img
                  src="/images/selectedclean.svg"
                  alt=" "
                  style={{ marginTop: "-4px" }}
                />
              )}
              &nbsp;&nbsp;{t("Marketplace")}
            </span>
          }
          value="2"
        />
        <Tab
          className={value === "3" ? classes.tabItemSelect : classes.tabItem}
          label={
            <span
              className={value === "3" ? classes.titleselect : classes.title}
            >
              {value === "3" && (
                <TownshipTabIcon />
              )}
              &nbsp;&nbsp;{t("Township")}
            </span>
          }
          value="3"
        />

      </Tabs> */}
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{ height: size?.height - 130, overflow: "auto" }}
      >
        <Grid item xs={12} sx={{ padding: "12px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                size="small"
                variant="outlined"

                placeholder={t("SearchServicesHere")}
                style={{ backgroundColor: "white", borderRadius: "4px" }}
                fullWidth
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className={classes.search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small">
                        <img src="/images/icons8-search (2).svg" alt="" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {value === "2" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.titles}>
                      {t("HomeCare")}
                    </Typography>
                  </Grid>
                  {homeCard
                    ?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })
                    ?.map((val) => {
                      return (
                        <Grid item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sx={{ margin: "12px 0px 0px 0px" }}>
                  <CarouselSliders autoScroll={true} data={banners} />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box height="4px" />
                    <Typography className={classes.titles}>
                      {t("Essentials")}
                    </Typography>
                  </Grid>
                  {EssentialsData?.filter((movie) => {
                    if (search === "") {
                      return movie;
                    } else if (
                      movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                    ) {
                      return movie;
                    }
                  })?.map((val) => {
                    return (
                      <Grid item xs={4}>
                        <ServiceCard data={val} noavailable />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box height="12px" />
                    <Typography className={classes.titles}>
                      {t("Homeproduct")}
                    </Typography>
                  </Grid>
                  {Homeproduct?.filter((movie) => {
                    if (search === "") {
                      return movie;
                    } else if (
                      movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                    ) {
                      return movie;
                    }
                  })?.map((val) => {
                    return (
                      <Grid item xs={4}>
                        <ServiceCard data={val} noavailable />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
            {value === "1" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className={classes.titles}>{t("community_services")}</Typography>
                  </Grid>
                  {requsetData
                    ?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })
                    ?.map((val) => {
                      return (
                        <Grid item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid item xs={12} sx={{ margin: "12px 0px 0px 0px" }}>
                  <CarouselSliders autoScroll={true} data={banners} />
                </Grid>
                <Grid container spacing={1}>
                  {/* <Grid item xs={12}>
                    <Box height="4px" />
                    <Typography className={classes.titles}>Services</Typography>
                  </Grid> */}
                  {serviceData
                    ?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })
                    ?.map((val) => {
                      return (
                        <Grid item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                </Grid>
                {/* <Box className={classes.box}>
                  {
                    surveyData?.map((item) => {
                      return (
                        <Box marginBottom={1.5}>
                          <SurveyCard
                            data={item}
                          />
                        </Box>
                      )
                    })
                  }
                </Box> */}

              </Grid>
            )}
            {value === "3" && (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {TownshipData
                    ?.filter((movie) => {
                      if (search === "") {
                        return movie;
                      } else if (
                        movie?.title?.toLowerCase()?.includes(search?.toLowerCase())
                      ) {
                        return movie;
                      }
                    })
                    ?.map((val) => {
                      return (
                        <Grid item xs={4}>
                          <ServiceCard data={val} />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withNamespaces("service")(Service)