import {useStyles} from "../styles";
import {Grid,Typography,Box} from "@mui/material";
import { FontFamilySwitch } from "../../../utils";


export const PaymentdetailCard=(props)=>{

    const {paymentDetails,onPdf=()=>false}=props;

    const classes=useStyles()
    return(
        <Grid className={classes.drawerBodyStyle}>
        <Grid container direction="column">
          <Grid
            className={classes.drawerBodyChildStyle}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
          >
            <Grid item direction={"column"}>
              <Typography
                style={{ fontSize: "14px", color: "#98A0AC" }}
              >
                {props?.t("transaction_amount")}
              </Typography>
              <Box height="4px" />
              <Typography
                style={{
                  fontSize: "20px",
                  color: "#091B29",
                  fontFamily: FontFamilySwitch().extraBold,
                }}
              >
                {paymentDetails?.amount_tagged &&
                 paymentDetails?.symbol +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(
                    Math.round(paymentDetails?.amount_tagged)
                  )}
              </Typography>
            </Grid>
            <Grid>
              <Box
                className={paymentDetails?.payment_response === "SUCCESS" ? classes.status : classes.status1}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontFamily: FontFamilySwitch().extraBold,
                  }}
                >
                  {paymentDetails?.payment_status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            style={{
              height: "4px",
              width: "100%",
              backgroundColor: "#F2F4F7",
            }}
          />
          <Grid
            className={classes.drawerBodyChildStyle}
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            direction={"row"}
          >
            <Grid container direction={"column"}>
              <Typography
                style={{
                  fontSize: "16px",
                  color: "#091B29",
                  fontFamily: FontFamilySwitch().bold,
                }}
              >
                {props?.t("transaction_details")}
              </Typography>
              <Box height="12px" />
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Grid>
                  <Typography
                    style={{ fontSize: "12px", color: "#4E5A6B" }}
                  >
                    {props?.t("ref_no")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#4E5A6B",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {paymentDetails?.settlement_no}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="16px" />
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Grid>
                  <Typography
                    style={{ fontSize: "12px", color: "#4E5A6B" }}
                  >
                    {props?.t("pay_mode")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#4E5A6B",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {paymentDetails?.payment_mode}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="16px" />
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Grid>
                  <Typography
                    style={{ fontSize: "12px", color: "#4E5A6B" }}
                  >
                    {props?.t("payment_through")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#4E5A6B",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {paymentDetails?.origin}
                  </Typography>
                </Grid>
              </Grid>
              <Box height="16px" />
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Grid>
                  <Typography
                    style={{ fontSize: "12px", color: "#4E5A6B" }}
                  >
                    {props?.t("pay_against")}
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    style={{
                      fontSize: "12px",
                      color: "#4E5A6B",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    1 {props?.t("invoice")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <>
              <Box
                style={{
                  height: "4px",
                  width: "100%",
                  backgroundColor: "#F2F4F7",
                }}
              />
              <Grid
                className={classes.drawerBodyChildStyle}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                direction={"row"}
              >
                <Grid item direction={"column"}>
                  <Typography
                    style={{ fontSize: "12px", color: "#4E5A6B" }}
                  >
                    {props?.t("payment_recipt")}
                  </Typography>
                  <Box height="4px" />
                  <Typography
                        style={{
                          fontSize: "12px",
                          color: "#091B29",
                          fontFamily: FontFamilySwitch().semiBold,
                        }}
                      >
                        {paymentDetails.reciepts_no}
                      </Typography>
                </Grid>
                <Grid onClick={onPdf}>
                  <Typography
                    style={{
                      fontSize: "14px",
                      color: "#5078E1",
                      fontFamily: FontFamilySwitch().semiBold,
                    }}
                  >
                    {props?.t("view_download")}
                  </Typography>
                </Grid>
              </Grid>
            </>
        </Grid>
      </Grid>
    )
}