/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import {
    Button,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { TitleBar } from "../../components";
// import Bicycle2 from "../../components/svg/bicycle2";
import Unit from "../../components/svg/unit";
import VehicleNo from "../../components/svg/vehicleNo";
import { AlertContext } from "../../contexts";
import { EDIT_PARKING_MASTER } from "../../graphql/mutations";
import { GET_VEHICLE, UPDATE_PARKING_SLOT } from "../../graphql/queries";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, vehicleTypeSVG } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ParkingSlotCard } from "./component/parkingSlotCard";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F5FA",
        padding: "0px",
    },
    input: {
        marginTop: "10px",
    },
    applybtn: {
        padding: "12px",
        borderRadius: "4px",
    },
    heading1: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "16px"
    },
    profession: {
        height: "44px",
        backgroundColor: "white",
        borderRadius: "4px",
        fontSize: "14px",
        color: "gray",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    bottomBarTitle: {
        fontSize: "18px",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "16px",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    helperImg: {
        height: "40px",
        borderRadius: theme.palette.borderRadius,
        width: "40px",
        objectFit: "cover",
    },
    helperRoot: {
        borderTop: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    name: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    pname: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #CED3DD",
        backgroundColor: "white",
        borderRadius: "4px",
        color: theme.typography.color.primary,
    },
    drap2: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderRadius: "4px",
        textAlign: "center",
        background: "#FFFFFF",
        border: "2px dashed #E4E8EE",
    },
    text2: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "14px"
    },
    Title: {
        color: "#091B29",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "16px"
    },
    label: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    value: {
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    value1: {
        fontSize: "20px",
        fontFamily: FontFamilySwitch().extraBold,
        color: "#091B29",
    },
    vehicleTypesvg:{
        height:"30px",
        width:"30px"
    }
}));

const ViewVehicle = ({ t }) => {
    const size = useWindowDimensions();
    const classes = useStyles();
    const theme = useTheme()
    const history = useHistory();
    const location = useLocation();
    const main = location?.state?.main ?? undefined;
    const [data, setdata] = React.useState(null);
    const [loading, setLoading] = React.useState(true);
    const client = useApolloClient();
    const alert = React.useContext(AlertContext);
    React.useEffect(() => {
        if (main) {
            getVehicle();
        } else {
            history.goBack(-1);
        }
        // eslint-disable-next-line
    }, []);

    const getVehicle = async () => {
        let result;
        result = await client.query({
            query: GET_VEHICLE(),
            fetchPolicy: 'network-only',
            variables: { parkingSlotID: main?.parkingSlotID },
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        });
        setdata(result?.data?.parking_slot?.[0]);
        setLoading(false);
    }

    const deleteVehicle = () => {
        const createdUpdatedBy = localStorage.getItem("userProfileId");
        const currentDateTime = new Date().toISOString();
        if (data?.vehicle_type === "BI-CYCLE" || data?.vehicle_type === "TWO-WHEELER") {
            client.mutate({
                mutation: UPDATE_PARKING_SLOT(),
                variables: {
                    parkingSlotID: main?.parkingSlotID,
                    payload: {
                        is_active: false,
                        updated_by: createdUpdatedBy,
                        updated_at: currentDateTime,
                    }
                }
            }).then((response) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.success,
                    msg: t("VehicleDeletedSuccessfully"),
                });
                history.replace({ pathname: Routes.viewVehicle, state: {} });
                history.goBack(-1);
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            })
        } else {
            client.mutate({
                mutation: EDIT_PARKING_MASTER(),
                variables: {
                    parkingMasterID: data?.parking_masterByID?.id,
                    payload: {
                        parking_status: "Vacant",
                        updated_by: createdUpdatedBy,
                        updated_at: currentDateTime
                    }
                }
            }).then((response) => {
                client.mutate({
                    mutation: UPDATE_PARKING_SLOT(),
                    variables: {
                        parkingSlotID: main?.parkingSlotID,
                        payload: {
                            company_id: null,
                            property_id: null,
                            agreement_unit_id: null,
                            vehicle_type: null,
                            number_plate: null,
                            updated_by: createdUpdatedBy,
                            updated_at: currentDateTime,
                            vehicle_image: null
                        }
                    }
                }).then((response) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.success,
                        msg: t("VehicleDeletedSuccessfully"),
                    });
                    history.replace({ pathname: Routes.viewVehicle, state: {} });
                    history.goBack(-1);
                }).catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                })
            }).catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            })
        }
    }

    return (<>
        {loading ? <></> : <Container className={classes.root} maxWidth="sm">
            <TitleBar
                text={(data?.vehicle_type === "BI-CYCLE" || data?.vehicle_type === "TWO-WHEELER") ?
                    data?.parking_areaByID?.vehicle_parking_area : data?.parking_masterByID?.parking_no}

                goBack={() => history.goBack(-1)}
            />
            <Grid container>
                <Grid
                    item
                    xs={12}
                    style={{ height: size?.height - 135, overflow: "overlay" }}>
                    <div style={{ margin: "16px", padding: "16px", background: "white", borderRadius: theme.palette.borderRadius, boxShadow: "0px 3px 30px #5C86CB2E" }}>
                        <Grid container direction={"column"}>
                            <Typography className={classes.Title}>Details</Typography>
                            <Box height={10} />
                            <img src={data?.vehicle_image} alt="" style={{ objectFit: "cover", borderRadius: theme.palette.borderRadius, height: "180px" }} />
                            <Box height={10} />
                            <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                <Box flexGrow={1} >
                                    <Typography className={classes.label}>
                                        {"Unit ID"}
                                    </Typography>
                                    <Box height={"8px"} />
                                    <Typography className={classes.value}>
                                        {data?.agreement_unitsByID?.unitByID?.unit_no}
                                    </Typography>
                                </Box>
                                <Unit />
                            </Box>
                            <Box height={10} />
                            <Box display="flex">
                                <Box flexGrow={1} >
                                    <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                        <Box flexGrow={1} >
                                            <Typography className={classes.label}>
                                                {"Vehicle"}
                                            </Typography>
                                            <Box height={"8px"} />
                                            <Typography className={classes.value}>
                                                {data?.vehicle_type}
                                            </Typography>
                                        </Box>
                                        {/* <Bicycle2 /> */}
                                        <img className={classes.vehicleTypesvg} src={vehicleTypeSVG[data?.vehicle_type] ?? "/images/Component 149 – 1.svg"} alt="" />
                                    </Box>
                                </Box>
                                <Box width={10} />
                                <Box flexGrow={1} >
                                    <Box display="flex" style={{ padding: "16px", borderRadius: theme.palette.borderRadius, height: "70px", backgroundColor: "rgba(80, 120, 225, .06)" }}>
                                        <Box flexGrow={1} >
                                            <Typography className={classes.label}>
                                                {"Vehicle No."}
                                            </Typography>
                                            <Box height={"8px"} />
                                            <Typography className={classes.value}>
                                                {data?.number_plate}
                                            </Typography>
                                        </Box>
                                        <VehicleNo />
                                    </Box>
                                </Box>
                            </Box>
                            <Box height={10} />
                            <ParkingSlotCard data={data} />
                        </Grid>
                    </div>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    xs={12}
                    style={{
                        padding: "12px",
                        backgroundColor: "white",
                        boxShadow: "0px -1px 6px #00000021",
                    }}
                >
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            className={classes.cancelbtn}
                            onClick={deleteVehicle}
                        >
                            {t("Delete")}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            className={classes.applybtn}
                            variant="contained"
                            onClick={() => history.push({
                                pathname: Routes.addVehicle,
                                state: {
                                    main: {
                                        agreementID: [data?.agreement_id],
                                        parkingSlotID: data?.id
                                    }
                                }
                            })}>
                            {t("Edit")}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
        }</>
    );
};
export default withNamespaces("vehicle")(ViewVehicle)