import React from "react";
import { withBottombar } from "../../HOCs";
import Agreement from "./agreementNew";
class AgreementParent extends React.Component {
  render() {
    return <Agreement />;
  }
}

export default withBottombar(AgreementParent);
