import {
    Box, Container, Grid, TextField, InputAdornment,
    Typography, Stack, Avatar, Divider, IconButton
} from '@mui/material'
import React from 'react'
import { MyBookingStyle } from './style'
import SearchIcon from '@mui/icons-material/Search';
import FilterSvg from '../../assets/filterSvg';
import { AlertProps, NetWorkCallMethods } from '../../utils';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useHistory } from 'react-router-dom';
import CircleIcon from "@mui/icons-material/Circle";
import { AlertContext, AuthContext } from '../../contexts';
import { config } from '../../config';
import { NetworkCall } from '../../networkcall';
import moment from 'moment';
import { Routes } from '../../router/routes';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowDimensions from '../../utils/useWindowDimensions';
import { UseDebounce } from '../../components';
import { withNamespaces } from 'react-i18next';
import { addSeconds } from 'date-fns';

const MyBooking = ({ t }) => {
    const auth = React.useContext(AuthContext)
    const classes = MyBookingStyle({ language: auth?.auth?.auth?.language })
    const [search, setSearch] = React.useState("")
    const [myBookingData, setMyBookingData] = React.useState([])
    const history = useHistory()
    const alert = React.useContext(AlertContext);
    const size = useWindowDimensions();
    const debounce = UseDebounce()
    // const state = useLocation()?.state
    const [offset, setOffset] = React.useState(0)

    const back = () => {
        history.goBack()
    }

    const navigate = (value) => {
        console.log("vaue from navigate",value)
        history.push({
            pathname: Routes.myBookingDetails,
            state: {
                bookingDetail: value
            }
        })
    }

    React.useEffect(() => {
        getAmenitiesBooking(0, "", false)
        // eslint-disable-next-line
    }, [])


    const getAmenitiesBooking = (offsetNum, search, searchBool) => {
        const payload =
        {
            property_facility_id: null,
            limit: 10,
            offset: offsetNum,
            search: search ?? ""
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_my_bookings`,
            NetWorkCallMethods.post,
            payload,
            { "x-build-code": config.app_x_build },
            true,
            false
        )
            .then((response) => {
                if (searchBool) {
                    setMyBookingData(response?.data?.data?.bookings ?? [])
                }
                else {
                    setMyBookingData(myBookingData.concat(response?.data?.data?.bookings))
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };


    const getBookingDetail = (value) => {
        const payload =
        {
            property_facility_id: value?.property_facility_id,
            booking_id: value?.booking_id
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_booked_details`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                navigate(response?.data?.data?.[0] ?? [])
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    const loadmore = () => {
        setOffset(offset + 10);
        let off = offset + 10
        getAmenitiesBooking(off, "", false)
    }

    const handleSearch = (value) => {
        setSearch(value)
        debounce(() => getAmenitiesBooking(0, value, true), 800)

      }
    return (
        <Container maxWidth="sm" className={classes.container}>
            <Box className={classes.navigate}>
                <IconButton className={classes.backbtn} onClick={back}>
                    <KeyboardArrowLeftIcon />
                </IconButton>
            </Box>
            <Box className={classes.topCard}>
                <img className={classes.topImgae} src='images/mybooking2x.png' alt="" />
                <Box className={classes.searchBox} p={0}>
                    <TextField
                        size="medium"
                        variant="outlined"
                        placeholder="Search Booking Here"
                        fullWidth
                        value={search}
                        onChange={(e) => handleSearch(e.target.value)}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        }}
                    />
                </Box>
            </Box>

            <Grid container sx={{ marginTop: "40px", display: "block" }} pb={2}>
                <Grid item sm={12}>
                    <Stack direction={"row"} justifyContent={"space-between"} sx={{ padding: "0px 16px" }}>
                        <Typography className={classes.bookingTitle}>{myBookingData?.length} {t("Bookings")}</Typography>
                        <FilterSvg />
                    </Stack>
                    <InfiniteScroll
                        dataLength={myBookingData?.length ?? 10}
                        next={loadmore}
                        hasMore={true}
                        height={size?.height - 260}
                        className={classes.scroll}
                    >
                        {
                            myBookingData?.map((e) => {
                                console.log("e from mybookings",e)
                                return (
                                    <Box p={1} className={classes.statusCard} m={2} onClick={() => getBookingDetail(e)}>
                                        <Stack direction={"row"} spacing={2} height={"55px"} alignItems="center" divider={
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }} >
                                                <Box display="flex" alignItems="center" style={{ borderRadius: "4px" }} flexDirection="column">
                                                    <Box style={{ overflow: "hidden" }}>
                                                        <CircleIcon
                                                            style={{ marginTop: "14px", fontSize: "16px", color: "#F5F7FA" }}
                                                        />
                                                    </Box>
                                                    <Divider className={classes.divider} />
                                                    <Box style={{ overflow: "hidden" }}>
                                                        <CircleIcon
                                                            style={{ marginBottom: "6px", fontSize: "16px", color: "#F5F7FA" }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </div>
                                        }>
                                            <Avatar className={classes.avatar} src={e?.amenity_assets}></Avatar>
                                            <Stack direction={"row"} justifyContent={"space-between"} width="100%" alignItems={"center"}>
                                                <Stack sx={{ marginLeft: "-16px" }}>
                                                    <Typography className={classes.title} noWrap sx={{ width: 100 }}> {e?.amenities_name} </Typography>
                                                    <Typography className={classes.content} noWrap sx={{ width: 100 }}> {e?.booking_id ?? "-"} </Typography>
                                                </Stack>
                                                <Stack alignItems={"center"} sx={{ marginLeft: "-60px" }}>
                                                    <Typography className={classes.title}> {moment(e?.booking_start_time).format("DD MMM")} </Typography>
                                                    {
                                                        e?.period === "Daily" ?
                                                            <Typography className={classes.content}> {moment(e?.booking_start_time).format("DD MMM YY")} - {moment(e?.booking_end_time).format("DD MMM YY")}</Typography>
                                                            :
                                                            <Typography className={classes.content}>
                                                                {moment(e?.booked_slots?.[0], "HH:mm").format("hh:mm A") + " - " + moment(addSeconds(new Date(moment(new Date()).format("YYYY-MM-DD")+" "+e?.booked_slots[e?.booked_slots?.length - 1]?.[1]),1)).format("hh:mm A")}                        
                                                                {/* {moment(e?.booked_slots[e?.booked_slots?.length - 1], "HH:mm:ss")} */}
                                                                {/* {moment(e?.booked_slots[e?.booked_slots?.length - 1], "HH:mm:ss").format("hh:mm A")), 1)).format("hh:mm A")} */}
                                                            </Typography>
                                                    }
                                                </Stack>
                                                <Stack sx={{ marginLeft: "-8px" }}>
                                                    <Typography className={e?.booking_status === "Booked" ? classes.booked : classes.not_booked}> {e?.booking_status} </Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                )
                            })
                        }
                    </InfiniteScroll>

                </Grid>
            </Grid>
        </Container>
    )
}

export default withNamespaces("amenitiesBooking")(MyBooking)