import React from "react";
import Units from './myunits';

class UnitsParent extends React.Component {
    render() {
        return <Units />;
    }
}

export default UnitsParent;
