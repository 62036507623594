import * as React from "react"
import { FontFamilySwitch } from "../utils/common";

const NoAmenitySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={374}
    height={251}
    {...props}
  >
    <defs>
      <linearGradient
        id="a"
        x1={0.56}
        y1={1}
        x2={0.559}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#f5f7fa" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.56}
        y1={0}
        x2={0.559}
        y2={1}
        xlinkHref="#a"
      />
      <linearGradient
        id="d"
        x1={0.553}
        y1={0.328}
        x2={0.559}
        y2={1}
        xlinkHref="#a"
      />
      <clipPath id="b">
        <path
          data-name="Rectangle 55521"
          transform="translate(219.476 96.387)"
          fill="#fff"
          stroke="#707070"
          opacity={0.25}
          d="M0 0h374v251H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 105925">
      <g
        data-name="Mask Group 102253"
        transform="translate(-219.476 -96.387)"
        clipPath="url(#b)"
      >
        <g data-name="Group 105918" opacity={0.5} fill="none" stroke="#e4e8ee">
          <path data-name="Line 1461" d="M274.617 124.495v188.489" />
          <path data-name="Line 1462" d="M424.74 124.495v188.489" />
          <path data-name="Line 1463" d="M349.679 124.495v188.489" />
          <path data-name="Line 1464" d="M499.802 124.495v188.489" />
          <path data-name="Line 1465" d="M274.617 86.819v261.359" />
          <path data-name="Line 1466" d="M424.74 86.819v261.359" />
          <path data-name="Line 1467" d="M349.679 86.819v261.359" />
          <path data-name="Line 1468" d="M499.802 86.819v261.359" />
          <path data-name="Line 1469" d="M312.148 86.819v261.359" />
          <path data-name="Line 1470" d="M462.271 86.819v261.359" />
          <path data-name="Line 1471" d="M387.21 86.819v261.359" />
          <path data-name="Line 1472" d="M537.332 86.819v261.359" />
          <path data-name="Line 1473" d="M237.274 124.537h337.777" />
          <path data-name="Line 1474" d="M237.087 199.974h337.777" />
          <path data-name="Line 1475" d="M237.087 275.265h337.777" />
          <path data-name="Line 1476" d="M236.899 162.256h337.777" />
          <path data-name="Line 1477" d="M236.899 86.819h337.777" />
          <path data-name="Line 1478" d="M236.899 237.693h337.777" />
          <path data-name="Line 1479" d="M236.899 312.983h337.777" />
          <path data-name="Line 1480" d="M236.899 350.556h337.777" />
        </g>
        <g data-name="Group 105920">
          <g data-name="Group 105919">
            <path
              data-name="icons8-plus-math (1)"
              d="m348.886 135.965-8.434-2.144-2.015-8.434h-.205l-1.9 8.434-8.439 2.144v.263l8.434 1.717 1.888 8.434h.239l2-8.434 8.434-1.7ZM485.395 229.953l-5.194-1.32-1.24-5.194h-.126l-1.173 5.194-5.194 1.32v.162l5.194 1.057 1.162 5.194h.144l1.23-5.194 5.194-1.045Z"
              fill="#ced3dd"
            />
            <g transform="translate(354.43 156.73)">
              <ellipse
                data-name="Ellipse 129531"
                cx={56.38}
                cy={2.339}
                rx={56.38}
                ry={2.339}
                transform="translate(0 59.67)"
                fill="#e4e8ee"
                opacity={0.47}
              />
              <path
                data-name="Path 99242"
                d="M34.17 53.99h-5.155a.871.871 0 0 1-.838-1.109l2.985-10.45a.873.873 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-2.985 10.45a.873.873 0 0 1-.838.632Z"
                fill="#717b89"
              />
              <path
                data-name="Path 99243"
                d="M70.316 53.99h5.155a.871.871 0 0 0 .838-1.109l-2.985-10.45a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l2.985 10.45a.873.873 0 0 0 .838.632Z"
                fill="#717b89"
              />
              <path
                data-name="Path 99244"
                d="M78.367 5.225A5.224 5.224 0 0 0 73.143 0H57.468a5.226 5.226 0 0 0-5.225 5.225v24.382h26.124Z"
                fill="#959ca6"
              />
              <path
                data-name="Path 99245"
                d="M52.243 5.225A5.224 5.224 0 0 0 47.019 0H31.344a5.226 5.226 0 0 0-5.225 5.225v24.382h26.124Z"
                fill="#959ca6"
              />
              <path
                data-name="Path 99246"
                d="M78.367 34.833H26.119v-6.967a3.484 3.484 0 0 1 3.483-3.483h45.282a3.484 3.484 0 0 1 3.483 3.483Z"
                fill="#465160"
              />
              <path
                data-name="Path 99247"
                d="M26.119 45.282h-1.742a6.967 6.967 0 0 1-6.966-6.967v-22.64a1.742 1.742 0 0 1 1.742-1.742h5.225a1.742 1.742 0 0 1 1.742 1.742Z"
                fill="#b8bdc4"
              />
              <path
                data-name="Path 99248"
                d="M73.324 42.431a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l1.176 4.115h6.966Z"
                opacity={0.05}
              />
              <path
                data-name="Path 99249"
                d="M73.324 42.431a.873.873 0 0 0-.838-.632h-5.155a.871.871 0 0 0-.838 1.109l.927 3.245h6.966Z"
                opacity={0.07}
              />
              <path
                data-name="Path 99250"
                d="M31.197 42.431a.871.871 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-1.176 4.115h-6.966Z"
                opacity={0.05}
              />
              <path
                data-name="Path 99251"
                d="M31.198 42.431a.871.871 0 0 1 .838-.632h5.155a.871.871 0 0 1 .838 1.109l-.928 3.245h-6.967Z"
                opacity={0.07}
              />
              <path
                data-name="Path 99252"
                d="M85.334 13.933h-5.223a1.742 1.742 0 0 0-1.742 1.742v19.158H17.411v3.483a6.967 6.967 0 0 0 6.966 6.966h55.734a6.967 6.967 0 0 0 6.966-6.966V15.675a1.742 1.742 0 0 0-1.743-1.742Z"
                fill="#b8bdc4"
              />
            </g>
          </g>
        </g>
        <path
          data-name="Rectangle 55517"
          transform="translate(219.476 284.387)"
          fill="url(#a)"
          d="M0 0h374v63H0z"
        />
        <path
          data-name="Rectangle 55518"
          transform="translate(219.475 96.387)"
          fill="url(#c)"
          d="M0 0h374v63H0z"
        />
        <path
          data-name="Rectangle 55519"
          transform="rotate(-90 437.431 -90.044)"
          fill="url(#a)"
          d="M0 0h244v65H0z"
        />
        <path
          data-name="Rectangle 55520"
          transform="rotate(-90 283.431 63.956)"
          fill="url(#d)"
          d="M0 0h251v63H0z"
        />
      </g>
      <text
        data-name="No Amenity Found"
        transform="translate(117 173)"
        fill="#4e5a6b"
        fontSize={16}
        fontFamily={FontFamilySwitch().extraBold}
        fontWeight={800}
      >
        <tspan x={0} y={0}>
          {"No Amenity Found"}
        </tspan>
      </text>
      <text
        data-name="No amenity found to this property"
        transform="translate(187 199)"
        fill="#98a0ac"
        fontSize={14}
        fontFamily={FontFamilySwitch().semiBold}
        fontWeight={600}
      >
        <tspan x={-107} y={0}>
          {"No amenity found to this property"}
        </tspan>
      </text>
    </g>
  </svg>
)

export default NoAmenitySvg
