import React from "react";
import { ParkingSlotCardStyle } from "./style";
import { Box, Typography } from '@mui/material'

export const ParkingSlotCard = (props) => {
    const classes = ParkingSlotCardStyle({ vehicle_type: props?.data?.vehicle_type })
    return (
        <Box display="flex" className={classes.parkingSlotNoStyle}>
            <Box flexGrow={1} >
                <Typography className={classes.label}>
                    {"Parking Slot No."}
                </Typography>
                <Box height={"8px"} />
                <Typography className={classes.value1}>
                    {(props?.data?.vehicle_type === "BI-CYCLE" || props?.data?.vehicle_type === "TWO-WHEELER") ?
                        props?.data?.parking_areaByID?.vehicle_parking_area :
                        props?.data?.parking_masterByID?.parking_no}
                </Typography>
            </Box>
        </Box>
    )
}