import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { saveAs } from "file-saver";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { DocumentViewer } from "../../components";
import SimpleMap from "../../components/simpleMapWithMarker";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  bottomTitle: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
    font: "normal Nunito Sans",
    textTransform: "capitalize"
  },
  bottomsub: {
    color: "#4E5A6B",
    fontSize: "14px",
    marginTop: "4px",
    textTransform: "capitalize"

  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  categoryTitle: {
    fontSize: "8px",
    backgroundColor: "#F1E6FE",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5D427F",
  },
  typeTitle: {
    fontSize: "8px",
    backgroundColor: "#F0F4FF",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5078E1",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "4px",
  },
  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    fontSize: "12px",
    color: theme.typography.color.secondary,
    flexFlow: 'wrap !important'
  },
  phone: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },

  screenPadding: {
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  call: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().semiBold,
    textDecoration: "none",
    color: theme.typography.color.secondary,
  },
  callbox: {
    cursor: "pointer",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
}));

const UnitDetails = (props) => {
  const { t } = props
  const size = useWindowDimensions();
  const classes = useStyles();
  const { unitdetails, setunitDrawer, handBookPdf } = props;
  const [phone, setPhone] = React.useState(false);
  const [calldata, setCalldata] = React.useState({});
  const [handbook, setHandbook] = React.useState(false);

  const handlePhone = (data) => {
    setCalldata({
      phone: `${data.business_country_code}${data.business_phone}`,
      business: `${data.mobile_country_code}${data.mobile_phone}`,
    });
    setHandbook(false);
    setPhone(true);
  };
  const returnCall = () => {
    return (
      <Container maxWidth="sm">
        <Grid className={classes.screen} item xs={12}>
          <Grid className={classes.topNavBarStyle}>
            {/* TopNavbar */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>{t("Dial")}</Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setPhone(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.screenPadding}>
            <a
              href={`tel:${calldata?.business}`}
              style={{ textDecoration: "none" }}
              className={classes.call}
            >
              {/* {
                data?.type === "warehose" ? data?.description:data?.type
              } */}
              <Box
                display="flex"
                alignItems="center"
                p={1}
                className={classes.callbox}
              >
                <Box>
                  <Typography className={classes.call}>
                    {calldata?.business}
                  </Typography>
                </Box>
                <Box marginLeft="8px">
                  <IconButton
                    size="small"
                    className={classes.phone}
                    onClick={() => setPhone(false)}
                  >
                    <PhoneIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </a>
            <a href={`tel:${calldata?.phone}`} className={classes.call}>
              <Box
                display="flex"
                alignItems="center"
                p={1}
                className={classes.callbox}
              >
                <Box>
                  {" "}
                  <Typography className={classes.call}>
                    {calldata?.phone}
                  </Typography>{" "}
                </Box>
                <Box marginLeft="8px">
                  <IconButton
                    size="small"
                    className={classes.phone}
                    onClick={() => setPhone(false)}
                  >
                    <PhoneIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
            </a>
          </Grid>
        </Grid>
      </Container>
    );
  };
  const returnhandbook = () => {
    return (
      <>
        <Container maxWidth="sm">
          <Grid className={classes.screen} item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {t("Handbook")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setHandbook(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Grid
              className={classes.topNavBarStyle}
              style={{ height: size?.height - 200 }}
            >
              {handBookPdf === null || handBookPdf === "" ? (
                t("NoDocumentFound")
              ) : (

                <DocumentViewer url={handBookPdf} />
              )}

              <Grid container className={classes.btncontainer} spacing={1}>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    className={classes.applybtn}
                    onClick={() => {
                      downloadURI(handBookPdf, unitdetails?.unit_name);
                    }}
                  >
                    {t("Download")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  const downloadURI = (url, name) => {
    saveAs(url, `Handbook #${name}`);
  };
  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid
          className={classes.screen}
          style={{ height: size?.height - 130 }}
          item
          xs={12}
        >
          <Grid className={classes.topNavBarStyle}>
            {/* TopNavbar */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}>
                  {handbook ? t("Handbook") : t("unit")}
                </Typography>
              </Box>
              <Box>
                <IconButton size="small" onClick={() => setunitDrawer(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          {/* {props.data.unit_c} */}
          <Grid
            className={classes.screenPadding}
            style={{
              height: size?.height - 200,
              paddingLeft: "5px",
              overflow: "auto",
            }}
          >
            <div style={{ padding: "10px" }}>
              {unitdetails?.assets?.length ? (
                <Slider dots={false}>
                  {unitdetails?.assets
                    ?.filter((x) => x?.asset_type !== 4)
                    .map((val) => {
                      return (
                        <img
                          src={val.url}
                          className={classes.unitImg}
                          alt="unitimg"
                        />
                      );
                    })}
                </Slider>
              ) : (
                <img
                  src={"/images/unitplaceholder.svg"}
                  className={classes.unitImg}
                  alt="unitimg"
                />
              )}

              <Box
                style={{ flexFlow: "wrap !important" }}
                display="flex"
                p={1}
                alignItems="center"
                className={classes.titleroot2}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {unitdetails?.unit_name} &nbsp;
                    <span className={classes.categoryTitle}>
                      {unitdetails?.unit_category_name}
                    </span>{" "}
                    &nbsp;
                    <span className={classes.typeTitle}>
                      {unitdetails?.unit_type_name}
                    </span>
                  </Typography>
                  <Typography className={classes.bottomsub}>
                    {unitdetails?.block_name}{" "}
                    {unitdetails?.block_name?.length > 0 &&
                    unitdetails?.floor_name?.length > 0
                      ? ","
                      : ""}
                    {unitdetails?.floor_name}
                    {/* {unitdetails?.property_name} */}
                  </Typography>
                  {/* <Typography className={classes.bottomsub}>
                    {unitdetails?.property_name}
                  </Typography> */}
                </Box>
                <Box>
                  <IconButton
                    size="small"
                    className={classes.phone}
                    onClick={() => handlePhone(unitdetails)}
                  >
                    <PhoneIcon color="primary" />
                  </IconButton>
                </Box>
              </Box>
              {unitdetails?.unit_category === "Residential Unit" ? (
                <>
                  <Grid
                    container
                    display="flex"
                    flexWrap={"wrap"}
                    p={2}
                    spacing={1}
                    alignItems="center"
                    className={classes.titleroot2}
                  >
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/bedIcon.svg" alt="bedroom" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp; &nbsp;{" "}
                            {unitdetails?.unit_type
                              ? unitdetails?.unit_type
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/bathsIcon.svg" alt="furnished" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_baths
                              ? unitdetails?.total_baths
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={4} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/homeIcon.svg" alt="home" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_bed_rooms
                              ? unitdetails?.total_bed_rooms
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={4} alignItems="center">
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        marginTop={"10px"}
                      >
                        <Box>
                          <img src="/images/Furnished.svg" alt="furnished" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.furnishing_type
                              ? unitdetails?.furnishing_type
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={4}
                      alignItems="center"
                      marginTop={"10px"}
                    >
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/SQ_FT.svg" alt="sqft" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_area
                              ? unitdetails?.total_area
                              : "0"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    display="flex"
                    flexWrap={"wrap"}
                    p={2}
                    spacing={1}
                    alignItems="center"
                    className={classes.titleroot2}
                  >
                    <Grid item lg={6} md={6} sm={6} xs={6} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/homeIcon.svg" alt="home" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.unit_category_name
                              ? unitdetails?.unit_category_name
                              : "-"}
                          </span>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={6} alignItems="center">
                      <Box display={"flex"} alignItems={"center"}>
                        <Box>
                          <img src="/images/SQ_FT.svg" alt="sqft" />
                        </Box>
                        <Box>
                          <span className={classes.bottomDetails}>
                            &nbsp;{" "}
                            {unitdetails?.total_area
                              ? unitdetails?.total_area
                              : "0"}
                            &nbsp;
                            {unitdetails?.uom_name ?? ""}
                          </span>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}

              <Box
                display="flex"
                p={2}
                alignItems="center"
                justifyContent="space-evenly"
                className={classes.titleroot2}
              >
                <Box flexGrow={1} display="flex" alignItems="center">
                  <img src="/images/Handbook.svg" alt="handbook" />
                  <Typography className={classes.bottomDetails}>
                    &nbsp; &nbsp;{t("Handbook")}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexWrap={"wrap"}
                  // p={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display={"flex"} alignItems="center">
                    <Typography
                      className={classes.viewHandbook}
                      onClick={() => {
                        setHandbook(true);
                        setPhone(false);
                      }}
                    >
                      {t("View")}
                    </Typography>
                  </Box>
                  &nbsp;/&nbsp;
                  <Box display={"flex"} alignItems="center">
                    <Typography
                      className={classes.viewHandbook}
                      onClick={() => {
                        setHandbook(true);
                        setPhone(false);
                      }}
                    >
                      {t("Download")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {(unitdetails?.block_name?.length > 0 ||
                unitdetails?.floor_name?.length > 0) ? (
                <Box display="flex" p={2} alignItems="center">
                  <Box flexGrow={1} display="flex" alignItems="center">
                    <img src="/images/Location.svg" alt="address" />
                    <Typography className={classes.bottomDetails}>
                      &nbsp; &nbsp;
                      {unitdetails?.block_name}{" "}
                      {unitdetails?.block_name?.length > 0 &&
                      unitdetails?.floor_name?.length > 0
                        ? ","
                        : ""}
                      {unitdetails?.floor_name}
                    </Typography>
                  </Box>
                </Box>
              ):<Box height={'10px'}/>}

              {unitdetails?.latitude && unitdetails?.longitude && (
                <>
                  <SimpleMap
                    lat={parseFloat(unitdetails?.latitude)}
                    lng={parseFloat(unitdetails?.longitude)}
                    center={{
                      lat: parseFloat(unitdetails?.latitude),
                      lng: parseFloat(unitdetails?.longitude),
                    }}
                    zoom={15}
                  />
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
      <Hidden smDown>
        <Dialog
          open={phone}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
          onClose={() => setPhone(false)}
        >
          {returnCall()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={phone}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setPhone(false)}
        >
          {returnCall()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          open={handbook}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
          onClose={() => setHandbook(false)}
        >
          {returnhandbook()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          open={handbook}
          anchor="bottom"
          className={classes.drawer}
          onClose={() => setHandbook(false)}
        >
          {returnhandbook()}
        </Drawer>
      </Hidden>
    </div>
  );
};
export default withNamespaces("units")(UnitDetails)