import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Success } from "../../components";
import { Routes } from "../../router/routes";
const useStyles = makeStyles((theme) => ({
  root: {},
}));

const SuccessPage = (props) => {
  const history = useHistory();
  const { state } = useLocation();
  const classes = useStyles();
  React.useEffect(() => {
    // if (state) {
    //   setTimeout(() => {
    //     if (state?.path) {
    //       if (state?.main) {
    //         history.push({
    //           pathname: state?.path,
    //           state: {
    //             propertyId: state?.main?.propertyId,
    //             tab: state?.main?.tab,
    //             agreementId: state?.main?.agreementId
    //           }
    //         })
    //       }
    //       else {
    //         history.push(state?.path);
    //       }
    //     } else {
    //       history.goBack();
    //     }
    //   }, 3000);
    // } else {
    //   history.push(Routes.dashboard);
    // }
    setTimeout(() => {
      history.push({
        pathname: Routes.dashboard,
        state: true
      })

    }, 4000);

    // eslint-disable-next-line
  }, []);


  return (
    <div className={classes.root}>
      <div className={classes.select}>
        <Success failure={props?.failure ?? false} customLabel={state?.customLabel} />
      </div>
    </div>
  );
};
export default SuccessPage;
