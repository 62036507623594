import { Avatar, Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PropertyDetailsStyle } from './style'
import { AuthContext } from '../../../contexts';

export const PropertyDetails = (
    {
        properties = [],
        setPropertyDetail = () => false,
    }
) => {
    const auth = React.useContext(AuthContext)
    const classes = PropertyDetailsStyle({ language: auth?.auth?.auth?.language })

    return (
        <Box>
            <Stack p={1} spacing={1}  className={classes.bodybox}>
                {
                    properties?.map((e) => {
                        return (
                            <div>
                                <Stack direction={"row"} justifyContent={"space-between"} p={2} className={classes.box} alignItems={"center"}
                                    onClick={() => setPropertyDetail(e)}>
                                    <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                                        <Avatar className={classes.avatar} src={e.asset_url ?? e.logo}></Avatar>
                                        <Stack>
                                            <Typography className={classes.apsName}> {!e.agreement_unit_id ?e.property_name : e?.unit_name} </Typography>
                                            {!e.agreement_unit_id && <Typography className={classes.apsArea}> {e.area}, {e.city} </Typography>}
                                        </Stack>
                                    </Stack>
                                    <Box>
                                        <IconButton className={classes.iconbtn}>
                                            <KeyboardArrowRightIcon />
                                        </IconButton>
                                    </Box>
                                </Stack>
                            </div>
                        )
                    })
                }
            </Stack>
        </Box>
    )
}