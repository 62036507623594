import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { SlotCardStyles } from "./style";
import { format } from "date-fns";

export const Slots = ({ onclick = () => false, ...props }) => {
  const classes = SlotCardStyles();
  var selectedIndex = []
  if (props?.selctedslot?.length > 0) {
    selectedIndex = props?.selctedslot?.map(e => {
      return e.index
    })
  }

  const getTimeWithAmPm = (timeString) => {
    const [hour] = timeString.split(":")
    if(hour === "00"){
      return `12:00 AM`
    }
    else if(hour === "24")
    {
      return `12:00 PM`
    }
    else{
      if (hour >= 12) {
        if (hour % 12 === 0) return `12:00 PM`
        else return `${hour % 12}:00 PM`.padStart(8, '0')
      } else return `${hour}:00 AM`
    }
  }

  return (
    <Box className={selectedIndex.includes(props?.index) ? classes.selectedBox : props?.changeBg ? classes.mainslot : classes.main} onClick={() => onclick(props?.data, props?.index)}>
      <Box className={classes.gridBox} >
        <Typography className={classes.slotTime}>
          {`${props?.data?.check_in_date ?
              format(new Date(props?.data?.check_in_date), "dd LLL yy")
              :
              getTimeWithAmPm(props?.data?.check_in_time)} 
            -
           ${props?.data?.check_out_date ?
              format(new Date(props?.data?.check_out_date), "dd LLL yy")
              :
              getTimeWithAmPm(props?.data?.check_out_time)
            }`}
        </Typography>
      </Box>
      <Box className={classes.gridBox}>
        <Typography className={classes.slotPerson}>
          {props?.data?.check_in_date ? "24 hrs" : "1 hr"} <span className={classes.dot}></span> {props?.response?.amenity?.[0]?.participants_count} {"Persons / Slot"}
        </Typography>
      </Box>
    </Box>
  );
};
