import jwt_decode from "jwt-decode";
/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  authToken: "authToken",
  version: "version",
  deviceToken: "device_token",
  accessType: "accessType",
  userProfileId: "userProfileId",
  client: "client",
  agreement: "agreement",
  clientID:"clientID",
  roleID:"roleID"
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};
export const calculateTime = (date1, date2) => {
  var oneDay = 24 * 60 * 60 * 1000;
  var date1InMillis = date1.getTime();
  var date2InMillis = date2.getTime();
  var days = Math.round((date2InMillis - date1InMillis) / oneDay);
  return days;
};

export const TabContant = (t) => {
  return {
    Tabs: [
      {
        mounth: t("jan"),
        value: 0,
      },
      {
        mounth: t("feb"),
        value: 1,
      },
      {
        mounth: t("mar"),
        value: 2,
      },
      {
        mounth: t("apr"),
        value: 3,
      },
      {
        mounth: t("may"),
        value: 4,
      },
      {
        mounth: t("jun"),
        value: 5,
      },
      {
        mounth: t("jul"),
        value: 6,
      },
      {
        mounth: t("aug"),
        value: 7,
      },
      {
        mounth: t("sep"),
        value: 8,
      },
      {
        mounth: t("oct"),
        value: 9,
      },
      {
        mounth: t("nov"),
        value: 10,
      },
      {
        mounth: t("dec"),
        value: 11,
      },
    ],

    TabYearly: [
      {
        label: "2022",
        value: 2022,
      },
      {
        label: "2021",
        value: 2021,
      },
    ],
  }
};

export let roles = {
  tenant: "Tenant"
}

export const getClient = (token) => jwt_decode(token)?.selected_client

export let requsetStatus = {
  open: "Open",
  assigned: "Assigned",
  closed: "Closed",
  maintenance: "Maintenance",
  general: "General"
}

export let parkingEnum = {
  parking_unit: "Parking Unit",
}

export let quotationActionCard = [
  {
    id: 1,
    img: "/images/icons8-paid-bill.svg",
    title: "Accept quote",
    color: "#35A27D",
    value: "Interested"
  },
  // {
  //   id: 2,
  //   img: "/images/icons8-leave-house (1).svg",
  //   title: "Request for visit",
  //   color: "#27AFCF",
  //   value: "Interested"
  // },
  {
    id: 4,
    title: "Not Interested",
    value: "Not Interested"
  }
];

export let quotationEnum = {
  interested: "Interested",
  notinterested: "Not Interested"

}

export let agreementEnum = {
  termination: "Termination",
  pending: "Pending",
  renwal: "Renewal"

}
// //fonts function
// export const Regular =
//   "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif";
// export const Bold =
//   "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif";
// export const SemiBold =
//   "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif";
// export const ExtraBold =
//   "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif";

//parkingunit category id
export const ParkingUnitCategoryId = 8


export const LanguageOptions = [
  { value: "en", label: "English" },
  { value: "ta", label: "Tamil" },
  { value: "hi", label: "Hindi" },
  { value: "ar", label: "Arabic" },
  // { value: "zh", label: "Chinese (Simplified)" },
  // { value: "es", label: "Spanish" },
  // { value: "fr", label: "French" },
  // { value: "ru", label: "Russian" },
  // { value: "pt", label: "Portuguese" },
  // { value: "it", label: "Italy" },
  // { value: "fil", label: "Filipino" },
  // { value: "id", label: "Indonesian" },
  // { value: "te", label: "Telugu" },
  // { value: "kn", label: "Kannada" },
  // { value: "ml", label: "Malayalam" },
  // { value: "de", label: "German" },
];

export const RTLLanguages = ["ar"]


export const AgreementRenewalType = (t) => [
  {
    title: t("As Per Agreement"),
    subTitle: t("YourCurrent Agreement terms will be replicated"),
    backGroundColor: "#E9EEFA"
  },
  {
    title: t("Custom"),
    subTitle: t("You can pick your specific agreement period"),
    backGroundColor: "#EDF8FE"
  },
]

export const YesNoOptions = (t) => [
  { value: true, label: t("Yes") },
  { value: false, label: t("No") },
];

export const findImageSize = (file) =>
  new Promise((resolve, reject) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    img.src = _URL.createObjectURL(file);
    img.onload = (e) => {
      resolve({
        width: e.target.width,
        height: e.target.height,
      });
    };
    img.onerror = (e) => {
      reject(e);
    };
  });

export const convertUrlToFileObject = async (url) => {
  // Here is the code for converting "image source"(url) to "Base64".***

  let result = null;
  //let url = 'https://cdn.shopify.com/s/files/1/0234/8017/2591/products/young-man-in-bright-fashion_925x_f7029e2b-80f0-4a40-a87b-834b9a283c39.jpg'
  const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))


  /// Here is code for converting "Base64" to javascript "File Object".***

  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  /// Calling both function ***

  await toDataURL(url)
    .then(async (dataUrl) => {
      //console.log('Here is Base64 Url', dataUrl)
      var fileData = await dataURLtoFile(dataUrl, "imageName.jpg");
      //console.log("Here is JavaScript File Object", fileData)
      result = fileData
    })
  return result;

}

export const image_size={
  height:738,
  width:1426
}
