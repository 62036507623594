import ChevronRight from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TopNavBars } from "../../components";
import { ProfileSimmaer } from "../../components/simmer/profileSimmer";
import { config } from "../../config";
import { AlertContext, AuthContext, FirebaseContext } from "../../contexts";
// import { LOG_OUT_TOKEN } from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  // screen: {
  //   borderRadius: "16px",
  //   border: "1px solid #F5F7FA",
  //   background: theme.palette.background.tertiary,
  // },
  topNavBarStyle: {
    // padding: "0px 16px 0px 16px",
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profileCardStyle: {
    padding: "24px 16px 16px 16px",
    backgroundImage: `url(${"/images/Illustration.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50%",
    background: theme.palette.background.tertiary1,
  },
  detailsCardStyle: {
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    backgroundColor: theme.palette.background.tertiary1,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
  bottomNavigationBarStyle: {
    borderRadius: "4px 4px 0px 0px",
    position: "fixed",
    width: "-webkit-fill-available",
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: theme.palette.borderRadius,
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawerHeaderTextStyle1: {
    textAlign: "center",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerButtonStyle: {
    "&:hover": "none",
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "normal",
    color: "#FFF",
  },
  drawerButtonStyle1: {
    "&:hover": {
      background: "white",
      color: theme.palette.primary.main,
    },
    borderRadius: theme.palette.borderRadius,
    padding: "14px",
    background: "white",
    fontSize: "14px",
    fontWeight: "normal",
    color: theme.palette.primary.main,
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  text: {
    fontSize: "14px",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().bold,
  },
  role: {
    fontSize: "10px",
    fontFamily: FontFamilySwitch().bold,
    color: "#6A68CC",
  },
  type: {
    fontSize: "10px",
    fontFamily: FontFamilySwitch().bold,
    color: "#27AFCF",
  },
  name: {
    fontSize: "24px",
    fontFamily: FontFamilySwitch().extraBold,
    color: "#091B29",
  },
  subText: {
    fontSize: "14px",
    color: "#091B29",
    fontFamily: FontFamilySwitch().bold,
    wordWrap: "break-word",
  },
  aboutpage: {
    textDecoration: "none !important"
  }
}));

const languageOptions = [
  { value: "en", label: "English" },
  { value: "ar", label: "Arabic" },
  { value: "ta", label: "Tamil" },
];

const ProfileView = (props) => {
  const { t, i18n } = props;
  const auth = React.useContext(AuthContext)
  const classes = useStyles({ language: auth?.auth?.auth?.language });
  const history = useHistory();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const firebaseContext = React.useContext(FirebaseContext);
  const [data, setDate] = React.useState({
    user: {},
    role: {},
  });
  const [openDrawer, setopenDrawer] = React.useState(false);
  const [openAboutUs, setOpenAboutUs] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const [languageModal, setLanguageModal] = React.useState({
    open: false,
    language: null,
  });
  const size = useWindowDimensions();

  // logout
  const getLogoutdeviceToken = () => {
    const params = {
      token: localStorage.getItem(LocalStorageKeys?.deviceToken),
      app_build: config.app_x_build,
    };
    NetworkCall(
      `${config.api_url}/logout`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        localStorage.clear();
        firebaseContext.deleteToken();
        history.push(Routes.login);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };
  // logout
  // const getLogoutdeviceToken = () => {
  //   const payload = {
  //     query: LOG_OUT_TOKEN,
  //     variables: {
  //       id: localStorage.getItem(LocalStorageKeys?.userProfileID),
  //       token: localStorage.getItem(LocalStorageKeys?.deviceToken),
  //       data: {
  //         is_active: false,
  //       },
  //     },
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     payload,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       localStorage.clear();
  //       firebaseContext.deleteToken();
  //       history.push(Routes.login);
  //     })
  //     .catch((error) => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: "Some Thing Went Wrong.",
  //       });
  //     });
  // };
  const Logout = () => {
    setopenDrawer(true);
  };
  // const handleAboutUs = () => {
  //   setOpenAboutUs(true);
  // };
  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      NetworkCall(
        `${config.api_url}/user-info`,
        NetWorkCallMethods.get,
        null,
        null,
        true,
        false
      )
        .then((response) => {
          setDate({
            user: response?.data?.data?.user_profiles?.[0],
            role: response?.data?.data?.role?.[0],
          });
          setLoading(false);
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some Thing Went Wrong.",
          });
        });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    let getselectedlag = i18n.language;
    setLanguageModal({
      ...languageModal,
      language:
        languageOptions.filter((val) => val.value === getselectedlag)?.[0] ??
        languageOptions[0],
    });
    // eslint-disable-next-line
  }, []);

  // change Language function
  // const changeLanguage = (type, val) => {
  //   if (type === "modal") {
  //     let getselectedlag = i18n.language;
  //     setLanguageModal({
  //       ...languageModal,
  //       opne: val,
  //       language:
  //         languageOptions.filter((val) => val.value === getselectedlag)?.[0] ??
  //         languageOptions[0],
  //     });
  //   } else if (type === "language") {
  //     setLanguageModal({
  //       ...languageModal,
  //       language: val,
  //     });
  //   }
  // };

  // const saveLanguage = () => {
  //   i18n.changeLanguage(languageModal?.language?.value);
  //   setLanguageModal({
  //     ...languageModal,
  //     opne: false,
  //   });
  //   auth.setAuth({
  //     ...auth,
  //     auth: {
  //       language: languageModal?.language?.value
  //     }
  //   })
  // };

  // const myBookingNavigate = () => {
  //   history.push({
  //     pathname: Routes.mybooking
  //   })
  // }

  return (
    <Container className={classes.root} maxWidth="sm">
      {loading ? (
        <ProfileSimmaer />
      ) : (
        <>
          <TopNavBars note={false} home={false} />
          <Grid
            className={classes.screenScrollEffect}
            style={{
              height: size?.height - (64 + 62),
              width: "inherit",
              marginTop: "2px",
            }}
          >
            <Grid className={classes.screen} item xs={12}>
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                className={classes.profileCardStyle}
              >
                <Grid>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #5078E1",
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "100%",
                        width: "100%",
                        border: "2px solid #ffffff",
                      }}
                    >
                      <img
                        src={
                          data?.user?.image_url ?? "/images/profile_image.png"
                        }
                        alt="profile_image"
                        style={{
                          height: "128px",
                          width: "128px",
                        }}
                      />
                    </Avatar>
                  </Avatar>
                  <Button
                    variant="contained"
                    style={{
                      padding: "4px 7px 4px 7px",
                      marginTop: "-30px",
                      marginLeft: "45px",
                      minWidth: "35px",
                      height: "24px",
                      backgroundColor: "#5078E1",
                      border: "2px solid #F5F7FA",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#FFFFFF",
                      }}
                    >
                      <div onClick={() => history.push(Routes.profileEdit)}>
                        {t("edit")}
                      </div>
                    </Typography>
                  </Button>
                </Grid>
                <Box width="26px" />
                <Grid>
                  <Typography className={classes.name}>
                    {data?.user?.first_name ?? ""}&nbsp;
                    {data?.user?.last_name ?? ""}
                  </Typography>
                  <Box height={"8px"} />
                  <Grid container direction={"row"}>
                    <Box
                      style={{
                        padding: "4px 8px 4px 8px",
                        border: "1px solid #27AFCF",
                        borderRadius: "4px",
                      }}
                    >
                      <Typography className={classes.type}>Tenant</Typography>
                    </Box>
                    <Box width="8px" />
                    {data?.role?.client_role_no && (
                      <Box
                        style={{
                          padding: "4px 8px 4px 8px",
                          border: "1px solid #6A68CC",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography className={classes.role}>
                          {data?.role?.client_role_no ?? ""}
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={{ padding: "16px" }}>
                <Grid container direction={"row"}>
                  <Grid
                    style={{ padding: "0px 8px 0px 0px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_39.svg"})`,
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#46B9DA",
                        }}
                      >
                        <img src="/images/icons8-phone.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.mobile_no_country_code ?? ""}&nbsp;
                        {data?.user?.mobile_no ?? ""}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.alternative_mobile_no_country_code ?? ""}
                        &nbsp;
                        {data?.user?.alternative_mobile_no ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{ padding: "0px 0px 0px 8px" }}
                    xs={6}
                    container
                    direction={"column"}
                  >
                    <Grid
                      container
                      display="inline"
                      direction={"column"}
                      className={classes.detailsCardStyle}
                      style={{
                        backgroundImage: `url(${"/images/Mask_Group_37.svg"})`,
                        // height: "-webkit-fill-available",
                        height: "112px",
                      }}
                    >
                      <Avatar
                        variant="circular"
                        style={{
                          height: "28px",
                          width: "28px",
                          backgroundColor: "#6A68CC",
                        }}
                      >
                        <img src="/images/icons8-mail.svg" alt="" />
                      </Avatar>
                      <Box height="12px" />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.email_id}
                      </Typography>
                      <Box height={"6px"} />
                      <Typography className={classes.text} noWrap>
                        {data?.user?.alternative_email_id ?? ""}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height={"16px"} />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.detailsCardStyle}
                    style={{
                      backgroundImage: `url(${"/images/Mask_Group_38.svg"})`,
                    }}
                  >
                    <Avatar
                      variant="circular"
                      style={{
                        height: "28px",
                        width: "28px",
                        backgroundColor: "#FF4B4B",
                      }}
                    >
                      <img src="/images/icons8-map-marker.svg" alt="" />
                    </Avatar>
                    <Box height="12px" />
                    <Typography className={classes.text}>
                      {data?.user?.contact?.street_1
                        ? `${data?.user?.contact?.street_1}, `
                        : ""}
                      {data?.user?.contact?.street_2
                        ? `${data?.user?.contact?.street_2}, `
                        : ""}
                      {data?.user?.contact?.street_3
                        ? `${data?.user?.contact?.street_3}, `
                        : ""}
                      {data?.user?.contact?.district
                        ? `${data?.user?.contact?.district}, `
                        : ""}
                      {data?.user?.contact?.city
                        ? `${data?.user?.contact?.city}, `
                        : ""}
                      {data?.user?.contact?.state
                        ? `${data?.user?.contact?.state}, `
                        : ""}
                      {data?.user?.contact?.country
                        ? `${data?.user?.contact?.country}, `
                        : ""}
                      {data?.user?.contact?.zipcode
                        ? `- ${data?.user?.contact?.zipcode}`
                        : ""}
                    </Typography>
                    <Box height={"12px"} />
                  </Grid>
                </Grid>

                {/* <Box height={"16px"} /> */}

                {/* Defualt Language chaange */}
                {/* <Grid
                  onClick={() => changeLanguage("modal", true)}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography className={classes.subText}>
                      {t("defualt_language")} - ({" "}
                      {languageModal?.language?.label} )
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid> */}

                <Box height={"16px"} />
                <a className={classes.aboutpage} href="https://propgoto.com/about_us.html">
                  <Grid
                    // onClick={handleAboutUs}
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    className={classes.detailsCardStyle}
                    style={{ cursor: "pointer" }}
                  >
                    <Grid xs={10} container alignItems="center" direction={"row"}>
                      <img src="/images/signout.svg" alt="signout" />
                      <Box width={"12px"} />
                      <Typography className={classes.subText}>
                        {t("about")}
                      </Typography>
                    </Grid>
                    <ChevronRight />
                  </Grid>
                </a>
                {/* <Box height={"16px"} />

                <Grid
                  onClick={myBookingNavigate}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography className={classes.subText}>
                      {t("myBooking")}
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid> */}

                <Box height={"16px"} />
                <Grid
                  onClick={Logout}
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                  className={classes.detailsCardStyle}
                  style={{ cursor: "pointer" }}
                >
                  <Grid xs={10} container alignItems="center" direction={"row"}>
                    <img src="/images/signout.svg" alt="signout" />
                    <Box width={"12px"} />
                    <Typography className={classes.subText}>
                      {t("log_out")}
                    </Typography>
                  </Grid>
                  <ChevronRight />
                </Grid>

                <Box height={"16px"} />
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                    color: "#091B29",
                  }}
                >
                  {"V" + localStorage.getItem(LocalStorageKeys.version)}
                </Typography>
              </Grid>
            </Grid>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            getLogoutdeviceToken();
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={openDrawer}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("confirmation")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setopenDrawer(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Typography className={classes.drawerHeaderTextStyle1}>
                      {t("are_you")}
                    </Typography>
                    <Box height={"32px"} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => setopenDrawer(false)}
                          fullWidth
                          className={classes.drawerButtonStyle1}
                          variant="contained"
                        >
                          {t("cancel")}
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          onClick={() => {
                            localStorage.clear();
                            history.push(Routes.login);
                            firebaseContext.deleteToken();
                          }}
                          fullWidth
                          className={classes.drawerButtonStyle}
                          variant="contained"
                        >
                          {t("log_out")}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>
            <Hidden smDown>
              <Dialog
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={openAboutUs}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("about")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setOpenAboutUs(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ height: "100px" }}
                    >
                      <img
                        src="/images/Propgoto logo-01.svg"
                        alt=""
                        style={{
                          height: "inherit",
                          width: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                className={classes.drawer}
                anchor="bottom"
                open={openAboutUs}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("about")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => setOpenAboutUs(false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid className={classes.drawerBodyStyle}>
                    <Grid
                      container
                      justifyContent={"center"}
                      alignItems={"center"}
                      style={{ height: "100px" }}
                    >
                      <img
                        src="/images/Propgoto logo-01.svg"
                        alt=""
                        style={{
                          height: "inherit",
                          width: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden>

            {/* Modal Language chaange */}
            {/* <Hidden smDown>
              <Dialog
                onClose={() => changeLanguage("modal", false)}
                className={classes.dialog}
                fullWidth={true}
                maxWidth="sm"
                open={languageModal?.opne}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.dialogStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("list.language")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => changeLanguage("modal", false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    className={classes.drawerBodyStyle}
                    style={{ height: "151px" }}
                  >
                    <SelectBox
                      label={`${t("select")} ${t("list.language")}`}
                      isRequired={false}
                      isPaginate={false}
                      createSelect={false}
                      value={languageModal?.language}
                      placeholder={""}
                      options={languageOptions}
                      onChange={(e) => changeLanguage("language", e)}
                    />

                    <Box height={"16px"} />
                    <Grid
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      container
                    >
                      <Button
                        onClick={() => saveLanguage()}
                        variant="contained"
                      >
                        {t("save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Hidden>
            <Hidden smUp>
              <Drawer
                anchor="bottom"
                className={classes.drawer}
                open={languageModal?.opne}
                onClose={() => changeLanguage("modal", false)}
              >
                <Grid
                  container
                  direction="column"
                  className={classes.bottomSheetStyle}
                >
                  <Grid
                    container
                    direction="row"
                    className={classes.drawerHeaderStyle}
                  >
                    <Grid>
                      <Typography className={classes.drawerHeaderTextStyle}>
                        {t("list.language")}
                      </Typography>
                    </Grid>
                    <Grid>
                      <IconButton
                        style={{
                          padding: "0px",
                        }}
                        onClick={() => changeLanguage("modal", false)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    className={classes.drawerBodyStyle}
                    style={{ height: "151px" }}
                  >
                    <SelectBox
                      label={`${t("select")} ${t("list.language")}`}
                      isRequired={false}
                      isPaginate={false}
                      createSelect={false}
                      value={languageModal?.language}
                      placeholder={""}
                      options={languageOptions}
                      onChange={(e) => changeLanguage("language", e)}
                    />

                    <Box height={"16px"} />
                    <Grid
                      justifyContent={"flex-end"}
                      alignItems={"flex-end"}
                      container
                    >
                      <Button
                        onClick={() => saveLanguage()}
                        variant="contained"
                      >
                        {t("save")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Drawer>
            </Hidden> */}
          </Grid>
        </>
      )}
    </Container>
  );
};
export default withNamespaces("profile")(ProfileView);
