import { Container, Typography, Grid, Hidden, Dialog, Drawer, Box, Button, IconButton, Avatar, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { tabsClasses } from "@mui/material/Tabs";
import React from "react";
import { AmenityImage, LoderSimmer, TitleBar } from "../../components";
import { AmenitiesBookingStyles } from "./style"
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Slots } from "../../components";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import { AlertContext, BackdropContext } from "../../contexts";
import { config } from "../../config";
import { eachMonthOfInterval, addDays, addMonths, eachDayOfInterval, isEqual, lastDayOfMonth, lightFormat } from "date-fns";
import { Routes } from "../../router/routes";
import BookingPaymentScreen from "./bookingPaymentScreen";
import { withNamespaces } from "react-i18next";


const AmenitiesBooking = ({t}) => {
  const { state } = useLocation()
  const size = useWindowDimensions();
  const classes = AmenitiesBookingStyles();
  const alert = React.useContext(AlertContext)
  const backdrop = React.useContext(BackdropContext)
  const [paymentDrawer, setPaymentDrawer] = React.useState(false);
  const [bookingDrawer, setBookingDrawer] = React.useState(false);
  const [companyId, setCompanyId] = React.useState("");
  const [loading, setLoading] = React.useState(true)
  const [availableSlots, setAvailableSlots] = React.useState([])
  const [bookingData, setBookingData] = React.useState("")
  const userProfileId = localStorage.getItem(LocalStorageKeys.userProfileId);
  const tabValue = state?.main?.type === "property" ? "1" : "2"
  //tabs handle change
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const history = useHistory()
  //close and open payment drawer
  const closePayment = () => {
    setBookingDrawer(false)
    setPaymentDrawer(!paymentDrawer)
  }
  //close and open booking drawer
  const closeBookPreview = (data) => {
    setBookingData(data)
    setBookingDrawer(!bookingDrawer)
  }

  const start_date = new Date()

  const end_date = state?.main?.period === "Daily" ? addMonths(start_date, 10) : addDays(start_date, 7)

  const Tabs = state?.main?.period === "Daily" ? eachMonthOfInterval(
    {
      start: start_date,
      end: end_date
    }
  ) : eachDayOfInterval(
    {
      start: start_date,
      end: end_date
    }
  )
  const [value, setValue] = React.useState(Tabs?.[0]);


  //property amenity solts
  const getSlotsForPropertyAmenities = () => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    const data = state?.main?.period === "Daily" ? {
      property_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      from_date: lightFormat(value, 'yyyy-MM-dd'),
      to_date: lightFormat(lastDayOfMonth(value), 'yyyy-MM-dd')
    } : {
      property_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      from_date: lightFormat(value, 'yyyy-MM-dd'),
      to_date: lightFormat(value, 'yyyy-MM-dd')
    }

    NetworkCall(
      `${config.api_url}/amenities_booking/available_slots`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false)
        setAvailableSlots(response?.data?.data)
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch((err) => {
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  }
  //unit amenity solts
  const getSlotsForUnitAmenities = () => {
    const data = state?.main?.period === "Daily" ? {
      unit_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      from_date: lightFormat(value, 'yyyy-MM-dd'),
      to_date: lightFormat(lastDayOfMonth(value), 'yyyy-MM-dd')
    } : {
      unit_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      from_date: lightFormat(value, 'yyyy-MM-dd'),
      to_date: lightFormat(value, 'yyyy-MM-dd')
    }

    NetworkCall(
      `${config.api_url}/amenities_booking/available_slots`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false)
        setAvailableSlots(response?.data?.data)
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  }

  const datas = [{
    avatar: "/images/hotels.jpg",
    title: state?.main?.name ?? "",
    dollar: state?.main?.rate ?? "",
    subText: state?.main?.description,
    time: state?.main?.period ?? "",
    alt: "Amenity"
  }]



  //slotbooked
  const slotBooked = () => {
    const propertyAmenityData = state?.main?.period === "Daily" ? {
      property_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      check_in_date: bookingData?.check_in_date,
      check_out_date: bookingData?.check_out_date,
      created_by: userProfileId
    } : {
      property_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      check_in_date: lightFormat(value, 'yyyy-MM-dd'),
      check_out_date: lightFormat(value, 'yyyy-MM-dd'),
      check_in_time: bookingData?.check_in_time,
      check_out_time: bookingData?.check_out_time,
      created_by: userProfileId

    }
    const unitAmenityData = state?.main?.period === "Daily" ? {
      unit_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      check_in_date: bookingData?.check_in_date,
      check_out_date: bookingData?.check_out_date,
      created_by: userProfileId

    } : {
      unit_amenity: state?.main?.id,
      amenity_period: state?.main?.period,
      check_in_date: lightFormat(value, 'yyyy-MM-dd'),
      check_out_date: lightFormat(value, 'yyyy-MM-dd'),
      check_in_time: bookingData?.check_in_time,
      check_out_time: bookingData?.check_out_time,
      created_by: userProfileId

    }
    const data = state?.main?.type === "property" ? propertyAmenityData : unitAmenityData

    NetworkCall(
      `${config.api_url}/amenities_booking/book`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setLoading(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Slot booked successfully",
        });
        history.push({
          pathname: Routes.success,
          state: {
            path: Routes.amenitiesdetails,
            main: {
              propertyId: state?.main?.propertyId,
              tab: tabValue,
              agreementId: state?.main?.agreementId
            }
          },
        });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });



  }
  React.useEffect(() => {
    if (state?.main?.type === "property") {
      getSlotsForPropertyAmenities()
    }
    if (state?.main?.type === "unit") {
      getSlotsForUnitAmenities()
    }
    setCompanyId(state?.main?.companyId)
    // eslint-disable-next-line
  }, [value, state?.main?.type])

  //booking preview modal
  const bookingPreview = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            className={classes.flexWithPadding}
          >
            <Box flexGrow={1}>
              <Typography className={classes.modalTitle}>
                {t("BookingPreview")}
              </Typography>
            </Box>
            <Box>
              <IconButton
                onClick={closeBookPreview}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} p={"16px 16px 0px"}
        >
          <div className={classes.Cardcontent}>
            <Box className={classes.flex}>
              <Box className={classes.img}>
                <Avatar />
              </Box>
              <Box flexGrow={1} marginLeft="10px">
                <Box className={classes.flex}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.titles}
                      noWrap
                    >
                      {state?.main?.name}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.flex} marginTop="0px">
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.subTitle}
                      noWrap
                    >
                      {state?.main?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.titles}
                  noWrap
                >
                  {state?.main?.rate}
                </Typography>
              </Box>
            </Box>
            <Box height="16px" />
            {/* <Box className={classes.justifyContent}>
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitle}
                  noWrap
                >
                  {"Court ID"}
                </Typography>
              </Box>
              <Box >
                <Typography
                  variant="subtitle2"
                  className={classes.title2}
                  noWrap
                >
                  {"AMH-001-S"}
                </Typography>
              </Box>
            </Box> */}
            <Box className={classes.justifyContent}>
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitle}
                  noWrap
                >
                  {t("BookingDate")}
                </Typography>
              </Box>
              <Box >
                <Typography
                  variant="subtitle2"
                  className={classes.title2}
                  noWrap
                >
                  {moment(new Date()).format("DD MMM YY, dddd")}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.justifyContent}>
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.subTitle}
                  noWrap
                >
                  {t("BookingSlot")}
                </Typography>
              </Box>
              <Box >
                <Typography
                  variant="subtitle2"
                  className={classes.title2}
                  noWrap
                >
                  {bookingData?.check_in_date ? moment(bookingData?.check_in_date).format("Do MMM YY") : moment(bookingData?.check_in_time, "HH:mm").format("hh:mm A")} - {bookingData?.check_out_date ? moment(bookingData?.check_out_date).format("Do MMM YY") : moment(bookingData?.check_out_time, "HH:mm").format("hh:mm A")}
                </Typography>
              </Box>
            </Box>

          </div>
        </Grid>
        <Grid item xs={12} className={classes.divider} />
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            spacing={1}
            p={"0px 16px"}
          >
            <Grid item xs={12}>
              <Typography
                className={classes.titles}
                style={{ marginTop: "8px" }}
              >
                {t("BillSummary")}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.flex} marginBottom="14px" >
              <Grid item xs={6}>
                <Typography className={classes.subTitle}>
                  {t("InvoiceAmount")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "right" }}>
                  <Typography className={classes.subTitle}>
                    {/* {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    }).format(Math.round(72828.2))} */}
                    {state?.main?.rate}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            {/* <Grid item xs={12} style={{ display: "flex", marginBottom: "14px" }}>
              <Grid item xs={6}>
                <Typography className={classes.subTitle}>
                  Vat Amount
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "right" }}>
                  <Typography className={classes.subTitle}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    }).format(Math.round(72828.2))}
                  </Typography>
                </div>
              </Grid>
            </Grid> */}
            <Grid
              item
              xs={12}
              className={classes.borderGrid}
            >
              <Grid item xs={6}>
                <Typography className={classes.totalAmount}>
                  {t("TotalAmountToPay")}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "right" }}>
                  <Typography className={classes.totalAmount}>
                    {state?.main?.rate}
                  </Typography>
                </div>
                <br />
              </Grid>
            </Grid>
            <Grid item xs={12} height={"16px"} />
            <Grid item xs={6}>
              <Button
                variant="outlined"
                className={classes.btn}
                fullWidth
                disabled
              >
                {t("Addtoinvoice")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                size="large"
                className={classes.btn}
                fullWidth
                onClick={closePayment}
              >
                {t("PayNow")}
              </Button>
            </Grid>
          </Grid>
          <br />
        </Grid>
      </Grid>
    )
  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={state?.main?.name}

        goBack={() => history.goBack()}
      />
      <div
        className={classes.roots}
        style={{
          height: size?.height - 60,
        }}
      >
        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            {datas?.length > 0 ? datas?.map((val) => {
              return (
                <>
                  <div>
                    <Box className={classes.box}
                      onClick={() =>
                        history.push(
                          Routes.amenitiestype +
                          "?Id=" +
                          state?.main?.id +
                          "&name=" +
                          state?.main?.name +
                          "&type=" +
                          state?.main?.type
                        )
                      }
                    >
                      <Box>
                        <Avatar src="/images/bookingHistory.svg" />
                      </Box>
                      <Box flexGrow={1} display="flex" marginTop="6px" marginLeft="8px">
                        <Box alignItems="center" marginBottom="2px">
                          <Typography className={classes.totalAmount} noWrap>
                            {t("MyBookingHistory")}
                          </Typography>
                          <Typography className={classes.textNotSelect1} noWrap>
                            {t("Viewandpayyourbookings")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"}>
                        {/* <Typography className={classes.tagStyle}>
                          {""}
                        </Typography> */}
                        <ArrowForwardIosIcon
                          fontSize="small"
                          sx={{ fontSize: "22" }}
                        />
                      </Box>
                    </Box>
                    <AmenityImage data={val}
                    />
                  </div>
                  <div className={classes.card}>
                    {/* tabs */}
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingBottom: "8px" }}>
                        <TabList
                          onChange={handleChange}
                          variant="scrollable"
                          scrollButtons
                          aria-label="scrollable auto tabs example"
                          className={classes.tabroot}
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                        >
                          {Tabs?.map((x, index) => {
                            return (
                              <Tab
                                className={isEqual(value, x) ? classes.tabItemSelect : classes.tabItem}
                                label={<>
                                  <div
                                  >
                                    <Typography className={isEqual(value, x) ? classes.textSelect1 : classes.textNotSelect1}>
                                      {state?.main?.period === "Daily" ? moment(x).format("MMM") : moment(x).format("ddd")}
                                    </Typography>
                                    <Typography className={isEqual(value, x) ? classes.textSelect2 : classes.textNotSelect2}>
                                      {state?.main?.period === "Daily" ? moment(x).format("MM") : moment(x).format("DD")}
                                    </Typography>
                                    <Typography className={classes.dot} />
                                  </div>
                                </>
                                }
                                value={x}

                              />
                            )
                          })}
                        </TabList>
                      </Box>
                      <TabPanel value={value} className={classes.panel}>
                        <Typography className={classes.name}>
                          {t("AVAILABLESLOTS")}
                        </Typography>
                        <Grid container spacing={1} height={"295px"} overflow={"auto"}>

                          {availableSlots.length > 0 ? availableSlots?.map((item) => {
                            console.log("slots", JSON.stringify(availableSlots))
                            return (
                              <>
                                {/* slots */}
                                <Grid item xs={6}>
                                  <Slots
                                    data={item}
                                    onClick={closeBookPreview}
                                  />
                                </Grid>
                              </>
                            )
                          })
                            :
                            <Typography className={classes.noData}>
                              {t("Datanotfound")}
                            </Typography>
                          }

                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </div>
                </>
              );
            }) :
              <Typography className={classes.noData}>
                {t("Datanotfound")}
              </Typography>}
          </>
        )}

        {/* payment screen */}
        {paymentDrawer &&
          <BookingPaymentScreen closePayment={closePayment} paymentDrawer={paymentDrawer} companyId={companyId} onClick={slotBooked} />
        }

        {/* Booking preview  */}
        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            maxWidth="sm"
            fullWidth
            open={bookingDrawer}
            onClose={closeBookPreview}
          >
            <div>{bookingPreview()}</div>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            anchor="bottom"
            className={classes.drawer}
            open={bookingDrawer}
            onClose={closeBookPreview}
          >
            <div>{bookingPreview()}</div>
          </Drawer>
        </Hidden>
      </div>
    </Container >
  );
};

export default withNamespaces("amenityBooking")(AmenitiesBooking)