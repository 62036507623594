import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils/common";

export const AmenityImageStyle = makeStyles((theme) => ({
  cardImage: {
    height: "192px",
    borderRadius: "4px",
  },

  imageCard: {
    borderRadius: "4px",

  },

  title: {
    flex: 1,
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "3px",
    color: "#091B29",
  },

  dollar: {
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "14px",
    fontWeight: "700",
    marginBottom: "3px",
    color: "#091B29",
  },

  subText: {
    flex: 1,
    fontSize: "12px",
    color: "#98A0AC",
  },

  time: {
    fontSize: "12px",
    color: "#98A0AC",
  },

  contentText: {
    padding: "12px !important",
  },
}));
