import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Badge,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  MenuItem,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import {
  Filter,
  LoderSimmer,
  TopNavBars,
  UnitAndRequestCard
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";

import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, requsetStatus } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: theme.palette.dashboard.primary,
  },
  screenScrollEffect: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  bottombar: {
    position: "fixed",
    bottom: "0px",
    width: "100%",
    left: "0px",
    right: "0px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "120px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "120px",
      left: "65%",
    },
  },
  menuContainer: {},
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
  menuItem: {
    position: "absolute",
    background: theme.palette.common.white,
    borderRadius: "4px",
    border: `1px solid ${theme.palette.border.primary}`,
    bottom: theme.spacing(15),
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
    },
    [theme.breakpoints.up("sm")]: {
      left: "60%",
    },
    "& .MuiMenuItem-root": {
      "&:last-child": {
        borderTop: `2px solid ${theme.palette.border.secondary}`,
      },
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  containers: {
    [theme.breakpoints.down("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },

      overflow: "hidden",
    },
    [theme.breakpoints.up("sm")]: {
      "&.MuiContainer-root": {
        paddingLeft: "12px",
        paddingRight: "12px",
      },

      overflow: "hidden",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    [theme.breakpoints.up("sm")]: {
      minHeight: "68vh",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "75vh",
    },
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
  },
  drawer: {
    "& .MuiPaper-root-MuiDrawer-paper": {
      // "background-color": "transparent",
      // "box-shadow": "none"
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  filter: {
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "8px",
  },
  scroll: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const RequestHomeScreen = (props) => {
  const { t } = props
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const [open, setOpen] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [offSet, setOffSet] = React.useState(0);
  const [requestList, setRequestList] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [requestsListCount, setRequestsListCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [statusType, setStatusType] = React.useState([]);
  const { state } = useLocation()
  const onClick = () => {
    history.push(Routes.requestDetails);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };

  //get requset
  const getAllRequsetData = (units, type, gen, offset, filter) => {
    const requestPayload = {
      tenantId: `${config.tenantid}`,
      request_units: units,
      request_type: type,
      request_status: gen,
      offset: offset,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/request`,
      NetWorkCallMethods.post,
      requestPayload,
      null,
      true,
      false
    )
      .then((response) => {
        setRequestsListCount(response?.data?.totalCount ?? "");
        if (filter) {
          setRequestList(response?.data?.data ?? []);
        } else {
          setRequestList(requestList.concat(response?.data?.data));
        }


        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // alert.setSnack({
        //   ...alert,
        //   open: true,
        //   severity: AlertProps.severity.error,
        //   msg: "Some Thing Went Wrong.",
        // });
      });
  };
  //get all units by user id
  const getAllunitsByuserId = () => {
    const temp_decoded = jwt_decode(authToken);
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let data = response?.data?.data.length > 0 ? response?.data?.data?.map(x => x?.agreement_unit).flat() : []
      getEnum(data?.length > 0 && data?.map((x) => x?.id))
      //get units for filter
      const allunits = data?.length > 0 && data?.map((val, index) => {
        let _d;
        try {
          _d = {
            label: val?.name,
            value: String(val.id),
          };
        } catch (err) {

        }

        return _d;
      });
      setUnitsfil(allunits);

    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };



  React.useEffect(() => {
    getAllunitsByuserId();
    // eslint-disable-next-line
  }, []);

  const filterData = [
    {
      id: "1",
      title: "Request Type",
      key: "request_type",
      filterType: "CHECKBOX",
      values: [
        {
          label: "General",
          value: requsetStatus.general,
        },
        {
          label: "Maintenance",
          value: requsetStatus.maintenance,
        },
      ],
    },

    {
      id: "2",
      title: "Units",
      key: "units",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "3",
      title: "Requset Status",
      key: "general",
      filterType: "CHECKBOX",
      values: statusType,
    },

  ];

  const applyFilter = (data) => {
    if (data?.request_type?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonerequesttype"),
      });
      return false;
    }
    if (data?.units?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    if (data?.general?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonegeneralstatus"),
      });
      return false;
    }

    getAllRequsetData(
      data?.units,
      data?.request_type,
      data?.general,
      0,
      true
    );
    setOffSet(0);
    setDrawer(false);
  };
  const fetchMoreData = () => {
    setOffSet(offSet + 10);
    getAllRequsetData(
      selectedFilter?.units,
      selectedFilter?.request_type,
      selectedFilter?.general,
      offSet + 10,
      false
    );
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.general_maintenance_type])
    setStatusType(stayType?.general_maintenance_type)
    getAllRequsetData(
      data,
      state ? state : [requsetStatus.general, requsetStatus.maintenance],
      stayType?.general_maintenance_type?.map(val => val?.value),
      0,
      false
    );
    setSelectedFilter({
      units: data,
      general: stayType?.general_maintenance_type?.map(val => val?.value),
      request_type: state ? state : [requsetStatus.general, requsetStatus.maintenance],
    });

  }
  return (
    <Container className={classes.root} maxWidth="sm">
      <TopNavBars />
      <Box
        display="flex"
        alignItems="center"
        sx={{
          padding: "12px",
        }}
      >
        {/* Total Request */}
        <Box flexGrow={1}>
          <Typography className={classes.total}>
            {t("Request")}&nbsp;({requestsListCount ?? '0'})
          </Typography>
        </Box>
        <Box>
          {/* Filter*/}

          <IconButton className={classes.filter} onClick={draweropen}>
            <Badge variant="dot" color="primary">
              <img src="/images/icons8-funnel.svg" alt="filter" />
            </Badge>
          </IconButton>
        </Box>
      </Box>
      <div>
        <Grid className={classes.screenScrollEffect}>
          <Grid container sx={{ backgroundColor: "#F2F4F7" }}>
            <Grid className={classes.screen} item xs={12}>
              {loading ? (
                <LoderSimmer count={10} />
              ) : (
                <>
                  {requestList?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={requestList?.length ?? null}
                      next={fetchMoreData}
                      hasMore={true}
                      height={size?.height - 190}
                      className={classes.scroll}
                    >
                      <Box height="12px" />
                      {requestList?.map((val) => (
                        <div style={{ padding: "0px 7px 0px 12px" }}>
                          <UnitAndRequestCard
                            data={val}
                            onClick={onClick}
                            type="request"
                          />
                          <Box height={'12px'} />
                        </div>
                      ))}
                    </InfiniteScroll>
                  ) : (
                    <Typography className={classes.menuText} textAlign="center">
                      {t("Norecordsfound")}
                    </Typography>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12} style={{ position: "relative" }}>
              <Box sx={{ display: "flex", float: "right" }}>
                <Box>
                  <Fab
                    color="primary"
                    className={classes.fab}
                    aria-label="add"
                    onClick={handleToggle}
                  >
                    {open ? <CloseIcon /> : <AddIcon />}
                    <Backdrop
                      className={classes.backdrop}
                      open={open}
                      onClick={handleClose}
                    >
                      <div className={classes.menuItem}>
                        <MenuItem onClick={() => history.push(Routes.general)}>
                          <Typography className={classes.menuText}>
                            &nbsp; {t("General")}
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          onClick={() => history.push(Routes.maintenanceParent)}
                        >
                          <Typography className={classes.menuText}>
                            &nbsp; {t("Maintenance")}
                          </Typography>
                        </MenuItem>
                      </div>
                    </Backdrop>
                  </Fab>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </div>


      <Hidden smDown>
        <Dialog
          open={drawer}
          onClose={drawerclose}
          fullWidth={true}
          maxWidth="sm"
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter?.agreement,
                  general: selectedFilter?.general,
                  units: selectedFilter?.units,
                  request_type: selectedFilter?.request_type,
                  maintanance: selectedFilter?.maintanance,
                }}
                unitsfil={unitsfil}
                setUnitsfil={setUnitsfil}
                onApply={applyFilter}
                setRequestList={setRequestList}
                onClose={drawerclose}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.filterDrawer}
          open={drawer}
          onClose={drawerclose}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  agreement: selectedFilter?.agreement,
                  general: selectedFilter?.general,
                  units: selectedFilter?.units,
                  request_type: selectedFilter?.request_type,
                  maintanance: selectedFilter?.maintanance,
                }}
                unitsfil={unitsfil}
                setUnitsfil={setUnitsfil}
                onApply={applyFilter}
                onClose={drawerclose}
                defaultData={selectedFilter}
                request
                setSelectedFilter={setSelectedFilter}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("request")(RequestHomeScreen)