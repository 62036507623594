import { Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";
import { UnitCard } from "./unitCard";
const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "0px 0px 16px 0px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 3px 30px #5C86CB2E",
    // textAlign: "center",
    backgroundColor: "#FFFFFF",
    paddingRight: "10px"
  },
  categoryTitle: {
    fontSize: "8px",
    backgroundColor: "#F1E6FE",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5D427F",
    float: "right",
    marginRight: "5px"
  },

  typeTitle: {
    fontSize: "8px",
    backgroundColor: "#F0F4FF",
    padding: "4px",
    alignItems: "center",
    borderRadius: "4px",
    color: "#5078E1",
  },
  topCard: {
    // padding: "12px",
    display: "flex",

  },
  bottomCard: {
    padding: "12px",
    // backgroundColor:"red",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
    textAlign: "left",
    textTransform: "capitalize",
  },
  subTitle1: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize"
  },
  subTitle2: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
  },
  subTitle: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
    fontFamily: FontFamilySwitch().semiBold
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "4px",
    padding: "3px 10px 3px 10px",
  },
  tagTextStyle: {
    fontSize: "12px",
    color: "#091B29",
    fontWeight: "bold",
    textAlign: "left",
  },
  extraDetailsStyle: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  horizontalDividerStyle: {
    backgroundColor: "#E4E8EE",
    height: "1px",
  },
  verticalDividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "20px",
  },
  iconButtonStyle: {
    backgroundColor: "#F2F4F7",
    borderColor: "#E4E8EE",
    height: "32px",
    width: "32px",
  },
  cardImage: {
    height: "127px",
    width: "101px",
    objectFit: "cover",
    borderRadius: theme.palette.borderRadius,
    [theme.breakpoints.down('sm')]: {

    },
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
    color: "black",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    height: "78vh",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  unitImg: {
    height: "250px",
    width: "100%",
    borderRadius: "4px",
    objectFit: "cotain",
  },
  titleroot2: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "8px",
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  phone: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
  },
  bottomDetails: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  viewHandbook: {
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
    cursor: "pointer",
  },
  bottomText: {
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    // width: "101px",
    padding: "4px",
    textAlign: "center",
    float: "right",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "12px",
  },
  bottomText1: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    // backgroundColor: "#071741",
    color: "white",
    width: "101px",
    // padding: "4px",
    textAlign: "center",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right"
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",

  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "0px 0px 0px 4px",
    // float: "right",

  },
  relative: {
    position: "relative"
  },
  bottomCardUnit: {
    position: "absolute",
    bottom: 0,
    borderRadius: "0px 0px 0px 4px",
    backgroundColor: "#071741",
    color: "white",
    width: "101px",
    padding: "4px",
    textAlign: "center"
  }
}));
export const UnitAndRequestCard = (props) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div>
      {/* Units data */}
      {props?.data && (
        props?.type === "unit" ?
          <div
            className={classes.root}
            onClick={() => {
              props?.type === "unit"
                ? props?.onClick(props?.data)
                : history.push(
                  Routes.requestDetails +
                  "?requestId=" +
                  props?.data?.id +
                  "&requestNo=" +
                  props?.data?.id +
                  "&requestType=" +
                  props?.data?.request_type +
                  "&status=" +
                  props?.data?.request_status
                );
            }}
            style={{ cursor: "pointer" }}
          >
            <Box alignItems="center" className={classes.topCard}>
              <Box position={"relative"}>
                {props.type === "unit" ?
                  <>
                    <img
                      src={props?.data?.logo?.length > 0 ? props?.data?.logo : "/images/unitplaceholder.svg"}
                      className={classes.cardImage}
                      alt="altimage"
                    />
                    <div className={classes.bottomCardUnit}>
                      <Typography fontSize={"10px"}>
                        {props?.data?.unit_no}
                      </Typography>
                    </div>
                  </>
                  :
                  <Box className={classes.relative}>
                    <img
                      src={
                        props?.data?.image_url?.length > 0 ? props?.data?.image_url?.substring(props?.data?.image_url?.lastIndexOf(".") + 1) !== 'mp4'
                          ? props?.data?.image_url
                          : "/images/unitplaceholder.svg" : "/images/unitplaceholder.svg"
                      }
                      className={classes.cardImage}
                      alt="altimage"
                    />
                    <div className={classes.bottomText1}>
                      {props?.data?.request_status === "Pending" && (
                        <Typography
                          variant="subtitle2"
                          noWrap
                          className={classes.progress}
                        >
                          In Progress
                        </Typography>
                      )}
                      {props?.data?.request_status === "Closed" && (
                        <Typography
                          variant="subtitle2"
                          className={classes.approved}
                        >
                          Closed
                        </Typography>
                      )}
                      {props?.data?.request_status === "Open" && (
                        <Typography
                          variant="subtitle2"
                          className={classes.delete}
                        >
                          Open
                        </Typography>
                      )}
                      {props?.data?.request_status === "Cancelled" && (
                        <Typography variant="subtitle2" className={classes.delete}>
                          Cancelled
                        </Typography>
                      )}
                    </div>
                  </Box>
                }

              </Box>
              {/* <Box sm={0.2} xs={0} /> */}

              <Box flexGrow={1} marginLeft={"10px"}>
                <Typography className={classes.title}>
                  {props?.type === "unit"
                    ? props?.data?.unit_name
                    : props?.data?.request_type}&nbsp;
                  {props?.data?.unit_category && <span className={classes.categoryTitle}>{props?.data?.unit_category}</span>}
                  {
                    props?.type === "request" &&
                    <Typography className={classes.bottomText}>
                      {props?.data?.request_no?.slice(0, 13)}
                    </Typography>
                  }

                </Typography>
                <Box height="10px" />

                <Box alignItems="center" display={"flex"}>

                  <>
                    <Typography className={classes.subTitle2}>
                      {props?.type === "unit"
                        ? props?.data?.property_name
                        : props?.data?.units?.length > 1
                          ? props?.data?.units?.length.toString() + "Units"
                          : props?.data?.units?.unit_no}
                    </Typography>

                  </>

                  {props?.type === "unit" &&
                    <>
                      {
                        props?.data?.block_name &&
                        <Box className={classes.periodStyle} />
                      }
                      <Typography className={classes.subTitle2}>
                        {props?.data?.block_name}
                      </Typography>
                    </>
                  }
                  {props?.type === "unit" && (
                    <>
                      {props?.data?.floor_name &&
                        <Box className={classes.periodStyle} />
                      }
                      <Typography className={classes.subTitle2}>
                        {props?.data?.floor_name}
                      </Typography>
                    </>

                  )}

                </Box>
                {props?.type === "unit" &&
                  <>
                    <Box height="10px" />

                    <Box alignItems="center" display={"flex"}>
                      <Typography className={classes.subTitle2}>

                        {props?.data?.unit_type_name ?? ""}
                      </Typography>
                      <Box className={classes.periodStyle} />

                      <Typography className={classes.subTitle2}>

                        {props?.data?.total_area && props?.data?.total_area + " " + props?.data?.uom_name}
                      </Typography>
                    </Box>
                    <Box height="10px" />
                    <Box marginLeft={"-10px"}>
                      <Divider />
                    </Box>
                    <Box height="10px" />
                    <Box alignItems="center" display={"flex"}>
                      <Typography className={classes.subTitle}>
                        {props?.data?.total_bed_rooms ? props?.data?.total_bed_rooms + "Beds" : "0"}
                      </Typography>
                      <Box className={classes.periodStyle} />

                      <Typography className={classes.subTitle}>
                        {props?.data?.total_baths ? props?.data?.total_baths + "Baths" : "0"}
                      </Typography>
                      <Box className={classes.periodStyle} />
                      <Typography className={classes.subTitle}>
                        {props?.data?.furnishing ? props?.data?.furnishing : props?.data?.furnishing_type}
                      </Typography>
                    </Box>

                  </>
                }
                {props?.type === "unit" && !props?.isUnit && (
                  <>
                    <Box height="8px" />

                    <Typography className={classes.subTitle2}>
                      {props?.data?.property_name}
                    </Typography>
                  </>
                )}
                {props?.type === "request" && (
                  <>

                    <Box container alignItems={"center"} direction={"row"} marginTop="10px">

                      {/* <Box
                        item
                        className={classes.tagStyle}
                        style={{ maxWidth: "fit-content" }}
                      >
                        <Typography className={classes.tagTextStyle}>
                          {props?.data?.request_status}
                        </Typography>
                      </Box> */}
                      {/* <Box className={classes.periodStyle} /> */}
                      <Typography className={classes.subTitle2}>
                        {props?.data?.subject}
                      </Typography>
                    </Box>
                    <Box alignItems={"center"} display={"flex"} marginTop="10px">
                      <img
                        sx={{ padding: "10px" }}
                        src={
                          props?.type === "request"
                          && "/images/icons8_calendar.svg"
                        }
                        alt="altimage"
                      />
                      <Box width="8px" />
                      <Typography className={classes.extraDetailsStyle}>
                        {props?.type === "unit" ? (
                          <>{props?.data?.total_area} </>
                        ) : (
                          props?.data?.raised_on
                        )}
                      </Typography>
                    </Box>

                  </>
                )}
              </Box>
              {/* <Box
                container
                sm={2}
                xs={1}
                justifyContent="right"
                alignItems="center"
              >
                <IconButton
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props?.type === "unit"
                      ? props.onClick(props?.data)
                      : history.push(
                        Routes?.requestDetails +
                        "?requestId=" +
                        props?.data?.id +
                        "&requestNo=" +
                        props?.data?.request_no +
                        "&requestType=" +
                        props?.data?.request_type
                      );
                  }}
                  className={classes.iconButtonStyle}
                >
                  <ChevronRightIcon />
                </IconButton>
              </Box> */}
            </Box>
            {/* <Box className={classes.horizontalDividerStyle} />
            {
              !props?.isUnit &&

              <Box
                container
                className={classes.bottomCard}
                direction="row"
                justifyContent="space-around"
                alignItems="center"

              >
                <Box
                  container
                  sm={4}
                  xs={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    sx={{ padding: "10px" }}
                    src={
                      props?.type === "request"
                      && "/images/icons8_calendar.svg"
                    }
                    alt="altimage"
                  />
                  <Box width="8px" />
                  <Typography className={classes.extraDetailsStyle}>
                    {props?.type === "unit" ? (
                      <>{props?.data?.total_area} </>
                    ) : (
                      props?.data?.raised_on
                    )}
                  </Typography>
                </Box>
                <Box className={classes.verticalDividerStyle} />
                <Box
                  container
                  sm={4}
                  xs={4}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    src={
                      props?.type === "request"
                      && "/images/Group_619.svg"
                    }
                    alt="altimage"
                  />
                  <Box width="8px" />
                  <Typography className={classes.extraDetailsStyle} noWrap>
                    {props?.type === "unit"
                      ? props?.data?.unit_type
                      : props?.data?.units?.length > 1
                        ? props?.data?.units?.length.toString() + " Units"
                        : props?.data?.units?.unit_no}
                  </Typography>
                </Box>
              </Box>
            } */}
          </div>
          :
          <UnitCard data={props?.data} />

      )}
    </div>
  );
};
