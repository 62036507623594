import { makeStyles } from "@mui/styles";
import moment from 'moment';
import React, { useContext } from "react";
import { useHistory } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { config } from "../../../config";
import { AlertContext } from "../../../contexts";
import { UNREADNOTIFICATION } from "../../../graphql/mutations";
import { NetworkCall } from "../../../networkcall";
import { Routes } from "../../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../../utils";
import { AnnouncementCard } from "./components";
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
const useStyles = makeStyles((theme) => ({
    slider: {
        height: "280px",
        transition: "all 200ms linear",
        "transform": "scale(0.8)",
        "WebkitTransform":"scale(0.8)",
        "&.swiper-slide-active": {
            transform: "scale(1) !important",
   
        },

    }

}))

export const CarouselSliders = (props) => {

    const { list } = props;
    const history = useHistory();
    const alert = useContext(AlertContext);
    const classes = useStyles()


    const onClickSlider = ({ id, is_read }) => {

        if (!is_read) {
            const variables = {
                query: UNREADNOTIFICATION,
                variables: {
                    id: id,
                    updatedData: {
                        is_read: true,
                    },
                },
            };

            NetworkCall(
                `${config.graphql_url}`,
                NetWorkCallMethods.post,
                variables,
                null,
                true,
                false
            )
                .catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: "Some Thing Went Wrong.",
                    });
                });
        }

        history.push(Routes.announcementDetails + "?Id=" + id)
    }

    return (

        <Swiper
            spaceBetween={1}
            slidesPerView={1.5}
            centeredSlides={true}
            roundLengths={true}
            loop={true}
            loopAdditionalSlides={10}
        >
            {
                list?.map((_, index) =>
                (
                    < SwiperSlide
                        key={index}
                        className={classes.slider}
                    >
                        <AnnouncementCard
                            logo={_?.description?.length > 0 ? _?.description[0]?.images : '/images/announsmentnot.svg'}
                            category={_?.category ? _?.category : "-"}
                            title={_?.title ? _?.title : "-"}
                            postedOn={_?.triggered_at ? `Posted at ${moment(new Date(_?.triggered_at)).fromNow()}` : "-"}
                            onClickSlider={() => onClickSlider(_)}
                        />
                    </SwiperSlide>
                ))
            }

        </Swiper >

    );
};
