import * as React from "react"

const VisitorRequest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.309}
    height={17.473}
    {...props}
  >
    <g fill={props?.color}>
      <path
        data-name="Path 1019"
        d="M8.155 0a4.66 4.66 0 1 1-4.66 4.66A4.66 4.66 0 0 1 8.155 0Z"
        opacity={0.7}
      />
      <path
        data-name="Path 1018"
        d="M13.59 11.649H2.718A2.82 2.82 0 0 0 0 14.561a2.82 2.82 0 0 0 2.718 2.912H13.59a2.82 2.82 0 0 0 2.718-2.912 2.82 2.82 0 0 0-2.718-2.912Z"
      />
    </g>
  </svg>
)

export default VisitorRequest
