import React from "react";
import FileManagerView from './fileManagerView';
import { withBottombar } from "../../HOCs";

class FileManagerViewParent extends React.Component {
    render() {
        return <FileManagerView />;
    }
}

export default withBottombar(FileManagerViewParent);
