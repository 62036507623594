import { Avatar, Box, Divider, Grid, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: theme.palette.background.paper,
    margin: "auto 4px",

    boxShadow: "0px 3px 30px #5C86CB2E"
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    // borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  cancel: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,
  },
  img: {
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },
  received: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  rejected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
  },
  boxes: {
    flexFlow: 'wrap !important'
  },
  avatar: {
    height: "124px",
    width: "100%",
    objectFit: "cover",
    [theme.breakpoints.down(321)]: {
      height: "142px",

    },
  },
  bold: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  semiBold: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,

  },
  bottomText: {
    bottom: 0,
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
    width: "110px",
    padding: "4px",
    textAlign: "center"
  },
  bottomText1: {
    position: "absolute",
    bottom: "0px",
    color: "white",
    textAlign: "center",
    right: "0px",
    left: "0px"
  },
  collected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,

  }
}));

export const DeliveryCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <Box sx={{ position: "relative" }}>
      <Grid container className={classes.card}
        onClick={() =>
          history.push(Routes.deliveryDetails + "?passId=" + props?.data?.id)
        }
      >

        <Grid item xs={4} md={3}>
          <Box style={{ position: "relative" }}>
            {props?.data?.vehicle_image ?
              <img src={props?.data?.vehicle_image} alt="" className={classes.avatar} /> :
              <Avatar className={classes.avatar} src={
                props?.parking
                  ? "/images/parkingslot.svg"
                  : "/images/workergate.svg"
              } variant="square" />
            }
            <Box position={"absolute"} className={classes.bottomText1}>
              {props?.data?.delivery_status === "Yet To Receive" && (
                <Typography
                  variant="subtitle2"
                  noWrap
                  className={classes.progress}
                >
                  Yet To Receive
                </Typography>
              )}

              {props?.data?.delivery_status === "Collected" && (
                <Typography
                  variant="subtitle2"
                  className={classes.collected}
                  style={{ backgroundColor: "#5AC782" }}
                >
                  Collected
                </Typography>
              )}
              {props?.data?.delivery_status === "Received At Gate" && (
                <>
                  <Typography
                    variant="subtitle2"
                    noWrap
                    className={classes.received}
                  >
                    Received
                  </Typography>
                </>
              )}
              {props?.data?.delivery_status === "Cancelled" && (
                <Typography variant="subtitle2" className={classes.cancel}>
                  Cancelled
                </Typography>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} md={9} alignItems="center" sx={{ padding: "12px" }}>
          <Typography noWrap className={classes.title}>
            {props?.data?.description}
          </Typography>
          <Stack direction="row" marginTop="6px" >
            <Typography

              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.gate}
            </Typography>

          </Stack>
          <Stack direction="row" marginTop="6px" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
            <Typography

              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.property_name}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.sub}
              noWrap
            >
              {props?.data?.unit?.[0]?.unit_no}
            </Typography>
          </Stack>
          <Stack direction="row" marginTop="6px" spacing={1}>
            <Typography
              variant="subtitle2"
              className={classes.bold}
              noWrap
            >

              {props?.data?.delivery_collected_date ? "Collected Date -" : "Request On -"}
            </Typography>

            <Typography
              variant="subtitle2"
              className={classes.bold}
              noWrap
            >

              {moment(props?.data?.delivery_collected_date ?? props?.data?.request_on)
                .tz(moment.tz.guess())
                .format("DD MMM YY ")}
            </Typography>
          </Stack>

        </Grid>
      </Grid>

    </Box>
  );
};
