import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "10px",
    padding: "8px",
    borderBottom: "1px solid #E4E8EE",
  },

  arrow: {
    fontSize: "16px",
  },

  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
  },
  title: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
  },
  dot: {
    fontSize: "10px",
    color: "#CED3DD",
    margin:"auto 4px"
  },
  endbtn: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
  },
}));

export const Paymenthistory = (props) => {
  const classes = useStyles(props);
  moment.defaultFormat = "DD MMM YY";

  return (
    <div>
      <Grid onClick={() => props.onClick(props?.data)} container justifyContent="center">
        <Grid item xs={12} className={classes.root}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="body1" className={classes.title}>
                {props?.data?.amount_tagged &&
                  (props?.data?.symbol+" "+ Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.data?.amount_tagged)))}
              </Typography>

              <Typography className={classes.sub} whiteSpace="nowrap" marginTop="4px">
               {props?.data?.settlement_no}
                <FiberManualRecordIcon
                  size="small"
                  className={classes.dot}
                />
                {/* {props.data.invoice && (
                  <>
                    {props.data.invoice}&nbsp;Invoice
                    <FiberManualRecordIcon
                      size="small"
                      className={classes.dot}
                    />
                  </>
                )}
                */}
                {moment(props?.data?.created_at)
                  .tz(moment.tz.guess())
                  .format()}
               
                {props?.data?.origin &&
                <FiberManualRecordIcon size="small" className={classes.dot} />
                }
                
                {props?.data?.origin ?? ""}

              </Typography>
            </Box>
            <Box marginLeft={2}>
              <IconButton className={classes.endbtn} size="small">
                <ArrowForwardIosRoundedIcon
                  size="small"

                  className={classes.arrow}
                />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
