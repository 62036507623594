import AddIcon from "@mui/icons-material/Add";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,

  Fab,
  Grid,
  IconButton,
  Tab,
  Tabs
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ContactList, DialogDrawer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import {
  GET_CONTACT_DROPDOWN,
  GET_CONTACT_DROPDOWN_VIEW
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { useApolloClient } from "@apollo/client";
import { SelectBox } from "../../components/dropDown";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  tabroot: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    padding: "0px",
    minHeight: "auto",
    borderRadius: "4px",
    "& .MuiButtonBase-root-MuiTab-root": {
      borderRadius: "4px",
    },
    minWidth: "auto",
    border: "1px solid #E4E8EE",
    marginRight: "8px",
    backgroundColor: "white",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "4px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },

  title: {
    border: "1px solid /serviceE4E8EE",
    padding: "8px 14px",
    borderRadius: "4px",
    background: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "4px",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    color: "white",
  },
  divider: {
    border: "2px solid #F5F7FA",
    marginTop: "18px",
  },
  Label: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "14px",
  },
  field: {
    marginTop: "10px",
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
    marginTop: "16px",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "10px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "40px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    width: "100%",
  },
  filterDrawer: {
    "& .MuiDrawer-paper": {
      width: "-webkit-fill-available",
    },
  },
}));

const ContactDetails = (props) => {
  const { t } = props
  const backdrop = React.useContext(BackdropContext);
  const classes = useStyles(props);
  const history = useHistory();
  const size = useWindowDimensions();
  const [myContacts, setMyContacts] = React.useState();
  const [publicContacts, setPublicContacts] = React.useState();
  const [propertyType, setpropertyType] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState([]);
  const { state } = useLocation();
  const action = "create_contact";
  const [value, setValue] = React.useState("1");
  const [drawer, setDrawer] = React.useState(null);
  const [professionId, setProfessionId] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const alert = React.useContext(AlertContext);
  const client = useApolloClient();
  const [loading1, setLoading1] = React.useState(null);



  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const handleChange = (event, newValue) => {
    // setSelectedFilter([]);
    // setSelectedFilter(professionMaster.map((val) => val?.value));
    setValue(newValue);
  };

  const goBack = () => {
    history.push(state ? Routes.dashboard : Routes.service);
  };

  const action2 = "view_contact";
  //view details
  const viewDetails = (data) => {
    // setView(true)
    history.push(Routes.contacts + "?action=" + action2 + "&id=" + data.id);
  };

  const getagreementUnitid = () => {
    NetworkCall(
      `${config.api_url}/community/community_units_filter`,
      NetWorkCallMethods.post,
      {}, null, true, false
    ).then((response) => {
      const allunits = response?.data?.data?.agreement_units?.map((val) => {
        let _d;
        try {
          _d = {
            label: val?.unit?.name,
            value: String(val?.id),
            propertyId: val?.unit?.property?.id
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }

        return _d;
      });
      setpropertyType(allunits.flat());
    }).catch(() => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    });
  }


  // get professionid
  const getProfessionid = () => {
    client
      .query({
        query: GET_CONTACT_DROPDOWN_VIEW(),
        fetchPolicy: "network-only",
        variables: {
        },
      })
      .then((response) => {
        let prof_id = response?.data?.profession_master.map(
          (val) => val.value
        );
        setProfessionId(prof_id);
        // setSelectedFilter(response?.data?.profession_master.map((val) => val?.value));
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  // my contacts
  const getMyContacts = () => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Loading",
    // });
    const data = {
      tenantId: `${config.tenantid}`,
      agreement_unit_id: propertyType.map((val) => val.value),
      profession_id: professionId,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/community/contact/private`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setMyContacts(response?.data?.data);
        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch(() => {
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  // Public contacts
  const getPublicContacts = () => {
    // backdrop.setBackDrop({
    //   ...backdrop,
    //   open: true,
    //   message: "Loading",
    // });
    const data = {
      tenantId: `${config.tenantid}`,
      property_id: propertyType.map((val) => val.propertyId),
      profession_id: professionId,
      offset: 0,
      limit: 10,
    };
    NetworkCall(
      `${config.api_url}/community/contact/public`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setPublicContacts(response?.data?.data);
        setLoading(false);
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        // backdrop.setBackDrop({
        //   ...backdrop,
        //   open: false,
        //   message: "",
        // });
      });
  };

  React.useEffect(() => {
    getProfessionid();
    getagreementUnitid();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (value === "1" && professionId.length > 0 && propertyType.length > 0) {
      getMyContacts();
    }
    if (value === "2") {
      getPublicContacts();
    }
    // eslint-disable-next-line
  }, [value, professionId, propertyType]);

  //apply filter
  const applyFilter = () => {
    if (selectedFilter?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("AtleastselectoneProfessionType"),
      });
      return false;
    }

    if (value === "1") {
      const data = {
        tenantId: `${config.tenantid}`,
        agreement_unit_id: propertyType.map((val) => val.value),
        profession_id: selectedFilter?.length > 0 && selectedFilter?.map((val) => val?.value),
        offset: 0,
        limit: 10,
      };
      NetworkCall(
        `${config.api_url}/community/contact/private`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          drawerclose();
          setMyContacts(response?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    if (value === "2") {
      const data = {
        tenantId: `${config.tenantid}`,
        property_id: propertyType.map((val) => val.propertyId),
        profession_id: selectedFilter?.length > 0 && selectedFilter?.map((val) => val?.value),
        offset: 0,
        limit: 10,
      };
      NetworkCall(
        `${config.api_url}/community/contact/public`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          drawerclose();
          setPublicContacts(response?.data?.data);
          setLoading(false);
        })
        .catch(() => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
  };
  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {

      case "Profession":
        result = await client.query({
          query: GET_CONTACT_DROPDOWN(),
          variables: {
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.profession_master],
          hasMore:
            array?.length + result?.data?.profession_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };
  return (
    <>
      <Container className={classes.root} maxWidth="sm">
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <TitleBar text={t("Contact")} goBack={goBack} />
            <div style={{ height: size.height - 60, overflow: 'auto' }}>
              <Grid container>
                <Grid item xs={10.5}>
                  {/* Tabs section */}
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="none"
                    textColor="secondary"
                    className={classes.tabroot}
                    variant="scrollable"
                    visibleScrollbar={false}
                    scrollButtons={false}
                  >
                    <Tab
                      className={
                        value === "1" ? classes.tabItemSelect : classes.tabItem
                      }
                      label={
                        <span
                          className={
                            value === "1" ? classes.titleselect : classes.title
                          }
                        >
                          {value === "1" && (
                            <img src="/images/contactdetails.svg" alt=" " />
                          )}
                          &nbsp;{t("MyContacts")}
                        </span>
                      }
                      value="1"
                    />
                    <Tab
                      className={
                        value === "2" ? classes.tabItemSelect : classes.tabItem
                      }
                      label={
                        <span
                          className={
                            value === "2" ? classes.titleselect : classes.title
                          }
                        >
                          {value === "2" && (
                            <img
                              src="/images/contactdetails.svg"
                              alt=" "
                              style={{ marginTop: "-4px" }}
                            />
                          )}
                          &nbsp;&nbsp;{t("PublicContacts")}
                        </span>
                      }
                      value="2"
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton className={classes.filter} onClick={draweropen}>
                    <img src="/images/icons8-funnel.svg" alt="filter" />
                  </IconButton>
                </Grid>

                {value === "1" && (
                  <>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      <Grid item xs={12}>
                        {myContacts?.map((val) => {
                          return (
                            <ContactList
                              data={val}
                              switch={true}
                              onClick={viewDetails}
                              MyContacts={getMyContacts}
                              callonly
                            />
                          );
                        })}
                      </Grid>
                    )}
                  </>
                )}
                {value === "2" && (
                  <>
                    {loading ? (
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    ) : (
                      <Grid item xs={12}>
                        {publicContacts?.map((val) => {
                          return (
                            <ContactList
                              data={val}
                              public
                              onClick={false}
                              call
                            />
                          );
                        })}
                      </Grid>
                    )}
                  </>
                )}{" "}
                <Fab
                  color="primary"
                  className={classes.fab}
                  onClick={() =>
                    history.push(Routes.contacts + "?action=" + action)
                  }
                >
                  <AddIcon />
                </Fab>
              </Grid>
            </div>



            {/* filter drawer */}
            <DialogDrawer
              height={"400px"}
              open={drawer}
              header={"Contact Filter"}
              padding={"16px"}
              component={<>
                <Grid container>
                  <Grid item xs={12}>
                    <SelectBox
                      value={selectedFilter}
                      label={t("SelectProfession")}
                      placeholder={t("SelectProfession")}
                      isPaginate
                      loadOptions={(search, array) =>
                        loadOptions(search, array, "Profession")
                      }
                      loading={loading1 === "Profession"}
                      debounceTimeout={800}
                      isMulti
                      onChange={(value) => setSelectedFilter(value)}

                    />
                  </Grid>

                  <Grid item xs={12} marginTop={30} >
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.applybtn}
                      onClick={applyFilter}
                    >
                      {"Apply"}
                    </Button>
                  </Grid>
                </Grid>
              </>}
              onClose={drawerclose}
            />

          </>
        )}
      </Container>
    </>
  );
};

export default withNamespaces("contacts")(ContactDetails)