import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, Message, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import { PasswordInfo } from "../../components/passwordInfo";
const useStyles = makeStyles((theme) => ({
  backgroundImage: {
    backgroundColor: "#F2F4F7",
    height: "213px",
    backgroundImage: `url("/images/Select house-cuate.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "100%"
  },
  content: {
    width: "inherit",
  },
  text: {
    fontSize: "24px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 6px 10px #00000014",
    padding: "16px",
    "&:hover": {
      color: "#FFFFFF",
      backgroundColor: "#5078E1",
    },
  },
  buttonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
  },
  img: {
    height: "8%",
    width: "28%",
  },
  btnCard:{
    position:"sticky",
    bottom:"0",
    padding:"16px",
    backgroundColor:"#fff"
  },
  // main:{
  //   height:"100vh"
  // }
}));

const InitialState = {
  email: "",
  enterPassword: "",
  confirmPassword: "",
  error: {
    email: "",
    enterPassword: "",
    confirmPassword: "",
  },
};

const SetupAccountDetails = ({t}) => {
  const classes = useStyles();
  const history = useHistory();
  const [account, setAccount] = React.useState({ ...InitialState });
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();

  const search = useLocation().search;
  const welcomePageAuthToken = new URLSearchParams(search).get(
    "welcomeAuthToken"
  );
  // const decoded =jwt_decode(welcomePageAuthToken);
  const [decoded, setDecoded] = React.useState(null);
  React.useEffect(() => {
    if (welcomePageAuthToken !== null && welcomePageAuthToken !== "") {
      setDecoded(jwt_decode(welcomePageAuthToken));
    }
    // eslint-disable-next-line
  }, []);

  const updateState = (key, value) => {
    let error = account.error;
    error[key] = "";
    setAccount({ ...account, [key]: value, error });
  };
  const isIamValideToCreateAccount = () => {
    let isValid = true;
    let error = account.error;

    const is_password_strong_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;
    const is_password_strong = is_password_strong_regex.test(account.enterPassword)


    //Checking enterPassword
    if (account.enterPassword.length === 0) {
      isValid = false;
      error.enterPassword = Message.English.requiredMessage(t("Password"));
    } else if (!Boolean(is_password_strong)) {
      isValid = false;
      error.enterPassword = "Password is not stong";
    }

    //Checking confirmPassword
    if (account.confirmPassword.length === 0) {
      isValid = false;
      error.confirmPassword =
        Message.English.requiredMessage(t("ConfirmPassword"));
    }
    if (account.confirmPassword.length > 0) {
      if (
        account.enterPassword.length > 0 &&
        account.enterPassword !== account.confirmPassword
      ) {
        isValid = false;
        error.confirmPassword = t("PassworddoesnotMatch");
      }
    }
    if (
      account.email &&
      account.enterPassword !== "" &&
      account.confirmPassword !== ""
    ) {
      if (account.enterPassword === account.confirmPassword) {
        isValid = true;
      }
    }

    setAccount({ ...account, error });
    return isValid;
  };

  const onSendAccountBtnClicked = () => {
    if (isIamValideToCreateAccount()) {
      const payload = {
        password: account.confirmPassword,
      };
      NetworkCall(
        `${config.auth_api_url}/auth/updatepassword/?token=${welcomePageAuthToken}`,
        NetWorkCallMethods.post,
        payload,
        null,
        false,
        false
      )
        .then((response) => {
          if (response.status === 201) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.success,
              msg: t("Passwordcreatedsuccessfully"),
            });
            localStorage.clear();
            history.push(
              Routes.login + "?welcomeAuthToken=" + welcomePageAuthToken
            );
          }
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      return false;
    }
  };

  return (
    <>
      <Container maxWidth="sm" style={{ padding: 0 , position:"relative" ,height:"100vh"}}>
        <Box className={classes.main}>
        <Grid container>
          <Grid
            item
            className={classes.content}
            style={{ height: size.height > 734 ? size.height - 94 : 640 }}
          >
            <Grid item xs={12} className={classes.backgroundImage} />
            <Grid style={{ padding: "0px 16px 0px 16px" }}>
              <Box height={"24px"} />
              <Grid
                container
                xs={12}
                direction="row"
                justifyContent={"space-between"}
                alignContent={"center"}
              >
                <img
                  src="/images/logoOne.svg"
                  alt="logo"
                  className={classes.img}
                />
                <img
                  src="/images/PA Logo.svg"
                  alt="logo"
                  className={classes.img}
                />
              </Grid>
              <Box height={"16px"} />
              <Grid item xs={12}>
                <Typography className={classes.text}>
                  {t("Setupyouraccountdetailsbelow")}
                </Typography>
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <TextBox
                  label={Message.English.createAccount.email.label}
                  placeholder={Message.English.createAccount.email.placeholder}
                  isError={account.error.email.length > 0}
                  errorMessage={account.error.email}
                  value={decoded && decoded.email_id ? decoded.email_id : ""}
                  isReadonly
                  disabled
                  isRequired
                />
              </Grid>
              <Box height={"32px"} />
              <Grid item xs={12}>
                <TextBox
                  value={account.enterPassword}
                  type="password"
                  onChange={(e) => updateState("enterPassword", e.target.value)}
                  label={Message.English.createAccount.enterPassword.label}
                  placeholder={
                    Message.English.createAccount.enterPassword.placeholder
                  }
                  isError={account.error.enterPassword.length > 0}
                  errorMessage={account.error.enterPassword}
                  isRequired
                />
               
              </Grid>
              <Box height={"24px"} />
              <Grid item xs={12}>
                <TextBox
                  value={account.confirmPassword}
                  type="password"
                  onChange={(e) =>
                    updateState("confirmPassword", e.target.value)
                  }
                  label={Message.English.createAccount.confirmPassword.label}
                  placeholder={
                    Message.English.createAccount.confirmPassword.placeholder
                  }
                  isError={account.error.confirmPassword.length > 0}
                  errorMessage={account.error.confirmPassword}
                  isRequired
                />
              </Grid>
              <Grid item xs={12}>
              <PasswordInfo />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <Box height={'50px'}/>
        </Box>
        <Box className={classes.btnCard}>
        <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={onSendAccountBtnClicked}
            >
              <Typography className={classes.buttonTextStyle}>
                {t("Setpassword")}
              </Typography>
          </Button>
        </Box>
      </Container>
    </>
  );
};
export default withNamespaces("welcomePage")(SetupAccountDetails)
