import { Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    boxShadow: "0px 3px 30px #5C86CB2E"
  },

  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },

  sub: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },

  checksub: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary,
  },
  checkheading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  bottom: {
    padding: "12px",
    marginTop: "-20px",
  },
  span: {
    backgroundColor: "#5078E11E",
    color: "#98A0AC",
    fontSize: "10px",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "6px",
    padding: "4px",
    borderRadius: "4px"

  },
  sucess: {
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.main,
    }
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    // padding: 2,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    }
  }
}));

const SwiftInOutCard = (props) => {
  const {t} = props
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.card}>
        {props?.data?.domestic_helper_request &&
          <>
            <Box alignItems="center" p={2} marginTop="-8px">
              <Typography variant="subtitle2" className={classes.checkheading}>
                {t("APPROVEDUNITSASDOMESTICHELPER")}
              </Typography>
              <Typography variant="subtitle2" className={classes.checksub}>
                {props?.data?.domestic_helper_request?.unit?.unit_no}
              </Typography>
            </Box>
            <Divider />
          </>
        }
        <Grid container spacing={1} className={classes.bottom}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              {t("CheckedIn")}
            </Typography>
            {props?.data?.check_in_time ?
              <Typography variant="subtitle2" className={classes.checksub}>
                {props?.data?.check_in_time ? moment(props?.data?.check_in_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" className={classes.checkheading}>
              {t("CheckedOut")}
            </Typography>
            {props?.data?.check_out_time ?
              <Typography variant="subtitle2" className={classes.checksub}>
                {props?.data?.check_out_time ? moment(props?.data?.check_out_time).tz(moment.tz.guess())
                  .format("DD MMM YY  hh:mm:a") : "-"}
              </Typography> : " - "
            }
          </Grid>

        </Grid>
      </div>
    </>
  );
};
export default withNamespaces("swiftInOutDetails")(SwiftInOutCard)