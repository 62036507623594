import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.dashboard.primary,
    },
    quotationRoot: {
        padding: "12px"
    },
    actionRoot: {
        backgroundColor: "white",
        padding: "12px 16px 16px 16px",
        borderRadius: "4px 4px 0px 0px",
        borderTop: "1px solid #E4E8EE",
    },
    notInterestedStyle: {
        textAlign: "center",
        textDecoration: "underline",
        color: "#5078E1",
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        cursor: "pointer",
        marginTop: "12px"
    },
    drawerButtonStyle: {
        padding: "12px"
    }
}));