import { Box, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ViewAmenitiesHeaderStyle } from './style'
import SearchIcon from '../../../assets/searchIcon';
import { useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { AuthContext } from '../../../contexts';

const ViewAmenitiesHeader = ({data={}, t}) => {
    const auth = React.useContext(AuthContext)
    const classes = ViewAmenitiesHeaderStyle({ language: auth?.auth?.auth?.language })
    const history = useHistory()
    const back = () => {
        history.goBack()
    }
    return (
        <Box p={1} className={classes.box}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <IconButton className={classes.icon_btn} onClick={back}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Stack>
                        <Typography className={classes.name}>{data?.amenity_category} {t("Amenities")}</Typography>
                        <Typography className={classes.subtitle}>{data?.amenitiesItems?.length} {data?.amenity_category} {t("amenitiesavailable")}</Typography>
                    </Stack>
                </Stack>
                <Box>
                    <SearchIcon />
                </Box>
            </Stack>
        </Box>
    )
}

export default withNamespaces("amenitiesBooking")(ViewAmenitiesHeader)