import { Box, Button, Divider, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
import { CancelBookingStyle } from './style'

const CancelBooking = ({ submit = () => false, remark = "", setRemarkValue = () => false, t }) => {
    const auth = React.useContext(AuthContext)
    const classes = CancelBookingStyle({ language: auth?.auth?.auth?.language })

    return (
        <Box>
            <Stack p={2}>
                <Typography className={classes.header}>{t("ReasonforCancellation")}</Typography>
                <TextField
                    multiline
                    minRows={4}
                    value={remark}
                    onChange={(e) => setRemarkValue(e.target.value)}
                />
            </Stack>
            <Divider></Divider>
            <Box p={1}>
                <Button variant='contained' fullWidth onClick={submit} className={classes.submitBtn}>{t("Submit")}</Button>
            </Box>
        </Box>
    )
}

export default withNamespaces("amenitiesBooking")(CancelBooking)