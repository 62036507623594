
import { Grid, Typography, Container, } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/system';
import * as React from 'react';
const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 0px",
        backgroundColor: "white"
    },
    iconBoxStyle: {
        height: "50px",
        width: "50px",
        borderRadius: theme.palette.borderRadius,
        backgroundColor: "#F5F7FA",
        padding: "16px"
    },
    typeStyle: {
        fontSize: "12px",
        color: "#98A0AC",
        fontWeight: "bold",
        textAlign: "left",
    },
    valueStyle: {
        fontSize: "12px",
        color: "#091B29",
        fontWeight: "bold",
        textAlign: "left",
    },
}))
export const ContactDetails = (props) => {
    const classes = useStyles();


    return (<>
        {props?.data?.map((item) => {
            return (<Container maxWidth="sm" sx={{ padding: 0 }}>
                <Grid
                    container
                    alignItems="center"
                    direction="row"
                    className={classes.root}>
                    <Grid
                        item>
                        <Box className={classes.iconBoxStyle}>
                            <img src={item.type === "Contact Name" ? "/images/icons8_person.svg" : "/images/icons8_call.svg"} alt="altimage" />
                        </Box>
                    </Grid>
                    <Box width="16px" />
                    <Grid
                        item
                        justifyContent="left"
                        direction="column">
                        <Typography
                            className={classes.typeStyle}>
                            {item.type}
                        </Typography>
                        <Box height="6px" />
                        <Typography
                            className={classes.valueStyle}>
                            {item.value}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>);
        })}</>
    );
}
