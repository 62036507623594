import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, AlertProps, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertContext } from "../../contexts";
import { PropertyListCard } from "../../components/propertyListCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  roots: {
    margin: "0px 12px",
    padding: "0px 4px",
  },
  image: {
    objectFit: "cover",
    width: "101px",
    height: "113px",
  },
  card: {
    backgroundColor: "white",
    marginTop: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px",
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "10px",
  },
  noData: {
    color: "#999",
    textAlign: "center",
    marginTop: "20vh",
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px"
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    margin: "10px 0px 0px 12px",
    alignItems: "center"
  }
}));

const Utilities = (props) => {
  const { t } = props
  const classes = useStyles();
  const history = useHistory();
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const searchURL = useLocation().search;
  const agreementId = new URLSearchParams(searchURL).get("Id");


  const getproperty = () => {
    const data = JSON.stringify({
      agreementId: [agreementId],
      tenantId: `${config.tenantid}`,
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDatas(response?.data?.data);
        if (response.data.data?.propertyInfo?.length === 1) {
          history.replace(
            Routes.utilitiesdetails +
            "?propertyId=" + response.data.data?.propertyInfo?.[0]?.property_id +
            "&name=" + response.data.data?.propertyInfo?.[0]?.property_name)
        } else {
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    getproperty()
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={t("Utilities")}

        goBack={() => history.goBack()}
      />
      <div
        style={{
          height: size?.height - 60,
          overflow: "scroll",
        }}
        className={classes.roots}
      >
        <Typography className={classes.total}>
          {t("Properties")}&nbsp;({datas?.propertyCount > 0 ? datas?.propertyCount : 0}
          )
        </Typography>

        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            <Grid container spacing={1}>
              {datas?.propertyInfo?.length > 0 ? datas?.propertyInfo?.map((val) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <PropertyListCard
                        normal
                        data={val}
                        onClick={() =>
                          history.push(
                            Routes.utilitiesdetails +
                            "?propertyId=" +
                            val?.property_id +
                            "&name=" +
                            val?.property_name
                          )
                        }
                      />
                    </Grid>

                  </>
                );
              }) :
                <Typography className={classes.noData}>
                  {t("Datanotfound")}
                </Typography>}
            </Grid>

          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("utilites")(Utilities)