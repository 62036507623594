import { Box, Container } from "@mui/material";
// import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar, UseDebounce } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { ParkingCard, Search } from "../agreementparking/component";
import { ParkinguseStyles } from "../agreementparking/style";
import { withNamespaces } from "react-i18next";

const Ownerslist = (props) => {
      const { t } = props
      const classes = ParkinguseStyles();
      const history = useHistory();
      const searchURL = useLocation().search;
      const propertyId = new URLSearchParams(searchURL).get("propertyId");
      const debounce = UseDebounce()
      const size = useWindowDimensions();
      const [offset, setOffset] = React.useState(0);
      const [datas, setDatas] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const [search, setSearch] = React.useState("")

      React.useEffect(() => {
            // getUnitids();
            getOwnersList(0, 10, "", false);
            // eslint-disable-next-line
      }, []);

      const getOwnersList = (offset, limit, searchText, filter) => {
            const data = {
                  tenantId: `${config.tenantid}`,
                  property_id: propertyId,
                  offset: offset,
                  limit: limit,
                  search: searchText
            };
            NetworkCall(
                  `${config.api_url}/owner-resident/getAllOwners`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        if (response?.data?.data?.length > 0) {
                              let dataSet = [];
                              response?.data?.data?.map((val) => {
                                    let modifieddata = {
                                          unit_name: (val?.first_name ?? "") + " " + (val?.last_name ?? ""),
                                          property_name: val?.unit_no ?? "",
                                          description: (val?.mobile_no_country_code ?? "") + " " + (val?.mobile_no ?? ""),
                                          email: val?.email_id ?? "",
                                          contact: (val?.mobile_no_country_code ?? "") + " " + (val?.mobile_no ?? ""),
                                          profile_url: val?.image_url ?? "",
                                    };
                                    return dataSet.push(modifieddata);
                              })

                              if (filter) {
                                    setDatas(dataSet);
                              } else {
                                    setDatas(datas?.concat(dataSet))
                              }

                        } else {

                        }
                        setLoading(false)
                  })
                  .catch((err) => {
                        console.log(err)
                  });
      }

      const fetchMoreData = () => {
            setOffset(offset + 10)
            // getparking(unit, offset + 10, 10, search, false);
            getOwnersList(offset + 10, 10, search, false)

      }
      const handleSearch = (value) => {
            setSearch(value)
            // debounce(() => getparking(unit, 0, 10, value, true), 800)
            debounce(() => getOwnersList(0, 10, value, true), 800)

      }
      return (
            <Container className={classes.root} maxWidth="sm">
                  <TitleBar text="Owners" goBack={() => history.goBack()} />
                  <div className={classes.roots}>
                        <Box display="flex" alignItems="center" p={1}>
                              <Box flexGrow={1}><Search onChange={handleSearch} value={search} placeholder={t("SearchOwners")} /></Box>
                        </Box>

                        {loading ? (
                              <LoderSimmer card count={10} size={80} />
                        ) : (
                              <InfiniteScroll
                                    dataLength={datas?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 116}
                              >
                                    <Box p={1}>
                                          {
                                                datas?.map((item) => {
                                                      return (<ParkingCard val={item} clickAction={false} />);
                                                })
                                          }
                                    </Box>
                              </InfiniteScroll>

                        )}
                  </div>

            </Container>
      );
};
export default withNamespaces("ownerList")(Ownerslist);
