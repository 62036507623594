import { Box, Button, Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { InvoicePayNowList } from "../../components";
import { FontFamilySwitch } from "../../utils";
import InvoicePay from "./invoicePay";
const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  totalAmount: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },

  subTitle: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.secondary,
    marginBottom: "4px",
  },

  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  btn: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "none",
  },
  btn1: {
    padding: "10px",
    borderRadius: theme.palette.borderRadius,
    backgroundColor: "white",
    border: "1px solid #5078E1",
    color: "#5078E1",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

const InvoicePaymentScreen = (props) => {
  const classes = useStyles();

  const [paymentoption, setPaymentoption] = React.useState(false);
  const [formopen, setformopen] = React.useState(false);
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" sx={{ padding: "0px" }}>
        <Grid Container>
          {props?.data?.map((val) => {
            return (
              <>
                {val.active === true && (
                  <Grid xs={12}>
                    <InvoicePayNowList
                      InvoicePay={val}
                      onDelete={() => props.onDelete(val, false)}
                      viewinvoice={props.viewinvoice}
                    />
                  </Grid>
                )}
              </>
            );
          })}
          {/* {props?.invoicePay?.invoices.map((item) => {
            return (
              <Grid xs={12}>
                <InvoicePayNowList
                  InvoicePay={item}
                  onDelete={props.onDelete}
                />
              </Grid>
            );
          })} */}
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <Grid item xs={12} style={{ marginTop: "4px" }}>
                <Typography className={classes.title}>
                  {props?.t("bill_summary")}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", marginTop: "-8px" }}>
                <Grid item xs={6}>
                  <Typography className={classes.subTitle}>
                    {props?.t("invoice_amount")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ float: "right" }}>
                    <Typography className={classes.subTitle}>
                      {props?.data?.[0]?.currency_master?.symbol +
                        Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 0,
                        }).format(Math.round(props?.total))}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              {/* <Grid item xs={12} style={{ display: "flex" }}>
                            <Grid item xs={6}>
                                <Typography className={classes.subTitle}>
                                    VAT Amount
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ float: "right" }}>
                                    <Typography className={classes.subTitle}>
                                        $60.00
                                    </Typography>
                                </div>
                                <br />
                            </Grid>
                        </Grid> */}

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  borderBottom: "2px dashed #CED3DD",
                  borderTop: "2px dashed #CED3DD",
                }}
              >
                <Grid item xs={6}>
                  <Typography className={classes.totalAmount}>
                    {props?.t("total_amount_to_pay")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ float: "right" }}>
                    <Typography className={classes.totalAmount}>
                      {props?.data?.[0]?.currency_master?.symbol +
                        Intl.NumberFormat("en-IN", {
                          minimumFractionDigits: 0,
                        }).format(Math.round(props?.total))}
                    </Typography>
                  </div>
                  <br />
                </Grid>
              </Grid>
              <Box height="12px" />
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className={classes.btn1}
                    onClick={() => setformopen(true)}
                    fullWidth
                    disabled={props?.activeinvoice <= 1 ? false : true}
                  >
                    {props?.t("already_paid")}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className={classes.btn}
                    fullWidth
                    onClick={() => setPaymentoption(true)}
                  >
                    {props?.t("pay_now")}
                  </Button>
                </Grid>
              </Grid>
              <Box height="12px" />
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <InvoicePay
        paymentoption={paymentoption}
        onClose={() => setPaymentoption(false)}
        formopen={formopen}
        onClose1={() => setformopen(false)}
        Outstanding_amount={props?.total}
        amount={props?.total}
        currency_symbol={props?.data?.[0]?.currency_master?.symbol}
        currency_id={props?.data?.[0]?.currency_id}
        company_id={props?.data?.[0]?.company_id}
        invoices={props?.data
          ?.filter((val) => val.active === true)
          .map((item) => item?.id)}
      />
    </div>
  );
};
export default withNamespaces("invoice")(InvoicePaymentScreen);
