import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { CLOSE_REQUEST_MAINTANCE } from "../../graphql/mutations";
import {
  GETSINGLEGENERALREQUESTBYGENERALREQUESTNO,
  GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from './style';
import ImageViewer from "react-simple-image-viewer";
import jwt_decode from "jwt-decode";

const InitialState = {
  remarks: "",
  error: {
    remarks: "",
  },
};
const RequestDetails = (props) => {
  const { t } = props
  const classes = useStyles();
  const history = useHistory();
  const search = useLocation().search;
  const alert = React.useContext(AlertContext);
  const jwt = new URLSearchParams(search).get("req_data");
  const token = jwt && jwt_decode(jwt)
  const requestId = jwt ? token?.id : new URLSearchParams(search).get("requestId");
  const requestNo = jwt ? token?.id :  new URLSearchParams(search).get("requestNo");
  const initialStatus =  jwt ? token?.status :  new URLSearchParams(search).get("status");
  const requestType = jwt ? token?.type :  new URLSearchParams(search).get("requestType");
  const [status, setStatus] = React.useState(initialStatus);
  const [request, setRequest] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const size = useWindowDimensions();
  const [cancel, setCancel] = React.useState(false);
  const [canceldata, setCancelData] = React.useState({ ...InitialState });
  const [view, setView] = React.useState({
    bool: false,
    data: 0
  });
  const updateState = (key, value) => {
    let error = canceldata.error;
    error[key] = "";
    setCancelData({ ...canceldata, [key]: value, error });
  };
  const goBack = () => {
    window.history.go(-1);
  };
  React.useEffect(() => {
    const payload = {
      query:
        requestType === "General"
          ? GETSINGLEGENERALREQUESTBYGENERALREQUESTNO
          : GETSINGLEMAINTENANCEREQUESTBYGENERALREQUESTNO,
      variables: {
        generalRequestNo: requestType === "General" ? requestNo : undefined,
        maintenanceRequestNo: requestType === "Maintenance" ? requestNo : undefined,
        referenceId: requestId,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        setRequest(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
    // eslint-disable-next-line
  }, [status]);
  const isIamValideTostep = () => {
    let isValid = true;
    let error = canceldata.error;
    //Checking Pass Type
    if (canceldata.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }

    setCancel({ ...canceldata, error });
    return isValid;
  };
  const cancelPass = () => {
    if (isIamValideTostep()) {
      const payload = {
        type: "Closed",
        remarks: canceldata?.remarks,
        updated_at: new Date().toISOString(),
        updated_by: localStorage.getItem(LocalStorageKeys.userProfileId),
        closed_on: new Date().toISOString(),
        is_active: true,
        client: localStorage.getItem(LocalStorageKeys?.client)?.id,
      }
      if (requestType === "Maintenance") {
        payload["maintenance_id"] = requestId
      }
      else {
        payload["general_id"] = requestId
      }

      const data = {
        query: CLOSE_REQUEST_MAINTANCE({
          typename:
            requestType === "General" ? "general_status" : "maintenance_status",
        }).loc.source.body,
        variables: {
          payload
        }
      }
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          history.replace(
            window.location.pathname +
            "?requestId=" +
            requestId +
            "&requestNo=" +
            requestNo +
            "&requestType=" +
            requestType +
            "&status=Closed"
          );
          setStatus("Closed");
          setCancel(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong please try again",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
    }
  };
  const renderCancelForm = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("CancelGateRequest")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCancel(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="12px" />
        <TextBox
          color
          label={t("Remarks")}
          placeholder={t("EnterRemarks")}
          value={canceldata.remarks}
          multiline
          onChange={(value) => updateState("remarks", value.target.value)}
          isError={canceldata?.error?.remarks?.length > 0}
          errorMessage={canceldata?.error?.remarks}
        />
        <Box height="12px" />
        <Button
          fullWidth
          className={classes.submitbtn}
          onClick={cancelPass}
          variant="contained"
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };
  const track = requestType === "General" ? request?.general_request?.[0]?.general_status : request?.maintenance_request?.[0]?.maintenance_status
  const viewDialog = (close, value) => {
    if (close) {
      setView({ ...view, bool: !view?.bool })
    }
    else {
      setView({ data: value, bool: !view?.bool })
    }
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <TitleBar
                  text={
                    requestType === "General"
                      ? request?.general_request[0]?.general_request_no
                        ? request.general_request[0]?.general_request_no
                        : "#"
                      : request?.maintenance_request[0]?.maintenance_request_no
                        ? request?.maintenance_request[0]?.maintenance_request_no
                        : "#"
                  }
                  goBack={goBack}
                />
              </Grid>
              <Box className={classes.divider} />
              <div
                className={classes.content}
                style={{
                  overflow: "scroll",
                  width: "inherit",
                  height: track?.some(x => x?.type === "Closed") ? size?.height - 55 : size?.height - 130,
                }}
              >
                <Grid item xs={12}>
                  <span className={classes.requsettop}>
                    {requestType === "General"
                      ? request?.general_request[0]?.general_category_master.type
                      : request?.maintenance_request[0]?.maintenance_category_master.type}
                  </span>{" "}
                  <span className={classes.requsettop}>
                    {requestType === "General"
                      ? request?.general_request[0]?.general_sub_category_master
                        .type
                      : request?.maintenance_request[0]?.maintenance_sub_category_master.type}
                  </span>
                  <Typography className={classes.requsettitle}>
                    {requestType === "General"
                      ? request?.general_request[0]?.subject
                      : request?.maintenance_request[0]?.subject}
                  </Typography>
                  <Typography className={classes.requsetsub}>
                    {requestType === "General"
                      ? request?.general_request[0]?.description
                      : request?.maintenance_request[0]?.description}
                  </Typography>
                  <Box
                    display="flex"
                    // justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    marginBottom="14px"
                  >
                    <Box>
                      <Typography className={classes.requsetsubs}>
                        {t("ProblemSince")}
                      </Typography>
                      <Typography className={classes.requsetsubs2}>
                        {" "}
                        <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                        <Box width={'4px'} />
                        <span>
                          {requestType === "General"
                            ? moment(request?.general_request[0]?.problem_since)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")
                            : moment(
                              request?.maintenance_request[0]?.problem_since
                            )
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")}
                        </span>
                      </Typography>
                    </Box>
                    <Box width="13px" />
                    <Box>
                      <Typography className={classes.requsetsubs}>
                        {t("Unit")}
                      </Typography>
                      <Typography className={classes.requsetsubs2}>
                        {" "}
                        <img src="/images/build.svg" alt="img" />{" "}
                        <Box width={'4px'} />
                        <span>
                          {" "}
                          {requestType === "General"
                            ? request?.general_request[0]?.general_request_units
                              .length > 1
                              ? request?.general_request[0]?.general_request_units
                                .length + " Units"
                              : request?.general_request[0]?.general_request_units[0]?.unit.name
                            : request?.maintenance_request[0]?.maintenance_request_units?.length > 1
                              ? request?.maintenance_request[0]?.maintenance_request_units.length + " Units"
                              : request?.maintenance_request[0]?.maintenance_request_units[0]?.unit.name}
                        </span>
                      </Typography>
                    </Box>
                    <Box width="13px" />
                    <Box>
                      <Typography className={classes.requsetsubs}>
                        {t("RaisedOn")}
                      </Typography>
                      <Typography className={classes.requsetsubs2}>
                        {" "}
                        <img src="/images/icons8_calendar.svg" alt="img" />{" "}
                        <Box width={'4px'} />
                        <span>
                          {requestType === "General"
                            ? moment(request?.general_request[0]?.raised_on)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")
                            : moment(request?.maintenance_request[0]?.raised_on)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={classes.divider} />
                  {request?.assets != null && request?.assets.length > 0 ? (
                    <>
                      <Grid
                        container
                        style={{ marginBottom: "10px", marginTop: "10px" }}
                      >
                        <Grid
                          item
                          xs={12}
                          style={{ marginBottom: "8px", marginTop: "8px" }}
                        >
                          <Typography className={classes.requsetsubs}>
                            {t("Images")}
                          </Typography>
                        </Grid>
                        
                        <Box className={classes.overflow}>
                          {
                            request?.assets?.map((src, index) => {
                              return (
                                <Box onClick={() => viewDialog(false, index)}>
                                  {
                                    src?.file_meta?.file_type === "mp4" ?
                                      <video
                                        className={classes.imageStyle}

                                      >
                                        <source src={src?.url} type="video/mp4" />
                                      </video> :
                                      <img
                                        src={src.url}
                                        width="300"
                                        key={index}
                                        className={classes.imageStyle}
                                        alt=""
                                      />
                                  }</Box>
                              )
                            })
                          }
                        </Box>
                      </Grid>
                      <Box className={classes.divider} />
                    </>
                  ) : (
                    <></>
                  )}
                  {requestType !== "General" ? (
                    <>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        marginBottom="14px"
                      >
                        <Box>
                          <Typography className={classes.requsetsubs}>
                            Preferred Visit Date And Time
                          </Typography>
                          <Typography className={classes.requsetsubs2}>
                            {" "}
                            <img
                              src="/images/icons8_calendar.svg"
                              alt="img"
                            /> <Box width={'4px'} />{" "}
                            <span>
                              {requestType === "General"
                                ? moment(
                                  request?.general_request[0]?.preferred_time
                                )
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY")
                                : moment(
                                  request?.maintenance_request[0]?.preferred_time
                                )
                                  .tz(moment.tz.guess())
                                  .format("DD MMM YY")}
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.divider} />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        marginTop="10px"
                        marginBottom="14px"
                      >
                        <Box>
                          <Typography className={classes.requsetsubs}>
                            {t("ContactDetails")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        marginTop="10px"
                        marginBottom="14px"
                      >
                        <Box>
                          <Avatar className={classes.avatar}>
                            <img src="/images/icons8-call.svg" alt="phone" />
                          </Avatar>
                        </Box>
                        <Box flexGrow={1} marginLeft="8px">
                          <Typography className={classes.requsetsubs}>
                            {requestType === "General"
                              ? request?.general_request[0]?.contact_name
                              : request?.maintenance_request[0]?.contact_name}
                          </Typography>
                          <Stack
                            direction="row"
                            divider={
                              <Divider orientation="vertical" flexItem />
                            }
                            spacing={1}
                          >
                            <Typography className={classes.requsetsubs2}>
                              {requestType === "General"
                                ? request?.general_request[0]?.contact_mobile !==
                                  null &&
                                  request?.general_request[0]?.contact_mobile !==
                                  ""
                                  ? "+91 " +
                                  request?.general_request[0]?.contact_mobile
                                  : ""
                                : request?.maintenance_request[0]?.contact_mobile !== null &&
                                  request?.maintenance_request[0]?.contact_mobile !== ""
                                  ? "+91 " +
                                  request?.maintenance_request[0]?.contact_mobile
                                  : ""}
                            </Typography>
                            <Typography className={classes.requsetsubs3}>
                              {requestType === "General"
                                ? request?.general_request[0]?.contact_alternative_mobile !== null &&
                                  request?.general_request[0]?.contact_alternative_mobile !== ""
                                  ? "+91 " +
                                  request?.general_request[0]?.contact_alternative_mobile
                                  : ""
                                : request?.maintenance_request[0]?.contact_alternative_mobile !== null &&
                                  request?.maintenance_request[0]?.contact_alternative_mobile !== ""
                                  ? "+91 " +
                                  request?.maintenance_request[0]?.contact_alternative_mobile
                                  : ""}
                            </Typography>
                          </Stack>
                        </Box>
                      </Box>
                      {/* <Box>
                        <Typography className={classes.requsetsubs}>
                          {"Notes"}
                        </Typography>
                      </Box>
                      <Box>
                        <Box>
                          <Typography>{"ajbcj"}</Typography>
                          <Typography>{"sjsjjsjsj"}</Typography>
                        </Box>
                      </Box>
                      <Divider /> */}

                    </>
                  ) : (
                    <></>
                  )}
                  {
                    track?.[0]?.notes?.length > 0 &&
                    <>
                      <Box className={classes.divider} />
                      <Box>
                        <Box height={'14px'} />
                        <Typography className={classes.notesTitle}>{t('notes')}</Typography>
                        <Box height={'4px'} />
                        <Box className={classes.statusBox}>
                          <Typography><span className={classes.status}>{t("Status")}</span>&nbsp;<span className={classes.subStatus}>{requestType === "General" ? request?.general_request[0]?.general_status?.some(x => x?.type === "Closed") ? "Closed" : "Open" : request?.maintenance_request[0]?.maintenance_status?.some(x => x.type === "Closed") ? "Closed" : "Open"}</span></Typography>
                          <Box height={'10px'} />
                          <Typography className={classes.recentNote}>{track?.[0]?.notes.reverse()?.[0]?.notes ?? '-'}</Typography>
                        </Box>
                        <Box height={'14px'} />
                      </Box>
                    </>
                  }
                  <Box className={classes.divider} />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop="10px"
                    marginBottom="14px"
                  >
                    <Box>
                      <Typography className={classes.requsetsubs}>
                        {t("Track")}
                      </Typography>
                    </Box>
                  </Box>
                  <Grid container direction={"column"} spacing={1}>
                    <div className={classes.createnewsectionlog1}>
                      {track.map((item, index, array) => {
                        return (
                          <Box className={classes.treeBox}>
                            <Box className={classes.border}>
                              <Avatar
                                variant="circular"
                                className={classes.avatars1}
                              >
                                <Avatar
                                  variant="circular"
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    backgroundColor: "#5078E1",
                                  }}
                                >
                                  <img
                                    src={
                                      item?.type === "Open"
                                        ? "images/clockWhite.svg"
                                        : "/images/tick.svg"
                                    }
                                    alt="document"
                                  />
                                </Avatar>
                              </Avatar>
                              <Typography
                                className={classes.title}
                              >
                                {item?.type}
                              </Typography>
                              <Box height={'4px'} />
                              {
                                item?.notes?.length > 0 ? <>
                                  {
                                    item?.notes?.map((x) => {
                                      return (
                                        <>
                                          <Typography
                                            className={classes.notes}
                                          >
                                            Notes: {x?.notes ?? "-"}
                                          </Typography>
                                          <Box height={'4px'} />
                                          <Typography
                                            className={classes.dateTime}
                                          >
                                            {moment(x?.created_at)
                                              .tz(moment.tz.guess())
                                              .format("YYYY-MM-DD hh:mm A")}
                                          </Typography>
                                          <Box height={'15px'} />
                                        </>
                                      )
                                    })
                                  }
                                </> :
                                  <>
                                    <Box height={'4px'} />
                                    <Typography
                                      className={classes.dateTime}
                                    >
                                      {moment(item?.created_at)
                                        .tz(moment.tz.guess())
                                        .format("YYYY-MM-DD hh:mm A")}
                                    </Typography></>
                              }
                              {
                                requestType === "General" ? request?.general_request?.[0]?.general_status?.length !== index + 1 && <Box height={'13px'} /> : request?.maintenance_request[0]?.maintenance_status?.length !== index + 1 && <Box height={'13px'} />
                              }
                            </Box>
                          </Box>
                        )
                      })}
                    </div>
                    {/* {quotationHistory.map((item, index, array) => {
                      return (
                        <Grid item xs={12}>
                          <QuotationHistory
                            data={item}
                            index={index}
                            length={array.length}
                          />
                        </Grid>
                      );
                    })} */}
                  </Grid>
                </Grid>
              </div>
              <Grid item xs={12}>
                {!track?.some(x => x?.type === "Closed") && (
                  <div
                    xs={12}
                    style={{
                      padding: "12px",
                      backgroundColor: "white",
                      boxShadow: "0px -1px 6px #00000021",
                    }}
                  >
                    <Button
                      fullWidth
                      className={classes.cancelbtn}
                      onClick={() => setCancel(true)}
                    >
                      {t("Cancelrequest")}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderCancelForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderCancelForm()}
        </Drawer>
      </Hidden>
      {/* <DialogDrawer
            height={"300px"}
            open={view?.bool}
            isnotTitle
            padding={"16px"}
            component={<> {
              view?.data?.file_meta?.file_type === "mp4" ? 
              <video
              className={classes.imageStyleView}
                  controls
              >
                  <source src={view?.data?.url} type="video/mp4" />
              </video> : 
              <img
                src={view?.data?.url}
            className={classes.imageStyleView}
                alt=""
              />
           }</>}
            onClose={() => viewDialog(true, "")}
          /> */}
      {/* <ImageViwer isOpen={view?.bool} onClose={() => viewDialog(true, "")} currImg={view?.data} onClickPrev={onClickPrev} onClickNext={onClickNext} images={request?.assets}/> */}
      {view?.bool && (
        <ImageViewer
          src={request?.assets?.map((x) => x?.url)
          }
          currentIndex={view?.data}
          onClose={() => viewDialog(true, "")}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}
    </div>
  );
};
export default withNamespaces("request")(RequestDetails)
