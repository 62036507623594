import * as React from "react"

const ChairSvg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <defs>
      <linearGradient
        id="b"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#7819a2" />
        <stop offset={1} stopColor="#771aa9" />
      </linearGradient>
      <linearGradient
        id="c"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#ae4cd5" />
        <stop offset={1} stopColor="#ac4ad5" />
      </linearGradient>
      <linearGradient
        id="d"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#c965eb" />
        <stop offset={1} stopColor="#c767e5" />
      </linearGradient>
      <clipPath id="a">
        <path
          data-name="Rectangle 55073"
          transform="translate(82 183)"
          fill="#fff"
          stroke="#707070"
          d="M0 0h16v16H0z"
        />
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 6441"
      transform="translate(-82 -183)"
      clipPath="url(#a)"
    >
      <g data-name="icons8-armchair (1)">
        <path
          data-name="Path 97264"
          d="M95.042 198.334h1.206a.2.2 0 0 0 .2-.26l-.744-2.592h-1.63l.772 2.7a.2.2 0 0 0 .196.152Z"
          fill="#e3a600"
        />
        <path
          data-name="Path 97265"
          d="M84.957 198.334h-1.206a.2.2 0 0 1-.2-.26l.745-2.592h1.63l-.772 2.7a.2.2 0 0 1-.197.152Z"
          fill="#e3a600"
        />
        <path
          data-name="Path 97266"
          d="M21.593 14.556a1.222 1.222 0 0 1-1.222 1.222h-8.149A1.222 1.222 0 0 1 11 14.556V7.222A1.222 1.222 0 0 1 12.222 6h8.148a1.222 1.222 0 0 1 1.222 1.222Z"
          transform="translate(73.704 177.667)"
          fill="url(#b)"
        />
        <path
          data-name="Path 97267"
          d="M21.593 25.852H11v-2.037a.815.815 0 0 1 .815-.815h8.963a.815.815 0 0 1 .815.815Z"
          transform="translate(73.704 167.593)"
          fill="url(#c)"
        />
        <path
          data-name="Path 97268"
          d="M8.037 24.333H7.63A1.63 1.63 0 0 1 6 22.7v-5.3a.408.408 0 0 1 .407-.4H7.63a.408.408 0 0 1 .407.407Z"
          transform="translate(76.667 171.148)"
          fill="url(#d)"
        />
        <path
          data-name="Path 97269"
          d="m95.93 196.274-.226-.792h-1.63l.233.815h1.4a1.969 1.969 0 0 0 .223-.023Z"
          opacity={0.05}
        />
        <path
          data-name="Path 97270"
          d="m95.874 196.076-.17-.594h-1.63l.175.611h1.455c.058 0 .112-.011.17-.017Z"
          opacity={0.07}
        />
        <path
          data-name="Path 97271"
          d="m84.071 196.274.226-.792h1.629l-.233.815h-1.4a1.969 1.969 0 0 1-.222-.023Z"
          opacity={0.05}
        />
        <path
          data-name="Path 97272"
          d="m84.127 196.076.17-.594h1.63l-.175.611h-1.455c-.058 0-.113-.011-.17-.017Z"
          opacity={0.07}
        />
        <path
          data-name="Path 97273"
          d="M20.259 17h-1.222a.408.408 0 0 0-.407.407V22.3H6v.815a1.63 1.63 0 0 0 1.63 1.63h11.407a1.63 1.63 0 0 0 1.63-1.63v-5.7a.408.408 0 0 0-.408-.415Z"
          transform="translate(76.667 171.148)"
          fill="url(#d)"
        />
      </g>
    </g>
  </svg>
)

export default ChairSvg
