import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import React from "react";
import { DocumentViewer } from "../../components";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #E4E8EE",
  },
  checkBox: {
    color: theme.palette.primary.main,
    borderRadius: "4px",
  },
  title: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
  },
  subTitle: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().regular,
    color: "#4E5A6B",
  },
  roundShape: {
    width: "6px",
    height: "6px",
    margin: "0px 6px 0px 6px",
    backgroundColor: "#CED3DD",
    borderRadius: "100%",
  },
  money: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#4E5A6B",
  },
  viewInvoice: {
    color: "#5078E1",
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: "14px",
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    marginBottom: "12px",
  },
  contentBottom: {
    padding: "12px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px",
    },
  },
  agreementimg: {
    height: "50vh",
    msOverflowY: "scroll",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  downloadsub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    display: "flex",
  },

  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
}));

export const Statement = ({ Statementdata = [],
  getPDFDetails = () => false,
  url = "",
  currencySymbol = "",
  showDrawer = false,
  onClose = () => false

}) => {
  const classes = useStyles();
  moment.defaultFormat = "YYYY-MM";
  const downloadURI = () => {
    saveAs(url, `${Statementdata?.type === "reciepts" ? "Reciept" : "Invoice"} ${Statementdata.account_no}`);
  };
  // const openDrawer = () => {
  //   getPDFDetails(Statementdata?.id)

  // }
  return (
    <div className={classes.root}>
      <Grid container padding={2}>
        <Grid
          justifyContent={"center"}
          alignItems={"center"}
          container
          direction={"column"}
        >
          <Grid
            alignItems={"center"}
            container
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Typography className={classes.title}>
              {moment(Statementdata?.created_at)
                .tz(moment.tz.guess())
                .format("DD MMM YYYY hh:mm:ss A")}
            </Typography>
            <Typography className={classes.money}>
              {Statementdata?.payment_amount &&
                Statementdata?.currency_symbol +
                Intl.NumberFormat("en-IN", {
                  minimumFractionDigits: 0,
                }).format(Math.round(Statementdata?.payment_amount))}
              {currencySymbol} &nbsp;{Statementdata?.amount}
            </Typography>
          </Grid>
          <Box height={"6px"} />
          <Grid alignItems={"center"} container direction={"row"}>
            {Statementdata?.invoice_id?.length && (
              <>
                <Typography className={classes.subTitle}>
                  {Statementdata?.invoice_id?.length +
                    (Statementdata?.invoice_id?.length > 1
                      ? " Invoices"
                      : " Invoice")}
                </Typography>
                <Box className={classes.roundShape} />
              </>
            )}

            <Typography className={classes.subTitle}>
              {Statementdata?.reference_no}
            </Typography>
            {/* <Box className={classes.roundShape} />
            <Typography className={classes.subTitle}>
              {Statementdata?.payment_response}
            </Typography> */}
            <Box className={classes.roundShape} />
            <Typography
              onClick={() => getPDFDetails(Statementdata?.id)}
              className={classes.viewInvoice}
            >
              {Statementdata?.type === "reciepts" ? "Reciept" : "Invoice"}
            </Typography>
            <Box flexGrow={1} textAlign={"end"}>
              <Typography className={classes.subTitle}>
                Balance : {currencySymbol}&nbsp; {Statementdata?.balance}
              </Typography>
            </Box>

          </Grid>
        </Grid>
      </Grid>
      {/* reciept drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          open={showDrawer}
          anchor="bottom"
          onClose={onClose}
        >
          <Container maxWidth="sm" style={{ overflow: "hidden" }}>
            <div className={classes.contentBottom}>
              <Box
                display="flex"
                p={1}
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography className={classes.bottomTitle}>
                    {Statementdata?.type === "reciepts" ? "Reciept" : "Invoice"}
                  </Typography>
                  {/* <Typography className={classes.downloadsub}>
                    <span>K-F01-U25</span>{" "}
                    <Box display="flex" className={classes.periodStyle} />
                    <span>Receipt ID 123</span>
                    <Box display="flex" className={classes.periodStyle} />
                    <span>26 Nov</span>{" "}
                    <Box display="flex" className={classes.periodStyle} />
                    <span>Net bank</span>
                  </Typography> */}
                </Box>
                <Box>
                  <IconButton size="small" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>

              <div className={classes.agreementimg}>
                <DocumentViewer url={url} />;
              </div>
              <Button
                variant="contained"
                fullWidth={true}
                sx={{
                  padding: "12px",
                  borderRadius: "4px",
                  marginTop: "12px",
                }}
                onClick={downloadURI}
              >
                Download
              </Button>
            </div>
          </Container>
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={showDrawer}
          className={classes.dialog}
        >
          <div className={classes.contentBottom}>
            <Box
              display="flex"
              p={1}
              alignItems="center"
              className={classes.titleroot}
            >
              <Box flexGrow={1}>
                <Typography className={classes.bottomTitle}> {Statementdata?.type === "reciepts" ? "Reciept" : "Invoice"}</Typography>
                {/* <Typography className={classes.downloadsub}>
                    <span>K-F01-U25</span>{" "}
                    <Box display="flex" className={classes.periodStyle} />
                    <span>Receipt ID 123</span>
                    <Box display="flex" className={classes.periodStyle} />
                    <span>26 Nov</span>{" "}
                    <Box display="flex" className={classes.periodStyle} />
                    <span>Net bank</span>
                  </Typography> */}
              </Box>
              <Box>
                <IconButton size="small" onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <div className={classes.agreementimg}>

              <DocumentViewer url={url} />;

            </div>
            <Button
              variant="contained"
              fullWidth={true}
              sx={{
                padding: "12px",
                borderRadius: "4px",
                marginTop: "12px",
              }}
              onClick={downloadURI}
            >
              Download
            </Button>
          </div>
        </Dialog>
      </Hidden>
    </div>
  );
};
