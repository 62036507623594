import { Box, Container, Hidden } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { AlertDialog, DrawerComponent, LoderSimmer, TitleBar, UseDebounce } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { DrawerDetails, ParkingCard, Search } from "./component";
import { ParkinguseStyles } from "./style";
export const AgreementParking = (props) => {
      const classes = ParkinguseStyles();
      const history = useHistory();
      const debounce = UseDebounce()
      const size = useWindowDimensions();
      const [offset, setOffset] = React.useState(0);
      const [datas, setDatas] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const [open, setOpen] = React.useState(false);
      const [selectedUnit, setSelectedUnit] = React.useState("");
      const [search, setSearch] = React.useState("")

      const getUnitids = (offset, limit, searchText, filter) => {
            const data = {
                  parking_unit: true,
                  offset: offset,
                  limit: limit,
                  search: searchText
            };
            NetworkCall(
                  `${config.api_url}/resident/access_unit`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        if (filter) {
                              setDatas(response?.data?.data)
                        } else {
                              setDatas(datas?.concat(response?.data?.data))
                        }
                        setLoading(false)
                  })
                  .catch((err) => {
                        console.log(err)
                        setLoading(false)

                  });
      };
      React.useEffect(() => {
            getUnitids(0, 10, "", false);
            // eslint-disable-next-line
      }, []);
      const closeDrawer = () => {
            setOpen(!open)
      }
      const fetchMoreData = () => {
            setOffset(offset + 10)
            getUnitids(offset + 10, 10, search, false);
      }
      const handleDetails = (data) => {
            setSelectedUnit(data)
            setOpen(true)
      }
      const handleSearch = (value) => {
            setSearch(value)
            debounce(() => getUnitids(0, 10, value, true), 800)

      }
      return (
            <Container className={classes.root} maxWidth="sm">
                  <TitleBar text="Parking" goBack={() => history.goBack()} />
                  <div className={classes.roots}>
                        <Box display="flex" alignItems="center" p={1}>
                              <Box flexGrow={1}><Search onChange={handleSearch} value={search} /></Box>
                        </Box>

                        {loading ? (
                              <LoderSimmer card count={10} size={80} />
                        ) : (
                              <InfiniteScroll
                                    dataLength={datas?.length ?? 10}
                                    next={fetchMoreData}
                                    hasMore={true}
                                    height={size?.height - 116}
                              >
                                    <Box p={1}>
                                          {
                                                datas?.map((item) => {
                                                      return (<ParkingCard val={item} handleDetails={handleDetails} />);
                                                })
                                          }
                                    </Box>
                              </InfiniteScroll>

                        )}
                  </div>

                  {/* drawer */}
                  <Hidden smUp>
                        <DrawerComponent onClose={closeDrawer} open={open} component={<DrawerDetails selectedUnit={selectedUnit} open={open} closeDrawer={closeDrawer} />} />
                  </Hidden>

                  {/* dialog */}
                  <Hidden smDown>
                        <AlertDialog toggleDrawer={closeDrawer} open={open} component={<DrawerDetails open={open} selectedUnit={selectedUnit} closeDrawer={closeDrawer} isMobile />} />
                  </Hidden>
            </Container>
      );
};
