import React from "react";
import FileManager from './fileManager';
import { withBottombar } from "../../HOCs";

class FileManagerParent extends React.Component {
    render() {
        return <FileManager />;
    }
}

export default withBottombar(FileManagerParent);
