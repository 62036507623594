import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { Folder, FolderView } from "../../assets";
import { useStyles } from "./style";

export const FolderComponent = ({ data = {}, t, type = 2, tabValue = 0, renameFile = () => false, deleteFile = () => false, viewDetails = () => false, onView = () => false, is_divider = true }) => {
  const classes = useStyles({ type });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div onClick={(e) => {
      e.stopPropagation()
      onView(data?.data)
    }}>
      {type === 2 ? (
        <>
          <Box className={classes.folderParent}>
            <IconButton
              className={classes.moreBtn}
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVertIcon className={classes.more} />
            </IconButton>
            <Folder />
          </Box>
          <Box height={"6px"} />
          <Typography className={classes.folderName} noWrap>
            {data?.fileNmae}
          </Typography>
          <Box height={"2px"} />
          <Typography className={classes.createdAt} noWrap>
            {data?.createdAt}
          </Typography>
        </>
      ) : (
        <Box className={classes.border}>
          {/* list view */}

          <Box className={classes.listFolderView}>
            <Box display="flex" alignItems={"center"}>
              <Box>
                <FolderView />
              </Box>
              <Box width={"12px"} />
              <Box>
                <Typography className={classes.folderName} noWrap>
                  {data?.fileNmae}
                </Typography>
                <Box height={"2px"} />
                <Typography className={classes.createdAt} noWrap>
                  {data?.createdAt}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                className={classes.moreBtn}
                onClick={(e) => {
                  e.stopPropagation()
                  setAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>
            </Box>
          </Box>
          <Box height={'6px'} />
          {
            is_divider && <Divider />
          }

        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menuList}
      >
        {/* {tabValue === 1 && (
          <>
            <MenuItem
              onClick={() => {
                handleClose();
                renameFile()
              }}
              className={classes.menuItem}
            >
              {t("Rename")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                deleteFile()
              }}
              className={classes.menuItem}
            >
              {t("Delete")}
            </MenuItem>
          </>
        )} */}

        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            handleClose();
            viewDetails(data)
          }}
          className={classes.menuItem}
        >
          {t("Details")}
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            handleClose();
          }}
          className={classes.menuItem}
        >
          {t("Download")}
        </MenuItem> */}
      </Menu>
    </div>
  );
};
