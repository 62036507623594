import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Container, Dialog, Drawer, Hidden, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import { TitleBar } from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import Delivery from "./delivery";
import Parking from "./parking";
import Swiftinout from "./swiftinout";
import Visitors from "./visitors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px"
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  field: {
    marginTop: "12px"
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "10px 8px",
    marginTop: "24px",
    boxShadow: "none"
  }
}));
const InitialState = {
  property: "",
  unit: "",
  type: "",
  error: {
    property: "",
    unit: "",
    type: "",
  },
};
const Entries = (props) => {
  const { t } = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const [open, setOpen] = React.useState(false)
  const [property, setProperty] = React.useState();
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [formData, setFormData] = React.useState({ ...InitialState })
  const [unit, setUnit] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState({
    property: "",
    unit: "",
    type: "",
  })
  const backdrop = React.useContext(BackdropContext);
  const history = useHistory()
  const typeList = [
    {
      value: "swift",
      label: "Swift in & Swift out"
    },
    {
      value: ["WGR", "VGR"],
      label: "Visitor & Worker",
    },
    {
      value: "PSR",
      label: "Parking slot"
    },
    {
      value: "DSR",
      label: "Delivery Collections"
    }
  ]
  const updateState = (key, value) => {
    let error = formData.error;
    error[key] = "";
    setFormData({ ...formData, [key]: value, error });
  };

  const getAggreement = (initial) => {
    const temp_decoded = jwt_decode(authToken);
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let agreementUnits = response?.data?.data?.[0]?.agreement_unit ?? []
      let property = agreementUnits?.length > 0 ? agreementUnits.map((val) => {
        return {
          label: val?.property_name,
          value: val?.property_id,
        }
      }) : []
      setProperty(property);
      let unit = agreementUnits?.length > 0 ? agreementUnits.map((val) => {
        return {
          label: val?.name,
          value: val?.id
        }
      }) : []
      setUnit(unit)
      if (initial) {
        setSearch({
          property: {
            value: property?.[0]?.value,
            label: property?.[0]?.label,
          },
          unit: {
            value: unit?.[0]?.value,
            label: unit?.[0]?.label
          },
          type: {
            value: ["WGR", "VGR"],
            label: "Visitor & Worker"
          },

        })
        backdrop.setBackDrop({
          ...backdrop,
          open: false,
          message: "",
        });
      }
    })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getAggreement(true)
    // eslint-disable-next-line
  }, [])
  const isIamValide = () => {
    let isValid = true;
    let error = formData.error;
    //Checking unit
    if (formData.unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking property
    if (formData.property.length === 0) {
      isValid = false;
      error.property = t("PropertyisRequired");
    }
    //Checking name
    if (formData.type.length === 0) {
      isValid = false;
      error.type = t("TypeisRequired");
    }
    setFormData({ ...formData, error });
    return isValid;
  };
  const apply = () => {
    if (isIamValide()) {
      setSearch({
        property: formData.property,
        unit: formData.unit,
        type: formData.type,
      })
      setOpen(false)
    } else {
      return false
    }
  }
  const renderSelectForm = () => {
    return (
      <div>
        <Box display="flex" alignItems="center" className={classes.titleroot} p={1}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>{t("EntryLogs")}</Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setOpen(false)}><CloseIcon /></IconButton>
          </Box>
        </Box>
        <Box p={1} height="325px" overflow="auto">
          <div className={classes.field}>
            <SelectBox label={t("Property")} placeholder={t("SelectProperty")} options={property}
              value={formData.property}
              onChange={(value) => {
                updateState("property", value)
                // getUnits(value?.value, false, false)
              }}
              isError={formData?.error?.property?.length > 0}
              errorMessage={formData?.error?.property}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("UnitID")} placeholder={t("SelectUnitID")} options={unit}
              value={formData.unit}
              onChange={(value) => updateState("unit", value)}
              isError={formData?.error?.unit?.length > 0}
              errorMessage={formData?.error?.unit}
            />
          </div>
          <div className={classes.field}>
            <SelectBox label={t("EntryType")} placeholder={t("SelectEntryType")} options={typeList}
              value={formData.type}
              onChange={(value) => updateState("type", value)}
              isError={formData?.error?.type?.length > 0}
              errorMessage={formData?.error?.type}
            />
          </div>
          <Button fullWidth className={classes.submitBtn} variant="contained" onClick={apply}>{t("Confirm")}</Button>
        </Box>
      </div>
    )
  }
  return (
    <Container maxWidth="sm" className={classes.root} style={{ height: size?.height }}>
      <TitleBar data={search} text={t("EntryLogs")} goBack={() => history.goBack()} entries handleClick={() => setOpen(true)} />

      {search.type?.label === "Visitor & Worker" &&
        <Visitors open={open} units={search?.unit?.value} unitlist={unit} />
      }
      {search.type?.label === "Parking slot" &&
        <Parking units={[search.unit?.value]} unitlist={unit} />
      }
      {search.type?.label === "Delivery Collections" &&
        <Delivery units={[search.unit?.value]} unitlist={unit} />
      }
      {search.type?.label === "Swift in & Swift out" &&
        <Swiftinout
          units={search.unit?.unit_id}
          unitlist={unit}
          property={formData.property}
          unit={formData.unit}
        />
      }
      <Hidden smDown>
        <Dialog className={classes.dialog} maxWidth="sm" fullWidth open={open} >
          {renderSelectForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)} className={classes.drawer}>
          {renderSelectForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("entriesLog")(Entries)