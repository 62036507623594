import { Avatar, Box, Divider, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
      root: {
            backgroundColor: "white",
            textTransform: "capitalize",
            cursor: "pointer",
      },
      roots: {
            padding: "8px",
            margin: "0px 8px",
            position: 'relative'
      },
      periodStyle: {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "#CED3DD",
            margin: "6px",
      },
      name: {
            fontSize: "14px",
            fontFamily: FontFamilySwitch().bold,
      },
      call: {
            fontSize: "12px",
            color: theme.typography.color.secondary,
      },
      mail: {
            fontSize: "12px",
            color: theme.typography.color.secondary,
      },
      tagStyle: {
            backgroundColor: "#F1E6FE",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: FontFamilySwitch().bold,
            color: "#5D427F",
            // textTransform: "uppercase",
            marginLeft: "8px",
            position: 'absolute',
            right: '10px'
      },
      tagStyle1: {
            backgroundColor: "#FF4B4B29",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: FontFamilySwitch().bold,
            color: "#FF4B4B",
            textTransform: "uppercase",
            marginLeft: "8px",
            position: 'absolute',
            right: '10px'
      },

      avatar: {
            backgroundColor: "#F0F4FF",
            width: "48px",
            height: "48px",
            borderRadius: '4px'
      },
}));

export const UtilitiesList = (props) => {
      const classes = useStyles();
      const history = useHistory();
      return (
            <div className={classes.root}>
                  <Grid container>
                        <Grid item xs={12} className={classes.roots}
                              onClick={() =>
                                    history.push(
                                          Routes.utilitiestype +
                                          "?utility_Id=" +
                                          props?.data?.utility_id +
                                          "&name=" +
                                          props?.data?.utility_name +
                                          "&unit_id=" +
                                          props?.unitId
                                    )
                              }
                        >
                              <Grid container>
                                    <Grid item xs={12}>
                                          <Box display="flex">
                                                <Box>
                                                      <Avatar className={classes.avatar}>
                                                            <img
                                                                  src="/images/utilitiesplaceholder.svg"
                                                                  alt={"phone"}
                                                                  className={classes.img}
                                                            />
                                                      </Avatar>
                                                </Box>
                                                <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                                                      <Box display="flex" alignItems="center" marginBottom="2px">
                                                            <Typography className={classes.name} noWrap>
                                                                  {props?.data?.utility_name ? props?.data?.utility_name : " - "}
                                                            </Typography>

                                                            {typeof props?.data?.status === "string" &&

                                                                  <Typography className={classes.tagStyle1}>
                                                                        {props?.data?.status}
                                                                  </Typography>
                                                            }
                                                            {typeof props?.data?.status === "number" &&

                                                                  <Typography className={classes.tagStyle}>
                                                                        {`${props?.data?.status} ${props?.data?.status === 1 ? "day" : "days"} ${"To Due"}`}
                                                                  </Typography>
                                                            }
                                                      </Box>

                                                      <Box display="flex" style={{ flexFlow: 'wrap' }}>
                                                            <Box>
                                                                  <Typography className={classes.call} noWrap>
                                                                        {`${props?.data?.readings} ${props?.data?.readings > 1 ? "Metres" : "Metre"}`}
                                                                  </Typography>
                                                            </Box>
                                                      </Box>
                                                </Box>
                                          </Box>
                                    </Grid>
                              </Grid>
                        </Grid>

                        <Grid item xs={12}>
                              <Divider variant="middle" sx={{ backgroundColor: "#E4E8EE" }} />
                        </Grid>
                  </Grid>
            </div>
      );
};
