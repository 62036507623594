import * as React from "react"

export const PDFImage = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={62} {...props}>
    <defs>
      <linearGradient
        id="a"
        x1={0.048}
        y1={250.588}
        x2={0.509}
        y2={250.127}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3079d6" />
        <stop offset={1} stopColor="#4997e8" />
      </linearGradient>
    </defs>
    <path
      data-name="Path 97716"
      d="M48 17.947v40.79A3.231 3.231 0 0 1 44.8 62H3.2A3.231 3.231 0 0 1 0 58.737V3.263A3.231 3.231 0 0 1 3.2 0h27.2l4.8 13.053Z"
      fill="#acf3ff"
    />
    <path
      data-name="Path 97717"
      d="M28 5v14.684a3.232 3.232 0 0 0 3.2 3.263h14.413Z"
      transform="translate(2.387 -5)"
      fill="url(#a)"
    />
    <path
      data-name="Path 97718"
      d="M37.593 31H10.407a.808.808 0 0 1-.8-.816v-1.631a.808.808 0 0 1 .8-.816h27.186a.808.808 0 0 1 .8.816v1.632a.808.808 0 0 1-.8.815Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97719"
      d="M34.41 37.526H10.392a.809.809 0 0 1-.8-.816v-1.631a.809.809 0 0 1 .8-.816H34.41a.809.809 0 0 1 .8.816v1.632a.809.809 0 0 1-.8.815Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97720"
      d="M37.593 44.052H10.407a.808.808 0 0 1-.8-.816v-1.631a.808.808 0 0 1 .8-.816h27.186a.808.808 0 0 1 .8.816v1.632a.808.808 0 0 1-.8.815Z"
      fill="#1a9cc6"
    />
    <path
      data-name="Path 97721"
      d="M34.41 50.579H10.392a.809.809 0 0 1-.8-.816v-1.631a.809.809 0 0 1 .8-.816H34.41a.809.809 0 0 1 .8.816v1.632a.809.809 0 0 1-.8.815Z"
      fill="#1a9cc6"
    />
  </svg>
)
