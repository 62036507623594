import DateAdapter from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
  },
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      height: 50,
      borderRadius: "4px",
      border: "1.5px solid  #CED3DD",
      boxShadow: "none",
    },
  },
}));

const DateWithTime = (props) => {
  const classes = useStyles(props);
  const getTitle = (props) => {
    return (
      <div style={{ display: "flex" }}>
      {
        <Typography
          variant="body1"
          color="textsecondary"
          className={classes.text}
          align="left"
          gutterBottom
        >
          {props?.title}
        </Typography>
      }
      {props?.isRequired && (
        <Typography color="error" variant="caption">
          &nbsp;*
        </Typography>
      )}
    </div>
    );
  };

  return (
    <>
      {getTitle(props)}

      <LocalizationProvider dateAdapter={DateAdapter}>
        <DateTimePicker
          label={props.placeholder}
          value={props.value ? props.value : new Date().toISOString()}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
          onChange={(newValue) => {
            props.onChange(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                backgroundColor: props.color ? "white" : "auto",
                height: 45,
                borderRadius: "4px",
              }}
              size="small"
              className={classes.textbox}
              fullWidth
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
export default DateWithTime;
