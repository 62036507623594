import {Box,Grid,Typography,IconButton} from "@mui/material"
import {useStyles} from "../styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


export const DetailCard=(props)=>{
    const {
        t=()=>false,
        symbol="en-IN",
        isChartOpen=false,
        openChart=()=>false,
        total=0
    }=props;
    const classes=useStyles()
    return(
        <Box className={classes.paymentHistoryCard}>
                  <Grid container direction="column">
                    <Grid
                      container
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      direction={"row"}
                    >
                      <Grid item xs={12}>
                        <Typography
                          style={{ fontSize: "12px", color: "#091B29" }}
                          className={classes.bold}
                        >
                          {t("history_des")}
                        </Typography>
                        <Box height="4px" />
                        <Typography
                          style={{
                            fontSize: "22px",
                            color: "#091B29",
                          }}
                          className={classes.bold}
                        >
                          {symbol} {total}
                          {/* {Intl.NumberFormat(, {
                              minimumFractionDigits: 0,
                            }).format(Math.round(total))} */}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1}>
                            {!isChartOpen && (
                              <Typography
                                onClick={openChart}
                                className={classes.viewChart}
                              >
                                {t("view_chart")} &nbsp;
                              </Typography>
                            )}
                          </Box>
                          <Box>
                            <IconButton className={classes.arrrow} onClick={openChart}>
                              <ArrowForwardIosIcon sx={{ fontSize: "14px" }} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
    )
}