import React from 'react'
import { Box, Button, Dialog, Divider, Drawer, Hidden, IconButton, Stack, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BillSvg from '../../assets/bill';
import SandClockSvg from '../../assets/sandclock';
import CalendarSvg from '../../assets/calendar';
import moment from 'moment';
import { MyBookingListStyle } from './style'
import { withNamespaces } from 'react-i18next';
import { AuthContext } from '../../contexts';
import { DocumentViewer } from '../../components';
import CloseIcon from "@mui/icons-material/Close";
import useWindowDimensions from '../../utils/useWindowDimensions';
const ListCard = ({ data = {}, t }) => {
    const auth = React.useContext(AuthContext)
    const size = useWindowDimensions();
    const classes = MyBookingListStyle({ language: auth?.auth?.auth?.language })
    const [open, setOpen] = React.useState(true)
    const [viewInvoice, setViewInvoice] = React.useState(false)
    return (
        <Stack alignItems={"center"}>
            <Stack className={classes.card} p={2} spacing={2}>
                <Stack direction={"row"} justifyContent={"space-between"} onClick={() => setOpen(open ? false : true)}>
                    <Stack direction={"row"} spacing={1} alignItems="center">
                        <Typography className={classes.header}>{data?.booking_id}</Typography>
                        {/* <Typography className={classes.day_to_due}>2 days to due</Typography> */}
                    </Stack>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Stack>
                {
                    open &&
                    <div>
                        <Stack container spacing={2} width={"100%"}>
                            <Stack>
                                <Stack className={classes.first} direction="row" justifyContent={"space-between"}>
                                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                        <BillSvg />
                                        <Stack>
                                            <Typography className={classes.title}>{t("TotalBill")}</Typography>
                                            <Typography className={classes.content}> {data?.currency} {data?.paid_amount} </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} alignItems={"center"} spacing={2}>
                                        <SandClockSvg />
                                        <Stack>
                                            <Typography className={classes.title}>{t("NumberOfHours")}</Typography>
                                            <Typography className={classes.content}>{data?.total_hours}</Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <Stack direction={"row"} justifyContent={"space-between"} className={classes.first} alignItems={"center"}>
                                    <Stack>
                                        <Typography className={classes.title}>{t("BookedFrom")}</Typography>
                                        <Typography className={classes.secondarY_content}>{moment(data?.booking_start_time).format("DD MMM YYYY")}</Typography>
                                    </Stack>
                                    <CalendarSvg />
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} className={classes.first} alignItems={"center"}>
                                    <Stack>
                                        <Typography className={classes.title}>{t("BookedTo")}</Typography>
                                        <Typography className={classes.secondarY_content}> {moment(data?.booking_end_time).format("DD MMM YYYY")}</Typography>
                                    </Stack>
                                    <CalendarSvg />
                                </Stack>
                            </Stack>
                            <Stack>
                                <Stack className={classes.first}>
                                    <Typography className={classes.title}>{t("BookedOnDate")}</Typography>
                                    <Typography className={classes.secondarY_content}>{moment(data?.booked_date).format("DD MMM YYYY")}</Typography>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} direction={"row"}>
                                <Stack direction={"row"} justifyContent={"space-between"} className={classes.first} alignItems={"center"}>
                                    <Stack>
                                        <Typography className={classes.title}>{t("PerHourPrice")}</Typography>
                                        <Typography className={classes.secondarY_content}>{data?.currency} {data?.paid_amount / data?.no_of_slots} </Typography>
                                    </Stack>
                                    <img src='images/clock.svg' alt='' />
                                </Stack>
                                <Stack direction={"row"} justifyContent={"space-between"} className={classes.first}>
                                    <Stack>
                                        <Typography className={classes.title}>{t("PaidOn")}</Typography>
                                        <Typography className={classes.secondarY_content}>{data?.paid_on === null ? '-' : moment(data?.paid_on).format("DD MMM YYYY")}</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack spacing={1} mt={2}>
                            <Divider></Divider>
                            <Button className={classes.viewinvoice} disabled={data?.pdf_url !== null ? false : true} onClick={() => {setViewInvoice(!viewInvoice)}}>
                                {t("ViewInvoice")}
                            </Button>
                        </Stack>
                    </div>
                }
            </Stack>

            <Hidden smDown>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={viewInvoice}
                    className={classes.dialog}
                    sx={{ padding: "0px" }}
                >
                    <Box
                        display="flex"
                        p={1}
                        alignItems="center"
                        className={classes.titleroot}
                    >
                        <Box flexGrow={1}>
                            <Typography className={classes.bottomTitle}>
                                {t("ViewInvoice")}
                            </Typography>
                        </Box>
                        <Box>
                            <IconButton
                                size="small"
                                onClick={() => setViewInvoice(!viewInvoice)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <div style={{ height: size?.height - 115 }}>
                        <DocumentViewer url={data?.pdf_url} />
                    </div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    maxWidth="sm"
                    anchor="bottom"
                    fullWidth
                    open={viewInvoice}
                    className={classes.drawer}
                >
                    <div>
                        <Box
                            display="flex"
                            p={1}
                            alignItems="center"
                            className={classes.titleroot}
                        >
                            <Box flexGrow={1}>
                                <Typography className={classes.bottomTitle}>
                                    {t("ViewInvoice")}
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    size="small"
                                    onClick={() => setViewInvoice(!viewInvoice)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <div style={{ height: size?.height - 115 }}>
                            <DocumentViewer url={data?.pdf_url} />
                        </div>
                    </div>
                </Drawer>
            </Hidden>
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(ListCard)