import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
import { BillSummaryStyle } from './style'

const BillSummary = ({data={},bookingSlots={},t}) => {
    const auth = React.useContext(AuthContext)
    const classes = BillSummaryStyle({ language: auth?.auth?.auth?.language })
    return (
        <Stack spacing={1}>
            <Typography className={classes.title}>{t("BillSummery")}</Typography>
            <Stack spacing={0.5}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className={classes.secondary}>{t("InvoiceAmount")}</Typography>
                    <Typography className={classes.secondary}>{data?.symbol} {data?.rate * bookingSlots.length}</Typography>
                </Stack>
                {/* <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography className={classes.secondary}>VAT Amount</Typography>
                    <Typography className={classes.secondary}>{data?.symbol} 0</Typography>
                </Stack> */}
            </Stack>
            <Box className={classes.dot_divider}></Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className={classes.primary}>{t("TotalAmountToPay")}</Typography>
                <Typography className={classes.primary}>{data?.symbol} {data.rate * bookingSlots.length} </Typography>
            </Stack>
            <Box className={classes.dot_divider}></Box>
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(BillSummary)