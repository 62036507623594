import { makeStyles } from "@mui/styles";
import { FontFamilySwitch, vehicleTypeBGSVG } from "../../../utils";

export const ParkingSlotCardStyle = makeStyles((theme)=>({
    parkingSlotNoStyle: {
        padding: "16px",
        borderRadius: theme.palette.borderRadius,
        height: "110px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 100%",
        backgroundSize: "cover",
        backgroundColor:"#F5F7FD",
        backgroundImage: (props) => `url("${vehicleTypeBGSVG[props?.vehicle_type] ?? `/images/Component 149 – 1.svg`}")`
    },
    label: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().semiBold,
        color: "#98A0AC",
    },
    value: {
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        color: "#091B29",
    },
    value1: {
        fontSize: "20px",
        fontFamily: FontFamilySwitch().extraBold,
        color: "#091B29",
    }
}))