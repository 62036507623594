
import { Box, Button, Container } from "@mui/material";
import { saveAs } from "file-saver";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Actioncard, DialogDrawer, DocumentViewer, Quotation as Details, QuotationCard, TopNavBars } from "../../components";
import { config } from "../../config";
import { BackdropContext } from "../../contexts";
import { GETALL } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, quotationActionCard, quotationEnum } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Status, StatusFrom } from "./componant";
import { useStyles } from "./styles";
const Quotation = () => {
    const classes = useStyles()
    const size = useWindowDimensions()
    const search = useLocation().search;
    const history = useHistory()
    const jwt = new URLSearchParams(search).get("quote");
    const backdrop = React.useContext(BackdropContext);
    const token = jwt_decode(jwt)
    const headers = {
        Slug: `${token?.tenant?.slug}`,
        "Content-Type": "application/json",
    };
    const [details, setDetails] = React.useState({
        quott: {},
        invoices: [],
        status: true
    })
    const [openAsset, setAsset] = React.useState(false)
    const [openStatus, setOpenStatus] = React.useState({
        value: {},
        flag: false
    })
    //get quotation details
    const getQuotationDetails = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Fetch Details",
        });
        const data = {
            query: GETALL,
            variables: {
                quotation_id: token?.id
            },
        }

        NetworkCall(
            `${config.graphql_url}`,
            NetWorkCallMethods.post,
            data,
            headers,
            false,
            false
        )
            .then((res) => {
                setDetails({
                    quott: res?.data?.data?.quotation?.[0],
                    invoices: res?.data?.data?.invoice,
                    status: res?.data?.data?.quotation?.[0]?.quote_status === quotationEnum?.interested ||
                        res?.data?.data?.quotation?.[0]?.quote_status === quotationEnum?.notinterested ? true : false
                })
                backdrop.setBackDrop({
                    ...backdrop,
                    open: false,
                    message: "Fetch Details",
                });
            })
            .catch((err) => {
                console.log(err)
            });
    }
    //initial load
    React.useEffect(() => {
        getQuotationDetails()
        // eslint-disable-next-line
    }, [])
    //onopen action
    const openAction = (val) => {
        setOpenStatus({
            value: val,
            flag: true
        })
    }
    //onclose action
    const closeAction = (val) => {
        setOpenStatus({
            value: {},
            flag: false
        })
    }
    //go payment screen
    const PaymentScreen = () => {
        history.push({
            pathname: Routes.quotationPayment,
            state: details
        })
    }
    //download quotation
    const downloadURI = (url, name) => {
        saveAs(url, `Quotation # ${details?.quott?.quotation_no}.pdf`);
    };

    return (
        <Container maxWidth="sm" sx={{ padding: "0px" }}>
            <TopNavBars note={false} home={false} />
            <Box className={classes.root}>
                <Box height={size?.height - ((details?.invoices?.length === 0 && !details?.status) ? 200 : 58)} className={classes.quotationRoot} overflow="auto">
                    {/*quotation details card */}
                    <QuotationCard
                        type="details"
                        quotationNo={details?.quott?.quotation_no}
                        generatedOn={details?.quott?.quote_start_date}
                        expiresOn={details?.quott?.quotation_expiry_date}
                        leasePeriod={`${details?.quott?.lease_duration} ${details?.quott?.lease_period}`}
                    />
                    <Box height="8px" />
                    {/*quotation asset card */}
                    <Details
                        onClickDownload={() => setAsset(true)}
                        url={details?.quott?.asset?.[0]?.url}
                        data={
                            {
                                symbol: details?.quott?.currency_master?.symbol,
                                title: "Quotation",
                                body: "Quotation by",
                                body1: `${details?.quott?.user?.first_name ?? ""} ${details?.quott?.user?.last_name ?? ""}`,
                                subBody: "Total amount :",
                                subBody2: Math.round(details?.quott?.total_amount),
                            }
                        }
                    />
                    <Box height="8px" />
                    {
                        details?.invoices?.length > 0 && <Status onClick={PaymentScreen} invoice_no={details?.invoices?.[0]?.invoice_no} />
                    }
                    {details?.status &&
                        <Status
                            status
                            status_type={`${details?.quott?.quote_status === quotationEnum?.interested ? "Accepted" : details?.quott?.quote_status} at
                         ${moment(details?.quott?.updated_at ?? details?.quott?.created_at).tz(moment.tz.guess()).format("DD MMM YYYY")}`} />
                    }

                </Box>

                {/*quotation actions */}
                {
                    (details?.invoices?.length === 0 && !details?.status) &&
                    <Box className={classes.actionRoot}>
                        {
                            quotationActionCard?.map((val) => {
                                return (
                                    <Actioncard data={val} action={() => openAction(val)} />
                                )
                            })
                        }

                    </Box>
                }

            </Box>
            {/*status dialog */}
            <DialogDrawer
                open={openStatus?.flag}
                details={openStatus?.value}
                header={openStatus?.value?.title}
                component={<StatusFrom headers={headers} data={details} details={openStatus?.value} quotation_id={details?.quott?.id} />}
                onClose={closeAction} />
            {/*asset dialog */}
            <DialogDrawer
                height={"400px"}
                open={openAsset}
                header={"Quotation #" + details?.quott?.quotation_no}
                component={details?.quott?.asset?.length > 0 ?
                    <>
                        <Box height="300px" overflow="auto" marginBottom="14px">
                            <DocumentViewer url={details?.quott?.asset[0]?.url} />
                        </Box>
                        <Button fullWidth className={classes.drawerButtonStyle}
                            variant="contained"
                            onClick={() => {
                                downloadURI(details?.quott?.asset[0]?.url);
                            }}
                        >
                            Download
                        </Button>
                    </>
                    : "No Date Found"}
                onClose={() => setAsset(false)} />
        </Container>
    );
};
export default withNamespaces("quotation")(Quotation)