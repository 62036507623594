/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { Filter, LoderSimmer, ParkingCard, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, enumCatagory, enumSelect, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
    overflow: "hidden",
  },
  menuText: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    marginTop: '30%',
    backgroundColor: '#F2F5FA'
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "120px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "80px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
  rootPadding: {
    padding: '16px '
  }
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
export const ParkingPass = ({ t }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const history = useHistory();
  const [filter, setFilter] = React.useState(false);
  const [gatelist, setGateList] = React.useState([]);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [total, setTotal] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const { state } = useLocation();
  const [statusType, setStatusType] = React.useState([]);
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const getPassList = (unit, status, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: ["PSR"],
      status: status,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      start_date:
        dateFilter?.startDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };
    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist.concat(response?.data?.data));
        }
        setTotal(response.data.totalRecords);
        setFilter(false);
        setLoading(false);
      })
      .catch(() => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const getagreementUnitid = () => {
    NetworkCall(
      `${config.api_url}/community/community_units_filter`,
      NetWorkCallMethods.post,
      {}, null, true, false
    ).then((res) => {
      getEnum(res?.data?.data?.agreement_units?.map((x) => x?.id))

      //get units for filter
      const allunits = res?.data?.data?.agreement_units?.map((val) => {
        let _d;
        try {
          _d = {
            label: val?.unit?.name,
            value: String(val.id),
          };
        } catch (err) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        }

        return _d;
      });
      setUnitsfil(allunits.flat());
    }).catch(() => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Something Went Wrong",
      });
    });
  }
  const filterData = [
    {
      id: "1",
      title: "Status",
      key: "status",

      filterType: "CHECKBOX",
      values: statusType ?? [],
    },
    {
      id: "2",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "3",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];

  const applyFilter = (data) => {
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    setOffset(0);
    setSelectedFilter({
      status: data.status,
      unit: data.unit,
    });
    getPassList(data.unit, data.status, true, 0);
  };
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(selectedFilter.unit, selectedFilter.status, false, offset + 20);
  };
  const goBack = () => {
    history.push(state ? Routes.dashboard : Routes.service);
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.security_status_enum_type])
    setStatusType(stayType?.security_status_enum_type)
    getPassList(
      data,
      stayType?.security_status_enum_type?.map(val => val?.value)
    );


    setSelectedFilter({
      status: stayType?.security_status_enum_type?.map(val => val?.value),
      unit: data,
    })

  }
  React.useEffect(() => {
    getagreementUnitid()
    // eslint-disable-next-line
  }, []);
  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar text="Parking Slot Requset" goBack={goBack} />
      <Grid
        container
        className={classes.screenScrollEffect}
        height={size?.height - 130}
      >
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" className={classes.rootPadding}>
                <Box flexGrow={1}>

                  <Typography className={classes.total}>
                    {t("Parkingslot")} ({total})
                  </Typography>
                </Box>
                <Box marginLeft="10px">
                  <IconButton
                    className={classes.filter}
                    onClick={() => setFilter(true)}
                  >
                    <Badge variant="dot" color="primary">
                      <img src="/images/icons8-funnel.svg" alt="filter" />
                    </Badge>
                  </IconButton>
                </Box>
              </Box>
              <div style={{ marginRight: "-6px" }}>
                <Box backgroundColor="white">
                  {loading ? (
                    <LoderSimmer count={10} />
                  ) : (
                    <>
                      {
                        gatelist.length > 0 ?
                          <InfiniteScroll
                            dataLength={gatelist.length}
                            next={fetchMoreData}
                            hasMore={true}
                            height={size?.height - 140}
                          >
                            <Box padding={"16px 18px 16px 10px"}>
                              {gatelist?.map((item) => {
                                return <Box marginBottom={"12px"}>
                                  <ParkingCard data={item} parking />
                                </Box>
                              })}
                            </Box>
                          </InfiniteScroll> :
                          <Typography className={classes.menuText} textAlign="center">
                            {t("Norecordsfound")}
                          </Typography>
                      }
                    </>




                  )}
                </Box>
              </div>
              <Fab
                color="primary"
                className={classes.fab}
                onClick={() => history.push(Routes.createParking)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            setSelectedFilter={setSelectedFilter}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("parkingpass")(ParkingPass)