import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { AuthContext } from '../../../contexts'
import { DetailHeadCard } from './detailHeadCard'
import { DetailCardStyle } from './style'

const DetailCard = ({ data = {}, t }) => {
    const auth = React.useContext(AuthContext)
    const classes = DetailCardStyle({ language: auth?.auth?.auth?.language })
    const [open, setOpen] = React.useState(false)
    const show = () => {
        open === false ? setOpen(true) : setOpen(false)
    }
    return (
        <Box p={1} onClick={show}>
            <Stack spacing={1}>
                <DetailHeadCard open={open} data={data} />
                <Divider />
                <Stack spacing={1}>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>{t("InvoiceAmount")}</Typography>
                        <Typography className={classes.content}>{data?.currency} {data?.paid_amount ?? 0}</Typography>
                    </Stack>
                    {/* <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.title}>VAT Amount</Typography>
                        <Typography className={classes.content}>$.12</Typography>
                    </Stack> */}
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Typography className={classes.total}>{t("Total")}</Typography>
                        <Typography className={classes.total}>{data?.currency} {data?.paid_amount ?? 0}</Typography>
                    </Stack>
                </Stack>
                {
                    open &&
                    <Stack spacing={1}>
                        <Divider></Divider>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Typography className={classes.title}>{t("UnitId")}</Typography>
                            <Typography className={classes.content}> {data?.unit_no} </Typography>
                        </Stack>
                    </Stack>
                }
            </Stack>
        </Box>
    )
}

export default withNamespaces("amenitiesBooking")(DetailCard)