import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef } from "react";
import {
  ContactDetails,
  MobileNumberInputComponent,
  TextBox,
  TitleBar
} from "../../components";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "0px 16px",
  },
  btnRoot: {
    position: "fixed",
    bottom: 0,
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
  },
  screen: {
    borderRadius: "4px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  drawer: {
    "& .css-9emuhu-MuiPaper-root-MuiDrawer-paper": {
      "box-shadow": "none",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .css-tlc64q-MuiPaper-root-MuiDialog-paper": {
      "background-color": "transparent",
      "box-shadow": "none",
    },
  },
  bottomSheetStyle: {
    background: "white",
    borderRadius: "4px 4px 0px 0px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  dialogStyle: {
    background: "white",
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    justifyContent: "center",
  },
  drawerHeaderStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
  drawerHeaderTextStyle: {
    fontSize: "16px",
    fontWeight: "bolder",
    color: theme.typography.color.primary,
  },
  drawerBodyStyle: {
    justifyContent: "space-between",
    alignItems: "center",
    padding: "16px",
  },
}));

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

const Step2 = (props) => {
  const {t} = props
  const classes = useStyles();
  const [showDrawer, setShowDrawer] = React.useState(false);
  const [contactName, setContactName] = React.useState(
    props?.request?.contactName ? props?.request?.contactName : ""
  );
  const [primaryNumber, setPrimaryNumber] = React.useState(
    props?.request?.primaryNumber ? props?.request?.primaryNumber : ""
  );
  const [primaryCountryCode, setPrimaryCountryCode] = React.useState(
    props?.request?.primaryCountryCode ? props?.request?.primaryCountryCode : ""
  );
  const [alternateNumber, setAlternateNumber] = React.useState(
    props?.request?.alternateNumber ? props?.request?.alternateNumber : ""
  );
  const [alternateNumberCountryCode, setAlternateNumberCountryCode] =
    React.useState(
      props?.request?.alternateNumberCountryCode
        ? props?.request?.alternateNumberCountryCode
        : ""
    );
  const isContactValid = () => {
    let isValid = true;
    let error = props?.request?.error;
    if (contactName.length === 0) {
      isValid = false;
      error.contactName = t("ContactNameisRequired");
    } else {
      props.request.contactName = contactName;
      error.contactName = "";
    }
    if (primaryNumber.length === 0 || primaryCountryCode === 0) {
      isValid = false;
      error.primaryNumber = t("PrimaryContactisRequired");
    } else {
      props.request.primaryNumber = primaryNumber;
      props.request.primaryCountryCode = primaryCountryCode;
      error.primaryNumber = "";
    }
    props.request.alternateNumber = alternateNumber;
    props.request.alternateNumberCountryCode = alternateNumberCountryCode;
    error.alternateNumber = "";
    props?.setRequest({ ...props.request, error });
    return isValid;
  };
  const onDrawerOpen = () => {
    setContactName(props.request.contactName);
    setPrimaryNumber(props.request.primaryNumber);
    setPrimaryCountryCode(props.request.primaryCountryCode);
    setAlternateNumber(props.request.alternateNumber);
    setAlternateNumberCountryCode(props.request.alternateNumberCountryCode);
    setShowDrawer(true);
  };
  const onDrawerClose = () => {
    if (isContactValid()) {
      setShowDrawer(false);
    }
  };
  const contactDetails = [
    {
      type: "Contact Name",
      value: props?.request?.contactName ? props?.request?.contactName : "",
    },
    {
      type: "Primary Contact Number",
      value:
        (props.request.primaryCountryCode
          ? props.request.primaryCountryCode +
          (props.request.primaryCountryCode ? " " : "")
          : "") +
        (props?.request?.primaryNumber ? props?.request?.primaryNumber : "-"),
    },
    {
      type: "Alternate Contact Number",
      value:
        (props.request.alternateNumberCountryCode
          ? props.request.alternateNumberCountryCode +
          (props.request.alternateNumberCountryCode ? " " : "")
          : "") +
        (props?.request?.alternateNumber
          ? props?.request?.alternateNumber
          : "-"),
    },
  ];

  const goBack = () => {
    props.previous(1);
  };
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        <TitleBar

          text={t("NewMaintenanceRequest")}
          goBack={goBack}
        />
        <Grid container className={classes.screen} xs={12}>
          <div style={{ zIndex: 999, width: "100%" }}>
            <Grid
              container
              className={classes.stepperBody}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <div className={classes.circle}>
                  <div className={classes.stepper}>2</div>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.steps}>Step 2 / 4</Typography>
                <Typography className={classes.details}>
                  {t("ContactDetails")}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={50}

              />
            </Box>
          </div>
          <Grid
            container
            className={classes.screenPadding}
          // style={{ height: size?.height - 300 }}
          >
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <Grid item xs={6}>
                <Typography className={classes.contact}>Contact</Typography>
              </Grid>
              <Grid item xs={6}>
                <div style={{ float: "right" }}>
                  <Typography onClick={onDrawerOpen} className={classes.change}>
                    {t("Change")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ContactDetails data={contactDetails} />
            </Grid>
          </Grid>
          <Container maxWidth="sm" className={classes.btnRoot}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btnPrevious}
                  onClick={() => {
                    props.previous(1);
                  }}
                >
                  {t("Previous")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  className={classes.btn}
                  onClick={() => {
                    props.next(3);
                  }}
                >
                  {t("Next")}
                </Button>
              </Grid>
            </Grid>
            {/* <br /> */}
          </Container>
        </Grid>

        <Hidden smDown>
          <Dialog
            className={classes.dialog}
            open={showDrawer}
            fullWidth={true}
            maxWidth="sm"
            onClose={() => setShowDrawer(false)}
          >
            <Grid container direction="column" className={classes.dialogStyle}>
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("ChangeContactDetails")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                <Box height={16} />
                <TextBox
                  isRequired
                  label="Contact Name"
                  value={contactName}
                  placeholder="Enter Contact Name"
                  onChange={(value) => setContactName(value.target.value)}
                  isError={props?.request?.error?.contactName?.length > 0}
                  errorMessage={props?.request?.error?.contactName}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  isRequired
                  label="Primary Contact"
                  placeholder=" 9876543210"
                  value={{
                    mobile: primaryNumber,
                    mobile_code: primaryCountryCode,
                  }}
                  handleChange={(value) => {
                    setPrimaryNumber(value.mobile);
                    setPrimaryCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.primaryNumber?.length > 0}
                  errorMessage={props?.request?.error?.primaryNumber}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Alternate Contact"
                  placeholder=" 9876543210"
                  value={{
                    mobile: alternateNumber,
                    mobile_code: alternateNumberCountryCode,
                  }}
                  handleChange={(value) => {
                    setAlternateNumber(value.mobile ?? "");
                    setAlternateNumberCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.alternateNumber?.length > 0}
                  errorMessage={
                    props?.request?.error?.alternateNumberCountryCode
                  }
                />
                <Box height={"32px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                  onClick={onDrawerClose}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Dialog>
        </Hidden>
        <Hidden smUp>
          <Drawer
            className={classes.drawer}
            anchor="bottom"
            open={showDrawer}
            onClose={() => setShowDrawer(false)}
          >
            <Grid
              container
              direction="column"
              className={classes.bottomSheetStyle}
            >
              <Grid
                container
                direction="row"
                className={classes.drawerHeaderStyle}
              >
                <Grid>
                  <Typography className={classes.drawerHeaderTextStyle}>
                    {t("ChangeContactDetails")}
                  </Typography>
                </Grid>
                <Grid>
                  <IconButton
                    style={{
                      padding: "0px",
                    }}
                    onClick={() => setShowDrawer(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />
              <Grid className={classes.drawerBodyStyle}>
                <Box height={16} />
                <TextBox
                  label="Contact Name"
                  value={contactName}
                  placeholder="Enter Contact Name"
                  onChange={(value) => setContactName(value.target.value)}
                  isError={props?.request?.error?.contactName?.length > 0}
                  errorMessage={props?.request?.error?.contactName}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Primary Contact"
                  placeholder=" 9876543210"
                  isRequired
                  value={{
                    mobile: primaryNumber,
                    mobile_code: primaryCountryCode,
                  }}
                  handleChange={(value) => {
                    setPrimaryNumber(value.mobile);
                    setPrimaryCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.primaryNumber?.length > 0}
                  errorMessage={props?.request?.error?.primaryNumber}
                />
                <Box height={16} />
                <MobileNumberInputComponent
                  label="Alternate Contact"
                  placeholder=" 9876543210"
                  // isRequired
                  value={{
                    mobile: alternateNumber,
                    mobile_code: alternateNumberCountryCode,
                  }}
                  handleChange={(value) => {
                    setAlternateNumber(value.mobile);
                    setAlternateNumberCountryCode(value.mobile_code);
                  }}
                  isError={props?.request?.error?.alternateNumber?.length > 0}
                  errorMessage={
                    props?.request?.error?.alternateNumberCountryCode
                  }
                />
                <Box height={"32px"} />
                <Button
                  fullWidth
                  className={classes.drawerButtonStyle}
                  variant="contained"
                  onClick={onDrawerClose}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </Drawer>
        </Hidden>
      </Container>
    </div>
  );
};
export default withNamespaces("maintance")(Step2)