export let Message = {
  'English': {
    requiredMessage: (field) => `${field ? field : "This field"} is required`,
    loading: "Loading...",
    unAuthorizedAccess: "404 - Unauthorized Access",
    navigationMessage: "Redirecting.... Please wait....",
    createAccount: {
      title: "Create Account",
      email: {
        label: "Email Id",
        placeholder: "Enter Email Id"
      },
      enterPassword: {
        label: "Password",
        placeholder: "Enter Password"
      },
      confirmPassword: {
        label: "Confirm Password",
        placeholder: "Confirm Password"
      },

    },
    login: {
      title: "login",
      email: {
        label: "Enter your email /mobile /UCID",
        placeholder: "Enter your email /mobile /UCID"
      },
      password: {
        label: "Password",
        placeholder: "Enter Password"
      },

    },
  }
};