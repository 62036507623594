import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { FontFamilySwitch } from "../../utils";
import { DialogDrawer } from "../dialogDrawer";
import { NoService } from "../noService";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    // border: "3px solid white",
    backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
    minHeight: "100px",
    display: "flex"
  },
  Cardcontent: {
    padding: "16px",
    flex: "auto"
  },
  title: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  count: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },

  image: {
    width: 46,
    height: 46,
    backgroundColor: (props) => props.data.color,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "12px 8px",
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    borderRadius: " 0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`
  }
}));

export const QuickLink = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [open, setopen] = React.useState(false);
  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          if (props?.data?.noavailable) {
            setopen(true);
          } else {
            history.push({
              pathname: props.data.link,
              state: {
                dashboard: true,
              },
            });
          }

        }}
      >
        <Box className={classes.sideBorder} />
        <div className={classes.Cardcontent}>
          <Grid container alignContent="center">
            <Grid xs={12}>
              <center>
                <img src={props.data.image} alt={props.data.name} />
                <Typography noWrap className={classes.count}>
                  {props.data.name}
                </Typography>
                <Typography noWrap className={classes.count} >
                  {props.data.count}
                </Typography>
              </center>
            </Grid>
          </Grid>
        </div>
      </div>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        noService={true}
        drawerComponent={
          <>
            <NoService
              buttonName={"Close"}
              mobile
              fullWidth
              onClose={() => setopen(false)}

            />
          </>
        }
        dialogComponent={
          <>
            <NoService
              buttonName={"Close"}
              fullWidth
              onClose={() => setopen(false)}

            />
          </>
        }
        onClose={() => setopen(false)} />
    </>
  );
};
