import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import { tabsClasses } from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { AlertDialog, DrawerComponent, PieCharts } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch } from "../../utils";
import {
  NetWorkCallMethods,
  TabContant
} from "../../utils/constants";
import {getTotal} from '../../utils'
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  cardNew: {
    borderRadius: theme.palette.borderRadius,
    // backgroundColor: theme.palette.background.paper,
    cursor: "pointer",
  },
  Expensestitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  expensesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#F6F9FF",
    padding: "12px",
    borderRadius: theme.palette.borderRadius,
    borderBottom: "1px solid #E4E8EE"

  },
  expensesHeader1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "14px 20px",
  },
  datevise: {
    backgroundColor: "white",
    borderRadius: theme.palette.borderRadius,
    display: "flex",
    padding: "4px"
  },
  select: {
    backgroundColor: theme.palette.primary.main,
    margin: "5px",
    color: "#fff",
    padding: "5px",
    fontSize: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  unselect: {
    margin: "5px",
    color: "#000",
    padding: "5px",
    fontSize: "10px",
    borderRadius: "4px",
    cursor: "pointer",
  },
  dot: {
    height: "10px",
    width: "10px",
    borderRadius: "50%",
    margin: "6px",
  },
  chartcontant: {
    fontSize: "16px",
    marginRight: "10px",
  },
  textDecoration: {
    color: theme.palette.primary.main,
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    textDecoration: "underline",
    margin: "10px 0px 0px 35px",
  },
  DrawerClass: {
    borderRadius: "4px 4px 0px 0px",
  },
  closebtn: {
    padding: "10px",
    "& button": {
      backgroundColor: theme.palette.primary.main,
      width: "100%",
      padding: "16px",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  amountCount: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  tab: {
    backgroundColor: "white"
  }
}));

const HomepageGraph = (props) => {
  const { t } = props
  const [Yearly, setYearly] = React.useState(true);
  const [Mountly, setMountly] = React.useState(false);
  const alert = React.useContext(AlertContext);
  // const [agreementID, setAgreementID] = React.useState([]);
  const [YearDataMain, setYearDataMain] = React.useState({
    amount: "",
    symbol: "",
  });
  const [YearDataGeneral, setYearDataGeneral] = React.useState({
    amount: "",
    symbol: "",
  });
  const [YearDataRent, setYearDataRent] = React.useState({
    amount: "",
    symbol: "",
  });
  const [monthDataMain, setMonthDataMain] = React.useState({
    amount: "",
    symbol: "",
  });
  const [monthDataGeneral, setMonthDataGeneral] = React.useState({
    amount: "",
    symbol: "",
  });
  const [monthDataRent, setMonthDataRent] = React.useState({
    amount: "",
    symbol: "",
  });

  const [graphChart , setGraphChart]=React.useState([])
  const [graphChartMonth , setGraphChartMonth]=React.useState([])

  const classes = useStyles();
  const history = useHistory();

  // tab functionality && states

  const [value, setValue] = React.useState(2022);
  const [value1, setValue1] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getYearMonth(newValue);
  };
  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
    getMonthData(newValue, value);
  };
  const showMounth = () => {
    setMountly(true);
    setYearly(false);
    getMonthData(value1, value);
  };
  const showYearly = () => {
    setMountly(false);
    setYearly(true);
  };

  // drawer

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getYearMonth = (key) => {
    setYearDataMain({
      amount: 0,
      symbol: "",
    });
    setYearDataGeneral({
      amount: 0,
      symbol: "",
    });
    setYearDataRent({
      amount: 0,
      symbol: "",
    });
    const data = {
      year: key,
    };
    NetworkCall(
      `${config.api_url}/invoice/stats`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const constractData = response?.data?.data?.map((x)=>{
          return{
            type:x?.type,
            symbol:x?.symbol,
            value:x?.amount,
            fill: `#${Math.random().toString(16).substring(2, 8)}`
          }
        })
        setGraphChart({
          data:constractData,
          total:getTotal(constractData)
        })
        let temp = response?.data?.data
          .filter((x) => x.type === "Maintenance")
          .map((val) => val);
        temp &&
          temp.length &&
          setYearDataMain({
            amount: temp[0].amount,
            symbol: temp[0].symbol,
          });

        // general
        let tempgeneral = response.data.data
          .filter((x) => x.type === "General")
          .map((val) => val);
        tempgeneral &&
          tempgeneral.length &&
          setYearDataGeneral({
            amount: tempgeneral[0].amount,
            symbol: tempgeneral[0].symbol,
          });

        // rental
        let tempRental = response.data.data
          .filter((x) => x.type === "Rental")
          .map((val) => val);
        tempRental &&
          tempRental.length &&
          setYearDataRent({
            amount: tempRental[0].amount,
            symbol: tempRental[0].symbol,
          });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };

  const getMonthData = (month, year) => {
    setMonthDataMain({
      amount: 0,
      symbol: "",
    });
    setMonthDataGeneral({
      amount: 0,
      symbol: "",
    });
    setMonthDataRent({
      amount: 0,
      symbol: "",
    });
    const data = JSON.stringify({
      year: year,
      month: month + 1,
    });
    NetworkCall(
      `${config.api_url}/invoice/stats`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const constractData = response?.data?.data?.map((x)=>{
          return{
            type:x?.type,
            symbol:x?.symbol,
            value:x?.amount,
            fill: `#${Math.random().toString(16).substring(2, 8)}`
          }
        })
        setGraphChartMonth({
          data:constractData,
          total:getTotal(constractData)
        })
        let temp = response?.data?.data
          .filter((x) => x.type === "Maintenance")
          .map((val) => val);
        temp &&
          temp.length &&
          setMonthDataRent({
            amount: temp[0].amount,
            symbol: temp[0].symbol,
          });

        // general
        let tempgeneral = response.data.data
          .filter((x) => x.type === "General")
          .map((val) => val);
        tempgeneral &&
          tempgeneral.length &&
          setMonthDataMain({
            amount: tempgeneral[0].amount,
            symbol: tempgeneral[0].symbol,
          });

        // rental
        let tempRental = response.data.data
          .filter((x) => x.type === "Rental")
          .map((val) => val);
        tempRental &&
          tempRental.length &&
          setMonthDataGeneral({
            amount: tempRental[0].amount,
            symbol: tempRental[0].symbol,
          });
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some Thing Went Wrong.",
        });
      });
  };

  React.useEffect(() => {

    const { data } = props;
    if (data) {
      getYearMonth(
        value
      );
      getMonthData(
        value1,
        value
      );
      // setAgreementID(data.agreements.result.map((x) => x.id));
    }
    else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);


  return (
    <>
      {/* Expenses Pie Chart */}
      <Box sx={{ width: "100%" }} className={classes.cardNew}>
        <Box className={classes.expensesHeader}>
          <Box>
            <Typography variant="subtitle2" className={classes.Expensestitle}>
              {t("dashInvoiceCategory")}
            </Typography>
          </Box>
          <Box>
            <Box className={classes.datevise}>
              {/* yearly */}
              <Box className={Yearly ? classes.select : classes.unselect}>
                <Typography
                  variant="subtitle2"
                  className={classes.yearly}
                  onClick={showYearly}
                >
                  &nbsp;{t("dashYearly")}&nbsp;
                </Typography>
              </Box>
              {/* monthly */}
              <Box className={Mountly ? classes.select : classes.unselect}>
                <Typography
                  variant="subtitle2"
                  className={classes.yearly}
                  onClick={showMounth}
                >
                  {t("dashMonthly")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* yearly */}
        <TabContext value={value}>
          {/* when yearly is show */}
          {Yearly === true && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "white" }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {TabContant(t).TabYearly.map((val) => (
                    <Tab label={val.label} value={val.value} />
                  ))}
                </TabList>
              </Box>

              <TabPanel value={value} className={classes.tab}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={5}>
                    <PieCharts
                      data={graphChart?.data ?? []}
                      width={111}
                      YearDataMain={YearDataMain}
                      YearDataGeneral={YearDataGeneral}
                      YearDataRent={YearDataRent}
                      total={graphChart?.total}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7} >
                    {
                      graphChart?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                  
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}
        </TabContext>
        {/* mountly */}
        <TabContext value={value1}>
          {/* when show month is show */}
          {Mountly === true && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: "divider", backgroundColor: "white" }}>
                <TabList
                  onChange={handleChange1}
                  variant="scrollable"
                  scrollButtons
                  aria-label="scrollable auto tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {TabContant(t).Tabs.map((x) => (
                    <Tab label={x.mounth} value={x.value} />
                  ))}
                </TabList>
              </Box>
              <TabPanel value={value1} className={classes.tab}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} sm={5}>
                    <PieCharts
                      data={graphChartMonth?.data ?? []}
                      width={111}
                      YearDataMain={monthDataMain}
                      YearDataGeneral={monthDataGeneral}
                      YearDataRent={monthDataRent}
                      total={graphChartMonth?.total}
                    />
                  </Grid>
                  <Grid item xs={12} sm={7}>
                  {
                      graphChartMonth?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                    {
                      graphChartMonth?.data?.length > 0 &&  <Typography
                      className={classes.textDecoration}
                      onClick={toggleDrawer}
                    >
                      {t("dashViewAll")}
                    </Typography>
                    }
                   
                  </Grid>
                </Grid>
              </TabPanel>
            </>
          )}
        </TabContext>
        {/* view  drawer in mobile view */}
        <Hidden smUp>
          <DrawerComponent
            open={open}
            toggleDrawer={toggleDrawer}
            onClose={toggleDrawer}
            component={
              <>
                <Box className={classes.expensesHeader1}>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.Expensestitle}
                    >
                      {t("dashExpenses")}
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={classes.datevise}>
                      {/* yearly */}
                      <Box
                        className={Yearly ? classes.select : classes.unselect}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.yearly}
                          onClick={showYearly}
                        >
                          &nbsp;{t("dashYearly")}&nbsp;
                        </Typography>
                      </Box>
                      {/* monthly */}
                      <Box
                        className={Mountly ? classes.select : classes.unselect}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.yearly}
                          onClick={showMounth}
                        >
                          {t("dashMonthly")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* yearly */}
                <TabContext value={value}>
                  {/* when yearly is show */}
                  {Yearly === true && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          {TabContant(t).TabYearly.map((val) => (
                            <Tab label={val.label} value={val.value} />
                          ))}
                        </TabList>
                      </Box>

                      <TabPanel value={value}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <PieCharts
                              data={graphChart?.data}
                              width={130}
                              YearDataMain={YearDataMain}
                              YearDataGeneral={YearDataGeneral}
                              YearDataRent={YearDataRent}
                              total={graphChart?.total}
                            />
                          </Grid>
                          <Grid item xs={12}>
                          {
                      graphChart?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  )}
                </TabContext>
                {/* mountly */}
                <TabContext value={value1}>
                  {/* when show month is show */}
                  {Mountly === true && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange1}
                          variant="scrollable"
                          scrollButtons
                          aria-label="scrollable auto tabs example"
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                        >
                          {TabContant(t).Tabs.map((x) => (
                            <Tab label={x.mounth} value={x.value} />
                          ))}
                        </TabList>
                      </Box>
                      <TabPanel value={value1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <PieCharts
                              data={graphChartMonth?.data}
                              width={130}
                              YearDataMain={monthDataMain}
                              YearDataGeneral={monthDataGeneral}
                              YearDataRent={monthDataRent}
                              total={graphChartMonth?.total}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: "-20px" }}>
                            <Box marginLeft="-8px">
                            {
                      graphChartMonth?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                            </Box>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  )}
                </TabContext>
                <div className={classes.closebtn}>
                  <Button variant="contained" onClick={toggleDrawer}>
                    {t("dashClose")}
                  </Button>
                </div>
              </>
            }
          />
        </Hidden>

        {/* view pop up in laptop view */}
        <Hidden smDown>
          <AlertDialog
            open={open}
            toggleDrawer={toggleDrawer}
            onClose={toggleDrawer}
            component={
              <div style={{ width: "100%" }}>
                <Box
                  className={classes.expensesHeader1}
                  style={{ pading: "13px 25px" }}
                >
                  <Box>
                    <Typography
                      variant="subtitle2"
                      className={classes.Expensestitle}
                    >
                      {t("dashExpenses")}
                    </Typography>
                  </Box>
                  <Box>
                    <Box className={classes.datevise}>
                      {/* yearly */}
                      <Box
                        className={Yearly ? classes.select : classes.unselect}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.yearly}
                          onClick={showYearly}
                        >
                          &nbsp;{t("dashYearly")}&nbsp;
                        </Typography>
                      </Box>
                      {/* monthly */}
                      <Box
                        className={Mountly ? classes.select : classes.unselect}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.yearly}
                          onClick={showMounth}
                        >
                          {t("dashMonthly")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {/* yearly */}
                <TabContext value={value}>
                  {/* when yearly is show */}
                  {Yearly === true && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          {TabContant(t).TabYearly.map((val) => (
                            <Tab label={val.label} value={val.value} />
                          ))}
                        </TabList>
                      </Box>

                      <TabPanel value={value}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <PieCharts
                              data={graphChart?.data ?? []}
                              width={130}
                              YearDataMain={YearDataMain}
                              YearDataGeneral={YearDataGeneral}
                              YearDataRent={YearDataRent}
                              total={graphChart?.total}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: "26px" }}>
                          {
                      graphChart?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  )}
                </TabContext>
                {/* mountly */}
                <TabContext value={value1}>
                  {/* when show month is show */}
                  {Mountly === true && (
                    <>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange1}
                          variant="scrollable"
                          scrollButtons
                          aria-label="scrollable auto tabs example"
                          sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                              "&.Mui-disabled": { opacity: 0.3 },
                            },
                          }}
                        >
                          {TabContant(t).Tabs.map((x) => (
                            <Tab label={x.mounth} value={x.value} />
                          ))}
                        </TabList>
                      </Box>
                      <TabPanel value={value1}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <PieCharts
                              data={graphChartMonth?.data ?? []}
                              width={130}
                              YearDataMain={monthDataMain}
                              YearDataGeneral={monthDataGeneral}
                              YearDataRent={monthDataRent}
                              total={graphChartMonth?.total}
                            />
                          </Grid>
                          <Grid item xs={12}>
                          {
                      graphChartMonth?.data?.map((val)=>{
                        return(
                          <Box>
                          {/* Rent*/}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0px 0px 10px",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                className={classes.dot}
                                style={{ backgroundColor: val?.fill }}
                              />
                              <Typography
                                variant="subtitle2"
                                className={classes.chartcontant}
                              >
                                {val?.type}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="subtitle2"
                                className={classes.amountCount}
                              >
                                {val.symbol ? (
                                  <>
                                    {val?.symbol}&nbsp;
                                    {val.value}
                                  </>
                                ) : (
                                  <>0</>
                                )}
                              </Typography>
                            </Box>
                          </div>
                        </Box>
                        )
                      })
                    }
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </>
                  )}
                </TabContext>
                <div className={classes.closebtn} style={{ padding: "25px" }}>
                  <Button variant="contained" onClick={toggleDrawer}>
                    {t("dashClose")}
                  </Button>
                </div>
              </div>
            }
          />
        </Hidden>
      </Box>

      {/* end */}
    </>
  );
};

export default withNamespaces("dashboard")(HomepageGraph)