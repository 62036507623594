import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box } from "@mui/material";
import React from "react";
import { useStyles } from "./styles";


export const AssetCard = ({
    x = {},
    onDelete = () => false,
    disable = false,
    onClick = () => false
}) => {
    const classes = useStyles();
    return (

        <Box position={"relative"} sx={{ cursor: "pointer", marginLeft: "10px" }}>
            {
                !disable &&
                <span onClick={onDelete} className={classes.deleteicon}>
                    <CancelRoundedIcon sx={{ color: "#071741" }} />
                </span>
            }

            <img src={x?.url} onClick={onClick} className={`${classes.selectedImg}`} alt="" />

        </Box>
    )
}