/* eslint-disable array-callback-return */
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ShareIcon from '@mui/icons-material/Share';

import {
  Box,
  Button, CircularProgress, Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import html2canvas from 'html2canvas';
import moment from "moment-timezone";
import React, { useRef } from "react";
import QRCode from "react-qr-code";
import { useHistory, useLocation } from "react-router-dom";
import { TextBox, TitleBar, VisiterCard } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { CANCEL_WORKER_PASS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, enumSelect, enumCatagory, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
    overflow: "hidden",
  },
  rootCard: {
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0px 3px 16px #F2F5FA",
  },
  img: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "2px 8px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  sub: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.Tertiary,
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  rejected: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#EC903F",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
    display: "inline",
  },
  round: {
    color: "#F2F5FA",
    fontSize: "40px",
  },
  border: {
    border: "1px solid #E4E8EE",
    marginTop: "-4px",
    backgroundColor: "#E4E8EE",
  },
  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  qr: {
    fontSize: "12px",
    color: theme.palette.success.dark,
    fontFamily: FontFamilySwitch().bold,
    margin: "0px 12px"
  },
  expire: {
    fontSize: "10px",
    color: theme.palette.success.dark,
    fontFamily: FontFamilySwitch().semiBold,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #5078E1",
    backgroundColor: "white",
    borderRadius: "4px",
    textTransform:"capitalize"

  },
  submitbtn: {
    borderRadius: "10px",
    padding: "12px 8px",
    textTransform:"capitalize"

  },

  propertyname: {
    color: "#78B1FE",
    marginLeft: "10px",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "14px"

  }
}));
const InitialState = {
  remarks: "",
  error: {
    remarks: "",
  },
};
const GatePassDetails = ({ t }) => {
  const size = useWindowDimensions();
  const history = useHistory();
  const searchURL = useLocation().search;
  const [drawer, setDrawer] = React.useState(false);
  const classes = useStyles();
  const exportRef = useRef();
  const [details, setDetails] = React.useState([]);
  const passId = new URLSearchParams(searchURL).get("passId");
  const [detailByuniq, setDetailsuniq] = React.useState({});
  const [canceldata, setCancelData] = React.useState({ ...InitialState });
  const [cancel, setCancel] = React.useState(false);
  const backdrop = React.useContext(BackdropContext);
  const logo = JSON.parse(localStorage.getItem("accessType"))?.filter((val) => val?.name === "Tenant")?.find((item) => item?.company_logo !== null)
  const clientLogo = localStorage.getItem("clientLogo")
  const [downlodeload, setDownloeload] = React.useState(false)
  const alert = React.useContext(AlertContext);
  const [enumValue, setenumValue] = React.useState({
    status: {},
    type: {}
  });
  const getPassDetails = () => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_id: passId,
    };
    NetworkCall(
      `${config.api_url}/security/request`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDetails(response.data.data[0]);
        backdrop.setBackDrop({ ...backdrop, open: false });
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  React.useEffect(() => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "",
    });
    getPassDetails();
    getEnum()
    // eslint-disable-next-line
  }, []);
  const getEnum = async (data) => {
    const result = await enumSelect([enumCatagory?.security_status_enum_type, enumCatagory?.security_request_type])
    let requestSourceMap = result?.security_request_type.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestType = Object.keys(requestSourceMap).map(key => requestSourceMap[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})

    let requestSourceMapStatus = result?.security_status_enum_type.map((item, index) => {
      return {
        [`type${index + 1}`]: item?.value
      }
    })

    let requestTypeStatus = Object.keys(requestSourceMapStatus).map(key => requestSourceMapStatus[key]).reduce((old, item) => (
      { ...old, ...item }
    ), {})


    setenumValue({ ...enumValue, status: requestTypeStatus, type: requestType })
  }
  const renderDetails = () => {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          className={classes.titleroot}
          p={1}
        >
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("View")} {details?.request_type === "VGR" && "Visitor"}
              {details?.request_type === "WGR" && "Worker"} {t("Detail")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <div style={{ padding: "12px" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" p={2}>
                <Box>

                  <img
                    src={detailByuniq?.visitor_image}
                    alt=""
                    style={{ objectFit: "cover", marginTop: "4px", borderRadius: "50%", height: "47px", width: "47px" }}
                  />

                </Box>
                <Box marginLeft="10px">
                  <Typography variant="subtitle2" className={classes.name}>
                    {detailByuniq?.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {detailByuniq?.id_type && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("IDPROOF")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {detailByuniq?.id_type}
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" className={classes.heading}>
                {t("PHONENUMBER")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#091B29" }}
              >
                {detailByuniq?.mobile_country_code}&nbsp;
                {detailByuniq?.mobile_no}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {detailByuniq?.identification_no && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("IDNUMBER")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {detailByuniq?.identification_no && (
                      <>
                        &nbsp;({detailByuniq?.identification_no.slice(0, 20)})
                        {detailByuniq?.identification_no?.length > 20 && "..."}
                      </>
                    )}
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" className={classes.heading}>
                {t("MAILID")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#091B29" }}
              >
                {detailByuniq?.email_id}
              </Typography>
            </Grid>
            {details?.status === "Approved" && (
              <>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("CHECKINBY")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {detailByuniq?.check_in_by}&nbsp;{" "}
                    {detailByuniq?.check_in_time && (
                      <>
                        {moment(detailByuniq?.check_in_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm a")}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("CHECKOUTBY")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {detailByuniq?.check_out_by}&nbsp;{" "}
                    {detailByuniq?.check_out_time && (
                      <>
                        {moment(detailByuniq?.check_out_time)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY hh:mm a")}
                      </>
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </>
    );
  };
  const handleDetails = (data) => {
    setDrawer(true);

    setDetailsuniq(data);
  };
  const updateState = (key, value) => {
    let error = canceldata.error;
    error[key] = "";
    setCancelData({ ...canceldata, [key]: value, error });
  };
  const isIamValideTostep = () => {
    let isValid = true;
    let error = canceldata.error;
    //Checking Pass Type
    if (canceldata.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }

    setCancel({ ...canceldata, error });
    return isValid;
  };
  const cancelPass = () => {
    if (isIamValideTostep()) {
      const data = {
        query: CANCEL_WORKER_PASS,
        variables: {
          Id: passId,
          data: {
            status: "Cancelled",
            cancel_remarks: canceldata?.remarks,
          },
        },
      };
      NetworkCall(
        `${config.graphql_url}`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          getPassDetails();
          setCancel(false);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      return false;
    }
  };
  const renderForm = () => {
    return (
      <div style={{ padding: "12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              {t("CancelGatePass")}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setCancel(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="12px" />
        <TextBox
          color
          label={t("Remarks")}
          placeholder={t("EnterRemarks")}
          value={canceldata.remarks}
          multiline
          onChange={(value) => updateState("remarks", value.target.value)}
          isError={canceldata?.error?.remarks?.length > 0}
          errorMessage={canceldata?.error?.remarks}
        />
        <Box height="12px" />
        <Button
          fullWidth
          className={classes.submitbtn}
          variant="contained"
          onClick={cancelPass}
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };

  //download ticket
  const downloadTicket = async (element) => {
    setDownloeload(true)
    setTimeout(() => {
      const node = document.getElementById("ticket");
      // useCors to draw image from different origin
      html2canvas(node, { useCORS: true }).then((canvas) => {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.download = `${element}.png`;
        a.href = canvas.toDataURL();
        a.click();
        setDownloeload(false)
      });
    }, 500);

  }

  //base 64 to normal file
  function urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  //share ticket
  const sharePass = async (fileName) => {
    setDownloeload(true)
    const node = document.getElementById("ticket");
    // useCors to draw image from different origin
    html2canvas(node, { useCORS: true }).then(async (canvas) => {

      let base64File = canvas.toDataURL();
      let file2 = await urltoFile(base64File, `${fileName}.png`, "image/png")
      setDownloeload(false)
      let fileArray = [file2];
      if (navigator.canShare && navigator.canShare({ files: fileArray })) {
        try {
          await navigator
            .share({
              files: fileArray
            })

          /*
            Show a message if the user shares something
          */
          console.log(`Thanks for Sharing!`);
        } catch (err) {
          /*
             This error will appear if the user cancels the action of sharing.
           */
          console.log(`Couldn't share ${err}`);
        }


      }

    })





  }



  return (
    <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
      <TitleBar
        text={
          details?.request_type === enumValue?.type?.type1
            ? "Visitor Gate Pass"
            : "Worker Gate Pass"
        }

        goBack={() => history.goBack()}
      />
      <Grid
        container
        className={classes.screenScrollEffect}
        style={{
          height:
            details?.status === "Pending"
              ? size?.height - 140
              : size?.height - 60,
          marginRight: "-6px",
          backgroundColor: "#F2F5FA",
          overflow: "scroll",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ padding: "12px 6px 12px 12px" }}
          ref={exportRef}
          id="ticket"
        >

          <div className={classes.rootCard}>
            {details?.status === "Approved" && (
              <>
                <br />
                <center>
                  <div style={{ marginBottom: "8px" }}>
                    <img src={logo?.company_logo ?? clientLogo} alt="" style={{ height: "30px" }} />
                  </div>
                  <QRCode value={`${details?.qr_no}`} size={220} />
                </center>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                  <Box display="flex" alignItems="center" bgcolor={"#E8F9E8"} style={{ borderRadius: "4px" }}>
                    <Box style={{ overflow: "hidden" }}>
                      <CircleIcon
                        style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                      />
                    </Box>
                    <Box flexGrow={1} padding={"8px 36px"}>
                      <Typography className={classes.qr}>
                        {t("Passcode")}: {details?.qr_no}
                      </Typography>
                      <Typography variant="subtitle2" className={classes.expire}>
                        {t("ValidTill")}&nbsp;
                        {moment(details?.request_to)
                          .tz(moment.tz.guess())
                          .format("DD MMM YY, hh:mm a ")}
                      </Typography>
                    </Box>
                    <Box style={{ overflow: "hidden" }}>
                      <CircleIcon
                        style={{ marginRight: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                      />
                    </Box>
                  </Box>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }} >
                  <div style={{ backgroundColor: "#F1F7FF", padding: "8px 16px", borderRadius: "4px" }}>
                    <img src="/images/icons8-city.svg" alt="city" />
                    <span className={classes.propertyname}>{details?.property_name}</span>
                  </div>
                </div>
                <Box display="flex" alignItems="center">
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginLeft: "-20px" }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginRight: "-20px" }}
                    />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" p={3} marginTop="-24px">
                  <Box className={classes.img}>
                    <img
                      src="/images/workergate.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  </Box>
                  <Box flexGrow={1} marginLeft="14px">
                    <Typography variant="subtitle2" className={classes.title}>
                      {details?.request_type === "VGR" && "Visitor Gate Pass"}
                      {details?.request_type === "WGR" && "Worker Gate Pass"}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {t("Requestedon")}{" "}
                      {moment(details?.request_from)
                        .tz(moment.tz.guess())
                        .format("DD MMM")}
                    </Typography>
                    {details?.status === "Pending" && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.progress}
                      >
                        {t("InProgress")}
                      </Typography>
                    )}
                    {details?.status === "Approved" && (
                      <Typography variant="subtitle2" className={classes.approved}>
                        {t("Approved")}
                      </Typography>
                    )}
                    {details?.status === "Rejected" && (
                      <Typography variant="subtitle2" className={classes.rejected}>
                        {t("Rejected")}
                      </Typography>
                    )}
                    {details?.status === "Cancelled" && (
                      <Typography variant="subtitle2" className={classes.delete}>
                        {t("Cancelled")}
                      </Typography>
                    )}
                  </Box>
                  <Box display={downlodeload && "none"}>
                    {details?.status !== "Cancelled" && (
                      <>
                        {details?.status === "Approved" ? (
                          <>
                            {
                              downlodeload ?
                                <CircularProgress />
                                :
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                >
                                  <IconButton
                                    onClick={() => sharePass(details?.request_type)}
                                  >
                                    <ShareIcon color="primary" />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => downloadTicket(details?.request_type)}
                                  >
                                    <DownloadOutlinedIcon color="primary" />
                                  </IconButton>
                                </Stack>

                            }

                          </>
                        ) : (
                          <IconButton
                            onClick={() =>
                              history.push(Routes.createPass + "?passId=" + passId)
                            }
                          >
                            <img
                              src="/images/Outline.svg"
                              alt=""
                              style={{ objectFit: "contain", marginTop: "4px" }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <Box flexGrow={1} margin="0px 20px">
                  <Box className={classes.border} />
                </Box>
              </>
            )}
            {details?.status !== "Approved" && (
              <>
                <Box display="flex" alignItems="center" p={3}>
                  <Box className={classes.img}>
                    <img
                      src="/images/workergate.svg"
                      alt=""
                      style={{ objectFit: "contain", marginTop: "4px" }}
                    />
                  </Box>
                  <Box flexGrow={1} marginLeft="14px">
                    <Typography variant="subtitle2" className={classes.title}>
                      {details?.request_type === "VGR" && "Visitor Gate Pass"}
                      {details?.request_type === "WGR" && "Worker Gate Pass"}
                    </Typography>
                    <Typography variant="subtitle2" className={classes.sub}>
                      {t("Requestedon")}{" "}
                      {moment(details?.request_from)
                        .tz(moment.tz.guess())
                        .format("DD MMM")}
                    </Typography>
                    {details?.status === "Pending" && (
                      <Typography
                        variant="subtitle2"
                        noWrap
                        className={classes.progress}
                      >
                        {t("InProgress")}
                      </Typography>
                    )}
                    {details?.status === "Approved" && (
                      <Typography variant="subtitle2" className={classes.approved}>
                        {t("Approved")}
                      </Typography>
                    )}
                    {details?.status === "Rejected" && (
                      <Typography variant="subtitle2" className={classes.rejected}>
                        {t("Rejected")}
                      </Typography>
                    )}
                    {details?.status === "Cancelled" && (
                      <Typography variant="subtitle2" className={classes.delete}>
                        {t("Cancelled")}
                      </Typography>
                    )}
                  </Box>
                  <Box>
                    {details?.status !== "Cancelled" && (
                      <>
                        {details?.status === "Approved" ? (
                          <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <IconButton
                              onClick={() => sharePass(details?.request_type)}
                            >
                              <ShareIcon color="primary" />
                            </IconButton>
                            <IconButton
                              onClick={() => downloadTicket(details?.request_type)}
                            >
                              <DownloadOutlinedIcon color="primary" />
                            </IconButton>
                          </Stack>

                        ) : (
                          <IconButton
                            onClick={() =>
                              history.push(Routes.createPass + "?passId=" + passId)
                            }
                          >
                            <img
                              src="/images/Outline.svg"
                              alt=""
                              style={{ objectFit: "contain", marginTop: "4px" }}
                            />
                          </IconButton>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginLeft: "-20px" }}
                    />
                  </Box>
                  <Box flexGrow={1}>
                    <Box className={classes.border} />
                  </Box>
                  <Box style={{ overflow: "hidden" }}>
                    <CircleIcon
                      className={classes.round}
                      style={{ marginRight: "-20px" }}
                    />
                  </Box>
                </Box>

              </>)}
            <Box p={3} marginTop="-24px">
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("REFERENCEID")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.request_no}
                  </Typography>
                </Grid>
                <Grid item xs={6}>

                  <Typography variant="subtitle2" className={classes.heading}>
                    {details?.request_type === "WGR" && "VISITOR"}
                    {details?.request_type === "VGR" && "WORKER"} STAY TYPE
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.stay_type}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("CHOOSEGATEENTRY")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.gate}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("UNIT")}
                  </Typography>
                  <Typography variant="subtitle2" className={classes.title}>
                    {details?.unit?.[0]?.name}
                  </Typography>

                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("VISITSTARTandENDDATE")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    noWrap
                  >
                    {moment(details?.request_from)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY hh:mm a")}
                    <Box height="4px" />
                    {moment(details?.request_to)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY hh:mm a")}
                  </Typography>
                </Grid>
                {details?.status === "Rejected" && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.heading}>
                      {t("RejectedRemarks")}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      noWrap
                    >
                      {details?.name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <br />
            </Box>
            {details?.visitors?.length > 0 && (
              <Box p={2} marginTop="-24px">
                <Box className={classes.border} />
                {details?.visitors?.map((val) => {
                  return <VisiterCard data={val} onClick={handleDetails} downlodeload={downlodeload} />;
                })}
              </Box>
            )}
          </div>
        </Grid>
      </Grid>
      {details?.status === "Pending" && (
        <div
          xs={12}
          style={{
            padding: "12px",
            backgroundColor: "white",
            boxShadow: "0px -1px 6px #00000021",
          }}
        >
          <Button
            fullWidth
            className={classes.cancelbtn}
            onClick={() => setCancel(true)}
          >
            {t("CancelService")}
          </Button>
        </div>
      )}

      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderDetails()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderDetails()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={cancel}
          onClose={() => setCancel(false)}
        >
          {renderForm()}
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("workerGatepass")(GatePassDetails)