/* eslint-disable array-callback-return */
import { Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
  TitleBar
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_AGREEMENTACESS } from "../../graphql/mutations";
import {
  CHECK_MOBILENO, GET_PROPERTY_BY_UNITID_VIEW
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },
  input: {
    marginTop: "10px",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
}));
const InitialState = {
  unit: "",
  userName: "",
  number: "",
  email: "",
  gate: "",
  error: {
    unit: "",
    userName: "",
    email: "",
    gate: "",
  },
};
const InitialState_2 = {
  account_no: "",
  userName: "",
  number: "",
  email: "",
  gate: "",
  error: {
    account_no: "",
    userName: "",
    email: "",
    gate: "",
  },
};
const CreateAggreementUnit = ({t}) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const searchURL = useLocation().search;
  const title = new URLSearchParams(searchURL).get("title");
  const [data, setdata] = React.useState({ ...InitialState });
  const [data2, setdata2] = React.useState({ ...InitialState_2 });
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [unitFilter, setFilter] = React.useState([]);
  const alert = React.useContext(AlertContext);
  // const passId = new URLSearchParams(searchURL).get("passId");
  const companyId = JSON.parse(localStorage.getItem("accessType"));
  const [unitAgreement, setUnitAgreement] = React.useState();

  const getpropertyByunit = (val) => {
    const data = {
      query: GET_PROPERTY_BY_UNITID_VIEW,
      variables: {
        unit_id: val,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        const propertyType = response?.data.data?.unit.map((val, index) => {
          let _d;
          try {
            _d = {
              label: val?.name,
              value: val?.id,
              propertyId: val?.property?.id,
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong")+".",
            });
          }
          return _d;
        });
        setFilter(propertyType);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };
  const getUnitids = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded.id,
    };
    NetworkCall(
      `${config.api_url}/dashboard`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        getpropertyByunit(
          response.data?.data?.units?.result.map((val) => val?.unit_name)
        );
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };
  const aggreementId = () => {
    const tempDecoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: tempDecoded.id,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setUnitAgreement(response?.data?.data);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };

  // const getGates = (val) => {
  //   const params = {
  //     query: GET_GATE_VIEW,
  //     variables: {
  //       property_id: val,
  //     },
  //   };
  //   NetworkCall(
  //     `${config.graphql_url}`,
  //     NetWorkCallMethods.post,
  //     params,
  //     null,
  //     true,
  //     false
  //   )
  //     .then((response) => {
  //       setGate([...gate, ...response.data.data.access_gates_master]);
  //     })
  //     .catch((error) => {
  //       alert.setSnack({
  //         ...alert,
  //         open: true,
  //         severity: AlertProps.severity.error,
  //         msg: t("SomethingWentwrong")+".",
  //       });
  //     });
  // };
  React.useEffect(() => {
    aggreementId();
    getUnitids();

    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const updateState2 = (key, value) => {
    let error = data2.error;
    error[key] = "";
    setdata2({ ...data2, [key]: value, error });
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking unit
    if (data.unit.length === 0) {
      isValid = false;
      error.unit = t("UnitisRequired");
    }
    //Checking vehicleType
    if (data.userName.length === 0) {
      isValid = false;
      error.userName = t("VehicleTypeisRequired");
    }
    //Checking startDate
    if (data.number.length === 0) {
      isValid = false;
      error.number = t("StartDateisRequired");
    }
    //Checking endDate
    if (data.email.length === 0) {
      isValid = false;
      error.email = t("EndDateisRequired");
    }

    setdata({ ...data, error });
    return isValid;
  };
  const isIamValideTostep2 = () => {
    let isValid = true;
    let error = data2.error;
    //Checking unit
    if (data2.account_no.length === 0) {
      isValid = false;
      error.account_no = "Account no is Required";
    }
    //Checking vehicleType
    if (data2.userName.length === 0) {
      isValid = false;
      error.userName = "userName is Required";
    }
    //Checking startDate
    if (data2.number.length === 0) {
      isValid = false;
      error.number = "Number is Required";
    }
    //Checking endDate
    if (data2.email.length === 0) {
      isValid = false;
      error.email = "email is Required";
    }

    setdata2({ ...data2, error });
    return isValid;
  };
  const add = () => {
    let currentDate = new Date().toISOString();
    let userProfileId = localStorage.getItem("userProfileId");
    const data = {
      query: GET_AGREEMENTACESS,
      variables: {
        data: [
          {
            account_id: data2?.account_no?.value,
            name: data2?.userName,
            mobile_no: data2?.number?.mobile,
            mobile_country_code: data2?.number?.mobile_code,
            email_id: data2?.email,
            property_id: unitFilter?.propertyId,
            is_active: true,
            company_id: companyId[0]?.company_id,
            request_type: "Agreement Access",
            created_at: currentDate,
            updated_at: currentDate,
            created_by: userProfileId,
            updated_by: userProfileId,
            client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
          },
        ],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Accountaccessrequestcreatedsuccessfully"),
        });
        history.push(Routes.aggreementUnitAccess);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };
  const add2 = () => {
    let currentDate = new Date().toISOString();
    let userProfileId = localStorage.getItem("userProfileId");
    let agreementId = unitAgreement
      .map((item) => {
        let dataUnit = item?.units?.some((i) => i === data?.unit?.value);
        if (dataUnit) {
          return item?.id;
        } else {
          return false;
        }
      })
      .filter(Boolean)[0];
    const data3 = {
      query: GET_AGREEMENTACESS,
      variables: {
        data: [
          {
            unit_id: data?.unit?.value,
            name: data?.userName,
            mobile_no: data?.number?.mobile,
            mobile_country_code: data?.number?.mobile_code,
            email_id: data?.email,
            property_id: data?.unit?.property?.id,
            is_active: true,
            agreement_id: agreementId,
            company_id: companyId[0]?.company_id,
            created_at: currentDate,
            updated_at: currentDate,
            created_by: userProfileId,
            updated_by: userProfileId,
            request_type: "Unit Access",
            client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
          },
        ],
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data3,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("Unitaccessrequestcreatedsuccessfully"),
        });
        history.push(Routes.aggreementUnitAccess);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };

  //CHECK MOBILE
  const CheckMobilAccountAccess = () => {
    const data = {
      query: CHECK_MOBILENO,
      variables: {
        mobile_no: data2?.number?.mobile,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.user_profiles.length === 0) {
          add();
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Mobilenumberalreadyexists"),
          });
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };
  const CheckMobileUnitAccess = () => {
    const data3 = {
      query: CHECK_MOBILENO,
      variables: {
        mobile_no: data?.number?.mobile,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      data3,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.user_profiles.length === 0) {
          add2();
        } else {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("Mobilenumberalreadyexists"),
          });
        }
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong")+".",
        });
      });
  };

  const createPass = () => {
    if (title === "account") {
      if (isIamValideTostep2()) {
        CheckMobilAccountAccess();
      }
    }
    if (title === "unit") {
      if (isIamValideTostep1()) {
        CheckMobileUnitAccess();
      }
    }
  };
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const userId = tempDecoded.id;
      const payload = {
        tenantId: `${config.tenantid}`,
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/user-accounts`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          const fetch = response?.data?.data.map((val, index) => {
            let _d;
            try {
              _d = {
                value: val.account_no,
                label: val.account_no,
                key: "account_no",
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong")+".",
              });
            }
            return _d;
          });
          setState(fetch);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong")+".",
          });
        });
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Container className={classes.root} maxWidth="sm">
      {title === "account" && (
        <TitleBar
          text={t("CreateAccountLevelAccess")}

          goBack={() => history.push(Routes.aggreementUnitAccess)}
        />
      )}
      {title === "unit" && (
        <TitleBar
          text={t("CreateUnitLevelAccess")}

          goBack={() => history.push(Routes.aggreementUnitAccess)}
        />
      )}
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ height: size?.height - 135, overflow: "scroll" }}
        >
          <div style={{ padding: "12px" }}>
            {title === "account" && (
              <>
                <div className={classes.input}>
                  <SelectBox
                    label="Account ID"
                    placeholder={t("SelectAccount")}
                    value={data2.account_no}
                    options={state}
                    fontSize={"14px"}
                    onChange={(value) => {
                      updateState2("account_no", value);
                      // getGates(value?.propertyId);
                    }}
                    isError={data2?.error?.account_no?.length > 0}
                    errorMessage={data2?.error?.account_no}
                  />
                </div>
                <div className={classes.input}>
                  <TextBox
                    color
                    label={t("UserName")}
                    placeholder={t("enterusername")}
                    value={data2.userName}
                    onChange={(e) => updateState2("userName", e.target.value)}
                  />
                </div>
                <div className={classes.input}>
                  <MobileNumberInputComponent
                    color
                    label={t("MobileNumber")}
                    placeholder={t("EnterMobileNumber")}
                    value={data2.number}
                    handleChange={(value) => updateState2("number", value)}
                  />
                </div>
                <div className={classes.input}>
                  <TextBox
                    color
                    label={t("Emailid")}
                    placeholder={t("Enteremailid")}
                    value={data2.email}
                    onChange={(e) => updateState2("email", e.target.value)}
                  />
                </div>
              </>
            )}
            {title === "unit" && (
              <>
                <div className={classes.input}>
                  <SelectBox
                    label={t("ChooseUnit")}
                    placeholder={t("SelectUnit")}
                    value={data.unit}
                    options={unitFilter}
                    onChange={(value) => {
                      updateState("unit", value);
                      // getGates(value?.propertyId);
                    }}
                    isError={data?.error?.unit?.length > 0}
                    errorMessage={data?.error?.unit}
                  />
                </div>
                <div className={classes.input}>
                  <TextBox
                    color
                    label={t("UserName")}
                    placeholder={t("enterusername")}
                    value={data.userName}
                    onChange={(e) => updateState("userName", e.target.value)}
                  />
                </div>
                <div className={classes.input}>
                  <MobileNumberInputComponent
                    color
                    label={t("MobileNumber")}
                    placeholder={t("EnterMobileNumber")}
                    value={data.number}
                    handleChange={(value) => updateState("number", value)}
                  />
                </div>
                <div className={classes.input}>
                  <TextBox
                    color
                    label={t("Emailid")}
                    placeholder={t("Enteremailid")}
                    value={data.email}
                    onChange={(e) => updateState("email", e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            <Button
              fullWidth
              className={classes.applybtn}
              variant="contained"
              onClick={createPass}
            >
              Create
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withNamespaces("aggreementUnitAccess")(CreateAggreementUnit)