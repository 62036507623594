/* eslint-disable array-callback-return */
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { TitleBar } from "../../components";
import { Routes } from "../../router/routes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { PassContext } from "./passContext";
import Step1 from "./step1";
import Step2 from "./step2";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },

  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  name: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "8px",
  },
  stepindicate: {
    color: theme.typography.color.primary,
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
  },

  stepindicateavatar: {
    backgroundColor: "white",
    padding: "10px",
    color: "#78B1FE",
    fontFamily: FontFamilySwitch().bold,
  },
  stepindicateavatarborder: {
    border: "1px solid #E4E8EE",
    borderRadius: "50%",
    padding: "4px",
  },
  steps: {
    borderBottom: "4px solid #F5F7FA",
    backgroundColor: "white",
  },
  steproot: {
    backgroundColor: "#78B1FE",
  },
  input: {
    marginTop: "10px",
  },

  btnroot: {
    backgroundColor: "#F5F7FA",
    padding: "14px",
  },
  previousbtn: {
    backgroundColor: "white",
    color: "#091B29",
    border: "1px solid #CED3DD",
    borderRadius: "4px",
    padding: "12px 8px",
    boxShadow: "none",
    textTransform:"capitalize"

  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
    textTransform:"capitalize"
  },
}));

const AddPass = ({ t }) => {
  const size = useWindowDimensions();
  const history = useHistory();
  const classes = useStyles();
  const { step, next, previous, disable } = React.useContext(PassContext);
  let boxParent = document.querySelector('#createDiv')?.offsetHeight;
  let fullSize = size?.height - 220
  return (
    <Container className={classes.root} maxWidth="sm" sx={{ padding: "0px" }}>
      <TitleBar
        text={t("CreateWorkerORVisitorGatePass")}

        goBack={() => history.push(Routes.workerGatePass)}
      />
      <Box
        display="flex"
        alignItems="center"
        p={1}
        className={classes.steproot}
      >
        <Box className={classes.stepindicateavatarborder}>
          <Avatar className={classes.stepindicateavatar}>{step}</Avatar>
        </Box>
        <Box flexGrow={1} marginLeft="10px">
          <Typography className={classes.stepindicate}>
            Step {step}/2
          </Typography>

          <Typography className={classes.name} style={{ color: "white" }}>
            {step === 1 && "Visit Details"}
            {step === 2 && "Visitor Details"}
          </Typography>
        </Box>
      </Box>
      <LinearProgress variant="determinate" value={step === 1 ? 40 : 100} />

      <Grid container style={{ height: size?.height - 205, overflow: "auto" }}>
        <Grid item xs={12}>
          <div id="createDiv">
          {step === 1 && <Step1    menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}/>}
          {step === 2 && <Step2  menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}/>}
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.btnroot}
        justifyContent="space-around"
        spacing={1}
      >
        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={step === 1 && true}
            className={classes.previousbtn}
            variant="contained"
            onClick={previous}
          >
            {t("Previous")}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            disabled={disable}
            className={classes.submitbtn}
            variant="contained"
            onClick={() => next()}
          >
            {t("Next")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withNamespaces("createPass")(AddPass)