import * as React from "react"

const GeneralRequest = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21.311}
    height={19.201}
    {...props}
  >
    <path
      d="M12.431 0a1.682 1.682 0 0 0-1.68 1.68v7.2a.72.72 0 0 0 1.229.509L14.169 7.2h5.462a1.682 1.682 0 0 0 1.68-1.68V1.68A1.682 1.682 0 0 0 19.631 0ZM5.309 5.4a3.33 3.33 0 1 0 3.33 3.33 3.334 3.334 0 0 0-3.33-3.33ZM1.67 12.96a1.623 1.623 0 0 0-1.671 1.565V16c0 1.822 2.283 3.2 5.31 3.2s5.31-1.375 5.31-3.2v-1.476a1.622 1.622 0 0 0-1.67-1.565Z"
      fill={props?.color}
    />
  </svg>
)

export default GeneralRequest
