import {
    Box, IconButton, Container, Typography,
    Stack, Divider, Avatar, Button, Hidden, Dialog, Drawer, Grid
} from '@mui/material'
import React from 'react'
import { MyBookingDetailsStyle } from './style'
import { useHistory } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { BookingCard, CancelBooking, DetailCard } from './components';
import QRCode from 'react-qr-code';
import CircleIcon from "@mui/icons-material/Circle";
import CitySvg from '../../assets/city';
import ChairSvg from '../../assets/chair';
import ShareIcon from '@mui/icons-material/Share';
import AmenityPass from '../../assets/amenityPass';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { addSeconds } from 'date-fns';
import { NetworkCall } from '../../networkcall';
import { AlertProps, NetWorkCallMethods } from '../../utils';
import { config } from '../../config';
import { AlertContext, AuthContext } from '../../contexts';
import { withNamespaces } from 'react-i18next';


const MyBookingDetails = ({ t }) => {
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const classes = MyBookingDetailsStyle({ language: auth?.auth?.auth?.language })
    const state = useLocation()?.state
    console.log("state?.bookingDetail", state?.bookingDetail)
    const alert = React.useContext(AlertContext)
    const back = () => {
        history.goBack()
    }
    const [open, setOpen] = React.useState(false)
    const show = () => {
        open === false ? setOpen(true) : setOpen(false)
    }

    const [pdOpen, setCBOpen] = React.useState(false)
    const [remark, setRemark] = React.useState("")
    // Set remark
    const setRemarkValue = (value) => {
        setRemark(value)
    }
    //Close popup
    const closeDrawer = () => {
        setCBOpen(false)
    }

    //Cancel Booking
    const submit = () => {
        cancelBooking()
    }

    const cancelBooking = () => {
        const data = {
            booking_id: state?.bookingDetail?.booking_id,
            remarks: remark
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/cancel_booking`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === "success") {
                    setCBOpen(false)
                    history.goBack()
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: ("SomethingWentwrong"),
                });
            });
    };

    return (
        <Container maxWidth="sm" sx={{ background: "#F5F7FA", padding: "0 !important" }}>
            {/* <div style={{background: "#F5F7FA", padding: "0 !important",}}> */}
            <Box className={state?.bookingDetail?.booking_status === "Booked" ? classes.body : classes.canceledBody}>
                <Box className={classes.navigate}>
                    <IconButton className={classes.backbtn} onClick={back}>
                        <KeyboardArrowLeftIcon />
                    </IconButton>
                </Box>
                <Box className={classes.topCard}></Box>
                <Box className={classes.detailBox} p={0}>
                    <Box>
                        <DetailCard data={state?.bookingDetail} />
                    </Box>
                </Box>

                <Stack className={classes.content_box} spacing={1.5} alignItems="center" m={1.7}>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                        <Box className={classes.diamond}></Box>
                        <Typography className={classes.your_booking}>{t("YOURBOOKINGPASS")}</Typography>
                        <Box className={classes.diamond}></Box>
                    </Stack>

                    <Stack className={classes.passCard} onClick={show}>
                        <Stack alignItems={"center"} spacing={2}>
                            {
                                open ? <Stack direction="row" justifyContent={"space-between"} className={classes.pass_card} alignItems="center">
                                    <Stack p={2}>
                                        <Typography className={classes.pass_title}>{t("AmenitiesPass")}</Typography>
                                        <Typography className={classes.pass_content}>{t("Passforallamenitiesbooking")}</Typography>
                                    </Stack>
                                    <AmenityPass />
                                </Stack> :
                                    <Stack className={classes.chair_box} direction={"row"} spacing={1}>
                                        <ChairSvg />
                                        <Typography className={classes.chair_content}>{t("AmenitiesBookingPass")}</Typography>
                                    </Stack>
                            }
                            <img className={classes.company_logo} src={state?.bookingDetail?.company_logo} alt="" />
                            <QRCode
                                value={state?.bookingDetail?.booking_id}
                                size={100}
                            />
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "14px" }} >
                                <Box display="flex" alignItems="center" bgcolor={"#E8F9E8"} style={{ borderRadius: "4px" }}>
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                                        />
                                    </Box>
                                    <Box flexGrow={1} padding={"8px 36px"}>
                                        <Stack alignItems={"center"}>
                                            {
                                                state?.bookingDetail?.booked_slots === null ?
                                                    <Typography className={classes.qrTime}>
                                                        {moment(state?.bookingDetail?.booked_date).format("YYYY-MM-DD")}
                                                    </Typography>
                                                    :
                                                    <Typography className={classes.qrTime}>
                                                        {
                                                            moment(state?.bookingDetail?.booked_slots?.[0]?.[0], "HH:mm").format("hh:mm A")}
                                                        -
                                                        {
                                                            moment(addSeconds(new Date(moment(new Date()).format("YYYY-MM-DD") + " " +
                                                                moment(state?.bookingDetail?.booked_slots[state?.bookingDetail?.booked_slots?.length - 1]?.[1], "HH:mm:ss").format("hh:mm A")), 60)).format("hh:mm A")
                                                        } - {moment(state?.bookingDetail?.booking_start_time).format("DD MMM YY")}
                                                    </Typography>
                                            }

                                            <Typography className={classes.qrDetail}>{t("SlotDetails")}</Typography>
                                        </Stack>
                                    </Box>
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginRight: "-7px", marginTop: "8px", fontSize: "16px", color: "white" }}
                                        />
                                    </Box>
                                </Box>
                            </div>
                            <Stack className={classes.city_box} direction={"row"} spacing={1}>
                                <CitySvg />
                                <Typography className={classes.city_content}>{state?.bookingDetail?.property_name}</Typography>
                            </Stack>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }} >
                                <Box display="flex" alignItems="center" style={{ borderRadius: "4px" }}>
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "16px", color: "#D2D5D938" }}
                                        />
                                    </Box>
                                    <Divider className={classes.divider} />
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginRight: "-7px", marginTop: "8px", fontSize: "16px", color: "#D2D5D938" }}
                                        />
                                    </Box>
                                </Box>
                            </div>
                            <Stack width="100%" p={1} sx={{ marginTop: "0 !important" }}>
                                <Stack direction="row" justifyContent={"space-between"}>
                                    <Stack direction={"row"} spacing={1}>
                                        <Avatar className={classes.avatar} src={state?.bookingDetail?.amenity_assets}></Avatar>
                                        <Stack>
                                            <Stack>
                                                <Typography className={classes.amenity_name} noWrap>{state?.bookingDetail?.amenities_name}</Typography>
                                                <Typography className={classes.amenity_content}>{t("Bookedon")} {moment(state?.bookingDetail?.booking_start_time).format("DD MMM YY")}</Typography>
                                            </Stack>
                                            <Typography className={state?.bookingDetail?.booking_status === "Booked" ? classes.booked : classes.not_booked}>{state?.bookingDetail?.booking_status}</Typography>
                                        </Stack>
                                    </Stack>
                                    <IconButton>
                                        <ShareIcon />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", }} >
                                <Box display="flex" alignItems="center" style={{ borderRadius: "4px" }}>
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginLeft: "-7px", marginTop: "8px", fontSize: "16px", color: "#D2D5D938" }}
                                        />
                                    </Box>
                                    <Divider className={classes.divider} />
                                    <Box style={{ overflow: "hidden" }}>
                                        <CircleIcon
                                            style={{ marginRight: "-7px", marginTop: "8px", fontSize: "16px", color: "#D2D5D938" }}
                                        />
                                    </Box>
                                </Box>
                            </div>


                        </Stack>
                        <Stack spacing={2} sx={{ marginTop: "0 !important" }}>
                            <Stack>
                                <BookingCard header='booked unit details'
                                    url={state?.bookingDetail?.unit_logo}
                                    name={state?.bookingDetail?.unit_name}
                                    content={state?.bookingDetail?.unit_no} />
                            </Stack>
                            <Stack>
                                <BookingCard header='BOOKED BY'
                                    url={state?.bookingDetail?.user_profile_picture}
                                    name={state?.bookingDetail?.user_first_name}
                                    content={state?.bookingDetail?.mobile_country_code
                                        + " " + state?.bookingDetail?.user_mobile_no} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            {
                state?.bookingDetail?.booking_status === "Booked" &&
                <Box p={2} className={classes.cancel_btn}>
                    <Button variant="outlined" className={classes.cancelButton} fullWidth onClick={() => setCBOpen(true)}>
                        {t("CancelBooking")}
                    </Button>
                </Box>
            }

            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={pdOpen}
                    onClose={closeDrawer}
                >
                    <div>{CancelBookingPopup(closeDrawer, submit, remark, setRemarkValue, t)}</div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    PaperProps={{ borderRadius: '4px' }}
                    anchor="bottom"
                    className={classes.drawer}
                    open={pdOpen}
                    onClose={closeDrawer}
                >
                    <div>{CancelBookingPopup(closeDrawer, submit, remark, setRemarkValue, t)}</div>
                </Drawer>
            </Hidden>
        </Container>

    )
}


const CancelBookingPopup = (closeDrawer, submit, remark, setRemarkValue, t) => {
    const classes = MyBookingDetailsStyle()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            {t("CancelBooking")}
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} height="auto">
                <CancelBooking submit={submit} remark={remark} setRemarkValue={setRemarkValue} />
            </Grid>
        </Grid>
    )
}


export default withNamespaces("amenitiesBooking")(MyBookingDetails)