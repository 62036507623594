import * as React from "react"

const YourTownshipIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={22}
        height={22}
        {...props}
    >
        <defs>
            <linearGradient
                id="c"
                y1={0.5}
                x2={1}
                y2={0.5}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#0d61a9" />
                <stop offset={0.551} stopColor="#0d60a7" />
                <stop offset={0.75} stopColor="#0f5ca0" />
                <stop offset={0.892} stopColor="#125695" />
                <stop offset={1} stopColor="#154e85" />
            </linearGradient>
            <linearGradient
                id="a"
                x1={0.5}
                y1={-891.995}
                x2={0.5}
                y2={-891.05}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#42a3f2" />
                <stop offset={1} stopColor="#42a4eb" />
            </linearGradient>
            <linearGradient id="d" y1={-443.495} y2={-442.55} xlinkHref="#a" />
            <linearGradient id="e" x1={0} y1={0.5} x2={1} y2={0.5} xlinkHref="#a" />
            <linearGradient
                id="b"
                x1={0.5}
                y1={-905.995}
                x2={0.5}
                y2={-905.05}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#1d59b3" />
                <stop offset={1} stopColor="#195bbc" />
            </linearGradient>
            <linearGradient id="f" y1={-909.995} y2={-909.05} xlinkHref="#b" />
            <linearGradient id="g" y1={-913.995} y2={-913.05} xlinkHref="#b" />
            <linearGradient id="h" y1={-901.995} y2={-901.05} xlinkHref="#b" />
        </defs>
        <g data-name="Group 103548">
            <path
                data-name="Rectangle 55260"
                transform="translate(11.427 2.662)"
                fill="url(#c)"
                d="M0 0h9.669v19.338H0z"
            />
            <path
                data-name="Rectangle 55261"
                transform="translate(18.459 7.057)"
                fill="url(#a)"
                d="M0 0h1.758v.879H0z"
            />
            <path
                data-name="Rectangle 55262"
                transform="translate(16.381 7.15)"
                fill="url(#a)"
                d="M0 0h1.1v1.1H0z"
            />
            <path
                data-name="Rectangle 55263"
                transform="translate(14.122 7.15)"
                fill="url(#a)"
                d="M0 0h1.1v1.1H0z"
            />
            <path
                data-name="Rectangle 55264"
                transform="translate(18.459 3.541)"
                fill="url(#d)"
                d="M0 0h1.758v2.637H0z"
            />
            <path
                data-name="Rectangle 55265"
                transform="translate(16.381 3.85)"
                fill="url(#d)"
                d="M0 0h1.1v2.2H0z"
            />
            <path
                data-name="Rectangle 55266"
                transform="translate(14.122 3.85)"
                fill="url(#d)"
                d="M0 0h1.1v2.2H0z"
            />
            <path
                data-name="Rectangle 55267"
                transform="translate(11.863 7.15)"
                fill="url(#a)"
                d="M0 0h1.1v1.1H0z"
            />
            <path
                data-name="Rectangle 55268"
                transform="translate(11.863 3.85)"
                fill="url(#d)"
                d="M0 0h1.1v2.2H0z"
            />
            <path
                data-name="Path 11870"
                d="M12.65 7.7 6.6 4.4.55 7.7V22h12.1Z"
                fill="#d35230"
            />
            <path
                data-name="Path 11871"
                d="m35.443 22.45-3.657-1.38a1.094 1.094 0 0 0-.772 0l-3.657 1.38a.553.553 0 0 0-.357.518V33.65h8.8V22.968a.553.553 0 0 0-.357-.518Z"
                transform="translate(-13.8 -11.65)"
                fill="url(#e)"
            />
            <path
                data-name="Path 11872"
                d="M12.649 8.25a.548.548 0 0 1-.263-.067L6.6 5.026.813 8.183a.55.55 0 1 1-.527-.966l6.05-3.3a.552.552 0 0 1 .527 0l6.05 3.3a.55.55 0 0 1-.264 1.033Z"
                fill="#b83c1f"
            />
            <path
                data-name="Path 11873"
                d="M23 6.75h9.9l-1.87-2.337A1.1 1.1 0 0 0 30.171 4h-4.442a1.1 1.1 0 0 0-.859.413Z"
                transform="translate(-12 -4)"
                fill="url(#e)"
            />
            <g data-name="Group 103546">
                <path
                    data-name="Rectangle 55269"
                    transform="translate(19.338 14.968)"
                    fill="url(#b)"
                    d="M0 0h.879v.879H0z"
                />
                <path
                    data-name="Rectangle 55270"
                    transform="translate(16.701 14.968)"
                    fill="url(#b)"
                    d="M0 0h1.758v.879H0z"
                />
                <path
                    data-name="Rectangle 55271"
                    transform="translate(14.943 14.968)"
                    fill="url(#b)"
                    d="M0 0h.879v.879H0z"
                />
                <path
                    data-name="Rectangle 55272"
                    transform="translate(19.338 16.726)"
                    fill="url(#f)"
                    d="M0 0h.879v1.758H0z"
                />
                <path
                    data-name="Rectangle 55273"
                    transform="translate(16.701 16.726)"
                    fill="url(#f)"
                    d="M0 0h1.758v1.758H0z"
                />
                <path
                    data-name="Rectangle 55274"
                    transform="translate(14.943 16.726)"
                    fill="url(#f)"
                    d="M0 0h.879v1.758H0z"
                />
                <path
                    data-name="Rectangle 55275"
                    transform="translate(19.338 19.363)"
                    fill="url(#g)"
                    d="M0 0h.879v.879H0z"
                />
                <path
                    data-name="Rectangle 55276"
                    transform="translate(16.701 19.363)"
                    fill="url(#g)"
                    d="M0 0h1.758v.879H0z"
                />
                <path
                    data-name="Rectangle 55277"
                    transform="translate(14.943 19.363)"
                    fill="url(#g)"
                    d="M0 0h.879v.879H0z"
                />
                <path
                    data-name="Rectangle 55278"
                    transform="translate(19.338 12.331)"
                    fill="url(#h)"
                    d="M0 0h.879v1.758H0z"
                />
                <path
                    data-name="Rectangle 55279"
                    transform="translate(16.701 12.331)"
                    fill="url(#h)"
                    d="M0 0h1.758v1.758H0z"
                />
                <path
                    data-name="Rectangle 55280"
                    transform="translate(14.943 12.331)"
                    fill="url(#h)"
                    d="M0 0h.879v1.758H0z"
                />
            </g>
            <g data-name="Group 103547" fill="#ff8f6b">
                <path data-name="Rectangle 55281" d="M2.638 9.694h1.758v2.637H2.638z" />
                <path data-name="Rectangle 55282" d="M5.275 9.694h2.637v2.637H5.275z" />
                <path data-name="Rectangle 55283" d="M8.791 9.694h2.637v2.637H8.791z" />
                <path
                    data-name="Rectangle 55284"
                    d="M2.638 14.089h1.758v1.758H2.638z"
                />
                <path
                    data-name="Rectangle 55285"
                    d="M5.275 14.089h2.637v1.758H5.275z"
                />
                <path
                    data-name="Rectangle 55286"
                    d="M8.791 14.089h2.637v1.758H8.791z"
                />
                <path
                    data-name="Rectangle 55287"
                    d="M2.638 17.605h1.758v1.758H2.638z"
                />
                <path
                    data-name="Rectangle 55288"
                    d="M5.275 17.605h2.637v1.758H5.275z"
                />
                <path
                    data-name="Rectangle 55289"
                    d="M8.791 17.605h2.637v1.758H8.791z"
                />
            </g>
        </g>
    </svg>
)

export default YourTownshipIcon
