import { Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
// import { WelcomeBanner } from "./welcomeScreenBanner";
import { withNamespaces } from "react-i18next";
import { useHistory } from "react-router-dom";
import ActiveUnitIcon from "../../assets/activeuniticon";
import AgeementImg from "../../assets/agreement";
import GeneralRequest from "../../assets/generalrequest";
// import ListingIcon from "../../assets/listingIcon";
import MainTanceIcon from "../../assets/maintanceicon";
// import MarketPlaceIcon from "../../assets/marketplaceIcon";
import UnpaidInvoices from "../../assets/unpaidinvoices";
import VisitorRequest from "../../assets/visitorrequest";
import {
  CardList,
  CarouselSliders,
  HomepageCard,
  HomepagePayCard,
  QuickLink,
  // SurveyCard,
  TopNavBars
} from "../../components";
import { DashboardSimmer } from "../../components/simmer/dashboardSimmer";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";
import { LocalStorageKeys, NetWorkCallMethods, requsetStatus } from "../../utils/constants";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { Announcement } from "./announcement";
import DashboardPay from "./dashboardPay";
import HomepageGraph from "./graphDashboard";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.dashboard.primary,
    overflow: "hidden",
  },
  username: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  msg: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().regular,
    color: theme.typography.color.primary,
  },
  invoice: {
    backgroundColor: "#F6F9FF",
    padding: "14px",
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
    marginTop: "5px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderBottom: "1px solid #E4E8EE"
  },
  invoice2: {
    backgroundColor: "white",
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderBottomLeftRadius: theme.palette.borderRadius,
    borderBottomRightRadius: theme.palette.borderRadius,

  },
  invoice3: {
    backgroundColor: "white",
    padding: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderBottomLeftRadius: theme.palette.borderRadius,
    borderBottomRightRadius: theme.palette.borderRadius,

  },
  graph: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: theme.palette.borderRadius,
    marginTop: "5px",
    boxShadow: "0px 3px 30px #5C86CB2E",
  },
  profileImg: {
    height: "55px",
    width: "55px",
    borderRadius: "50%",
    border: "2px solid #091B29",
  },
  relative: {
    position: "relative",
    cursor: "pointer",
  },
  absolute: {
    position: "absolute",
    bottom: "4px",
    backgroundColor: "#091B29",
    borderRadius: "4px",
    color: "white",
    left: "0px",
    right: "0px",
    fontSize: "12px",
    textAlign: "center",
    fontFamily: FontFamilySwitch().bold,
    display: "flex",
    justifyContent: "center",
    padding: "0px 5px",
  },
  closeIcon: {
    position: 'absolute',
    top: '24px',
    right: '24px',
    cursor: 'pointer'
  },
  backgroundImage: {
    objectFit: 'cover',
    // height: '100%',
    width: '100%',
  },
  rootmain: {
    '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
      margin: '10px',
    },
    width: "100%",
    maxWidth: "auto",
    padding: "0 !important",
    "& .MuiPaper-root": {
      width: "991px !important",
      borderRadius: theme.palette.borderRadius,
    },
    "& .MuiDialogContent-root": {
      padding: "0px !important",
      position: 'relative',
      display: 'flex'
    },
    '& .MuiDialog-container': {
      padding: (props) => props?.padding ?? '',
    }
  },
  welcome: {
    color: '#091B29',
    fontSize: '24px',
    fontFamily: FontFamilySwitch().extraBold,
    position: 'absolute',
    top: '62px',
    left: '23px'
  },
  name: {
    color: '#5078E1 !important',
  },
  referCard: {
    background: "white",
    borderRadius: theme.palette.borderRadius,
    padding: "14px 16px"
  },
  btn: {
    padding: "8px 12px",
    textTransform: "none",
    marginTop: "8px"
  },
  name2: {
    fontSize: "20px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary
  },
  subName: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme.typography.color.primary
  },
  box: {
    padding: "16px 16px 4px 16px"
  },
  invoice_action: {
    backgroundColor: "#F6F9FF",
    padding: "14px",
    borderTopLeftRadius: theme.palette.borderRadius,
    borderTopRightRadius: theme.palette.borderRadius,
    marginTop: "14px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderBottom: "1px solid #E4E8EE"
  },
}));

const DashboardMain = ({ props, t, i18n }) => {
  const classes = useStyles();
  // const { state } = useLocation()
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const size = useWindowDimensions();
  const history = useHistory();
  const hour = new Date().getHours();
  const [decoded, setDecoded] = React.useState(null);
  const [main, setMain] = React.useState({
    agreements: {},
    invoices: {},
    units: {},
    general: {},
    maintenance: {},
    invoicetotal: {},
    profileImg: "",
    security_request: {},
    unread: false,
  });
  const [banners, setBanners] = React.useState([]);
  const [payOpen, setpayOpen] = React.useState(false);
  const [load, setLoad] = React.useState(true);
  const logo = JSON.parse(localStorage?.getItem("accessType"))?.filter(
    (val) => val?.name === "Tenant"
  );
  // open dialog
  // const [open, setOpen] = React.useState(state ?? false)
  // // close dialog
  // const closeDrawer = () => {
  //   setOpen(!open)
  // }
  const getBanners = () => {
    let data = {
      tenantId: `${config.tenantid}`,
      company_id: logo?.map((val) => val?.company_id),
      offset: 0,
      type: "Dashboard",
      limit: 1000,
    };
    NetworkCall(
      `${config.api_url}/company-banner/list`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
      let bannerss = response?.data?.data?.map((item) => item?.assets)?.flat();
      setBanners(bannerss);
    });
  };

  React.useEffect(() => {
    if (authToken) {
      setDecoded(jwt_decode(authToken));
      getBanners();
      NetworkCall(
        `${config.api_url}/dashboard`,
        NetWorkCallMethods.post,
        {},
        null,
        true,
        false
      ).then((response) => {
        let data = response?.data?.data;

        localStorage.setItem(
          "userProfileId",
          response?.data?.data?.userProfileId
        );
        localStorage.setItem(
          "contactID",
          response?.data?.data?.contactId
        );
        setMain({
          agreements: data?.agreements,
          invoices: data?.invoices,
          units: data?.units,
          general: data?.general,
          maintenance: data?.maintenance,
          profileImg: data?.profileImg,
          security_request: data?.security_request,
          unread: data?.is_unread,
          notification: data?.notifications,
          responseData: response?.data.data
        });
        setLoad(false);
      });
    } else {
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  const temp_decoded = jwt_decode(authToken);
    const data = {
      tenantId: `${config.tenantid}`,
      userId: temp_decoded.id,
    };
    NetworkCall(
      `${config.api_url}/agreement-unit-info/switcher `,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    ).then((response) => {
    //   let localAgreement = JSON.parse(localStorage.getItem(LocalStorageKeys.agreement))
    //   let tempAgreement = localAgreement?.id ? localAgreement : response?.data?.data[0]
    //   setAgreement(tempAgreement);
    //   setAgreementList(response?.data?.data);
    //   getAgreementData(tempAgreement?.id, tempAgreement?.agreement_unit?.map((val) => val?.id));
      localStorage.setItem(LocalStorageKeys.agreement, JSON.stringify(response?.data?.data?.[0]))
    });
    
  const TopCarddata = [
    {
      id: 1,
      name: t("active_agreements"),
      count: main?.agreements?.totalCount,
      image: <AgeementImg color={"#50cbc7"} />,
      color: "#46B9DA",
      link: "/agreement",
      main: null,
      borderColor: "#50cbc7"
    },
    {
      id: 2,
      name: t("unpaid_invoices"),
      count: main?.invoices?.totalCount,
      image: <UnpaidInvoices color={"#36aae2"} />,
      color: "#E29336",
      link: "/invoiceScreen",
      main: {
        agreemntlist: main?.agreements?.result,
        outstanding: true,
      },
      borderColor: "#36aae2"

    },
    {
      id: 3,
      name: t("active_units"),
      count: main?.units?.totalCount,
      image: <ActiveUnitIcon color={"#2881e7"} />,
      color: "#6248E2",
      link: "/units",
      main: main?.agreements?.result,
      borderColor: "#2881e7"

    },

    {
      id: 4,
      name: t("general_requests"),
      count: main?.general?.totalRequests,
      image: <GeneralRequest color={"#119da4"} />,
      color: "#F34276",
      link: "/requestscreen",
      main: [requsetStatus.general],
      borderColor: "#119da4"

    },
    {
      id: 5,
      name: t("maintenance_requests"),
      count: main?.maintenance?.totalRequests,
      image: <MainTanceIcon color={"#f17360"} />,
      color: "#F34276",
      link: "/requestscreen",
      main: [requsetStatus.maintenance],
      borderColor: "#f17360"

    },
    {
      id: 6,
      name: t("visitor_requests"),
      count: main?.security_request?.totalCount,
      image: <VisitorRequest color={"#c78920"} />,
      color: "#F34276",
      link: "/worker_gate_pass",
      main: null,
      borderColor: "#c78920"

    },
  ];
  const QuickLinks = [
    {
      id: 1,
      name: t("visitor"),
      count: t("requests"),
      image: "/images/visiterRequset.svg",
      color: "#46B9DA",
      link: "/worker_gate_pass",
      borderColor: "#FFD869"

    },
    {
      id: 2,
      name: t("my"),
      count: t("bookings"),
      image: "/images/myBookings.svg",
      color: "#E29336",
      link: "/mybooking",
      borderColor: "#EA2F3E"

    },
    {
      id: 3,
      name: t("community"),
      count: t("discussion"),
      image: "/images/communityLink.svg",
      color: "#6248E2",
      link: "/community_discussion",
      borderColor: "#20AFB5"

    },

    {
      id: 4,
      name: t("my_contacts"),
      image: "/images/usefulLink.svg",
      color: "#F34276",
      link: "/contactdetails",
      borderColor: "#199BE2"

    },
    {
      id: 6,
      name: t("my"),
      count: t("neighbors"),
      image: "/images/neigbourLink.svg",
      color: "#F34276",
      link: "/my_neighbours",
      borderColor: "#707B85"

    },
    // {
    //   id: 5,
    //   name: t("my_bills"),
    //   image: "/images/rulesLink.svg",
    //   color: "#F34276",
    //   link: "/dashboard",
    //   noavailable: true,
    //   borderColor: "#16528C"

    // },
  ];
  //Quick dashboard stats link, the route is not added to the repository

  // const MyFavouritesLink = [
  //   {
  //     id: 1,
  //     name: t("Vehicles"),
  //     image: "/images/visiterRequset.svg",
  //     color: "#46B9DA",
  //     link: "/worker_gate_pass",
  //     borderColor: "#FFD869"

  //   },
  //   {
  //     id: 2,
  //     name: t("bookings"),
  //     image: "/images/myBookings.svg",
  //     color: "#E29336",
  //     link: "/mybooking",
  //     borderColor: "#EA2F3E"

  //   },
  //   {
  //     id: 3,
  //     name: t("Spendings"),
  //     image: "/images/communityLink.svg",
  //     color: "#6248E2",
  //     link: "/community_discussion",
  //     borderColor: "#20AFB5"

  //   },

  //   {
  //     id: 4,
  //     name: t("Contacts"),
  //     image: "/images/usefulLink.svg",
  //     color: "#F34276",
  //     link: "/contactdetails",
  //     borderColor: "#199BE2"

  //   },
  //   {
  //     id: 6,
  //     name: t("Neighbors"),
  //     image: "/images/neigbourLink.svg",
  //     color: "#F34276",
  //     link: "/my_neighbours",
  //     borderColor: "#707B85"

  //   },
  //   {
  //     id: 5,
  //     name: t("PayBills"),
  //     image: "/images/rulesLink.svg",
  //     color: "#F34276",
  //     link: "/dashboard",
  //     noavailable: true,
  //     borderColor: "#16528C"

  //   }
  // ];
  // const surveyData = [
  //   {
  //     name: t("marketplace"),
  //     type: "Marketplace",
  //     icon: <MarketPlaceIcon />
  //   },
  //   {
  //     name: t("listing"),
  //     type: "Listing",
  //     icon: <ListingIcon />
  //   }
  // ]
  return (
    <>
      {load ? (
        <DashboardSimmer />
      ) : (
        <div className={classes.root}>
          <TopNavBars
            t={t}
            i18n={i18n}
            note={true}
            count="4"
            handleClicknote={() => history.push(Routes.notification)}
            new={main?.unread}
            document={() => history.push(Routes?.fileManager)}
          />
          <div
            style={{
              height: size?.height - 135,
              overflow: "auto",
              marginRight: "-6px",
            }}
          >
            <Box p={2}>
              {/* Profile*/}
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography className={classes.username}>
                    {t("dashHi") + ", " +
                      (decoded && decoded.username ? decoded.username : "")}
                  </Typography>
                  <Typography className={classes.msg}>
                    {t("dashGood") + " " +
                      (hour < 12
                        ? t("dashMorning")
                        : hour >= 12 && hour < 18
                          ? t("dashAfternoon")
                          : t("dashEvening")) +
                      ", " + t("dashWelcomeBack") + "!"}
                  </Typography>
                </Box>
                <Box
                  className={classes.relative}
                  onClick={() => history.push(Routes.profileView)}
                >
                  <img
                    src={main?.profileImg}
                    className={classes.profileImg}
                    alt=""
                  />
                  {/* <Typography className={classes.absolute}>
                    &nbsp;&nbsp;{t("dashResident")}&nbsp;&nbsp;
                  </Typography> */}
                </Box>
              </Box>
              <Box height="12px" />
              {/* Dashboard Card */}
              <Grid container spacing={1.3}>
                {TopCarddata?.map((item) => {
                  return (
                    <Grid item xs={4}>
                      <HomepageCard data={item} />
                    </Grid>
                  );
                })}
              </Grid>
              <Box height="12px" />
              {/* Carousel Slider Ad */}
              <CarouselSliders autoScroll={true} data={banners} />
              <div className={classes.invoice}>
                <Typography className={classes.username}>{t("dashInvoice")}</Typography>
              </div>
              <div className={classes.invoice3}>
                <HomepagePayCard
                  Outstanding_amount={main?.invoices?.totalOutStandingAmount}
                  amount={main?.invoices?.totalOverDueAmount}
                  currency_symbol={main?.invoices?.result?.[0]?.currency_symbol}
                  onClick={() => setpayOpen(true)}
                />
                {/* <Box height="14px" /> */}
              </div>
              <div className={classes.invoice_action}>
                <Typography className={classes.username}>{t("dashAction")}</Typography>
              </div>
              <div className={classes.invoice2}>
                <CardList
                  main={{
                    invoices: main?.invoices?.result,
                    units: main?.units?.result,
                    general: main?.general?.result,
                    maintenance: main?.maintenance?.result,
                    invoicetotal: main?.invoices,
                  }}
                  primary={true}
                />
              </div>
              <Box height="10px" />
              {/* <Typography className={classes.username}>
                &nbsp;Expense Graph
              </Typography> */}

              <div className={classes.graph}>
                <HomepageGraph
                  data={main?.responseData}
                />
              </div>
              <Box height="10px" />
              <Typography className={classes.username}>
                &nbsp;{t("dashQuickLinks")}
              </Typography>
              <Box height="10px" />
              <div style={{ paddingBottom: "10px" }}>
                <Grid container spacing={1}>
                  {QuickLinks?.map((item) => {
                    return (
                      <Grid item xs={4}>
                        <QuickLink data={item} />
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
              {/* <Box height="10px" />
                  <Typography className={classes.username}>
                    &nbsp;{t("dashMyFavourites")}
                  </Typography>
                  <Box height="10px" />
                  <div style={{ paddingBottom: "10px" }}>
                    <Grid container spacing={1}>
                      {MyFavouritesLink?.map((item) => {
                        return (
                          <Grid item xs={4}>
                            <QuickLink data={item} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div> */}
            </Box>
            {/* Announcement carousel */}
            {
              main?.notification?.list.length > 0 &&
              <div>
                <Announcement
                  t={t}
                  data={main?.notification}
                  loading={load}
                />
              </div>
            }
            {/* <Box className={classes.box}>
              {
                surveyData?.map((item) => {
                  return (
                    <Box marginBottom={1.5}>
                      <SurveyCard
                        data={item}
                      />
                    </Box>
                  )
                })
              }
            </Box> */}
            {/* <Grid container className={classes.referCard}>
              <Grid item xs={6}>
                <Typography className={classes.name2}>
                  {t("ReferWin")}
                </Typography>
                <Typography className={classes.subName}>
                  {t("ReferFamilyAndFriendsToCashback")}
                </Typography>
                <Button
                  variant="contained"
                  className={classes.btn}
                >
                  {t("referFriend")}
                </Button>
              </Grid>
              <Grid item xs={6} textAlign={"right"}>
                <img src="/images/Refer.svg" alt="refer friend" />
              </Grid>
            </Grid> */}
          </div>


          {/* dashboard invoice pay */}
          <DashboardPay
            open={payOpen}
            onClose={() => setpayOpen(false)}
            agreementlist={main?.agreements?.result}
            Outstanding_amount={main?.invoices?.totalOverDueAmount}
            amount={main?.invoices?.totalOutStandingAmount}
            currency_symbol={main?.invoices?.result?.[0]?.currency_symbol}
            invoices={main?.invoices?.result?.map((val) => val.id)}
            companyList={main?.invoices?.companyInvoices}
          />

          {/* dialog */}
          {/* <WelcomeBanner name={decoded.username} open={open} closeDrawer={closeDrawer} /> */}
        </div>
      )}
    </>
  );
};
export default withNamespaces("dashboard")(DashboardMain)