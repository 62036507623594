import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const propertyStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: "#F2F5FA",
        overflow: "hidden",
    },
    roots: {
        margin: "0px 12px",
        padding: "0px 4px",
    },
    image: {
        objectFit: "cover",
        width: "110px",
        height: "113px",
        [theme.breakpoints.down(321)]: {
            height: "125px"
        },
    },
    card: {
        backgroundColor: "white",
        marginTop: "5px",
        borderRadius: theme.palette.borderRadius,
        boxShadow: "0px 0px 4px #00000029",
        cursor: "pointer",
        position: "relative"
    },
    name: {
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px",
    },
    total: {
        fontSize: "16px",
        fontFamily: FontFamilySwitch().bold,
        color: "#071741",
        marginTop: "12px",
    },
    arrow: {
        border: "1px solid #E4E8EE",
        backgroundColor: "#F2F4F7",
        fontSize: "10px",
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    sub: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,

    },
    details: {
        flexFlow: 'wrap !important',
        display: "flex",
        margin: "5px 0px 0px 12px",
        alignItems: "center"
    },
    bottomText: {
        position: "absolute",
        bottom: 0,
        borderRadius: "0px 0px 0px 4px",
        backgroundColor: "#071741",
        color: "white",
        padding: "4px",
        textAlign: "center",
        width: "110px"
    },
}));