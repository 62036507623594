import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { FormGenerator } from "../../../components";
import { useStyles } from "./style";
import { NetWorkCallMethods, getDate, AlertProps } from "../../../utils";
import { config } from "../../../config";
import { NetworkCall } from "../../../networkcall";
import { subDays } from "date-fns";
import { AlertContext } from "../../../contexts";
import { SuccessRequest } from "../../../assets";
import moment from "moment-timezone";
const initial = {
  duration: "",
  start_date: "",
  end_date: "",
  des: "",
  type: "Year",
  typeValue: "",
  sucess: false,
  error: {
    duration: "",
    start_date: "",
    end_date: "",
    des: "",
    type: "Year",
  },
};
export const CustomForm = ({
  t,
  agreement_id,
  contact_id,
  reload = () => false,
  agreement_data,
}) => {
  const classes = useStyles();
  // context
  const alert = React.useContext(AlertContext);
  const [state, setState] = useState({ ...initial });
  const updateState = (k, v) => {
    let error = state?.error;
    error[k] = "";
    setState({ ...state, [k]: v, error });
  };
  const validateContact = () => {
    let isValid = true;
    let error = state.error;
    if (state?.duration?.length === 0) {
      isValid = false;
      error.duration = "Agreement Duration is Required";
    }
    if (state?.start_date === "") {
      isValid = false;
      error.start_date = "Start Date is Required";
    }
    if (state?.des?.length === 0) {
      isValid = false;
      error.des = "Remarks is Required";
    }

    setState({ ...state, error });

    return isValid;
  };
  React.useMemo(() => {
    if (state?.start_date !== "") {
      const lease_end_date = getDate(
        state?.type,
        state?.start_date,
        state?.duration
      );
      setState({
        ...state,
        end_date: subDays(lease_end_date, 1),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.type, state?.start_date, state?.duration]);

  const sumbit = () => {
    if (validateContact()) {
      const data = {
        agreement_id: agreement_id,
        remarks: state?.des,
        contact_id: agreement_data?.contact_id ?? null,
        renewal_type: "Custom",
        period: state?.type === "Year" ? "Yearly" : "Monthly",
        start_date: moment(state?.start_date).format("YYYY/MM/DD") ?? null,
        end_date: moment(state?.end_date).format("YYYY/MM/DD") ?? null,
        request_purpose: "Renewal",
        status: "Pending",
      };
      NetworkCall(
        `${config.api_url}/agreement_request/create_agreement_request`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Requset submitted successfully",
          });
          reload();
          setState({
            ...initial,
            sucess: true,
          });
        })
        .catch((error) => {
          reload();
          setState({
            ...initial,
            sucess: false,
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Some thing Went wrong.",
          });
        });
    }
  };
  return (
    <Box>
      {state?.sucess ? (
        <Box textAlign={"center"} p={2}>
          <SuccessRequest />
          <Box height={"5px"} />
          <Typography className={classes.sucessTitle}>
            {t("Request Submitted Successfully")}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Grid container>
            {["Year", "Month"].map((x) => {
              return (
                <Grid item xs={6}>
                  <Box
                    onClick={() => updateState("type", x)}
                    className={classes.btn}
                    style={{
                      backgroundColor:
                        x === state?.type ? "#5078E1" : "#FFFFFF",
                    }}
                  >
                    <Typography
                      className={classes.btnText}
                      style={{
                        color: x === state?.type ? "#FFFFFF" : "#4E5A6B",
                      }}
                    >
                      {x}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box p={2}>
            <FormGenerator
              components={[
                {
                  isActive: true,
                  component: "text",
                  label: t("Agreement Duration"),
                  value: state?.duration,
                  placeholder: t("Agreement Duration"),
                  onChange: (e) => updateState("duration", e?.target?.value),
                  error: state?.error?.duration,
                  endAdornment: state?.type,
                  size: {
                    xs: 12,
                  },
                },
                {
                  isActive: true,
                  component: "date",
                  label: t("Start Date"),
                  value: state?.start_date,
                  placeholder: t("Start Date"),
                  onChange: (value) => updateState("start_date", value),
                  error: state?.error?.start_date,
                  minDate: new Date(),
                  isRequired: true,
                  size: {
                    xs: 6,
                  },
                },
                {
                  isActive: true,
                  component: "date",
                  label: t("End Date"),
                  value: state?.end_date,
                  placeholder: t("End Date"),
                  onChange: (value) => updateState("end_date", value),
                  error: state?.error?.end_date,
                  minDate: new Date(),
                  isRequired: true,
                  isReadonly: true,
                  size: {
                    xs: 6,
                  },
                },
                {
                  isActive: true,
                  component: "text",
                  label: t("Remarks (optional)"),
                  value: state?.des,
                  placeholder: t("Remarks (optional)"),
                  onChange: (e) => updateState("des", e?.target?.value),
                  error: state?.error?.des,
                  multiline: true,
                  size: {
                    xs: 12,
                  },
                  isPaginate: true,
                },
                {
                  isActive: true,
                  component: "button",
                  label: t("Submit"),
                  onClick: sumbit,
                  size: {
                    xs: 12,
                  },
                },
              ]}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
