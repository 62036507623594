import { Container, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { NetWorkCallMethods, AlertProps, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AlertContext } from "../../contexts";
import { PropertyListCard } from "../../components/propertyListCard";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  roots: {
    margin: "0px 12px",
    padding: "0px 4px",
  },
  image: {
    objectFit: "cover",
    width: "64px",
    borderRadius: theme.palette.borderRadius,
    height: "60px",
    border: "1px solid #5C86CB2E",
  },
  card: {
    backgroundColor: "white",
    padding: "16px 16px 9px 16px",
    marginTop: "12px",
    borderRadius: "4px",
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px",
    marginTop: "-8px",
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "10px",
  },
  noData: {
    color: "#999",
    textAlign: "center",
    marginTop: "20vh",
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    marginLeft: "12px"
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().regular,

  },
  details: {
    flexFlow: 'wrap !important',
    display: "flex",
    marginLeft: "12px",
    alignItems: "center"
  }
}));

const PropertiesList = (props) => {
  const { t } = props
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation()
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const getproperty = (id) => {
    const data = JSON.stringify({
      agreementId: [id],
      type: "payload_agreement",
      tenantId: `${config.tenantid}`,
    });
    NetworkCall(
      `${config.api_url}/agreement-property-info/get`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        setDatas(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    let path = location;
    if (path?.state?.agreement) {
      getproperty(path.state.agreement);
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={t("Owners")}

        goBack={() => history.goBack()}
      />
      <div
        style={{
          height: size?.height - 60,
          overflow: "scroll",
        }}
        className={classes.roots}
      >
        <Typography className={classes.total}>
          {datas?.propertyCount > 0 ? datas?.propertyCount : 0}&nbsp;{t("Properties")}
        </Typography>

        {loading ? (
          <LoderSimmer card count={10} size={80} />
        ) : (
          <>
            <Grid container spacing={1}>
              {datas?.propertyInfo?.length > 0 ? datas?.propertyInfo?.map((val) => {
                return (
                  <>
                    <Grid item xs={12}>
                      <PropertyListCard
                        normal
                        data={val}
                        onClick={() =>
                          history.push(
                            Routes.ownerslists +
                            "?propertyId=" +
                            val?.property_id +
                            "&name=" +
                            val?.property_name
                          )
                        }
                      />
                    </Grid>

                  </>
                );
              }) :
                <Typography className={classes.noData}>
                  t{"Datanotfound"}
                </Typography>}
            </Grid>
            {/* {datas?.propertyInfo?.length > 0 ? datas?.propertyInfo?.map((val) => {
              return (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.card}
                    onClick={() =>
                      history.push(
                        Routes.ownerslists +
                        "?propertyId=" +
                        val?.property_id +
                        "&name=" +
                        val?.property_name
                      )
                    }
                  >
                    <Box>
                      <img
                        src={val?.asset_url}
                        alt=""
                        className={classes.image}
                      />
                    </Box>
                    <Box flexGrow={1}>

                      <Typography className={classes.name}>
                        {val?.property_name}
                      </Typography>
                      <Box className={classes.details}>
                        <Typography className={classes.sub}>{val?.area ?? ""}</Typography>
                        <Box className={classes.dot} />

                        <Typography className={classes.sub}>{val?.city ?? ""}</Typography>
                        <Box className={classes.dot} />

                        <Typography className={classes.sub}>{val?.country ?? ""}</Typography>
                      </Box>
                      <Box className={classes.details}>
                        <Typography className={classes.sub}>{val?.mobile_code ? val?.mobile_code + " - " : ""}{val?.mobile_no ?? ""}</Typography>
                        <Box className={classes.dot} />
                        <Typography className={classes.sub}>{val?.email ?? ""}</Typography>
                      </Box>
                    </Box>

                  </Box>
                </>
              );
            }) :
              <Typography className={classes.noData}>
                {"Data not found"}
              </Typography>} */}
          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("ownerList")(PropertiesList)