import * as React from "react"

const ContactMeIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={22}
        height={22}
        {...props}
    >
        <defs>
            <linearGradient
                id="b"
                x1={0.229}
                y1={0.065}
                x2={0.808}
                y2={1.035}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#0176d0" />
                <stop offset={1} stopColor="#16538c" />
            </linearGradient>
            <linearGradient
                id="a"
                x1={0.393}
                y1={-1.428}
                x2={0.617}
                y2={2.609}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#199ae0" />
                <stop offset={1} stopColor="#0782d8" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={0.389}
                y1={-1.145}
                x2={0.621}
                y2={2.299}
                xlinkHref="#a"
            />
            <linearGradient
                id="e"
                x1={0.241}
                y1={0.105}
                x2={0.648}
                y2={0.996}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#33bef0" />
                <stop offset={1} stopColor="#22a5e2" />
            </linearGradient>
            <radialGradient
                id="d"
                cx={0.224}
                cy={0.144}
                r={0.848}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.627} />
                <stop offset={1} stopOpacity={0} />
            </radialGradient>
        </defs>
        <path
            data-name="Path 11880"
            d="m30.19 29.5-1.916-4.339a7.636 7.636 0 1 0-2.921 2.963l4.094 2.076a.542.542 0 0 0 .743-.7Z"
            transform="translate(-8.238 -8.263)"
            fill="url(#b)"
        />
        <path
            data-name="Path 11881"
            d="M21.262 28.043h8.381a.261.261 0 0 0 .262-.261v-.522a.261.261 0 0 0-.262-.26h-8.381a.261.261 0 0 0-.262.261v.522a.261.261 0 0 0 .262.26Z"
            transform="translate(-11.572 -14.484)"
            fill="url(#a)"
        />
        <path
            data-name="Path 11882"
            d="M21.262 32.043h6.81a.261.261 0 0 0 .262-.261v-.522a.261.261 0 0 0-.263-.26h-6.81a.261.261 0 0 0-.262.261v.522a.261.261 0 0 0 .263.26Z"
            transform="translate(-11.572 -16.398)"
            fill="url(#c)"
        />
        <path
            data-name="Path 11883"
            d="M14 21.562a7.529 7.529 0 0 0 .3 2.077A8.56 8.56 0 0 0 24.991 15.3c0-.173-.016-.341-.026-.511A7.588 7.588 0 0 0 14 21.562Z"
            transform="translate(-8.238 -8.263)"
            opacity={0.15}
            fill="url(#d)"
        />
        <path
            data-name="Path 11884"
            d="M11.119 3a7.056 7.056 0 0 0-7.071 7.04 6.968 6.968 0 0 0 .68 3 .467.467 0 0 0-.021.046l-1.659 3.756a.542.542 0 0 0 .743.7l3.337-1.692A7.051 7.051 0 1 0 11.119 3Z"
            transform="translate(-3.001 -3)"
            fill="url(#e)"
        />
        <path
            data-name="Path 11885"
            d="M10.262 14.043h8.381a.261.261 0 0 0 .262-.261v-.522a.261.261 0 0 0-.262-.26h-8.381a.261.261 0 0 0-.262.261v.522a.261.261 0 0 0 .262.26Z"
            transform="translate(-6.334 -7.785)"
            fill="url(#a)"
        />
        <path
            data-name="Path 11886"
            d="M10.262 18.043h6.81a.261.261 0 0 0 .262-.261v-.522a.261.261 0 0 0-.263-.26h-6.81a.261.261 0 0 0-.262.261v.522a.261.261 0 0 0 .263.26Z"
            transform="translate(-6.334 -9.699)"
            fill="url(#c)"
        />
    </svg>
)

export default ContactMeIcon
