import { useApolloClient } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import { Box, Container, Fab, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar, UseDebounce } from "../../components";
import Bicycle from "../../components/svg/bicycle";
import { AlertContext } from "../../contexts";
import { GET_VEHICLES } from "../../graphql/queries";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import jwt_decode from "jwt-decode";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
    overflow: "hidden",
  },
  tag: {
    padding: "3px 6px",
    fontSize: "8px",
    fontFamily: FontFamilySwitch().bold,
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    borderRadius: "4px"
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "20px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "20px",
      left: "65%",
    },
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  roots: {
    margin: "0px 12px",
    padding: "0px 4px",
  },
  image: {
    objectFit: "cover",
    borderRadius: theme.palette.borderRadius,
    height: "120px",
    width: "101px"
  },

  card: {
    backgroundColor: "white",
    // padding: "12px",
    marginTop: "12px",
    borderRadius: theme.palette.borderRadius,
    boxShadow: "0px 0px 4px #00000029",
    cursor: "pointer",
  },
  name: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    margin: "0px 8px 0px 12px"
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    marginTop: "12px",
  },
  arrow: {
    border: "1px solid #E4E8EE",
    backgroundColor: "#F2F4F7",
    fontSize: "10px",
  },
  vehicleNoStyle: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().regular,
    margin: "0px 6px 0px 12px"
  },
  unitStyle: {
    color: "#4E5A6B",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().regular,
    margin: "0px 6px"
  },
}));

const Vehicle = (props) => {
  const { t } = props
  const classes = useStyles();
  const history = useHistory();
  const debounce = UseDebounce()
  const size = useWindowDimensions();
  const [datas, setDatas] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const location = useLocation();
  const main = location?.state?.main ?? undefined;
  const client = useApolloClient();
  const alert = React.useContext(AlertContext);
  const [search, setSearch] = React.useState("")
  const [offset, setOffset] = React.useState(0);
  const limit = 20;
  const searchURL = location.search;
  const jwt = new URLSearchParams(searchURL).get("vehicle_data");
  const parking_slot_id = new URLSearchParams(searchURL).get("parkingSlotID")
  const token = jwt && jwt_decode(jwt)

  React.useEffect(() => {
    if (main) {
      getVehicleList(search, offset, limit, true);
    } else {
      history.goBack(-1);
    }

    if (jwt) {
      history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: token?.parkingSlotID } }})
    }else if(parking_slot_id){
      history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: parking_slot_id } }})
    }
    // eslint-disable-next-line
  }, []);

  const getVehicleList = async (search, offset, limit, isSearch) => {
    let result;
    result = await client.query({
      query: GET_VEHICLES(),
      fetchPolicy: 'network-only',
      variables: {
        agreementIDs: [main?.agreement?.id],
        parkingStatus: "Occupied", search, offset, limit,
        client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
      },
    }).catch((error) => {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    });
    if (isSearch) {
      setDatas(result?.data?.parking_slot);
    } else {
      setDatas(datas.concat(result?.data?.parking_slot));
    }
    // if (condition) {
    //   setDatas(result?.data?.parking_slot);
    // } else {
    //   setDatas(datas.concat(result?.data?.parking_slot));
    // }
    setLoading(false);
  }

  const handleSearch = (value) => {
    setLoading(true)
    setSearch(value)
    setOffset(0);
    debounce(() => getVehicleList(value, 0, limit, true), 800)
  }

  const fetchMoreData = () => {
    setOffset(offset + limit);
    getVehicleList(search, offset + limit, limit, false);
  };

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={t("Vehicles")}

        goBack={() => history.goBack(-1)}
      />
      <div
        style={{
          height: size?.height - 60,
          overflow: "overlay",
        }}
        className={classes.roots}
      >
        <Box display="flex" alignItems="center" style={{ marginTop: "8px" }} >
          <Box flexGrow={1}>
            <TextField
              size="small"
              variant="outlined"
              color="secondary"
              placeholder={t("SearchServicesHere")}
              style={{ backgroundColor: "white", borderRadius: "10px" }}
              fullWidth
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              className={classes.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <img src="/images/icons8-search (2).svg" alt="" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* <Box marginLeft="8px">
            <IconButton
              className={classes.filter}
              onClick={() => { }}>
              <Badge variant="dot" color="primary">
                <img src="/images/icons8-funnel.svg" alt="filter" />
              </Badge>
            </IconButton>
          </Box> */}
        </Box>
        {loading ? (
          <LoderSimmer card count={10} size={70} />
        ) : (
          <>
            {datas?.length > 0 ? (
              <InfiniteScroll
                dataLength={datas.length}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 130}
              >
                {datas?.map((val) => {
                  return (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        className={classes.card}
                        onClick={() => history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: val?.id } } })}>
                        {val?.vehicle_image ? <img
                          src={val?.vehicle_image}
                          alt=""
                          className={classes.image} /> :
                          <Box style={{ borderRadius: "4px", backgroundColor: "#F0F4FF", height: "48px", width: "48px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                            <Bicycle />
                          </Box>}
                        <Box flexGrow={1}>
                          <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                            <Typography className={classes.name}>
                              {(val?.vehicle_type === "BI-CYCLE" || val?.vehicle_type === "TWO-WHEELER") ?
                                val?.parking_areaByID?.vehicle_parking_area : val?.parking_masterByID?.parking_no}
                            </Typography>
                            <Typography className={classes.tag}>
                              {val?.vehicle_type}
                            </Typography>
                          </Grid>
                          <Box height={"6px"} />
                          <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                            <Typography className={classes.vehicleNoStyle}>
                              {val?.number_plate}
                            </Typography>
                            <Box style={{ height: "6px", width: "6px", borderRadius: "50%", backgroundColor: "#CED3DD" }} />
                            <Typography className={classes.unitStyle}>
                              {val?.agreement_unitsByID?.unitByID?.unit_no}
                            </Typography>

                          </Grid>

                        </Box>
                      </Box>
                    </>
                  );
                })}
              </InfiniteScroll>
            ) : (
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: FontFamilySwitch().semiBold,
                  textAlign: "center",
                  marginTop: "40px"
                }}>
                {t("NoVehiclesFound")}
              </Typography>
            )}
          </>
        )}
        {/* {loading ? (
          <LoderSimmer card count={3} size={70} />
        ) : (
          <>
            {datas?.length > 0 ? datas?.map((val) => {
              return (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.card}
                    onClick={() => history.push({ pathname: Routes.viewVehicle, state: { main: { parkingSlotID: val?.id } } })}>
                    {val?.vehicle_image ? <img
                      src={val?.vehicle_image}
                      alt=""
                      className={classes.image} /> :
                      <Box style={{ borderRadius: "4px", backgroundColor: "#F0F4FF", height: "48px", width: "48px", justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <Bicycle />
                      </Box>}
                    <Box flexGrow={1}>
                      <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                        <Typography className={classes.name}>
                          {(val?.vehicle_type === "BI-CYCLE" || val?.vehicle_type === "TWO-WHEELER") ?
                            val?.parking_areaByID?.vehicle_parking_area : val?.parking_masterByID?.parking_no}
                        </Typography>
                        <Typography className={classes.tag}>
                          {val?.vehicle_type}
                        </Typography>
                      </Grid>
                      <Box height={"6px"} />
                      <Grid container justifyContent={"start"} alignItems={"center"} direction={"row"}>
                        <Typography className={classes.vehicleNoStyle}>
                          {val?.number_plate}
                        </Typography>
                        <Box style={{ height: "6px", width: "6px", borderRadius: "50%", backgroundColor: "#CED3DD" }} />
                        <Typography className={classes.unitStyle}>
                          {val?.agreement_unitsByID?.unitByID?.unit_no}
                        </Typography>

                      </Grid>

                    </Box>
                    <Box>
                      <IconButton className={classes.arrow} size="small">
                        <ArrowForwardIosIcon
                          fontSize="small"
                          sx={{ fontSize: "22" }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </>
              );
            }) :
              <Typography
                style={{
                  fontSize: "14px",
                  fontFamily: FontFamilySwitch().semiBold,
                  textAlign: "center",
                  marginTop: "40px"
                }}>
                No Vehicles Found
              </Typography>}
          </>
        )} */}
      </div>
      <Fab
        color="primary"
        onClick={() => history.push({ pathname: Routes.addVehicle, state: { main: { agreementID: [main?.agreement?.id] } } })}
        className={classes.fab}>
        <AddIcon />
      </Fab>
    </Container>
  );
};
export default withNamespaces("vehicle")(Vehicle)