export const Routes = {
  // home: "/",
  dashboard: "/dashboard",
  welcomePage: "/welcome",
  quotationDetails: "/quotation",
  oldQuotationDetails: "/oldquotationdetails",
  quotationPayment: "/quotationpayment",
  setupAccount: "/setupaccount",
  login: "/login",
  otpPage: "/otppage",
  resetpassword: "/resetpassword",

  agreement: "/agreement",
  requestScreen: "/requestscreen",
  profileView: "/profileView",
  units: "/units",
  invoiceScreen: "/invoicescreen",
  experied: "/experiedagreement",
  notification: "/notification",
  requestDetails: "/requestdetails",
  statementofaccount: "/statementofaccount",
  general: "/general",
  maintenanceParent: "/maintenance",
  profileEdit: "/profileedit",
  addAddress: "/addaddress",
  paymentHistory: "/paymenthistory",
  service: "/service",
  success: "/paymentstatus/success",
  failure: "/paymentstatus/failure",
  communityDiscussion: "/community_discussion",
  createDiscussion: "/create_discussion",
  discussions: "/discussions",
  contacts: "/contacts",
  contactsDetails: "/contactdetails",
  workerGatePass: "/worker_gate_pass",
  passDetails: "/pass_details",
  createPass: "/create_pass",
  parkingPass: "/parking_pass",
  parkingDetails: "/parking_details",
  createParking: "/create_parking",
  delivery: "/delivery",
  deliveryDetails: "/delivery_details",
  createDelivery: "/create_delivery",
  domesticHelp: "/domestic_help",
  domesticDetails: "/domestic_details",
  domesticCreate: "/domestic_create",
  emergencyNumber: "/emergency_contact",
  emergencyNumberDetails: "/emergency_details",
  announcement: "/announcement",
  announcementDetails: "/announcement_details",
  myNeighbours: "/my_neighbours",
  myNeighboursDetails: "/neighbours_details",
  aggreementUnitAccess: "/aggreement_unit_access",
  createNew: "/create_new",
  aggreementUnitAccessDetails: "/aggreement_unit_access_details",
  entries: "/entries",
  swiftinout: "/entries-details",
  vehicle: "/vehicle",
  addVehicle: "/addVehicle",
  viewVehicle: "/viewVehicle",
  managment: '/managment',
  managementdetails: '/managementdetails',
  utilities: '/utilities',
  utilitiesdetails: '/utilitiesdetails',
  utilitiestype: '/utilitiestype',
  agreementparking: '/agreementparking',
  amenities: '/amenities',
  amenitiesdetails: '/amenitiesdetails',
  amenitiestype: '/amenitiestype',
  propertiesList: "/propertiesList",
  ownerslists: '/ownerslist',
  DatePickerTest: '/DatePickerTest',
  companyselect: '/companyselect',
  amenitiesBookingDetails: '/amenitiesBookingDetails',
  amenitiesBookingNew: "/amenitiesBookingNew",
  amenitiesdetailsNew: "/amenitiesdetailsNew",
  viewAmenities: '/viewAmenities',
  availableSlot: '/availableSlot',
  mybooking: '/mybooking',
  myBookingDetails: "/myBookingDetails",
  myBookingList: "/myBookingList",
  payment: '/payment',
  fileManager:'/fileManager',
  fileManagerView:"/fileManagerView",
  allAgreement:"/all-agreement"
};
