import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { ContactList, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_CONTACT_PROPERTY } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: "#F2F5FA",
  },
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    textAlign: "center",
    marginTop: "5%",
  },
}));

const EmergencyNumberDetails = (props) => {
  const {t} = props
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const searchURL = useLocation().search;
  const propertyId = new URLSearchParams(searchURL).get("propertyId");
  const propertyName = new URLSearchParams(searchURL).get("name");
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))

  const getContactDetails = (offsets) => {
    const params = {
      query: GET_CONTACT_PROPERTY,
      variables: {
        id: propertyId,
        client: clientID?.id,
        limit: 20,
        offset: offsets,
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setData(data.concat(response.data.data.emergency_master));

        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  React.useEffect(() => {
    getContactDetails(0);
    // eslint-disable-next-line
  }, []);
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getContactDetails(offset + 20);
  };
  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      <TitleBar
        text={propertyName}

        goBack={() => history.push(Routes.emergencyNumber)}
      />
      <div>
        {loading ? (
          <LoderSimmer count={10} />
        ) : (
          <>
            {data.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 60}
                >
                  {data?.map((val) => {
                    return <ContactList data={val} call />;
                  })}
                </InfiniteScroll>
              </>
            ) : (
              <Typography className={classes.title}>{t("NoDataFound")}</Typography>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("emergency")(EmergencyNumberDetails)