import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ParkinguseStyles } from "./style";
export const Search = ({ value, onChange, placeholder = "Search Parking" }) => {
    const classes = ParkinguseStyles()
    return (
        <TextField size="small" variant="outlined" placeholder={placeholder} style={{ backgroundColor: "white", borderRadius: "10px" }} fullWidth
            value={value}
            onChange={(e) => onChange(e.target.value)}
            className={classes.search}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small">
                            <img src="/images/icons8-search (2).svg" alt="" />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}