import { Box, Divider, Stack, Typography, Tab, IconButton } from '@mui/material'
import React from 'react'
import { DetailCardStyle } from './style'
import TabList from "@mui/lab/TabList";
import { tabsClasses } from "@mui/material/Tabs";
import TabContext from "@mui/lab/TabContext";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { withNamespaces } from 'react-i18next';
import { AuthContext } from '../../../contexts';


const DetailCard = (
    {
        click = () => false,
        back = () => false,
        scroll = "",
        data = {},
        value = "",
        selecteDate = () => false,
        tabDate = {},
        t
    }
) => {
    const auth = React.useContext(AuthContext)
    const { state } = useLocation()
    const classes = DetailCardStyle({ language: auth?.auth?.auth?.language })

    //tabs handle change
    const handleChange = (event, newValue) => {
        selecteDate(newValue)
    };

    const sameDate = (getDate) =>{
        if(moment(getDate).format("YYYY-MM-DD") === moment(value).format("YYYY-MM-DD")){
            return true
        }
        else return false
    }
    return (
        <Stack className={scroll ? classes.scorllBox : classes.box} p={2} spacing={2} onClick={click}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack>
                    <Stack direction={"row"} spacing={2}>
                        {
                            scroll &&
                            <IconButton className={classes.backbtn1} onClick={back}>
                                <KeyboardArrowLeftIcon />
                            </IconButton>
                        }
                        <Stack>
                            <Typography className={classes.name}> {data?.amenities_name} </Typography>
                            <Typography className={classes.subtitle}>{data?.category} {t("Amenities")}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack alignItems={"center"}>
                    <Typography className={classes.name}>{data?.symbol} {data?.rate}</Typography>
                    <Typography className={classes.subtitle}>Per/Hr</Typography>
                </Stack>
            </Stack>
            {data?.period !== "Daily" && <Divider />}
            {
                data?.period !== "Daily" &&
                <div className={classes.card}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "transparent", paddingBottom: "8px" }}>
                            <TabList
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                aria-label="scrollable auto tabs example"
                                className={classes.tabroot}
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        "&.Mui-disabled": { opacity: 0.3 },
                                    },
                                }}
                            >
                                {tabDate?.map((x, index) => {
                                    return (
                                        <Tab
                                            className={sameDate(x) ? classes.tabItemSelect : classes.tabItem}
                                            label={<>
                                                <div
                                                >
                                                    <Typography className={sameDate(x) ? classes.textSelect1 : classes.textNotSelect1}>
                                                        {state?.main?.period === "Daily" ? moment(x).format("MMM") : moment(x).format("ddd")}
                                                    </Typography>
                                                    <Typography className={sameDate(x) ? classes.textSelect2 : classes.textNotSelect2}>
                                                        {state?.main?.period === "Daily" ? moment(x).format("MM") : moment(x).format("DD")}
                                                    </Typography>
                                                    <Typography className={classes.dot} />
                                                </div>
                                            </>
                                            }
                                            value={x}

                                        />
                                    )
                                })}
                            </TabList>
                        </Box>
                    </TabContext>
                </div>
            }
        </Stack>
    )
}

export default withNamespaces("amenitiesBooking")(DetailCard)