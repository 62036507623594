import { useApolloClient } from "@apollo/client";
import { Box, Container, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { LoderSimmer, TitleBar } from "../../components";
import { AlertContext } from "../../contexts";
import { GET_PROPERTY_DETAILS_FOR_MANAGEMENT } from "../../graphql/queries";
import { AlertProps, FontFamilySwitch, LocalStorageKeys } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import ManagementList from "./detailCard";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
    backgroundColor: '#F2F5FA',
  },
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
    textAlign: "center",
    marginTop: "5%",
  },
  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: 50,
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
    },
  },
}));

const ManagementDetails = (props) => {
  const {t} = props
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const searchURL = useLocation().search;
  const propertyId = new URLSearchParams(searchURL).get("propertyId");
  const propertyName = new URLSearchParams(searchURL).get("name");
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const client = useApolloClient();
  const clientID = JSON.parse(localStorage.getItem(LocalStorageKeys.client))


  const getContactDetails = (offset, search, filter) => {
    client.query({
      query: GET_PROPERTY_DETAILS_FOR_MANAGEMENT(),
      fetchPolicy: 'network-only',
      variables: {
        propertyID: propertyId,
        searchText: search,
        client: clientID?.id,
      }
    })
      .then((response) => {
        if (filter) {
          setData(response.data?.management_committee);
        } else {
          setData(data.concat(response.data?.management_committee));
        }
        setLoading(false);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };


  React.useEffect(() => {
    getContactDetails(offset, search);
    // eslint-disable-next-line
  }, []);

  const fetchMoreData = () => {
    setOffset(offset + 10);
    getContactDetails(offset + 10, search);
  };

  const handleSearch = (value) => {
    setSearch(value)
    getContactDetails(0, value, true)
  }
  return (
    <Container
      className={classes.root}
      maxWidth="sm"
      style={{ height: size?.height }}
    >
      <TitleBar
        text={propertyName}

        goBack={() => history.goBack()}
      />
      <div>
        <Box display="flex" alignItems="center" p={1}>
          <Box flexGrow={1}>
            <TextField
              size="small"
              variant="outlined"

              placeholder="Search"
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
              value={search}
              fullWidth
              className={classes.search}
            />
          </Box>
        </Box>
        {loading ? (
          <LoderSimmer count={10} />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={data.length}
                  next={fetchMoreData}
                  hasMore={true}
                  height={size?.height - 120}
                >
                  {data?.map((val) => {
                    return <ManagementList data={val} />;
                  })}
                </InfiniteScroll>
              </>
            ) : (
              <Typography className={classes.title}>{t("NoDataFound")}</Typography>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
export default withNamespaces("management")(ManagementDetails)