import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
const useStyles = makeStyles((theme) => ({
  root: {},
  tabroot: {
    display: "flex",
    alignItems: "center",
  },
  tabItem: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: "#071741",
    padding: "0px",
    minHeight: "auto",
    // borderRadius: "20px",
    "& .MuiButtonBase-root-MuiTab-root": {
      // borderRadius: "20px",
    },
    minWidth: "auto",

    marginRight: "8px",
  },
  tabItemSelect: {
    textTransform: "capitalize",
    fontWeight: "bold",
    color: theme.typography.color.primary,
    marginRight: "8px",
    padding: "0px",
    borderRadius: "4px",
    minHeight: "auto",
    "& .MuiButtonBase-root-MuiTab-root": {
      minHeight: "0px",
    },
    minWidth: "auto",
  },
  title: {
    padding: "8px 14px",
    borderRadius: "4px",
    // background: theme.palette.background.paper,
    color: "#071741",

  },
  titleselect: {
    padding: "8px 14px",
    borderRadius: "4px",
    backgroundColor: "#071741",
    color: "white",
  },
  more: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    background: theme.palette.background.paper,
  },
}));
export const TabsComponent = ({
  selectedTab = 0,
  tab1Label = "Home",
  tab2Label = "Invoice",
  tab3Label = "Payment",
  onTab1Clicked = () => false,
  onTab2Clicked = () => false,
  onTab3Clicked = () => false,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* Tab Section */}
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            indicatorColor="none"
            textColor="secondary"
            className={classes.tabroot}
            variant="scrollable"
            visibleScrollbar={false}
            scrollButtons={false}
          >
            <Tab
              className={
                selectedTab === 0 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 0 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 0 && (
                    <img src="/images/Homelight.svg" alt="img1" />
                  )}
                  &nbsp;&nbsp;{tab1Label}
                </span>
              }
              onClick={onTab1Clicked}
            />
            <Tab
              className={
                selectedTab === 1 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 1 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 1 && (
                    <img src="/images/Invoiceslight.svg" alt="img2" />
                  )}
                  &nbsp;&nbsp;{tab2Label}
                </span>
              }
              onClick={onTab2Clicked}
            />
            <Tab
              className={
                selectedTab === 2 ? classes.tabItemSelect : classes.tabItem
              }
              label={
                <span
                  className={
                    selectedTab === 2 ? classes.titleselect : classes.title
                  }
                >
                  {selectedTab === 2 && (
                    <img src="/images/Payment Historylight.svg" alt="img3" />
                  )}
                  &nbsp;&nbsp;{tab3Label}
                </span>
              }
              onClick={onTab3Clicked}
            />
          </Tabs>
        </Grid>
      </Grid>
    </div>
  );
};
