/* eslint-disable array-callback-return */
import AddIcon from "@mui/icons-material/Add";
import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory } from "react-router-dom";
import { DeliveryCard, Filter, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods, enumSelect, enumCatagory, FontFamilySwitch } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
    overflow: "hidden",
  },

  search: {
    [`& fieldset`]: {
      borderRadius: "4px",
      height: "38px",
      display: "flex",
      alignItems: "center",
      border: "1px solid #CED3DD",
      "& :hover": {
        border: "1px solid #5078E1",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 10px 10px",
      fontSize: "14px",
    },
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  fab: {
    textTransform: "capitalize",
    "& .MuiButton-root": {
      color: theme.palette.background.paper,
    },

    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "120px",
      right: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      position: "fixed",
      bottom: "80px",
      left: "65%",
    },
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const DeliveryPass = ({ t }) => {
  const size = useWindowDimensions();

  const classes = useStyles();
  // const [search, setSearch] = React.useState("");
  const [gatelist, setGateList] = React.useState([]);
  // const [unitList, setUnitList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [selectedFilter, setSelectedFilter] = React.useState({});
  const history = useHistory();
  const [offset, setOffset] = React.useState(0);
  const [filter, setFilter] = React.useState(false);
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const [total, setTotal] = React.useState(0);
  const alert = React.useContext(AlertContext);
  const [unitsfil, setUnitsfil] = React.useState([]);
  const [statusType, setStatusType] = React.useState([]);

  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  //get pass List
  const getPassList = (unit, status, filter, offset) => {
    const data = {
      tenantId: `${config.tenantid}`,
      request_type: ["DCR"],
      status: status,
      offset: offset,
      limit: 20,
      agreement_unit_id: unit,
      start_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.startDate).toISOString()
          : undefined,
      end_date:
        dateFilter?.endDate?.toString().length > 0
          ? new Date(dateFilter?.endDate).toISOString()
          : undefined,
    };

    NetworkCall(
      `${config.api_url}/security/visitors`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setGateList(response?.data?.data);
        } else {
          setGateList(gatelist.concat(response?.data?.data));
        }
        setTotal(response.data.totalRecords);
        setFilter(false);
        setLoading(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getUnitids = () => {
    const data = {

    };
    NetworkCall(
      `${config.api_url}/resident/access_unit_community`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((res) => {
        getEnum(res?.data?.data?.map((x) => x?.agreement_unit_id),)
        //get units for filter
        const allunits = res?.data?.data?.map((val, index) => {
          let _d;
          try {
            _d = {
              label: val?.unit_name,
              value: String(val.agreement_unit_id),
            };
          } catch (err) {
            alert.setSnack({
              ...alert,
              open: true,
              severity: AlertProps.severity.error,
              msg: t("SomethingWentwrong"),
            });
          }

          return _d;
        });
        setUnitsfil(allunits.flat());
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  React.useEffect(() => {
    getUnitids();
    // eslint-disable-next-line
  }, []);
  const filterData = [
    {
      id: "1",
      title: "Status",
      key: "status",
      showSearch: true,
      filterType: "CHECKBOX",
      values: statusType ?? [],
    },
    {
      id: "3",
      title: "Unit",
      key: "unit",
      filterType: "CHECKBOX",
      values: unitsfil,
    },
    {
      id: "4",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
  ];
  const applyFilter = (data) => {
    setSelectedFilter({
      status: data.status,
      unit: data.unit,
    });
    if (data?.status?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectonestatus"),
      });
      return false;
    }
    if (data?.unit?.length === 0) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Atleastselectoneunit"),
      });
      return false;
    }
    setOffset(0);
    getPassList(data.unit, data.status, true, 0);
  };
  const getEnum = async (data) => {
    const stayType = await enumSelect([enumCatagory?.delivery_status_type])
    setStatusType(stayType?.delivery_status_type)
    getPassList(
      data,
      stayType?.delivery_status_type?.map((val) => val?.value)
    );
    setSelectedFilter({
      unit: data,
      status: stayType?.delivery_status_type?.map((val) => val?.value),
    });

  }
  const fetchMoreData = () => {
    setOffset(offset + 20);
    getPassList(selectedFilter.unit, selectedFilter.status, false, offset + 20);
  };
  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={t("DeliveryOrderCollection")}

        goBack={() => history.push(Routes.service)}
      />
      <Grid container className={classes.screenScrollEffect}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" p={1}>
                <Box flexGrow={1}>
                  {/* <TextField
                    size="small"
                    variant="outlined"

                    placeholder="Search Delivery Orders"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "10px",
                      height: "30px",
                    }}
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className={classes.search}
                  /> */}
                  <Typography className={classes.total}>
                    {t("Delivery")} ({total})
                  </Typography>
                </Box>
                <Box marginLeft="10px">
                  <IconButton
                    className={classes.filter}
                    onClick={() => setFilter(true)}
                  >
                    <Badge variant="dot" color="primary">
                      <img src="/images/icons8-funnel.svg" alt="filter" />
                    </Badge>
                  </IconButton>
                </Box>
              </Box>
              <div style={{ marginRight: "-6px" }}>
                {loading ? (
                  <LoderSimmer count={10} />
                ) : (
                  <InfiniteScroll
                    dataLength={gatelist.length}
                    next={fetchMoreData}
                    hasMore={true}
                    height={size?.height - 140}
                  >
                    <Box padding={"16px"}>
                      {gatelist?.map((item) => {
                        return <Box marginBottom={"12px"}> <DeliveryCard data={item} parking />
                        </Box>
                      })}
                    </Box>
                  </InfiniteScroll>
                )}
              </div>

              <Fab
                color="primary"
                className={classes.fab}
                onClick={() => history.push(Routes.createDelivery)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={filter}
          onClose={() => setFilter(false)}
        >
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={filter} onClose={() => setFilter(false)}>
          <Filter
            filterData={filterData}
            selectedList={filterData[0]}
            filters={{
              status: selectedFilter.status,
              unit: selectedFilter.unit,
            }}
            onGoBack={() => setFilter(false)}
            onApply={applyFilter}
            onClose={() => setFilter(false)}
            updateState={updateState}
            data={dateFilter}
          />
        </Drawer>
      </Hidden>
    </Container>
  );
};
export default withNamespaces("deliveryInfo")(DeliveryPass)