import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FontFamilySwitch } from "../../utils/common";
const useStyles = makeStyles((theme) => ({
  img: {
    backgroundColor: "white",
  },
  rootcard: {
    borderRadius: "4px",
    padding: "8px 12px",
    marginTop: "10px",
    //backgroundImage: `url("/images/Mask_Group_47.svg")`,
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundPositionX: "100%",
  },
  title: {
    color: "white",
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "12px"
  },
  arrow: {
    fontSize: "15px",
  },
  notInterestedStyle: {
    textAlign: "center",
    textDecoration: "underline",
    color: "#5078E1",
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    marginTop: "12px"
  },
}));

export const Actioncard = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        onClick={() => {
          props.action();
        }}
      >
        <Grid item xs={12}>
          {
            props?.data?.id === 4 ?
              <Typography className={classes.notInterestedStyle}>
                Not Interested
              </Typography>

              :
              <Box
                display="flex"
                style={{ backgroundColor: props.data.color }}
                alignItems="center"
                className={classes.rootcard}
              >
                <Box>
                  <Avatar className={classes.img}>
                    <img src={props.data.img} alt={props.data.title} />
                  </Avatar>
                </Box>
                <Box flexGrow={1}>
                  <Typography className={classes.title}>
                    &nbsp;&nbsp;{props.data.title}
                  </Typography>
                </Box>
                <Box>
                  <Avatar className={classes.img} sx={{ width: 30, height: 30 }}>
                    <ArrowForwardIosIcon
                      className={classes.arrow}
                      style={{ color: props.data.color }}
                    />
                  </Avatar>
                </Box>
              </Box>
          }

        </Grid>
      </Grid>
    </div>
  );
};
