/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { Button, Container, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/system";
import moment from "moment";
import React, { useRef } from "react";
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DatePickerNew, TextBox, TitleBar } from "../../components";
import { SelectBox } from "../../components/dropDown";
import Bicycle3 from "../../components/svg/bicycle3";
import FourWheel from "../../components/svg/fourWheel";
import TwoWheel from "../../components/svg/twoWheel";
import { config } from "../../config";
import { AlertContext, AuthContext } from "../../contexts";
import { ADD_VEHICLE, EDIT_PARKING_MASTER } from "../../graphql/mutations";
import {
    GET_PARKING_SPACE_FOR_FOUR_WHEELER,
    GET_PARKING_SPACE_FOR_TWO_WHEELER_BI_CYCLE,
    GET_UNIT_FOR_ADDING_VEHICLE,
    GET_VEHICLE,
    UPDATE_PARKING_SLOT
} from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods, YesNoOptions } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#F2F5FA",
        padding: "0px",
    },
    input: {
        marginTop: "16px",
    },
    applybtn: {
        padding: "12px",
        borderRadius: "4px",
    },
    heading1: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "16px",
        paddingBottom: "12px"
    },
    profession: {
        height: "44px",
        backgroundColor: "white",
        borderRadius: "4px",
        fontSize: "14px",
        color: "gray",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        border: "1px solid #E4E8EE",
    },
    label: {
        marginTop: "10px",
        fontSize: "12px",
        color: "#98A0AC",
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
        },
        "& .MuiContainer-root": {
            padding: "0px 8px",
        },
    },
    dialog: {
        "& .MuiDialog-paper": {
            borderRadius: "4px",
            padding: "0px",
        },
    },
    bottomBarTitle: {
        fontSize: "18px",
        fontFamily: FontFamilySwitch().extraBold,
        color: theme.typography.color.primary,
    },
    title: {
        fontSize: "16px",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.secondary,
    },
    titleroot: {
        borderBottom: "1px solid #c1c1c1",
        padding: "4px 8px 8px 8px",
    },
    helperImg: {
        height: "40px",
        borderRadius: theme.palette.borderRadius,
        width: "40px",
        objectFit: "cover",
    },
    helperRoot: {
        borderTop: "1px solid #E4E8EE",
        cursor: "pointer",
    },
    name: {
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        color: theme.typography.color.primary,
    },
    pname: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.secondary,
    },
    cancelbtn: {
        padding: "12px",
        border: "1px solid #CED3DD",
        backgroundColor: "white",
        borderRadius: "4px",
        color: theme.typography.color.primary,
    },
    drap2: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "70px",
        borderRadius: "4px",
        textAlign: "center",
        background: "#FFFFFF",
        border: "2px dashed #E4E8EE",
    },
    text2: {
        color: "#404E61",
        fontFamily: FontFamilySwitch().semiBold,
        fontSize: "14px"
    },
    Label1: {
        color: theme.typography.color.Tertiary,
        fontSize: "12px",
        paddingBottom: "8px"
    },
    Label2: {
        color: "Choose Parking Space",
        fontSize: "14px",
        fontFamily: FontFamilySwitch().semiBold,
        paddingBottom: "8px"
    },
    selected_button: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: "4px",
        border: `"1px solid ${theme.palette.primary.main}"`,
        fontSize: "14px",
        fontFamily: FontFamilySwitch().semiBold,
        textTransform: "capitalize",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        }
    },
    unselected_button: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        borderRadius: "4px",
        border: `"1px solid ${theme.palette.primary.main}"`,
        fontSize: "14px",
        fontFamily: FontFamilySwitch().regular,
        textTransform: "capitalize"
    }
}));
const InitialState = {
    unit: "",
    slot: "",
    type: "",
    vehicleNo: "",
    img: "",
    imgname: "",
    insured: false,
    insuranceName: "",
    insuranceValidity: new Date(),
    insuranceNote: "",
    error: {
        unit: "",
        slot: "",
        type: "",
        vehicleNo: "",
        img: "",
    },
};

const VehicleTypeList = [
    {
        type: "Four Wheeler",
        value: "FOUR-WHEELER",
        icon: (<FourWheel />),
        selected_icon: (<FourWheel color="#5078e1" />)
    },
    {
        type: "Two Wheeler",
        value: "TWO-WHEELER",
        icon: (<TwoWheel />),
        selected_icon: (<TwoWheel color="#5078e1" />)
    },
    {
        type: "Bi Cycle",
        value: "BI-CYCLE",
        icon: (<Bicycle3 />),
        selected_icon: (<Bicycle3 color="#5078e1" />)
    },
]

const AddVehicle = ({ t }) => {
    const size = useWindowDimensions();
    const auth = React.useContext(AuthContext)
    const classes = useStyles({ language: auth?.auth?.auth?.language });
    const history = useHistory();
    const location = useLocation();
    const main = location?.state?.main ?? undefined;
    const [data, setdata] = React.useState({ ...InitialState });
    const [optionLoading, setOptionLoading] = React.useState(null)
    const [parkingSpacesList, setParkingSpacesList] = React.useState([])
    const client = useApolloClient();
    const inputFile = useRef(null);
    const alert = React.useContext(AlertContext);
    const [loading, setLoading] = React.useState(true);
    const [previousSlot, setPreviousSlot] = React.useState(null)
    const [disable, setDisable] = React.useState(false)

    React.useEffect(() => {

        if (main?.agreementID?.length > 0) {
            if (main?.parkingSlotID) {
                getVehicle();
            } else {
                setLoading(false);
            }
        } else {
            history.goBack(-1);
        }
        // eslint-disable-next-line
    }, []);

    const getVehicle = async () => {
        let result;
        result = await client.query({
            query: GET_VEHICLE(),
            fetchPolicy: 'network-only',
            variables: { parkingSlotID: main?.parkingSlotID },
        }).catch((error) => {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        });
        let tempData = result?.data?.parking_slot?.[0];
        if (tempData?.vehicle_type === "FOUR-WHEELER") {

            let option = await client.query({
                query: GET_PARKING_SPACE_FOR_FOUR_WHEELER(),
                fetchPolicy: 'network-only',
                variables: { agreementIDs: [tempData?.agreement_id] },
            });
            let tempSpace = option?.data?.parking_slot?.map((item) => {
                let _au = { ...item, value: "", name: "" };
                _au.value = item?.parking_id;
                _au.name = item?.parking_masterByID?.parking_no;
                _au.status = item?.parking_masterByID?.parking_status;
                return _au;
            }) ?? [];
            setParkingSpacesList(tempSpace)
        } else if (tempData?.vehicle_type === "TWO-WHEELER" || tempData?.vehicle_type === "BI-CYCLE") {
            let option = await client.query({
                query: GET_PARKING_SPACE_FOR_TWO_WHEELER_BI_CYCLE(),
                fetchPolicy: 'network-only',
                variables: { propertyID: tempData?.agreement_unitsByID?.unitByID?.property_id },
            });
            setParkingSpacesList(option?.data?.parking_area)
        }
        setPreviousSlot(tempData)
        setdata({
            unit: {
                ...tempData?.agreement_unitsByID,
                value: tempData?.agreement_unitsByID?.id,
                label: tempData?.agreement_unitsByID?.unitByID?.name + " - " + tempData?.agreement_unitsByID?.unitByID?.unit_no
            },
            slot: (tempData?.vehicle_type === "TWO-WHEELER" || tempData?.vehicle_type === "BI-CYCLE") ?
                {
                    __typename: tempData?.parking_areaByID?.__typename,
                    value: tempData?.parking_areaByID?.id,
                    name: tempData?.parking_areaByID?.vehicle_parking_area,
                    is_active: true
                } : tempData?.parking_masterByID?.id,
            type: tempData?.vehicle_type,
            vehicleNo: tempData?.number_plate,
            img: tempData?.vehicle_image,
            imgname: tempData?.vehicle_image.split('/').pop().split('-').pop(),
            insured: tempData?.is_insured,
            insuranceName: tempData?.insurance_name,
            insuranceNote: tempData?.insurance_notes,
            insuranceValidity: tempData?.valid_till ? new Date(moment(tempData?.valid_till)) : null,
            error: InitialState?.error,
        });
        setLoading(false);
    }

    const loadOptions = async (search = "", array, type) => {
        setOptionLoading(type);
        let result, offset = 0;
        if (search && !Boolean(array?.length)) {
            offset = 0;
        } else {
            offset = array?.length;
        }
        switch (type) {
            case "agreement_units":
                if (!main?.agreementID?.length > 0) {
                    setOptionLoading(null);
                    return { options: [] };
                }
                result = await client.query({
                    query: GET_UNIT_FOR_ADDING_VEHICLE(),
                    fetchPolicy: 'network-only',
                    variables: {
                        agreementIDs: main?.agreementID,
                        unitCategoryID: 2,
                        offset, limit: 10, search,
                    },
                });
                let tempAgreementUnits = [];
                tempAgreementUnits = result?.data?.agreement_units?.map((item) => {
                    let _au = { ...item, value: "", label: "" };
                    _au.value = item?.id;
                    _au.label = item?.unitByID?.name + " - " + item?.unitByID?.unit_no;
                    return _au;
                }) ?? [];
                setOptionLoading(null);
                return { options: [...tempAgreementUnits], hasMore: array?.length + tempAgreementUnits?.length < result?.data?.count[0]?.count, };
            default:
                return { options: [] };
        }
    };

    const updateState = async (key, value) => {
        let error = data.error;
        error[key] = "";
        if (key === "unit") {
            setdata({ ...data, [key]: value, slot: "", error });
            if (value) {
                if (data?.type === "FOUR-WHEELER") {

                    let result = await client.query({
                        query: GET_PARKING_SPACE_FOR_FOUR_WHEELER(),
                        fetchPolicy: 'network-only',
                        variables: { agreementIDs: [value?.agreement_id] },
                    });
                    let tempSpace = result?.data?.parking_slot?.map((item) => {
                        let _au = { ...item, value: "", name: "" };
                        _au.value = item?.parking_id;
                        _au.name = item?.parking_masterByID?.parking_no;
                        _au.status = item?.parking_masterByID?.parking_status;
                        return _au;
                    }) ?? [];
                    setParkingSpacesList(tempSpace)
                } else if (data?.type === "TWO-WHEELER" || data?.type === "BI-CYCLE") {
                    let result = await client.query({
                        query: GET_PARKING_SPACE_FOR_TWO_WHEELER_BI_CYCLE(),
                        fetchPolicy: 'network-only',
                        variables: { propertyID: value?.unitByID?.property_id },
                    });
                    setParkingSpacesList(result?.data?.parking_area)
                }
            }
        } else if (key === "type") {
            setdata({ ...data, [key]: value, slot: "", error });
            if (data?.unit && value === "FOUR-WHEELER") {

                let result = await client.query({
                    query: GET_PARKING_SPACE_FOR_FOUR_WHEELER(),
                    fetchPolicy: 'network-only',
                    variables: { agreementIDs: [data?.unit?.agreement_id] },
                });
                let tempSpace = result?.data?.parking_slot?.map((item) => {
                    let _au = { ...item, value: "", name: "" };
                    _au.value = item?.parking_id;
                    _au.name = item?.parking_masterByID?.parking_no;
                    _au.status = item?.parking_masterByID?.parking_status;
                    return _au;
                }) ?? [];
                setParkingSpacesList(tempSpace)
            } else if (data?.unit && (value === "TWO-WHEELER" || value === "BI-CYCLE")) {
                let result = await client.query({
                    query: GET_PARKING_SPACE_FOR_TWO_WHEELER_BI_CYCLE(),
                    fetchPolicy: 'network-only',
                    variables: { propertyID: data?.unit?.unitByID?.property_id },
                });
                setParkingSpacesList(result?.data?.parking_area)
            }
        } else {
            setdata({ ...data, [key]: value, error });
        }
    };

    const updateimg = (image) => {
        setDisable(true)
        let error = data.error;
        error.img = "";
        // setdata({ ...data, img: URL.createObjectURL(image), imgname: image.name, error })
        let bytes = image?.size
        let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
        if (finalSize <= 2.00) {
            const formData = new FormData();
            formData.append("files", image);
            formData.append("tenantId", `${config.tenantid}`);
            NetworkCall(
                `${config.api_url}/file-upload`,
                NetWorkCallMethods.post,
                formData,
                null,
                true,
                false).then((res) => {
                    let error = data.error;
                    error.img = "";
                    setdata({ ...data, img: res?.data?.fileUrls?.[0]?.url, imgname: image.name, error })
                    setDisable(false)
                }).catch((err) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                        vertical: AlertProps.vertical.top,
                        horizontal: AlertProps.horizontal.center,
                    });
                });
        }
        else{
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Please upload less then 2MB File",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
            });
        }
    };

    const removeimg = () => {
        let error = data.error;
        error.img = "";
        setdata({ ...data, img: "", imgname: "", error })
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const validate = () => {
        let isValid = true;
        let error = data.error;

        if (data?.unit?.length === 0) { isValid = false; error.unit = "Unit is Required"; }
        if (data?.slot?.length === 0) { isValid = false; error.slot = "Slot is Required"; }
        if (data?.type?.length === 0) { isValid = false; error.type = "Vehicle Type is Required"; }
        if (data?.vehicleNo?.length === 0) { isValid = false; error.vehicleNo = "Vehicle Number is Required"; }
        if (data?.img?.length === 0) { isValid = false; error.img = "Vehicle Image is Required"; }

        if (data?.unit?.length === 0 || data?.slot?.length === 0 ||
            data?.type?.length === 0 || data?.vehicleNo?.length === 0 ||
            data?.img?.length === 0) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("Pleasefillallmandatoryfield"),
            });
        }
        setdata({ ...data, error });
        return isValid;
    };

    const save = () => {
        if (validate()) {
            const createdUpdatedBy = localStorage.getItem("userProfileId");
            const currentDateTime = new Date().toISOString();
            if (data?.type === "TWO-WHEELER" || data?.type === "BI-CYCLE") {
                let tempPayload = {
                    company_id: data?.unit?.propertyByID?.company_id,
                    property_id: data?.unit?.property_id,
                    agreement_id: data?.unit?.agreement_id,
                    agreement_unit_id: data?.unit?.id,
                    vehicle_type: data?.type,
                    number_plate: data?.vehicleNo,
                    is_active: true,
                    created_by: createdUpdatedBy,
                    updated_by: createdUpdatedBy,
                    created_at: currentDateTime,
                    updated_at: currentDateTime,
                    parking_area_id: data?.slot?.value,
                    vehicle_image: data?.img,
                    parking_type: "Reserved",
                    allocated_from: data?.unit?.agreementByID?.lease_start_date,
                    allocated_to: data?.unit?.agreementByID?.lease_end_date,
                    client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id,
                    is_insured: data?.insured,
                    insurance_name: data?.insuranceName,
                    insurance_notes: data?.insuranceNote,
                    valid_till: data?.insuranceValidity
                }
                if (data?.insured) {
                    tempPayload["insurance_name"] = data?.insuranceName;
                    tempPayload["valid_till"] = data?.insuranceValidity;
                    tempPayload["insurance_notes"] = data?.insuranceNote;
                }
                let payload = [tempPayload]
                if (main?.parkingSlotID) {
                    client.mutate({
                        mutation: UPDATE_PARKING_SLOT(),
                        variables: { parkingSlotID: main?.parkingSlotID, payload }
                    }).then((response) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: t("VehicleUpdatedSuccessfully"),
                        });
                        history.replace({ pathname: Routes.addVehicle, state: {} });
                        history.goBack(-1);
                    }).catch((error) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("SomethingWentwrong"),
                        });
                    })
                } else {
                    client.mutate({
                        mutation: ADD_VEHICLE(),
                        variables: { payload }
                    }).then((response) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: t("VehicleAddedSuccessfully"),
                        });
                        history.goBack(-1);
                    }).catch((error) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("SomethingWentwrong"),
                        });
                    })
                }
            } else {
                if (previousSlot) {
                    client.mutate({
                        mutation: EDIT_PARKING_MASTER(),
                        variables: {
                            parkingMasterID: previousSlot?.parking_masterByID?.id,
                            payload: {
                                parking_status: "Vacant",
                                updated_by: createdUpdatedBy,
                                updated_at: currentDateTime
                            }
                        }
                    }).then((response) => {
                        let payload = {
                            company_id: null,
                            property_id: null,
                            agreement_unit_id: null,
                            vehicle_type: null,
                            number_plate: null,
                            updated_by: createdUpdatedBy,
                            updated_at: currentDateTime,
                            vehicle_image: null,
                            is_insured: null,
                            insurance_name: null,
                            valid_till: null,
                            insurance_notes: null,
                        }
                        client.mutate({
                            mutation: UPDATE_PARKING_SLOT(),
                            variables: {
                                parkingSlotID: previousSlot?.id,
                                payload: payload
                            }
                        }).then((response) => { }).catch((error) => {
                            alert.setSnack({
                                ...alert,
                                open: true,
                                severity: AlertProps.severity.error,
                                msg: t("SomethingWentwrong"),
                            });
                        })
                    }).catch((error) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("SomethingWentwrong"),
                        });
                    })
                }
                client.mutate({
                    mutation: EDIT_PARKING_MASTER(),
                    variables: {
                        parkingMasterID: data?.slot?.value,
                        payload: {
                            parking_status: "Occupied",
                            updated_by: createdUpdatedBy,
                            updated_at: currentDateTime
                        }
                    }
                }).then((response) => {
                    let payload = {
                        company_id: data?.unit?.propertyByID?.company_id,
                        property_id: data?.unit?.property_id,
                        agreement_unit_id: data?.unit?.id,
                        vehicle_type: data?.type,
                        number_plate: data?.vehicleNo,
                        updated_by: createdUpdatedBy,
                        updated_at: currentDateTime,
                        vehicle_image: data?.img,
                        is_insured: data?.insured,
                    }
                    if (data?.insured) {
                        payload["insurance_name"] = data?.insuranceName;
                        payload["valid_till"] = data?.insuranceValidity;
                        payload["insurance_notes"] = data?.insuranceNote;
                    }
                    client.mutate({
                        mutation: UPDATE_PARKING_SLOT(),
                        variables: {
                            parkingSlotID: data?.slot?.id,
                            payload: payload
                        }
                    }).then((response) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.success,
                            msg: t("VehicleAddedSuccessfully"),
                        });
                        history.replace({ pathname: Routes.addVehicle, state: {} });
                        history.goBack(-1);
                        history.replace({
                            pathname: Routes.viewVehicle,
                            state: {
                                main: {
                                    agreementID: [data?.agreement_id],
                                    parkingSlotID: data?.slot?.id
                                }
                            }
                        })
                    }).catch((error) => {
                        alert.setSnack({
                            ...alert,
                            open: true,
                            severity: AlertProps.severity.error,
                            msg: t("SomethingWentwrong"),
                        });
                    })
                }).catch((error) => {
                    alert.setSnack({
                        ...alert,
                        open: true,
                        severity: AlertProps.severity.error,
                        msg: t("SomethingWentwrong"),
                    });
                })
            }
        } else { return false; }
    }

    return (<>
        {loading ? <></> :
            <Container className={classes.root} maxWidth="sm">
                <TitleBar
                    text={main?.parkingSlotID ? t("UpdateVehicle") : t("CreateNewVehicle")}
                    goBack={() => history.goBack(-1)}
                />
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        style={{ height: size?.height - 135, overflow: "overlay" }}>
                        <div style={{ padding: "16px" }}>
                            <div className={classes.input} style={{ marginTop: "0px" }}>
                                <SelectBox
                                    label={t("UnitReference")}
                                    placeholder={t("SelectUnit")}
                                    value={data.unit}
                                    isPaginate
                                    loadOptions={(search, array) => loadOptions(search, array, "agreement_units")}
                                    loading={optionLoading === "agreement_units"}
                                    debounceTimeout={800}
                                    onChange={(value) => updateState("unit", value)}
                                    isError={data?.error?.unit?.length > 0}
                                    errorMessage={data?.error?.unit} />
                            </div>
                            <div className={classes.input}>
                                <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label1}
                                    align="left">
                                    {t("SelectVehicle")}
                                </Typography>
                                {/* <Box height={"8px"} /> */}
                                <div style={{ display: "flex", columnGap: "10px" }}>
                                    {VehicleTypeList.map((item) => {
                                        return (<Grid
                                            container direction={"column"}
                                            style={{
                                                display: "flex",
                                                backgroundColor: "#FFFFFF",
                                                border: data?.type === item.value ? "1px solid #5078E1" : undefined,
                                                boxShadow: "0px 3px 6px #00000029",
                                                borderRadius: "4px",
                                                padding: "12px",
                                                width: "33.33%",
                                                alignItems: "center",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => updateState("type", item.value)}>
                                            {data?.type === item.value ? item?.selected_icon : item?.icon}
                                            <Box height={"16px"} />
                                            <Typography
                                                style={{
                                                    textAlign: "center",
                                                    fontSize: "14px",
                                                    color: data?.type === item.value ? "#091B29" : "#98A0AC",
                                                    fontFamily: FontFamilySwitch().semiBold
                                                }}>
                                                {item.type}
                                            </Typography>
                                        </Grid>)
                                    })}
                                </div>
                                {data?.error?.type?.length > 0 && (
                                    <Typography variant={"caption"} color={"error"}>
                                        {data?.error?.type}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.input}>
                                <TextBox
                                    label={t("VehicleNumber")}
                                    placeholder={t("EnterVehicleNumber")}
                                    color={true}
                                    value={data?.vehicleNo}
                                    onChange={(e) => { updateState("vehicleNo", e.target.value); }}
                                    isError={data?.error?.vehicleNo?.length > 0}
                                    errorMessage={data?.error?.vehicleNo} />
                            </div>
                            {(data?.unit && data?.type) && <>
                                <div className={classes.input}>
                                    <Typography className={classes.Label2}>
                                        {t("ChooseParkingSpace")}
                                    </Typography>
                                    {parkingSpacesList?.length > 0 ?
                                        <Grid container style={{ display: "flex", columnGap: "8px", rowGap: "8px" }}>
                                            {parkingSpacesList.map((item) => {
                                                return (<Grid style={{
                                                    display: "flex",
                                                    backgroundColor: data?.slot?.value === item.value ? "#5078E1" :
                                                        item?.status === "Occupied" ? "#E4E8EE" : "#FFFFFF",
                                                    border: data?.slot?.value === item.value ? "1px solid #5078E1" : "1px solid #E4E8EE",
                                                    borderRadius: "4px",
                                                    padding: "16px 10px",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                    minWidth: "55px",
                                                    minHeight: "55px",
                                                    maxHeight: "110px"
                                                }}
                                                    onClick={() => {
                                                        if (item?.is_active || item?.status === "Vacant") {
                                                            updateState("slot", item)
                                                        } else { return false; }
                                                    }}>
                                                    <Typography
                                                        style={{
                                                            textAlign: "center",
                                                            fontSize: "14px",
                                                            color: (item?.status === "Occupied" || data?.slot?.value === item.value) ?
                                                                "#FFFFFF" : "#98A0AC",
                                                            fontFamily: FontFamilySwitch().semiBold
                                                        }}>
                                                        {item.name}
                                                    </Typography>
                                                </Grid>)
                                            })}
                                        </Grid> :
                                        <div style={{ padding: "10px", justifyContent: "center" }}>
                                            <Typography style={{ fontSize: "12px", fontFamily: FontFamilySwitch().regular, textAlign: "center" }}>
                                                {t("NoParkingSpaceAvailable")}
                                            </Typography>
                                        </div>}
                                    {data?.error?.slot?.length > 0 && (
                                        <Typography variant={"caption"} color={"error"}>
                                            {data?.error?.slot}
                                        </Typography>
                                    )}
                                </div>
                            </>}
                            <div className={classes.input}>
                                <Typography className={classes.heading1}>{t("UploadImage")}</Typography>
                                {data?.img ?
                                    <div style={{ backgroundColor: "#FFFFFF", borderRadius: "8px", display: "flex", padding: "12px" }}>
                                        <img src={data?.img} alt=""
                                            style={{
                                                objectFit: "cover", height: "46px", width: "46px",
                                                borderRadius: "10px", boxShadow: "0px 3px 6px #00000014"
                                            }} />
                                        <Box width={"16px"} />
                                        <Box flexGrow={1} alignSelf={"center"}>
                                            <Typography style={{ color: "#091B29", fontSize: "14px", fontFamily: FontFamilySwitch().semiBold }}>
                                                {data?.imgname}
                                            </Typography>
                                        </Box>
                                        <IconButton onClick={removeimg}>
                                            <DeleteForeverTwoToneIcon htmlColor="red" />
                                        </IconButton>
                                    </div> :
                                    <div onClick={onButtonClick} className={classes.drap2}>
                                        <input type='file' accept="image/*" id='file'
                                            ref={inputFile} style={{ display: 'none' }}
                                            value={data?.img}
                                            onChange={(e) => { updateimg(e?.target?.files?.[0]) }}
                                            onClick={(event) => { event.target.value = null }} />
                                        <Grid container direction={"row"}
                                            justifyContent={"center"}
                                            alignItems={"center"}>
                                            <IconButton>
                                                <img src="/images/uploadImg.svg" alt="upload_image" height={"18px"} width={"18px"} />
                                            </IconButton>
                                            <Typography className={classes.text2}>
                                                {t("UploadImagesHere")}
                                            </Typography>
                                        </Grid>
                                    </div>}
                                {data?.error?.img?.length > 0 && (
                                    <Typography variant={"caption"} color={"error"}>
                                        {data?.error?.img}
                                    </Typography>
                                )}
                            </div>
                            <div className={classes.input}>
                                <Typography
                                    variant="body1"
                                    color="textsecondary"
                                    className={classes.Label1}
                                    align="left">
                                    {t("Insured")}
                                </Typography>
                                <div style={{ display: "flex", columnGap: "10px" }}>
                                    {YesNoOptions(t)?.map((item) => {
                                        return <Button className={item?.value === data?.insured ?
                                            classes.selected_button : classes.unselected_button}
                                            onClick={() => updateState("insured", item?.value)}>
                                            {item?.label}
                                        </Button>
                                    })}
                                </div>
                                {
                                    data?.insured && <>
                                        <div className={classes.input}>
                                            <TextBox
                                                label={"Insurance Name"}
                                                placeholder={"Enter Insurance Name"}
                                                color={true}
                                                value={data?.insuranceName}
                                                onChange={(e) => updateState("insuranceName", e?.target?.value)} />
                                        </div>
                                        <div className={classes.input}>
                                            <DatePickerNew
                                                minDate={new Date()}
                                                label={"Valid Till"}
                                                placeholder={"Enter Valid Till"}
                                                value={data?.insuranceValidity}
                                                onChange={(value) => updateState("insuranceValidity", value)} />
                                        </div>
                                        <div className={classes.input}>
                                            <TextBox
                                                multiline
                                                label={"Insurance Notes"}
                                                placeholder={"Enter Insurance Notes"}
                                                color={true}
                                                value={data?.insuranceNote}
                                                onChange={(e) => updateState("insuranceNote", e?.target?.value)} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        item
                        xs={12}
                        style={{
                            zIndex: 1,
                            padding: "12px",
                            backgroundColor: "white",
                            boxShadow: "0px -1px 6px #00000021",
                        }}>
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                className={classes.cancelbtn}
                                onClick={() => history.goBack(-1)}>
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={save}
                                fullWidth
                                className={classes.applybtn}
                                variant="contained"
                                disabled={disable}>
                                {main?.parkingSlotID ? "Update" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>}
    </>);
};
export default withNamespaces("vehicle")(AddVehicle)