import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import AmenityBookingNew from "../../screens/amenityBookingNew/amenityBookingNew";
import { FontFamilySwitch } from "../../utils";
import { DialogDrawer } from "../dialogDrawer";
import { NoService } from "../noService";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 3px 30px #5C86CB2E",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    display: "flex"
  },
  Cardcontent: {
    // padding: "8px 12px",
    height: "99px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "auto"
  },
  title: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    marginTop: "4px",
  },
  submitBtn: {
    borderRadius: "4px",
    padding: "12px 8px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  sideBorder: {
    backgroundColor: (props) => props?.data?.borderColor,
    margin: " 3px 0px",
    borderRadius: " 0px 10px 10px 0px",
    border: (props) => `3px solid ${props?.data?.borderColor}`
  }
}));

export const ServiceCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const [open, setopen] = React.useState(false);

  return (
    <>
      <div
        className={classes.card}
        onClick={() => {
          if (props?.data?.noavailable) {
            setopen(true);
          } else {
              history.push({ 
                pathname: props.data.link,
              });
          }
        }}
      >
        <Box className={classes.sideBorder} />
        <div className={classes.Cardcontent}>
          <div style={{ flex: "auto" }}>
            <center>
              {!props.data.icon ?
                <img
                  src={props.data.img}
                  alt=""
                  style={{ objectFit: "contain", marginTop: "4px" }}
                /> :
                props.data.icon
              }

              <Typography variant="subtitle2" className={classes.title} noWrap>
                {props.data.title}
              </Typography>
              {props.data.sub ? (
                <Typography variant="subtitle2" className={classes.title} noWrap>
                  {props.data.sub}
                </Typography>
              ) : (
                <Box height="12px" />
              )}
            </center>
          </div>
        </div>
      </div>
      <DialogDrawer
        height={"300px"}
        maxWidth={"sm"}
        open={open}
        header={" "}
        noService={true}
        component={
          <>
            {
              !props?.data?.noService ?
                <AmenityBookingNew closeServicePopup={() => setopen(false)} />
                :
                <NoService
                  buttonName={"Close"}
                  fullWidth
                  onClose={() => setopen(false)}

                />
            }
          </>
        }
        onClose={() => setopen(false)} />

    </>
  );
};
