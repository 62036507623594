import {
  Badge,
  Box,
  Container,
  Dialog,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { Filter, Statement, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import lightFormat from "date-fns/lightFormat";
import InfiniteScroll from "react-infinite-scroll-component";
import { GET_INVOICE_DETAILS } from "../../graphql/queries";
import { useApolloClient } from "@apollo/client";


// styles
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.secondary,
  },
  bottomTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    paddingBottom: "8px",
  },
  agreement: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.palette.borderRadius,
    padding: "4px 8px",
    margin: "8px",
    cursor: "pointer",
    border: "1px solid #E4E8EE"
  },
  statementtotal: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  iconbtn: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
  },
  statement: {
    background: theme.palette.background.paper,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    color: "black",
    backgroundColor: "white",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    width: "100%",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    width: "100%",
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    width: "100%",
    marginLeft: "4px",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
    width: "100%",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 4px",
    },
  },
  screenPadding: {
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  list: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E4E8EE",
    padding: '12px',
    cursor: "pointer"
  },
  Tittle: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: "#071741",
  },
  agreementImg: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "6px 6px 0px 6px"
  }
}));
const InitialDate = {
  startDate: null,
  endDate: null,
};
const Statementofaccounts = (props) => {
  const { t } = props
  const classes = useStyles();
  const client = useApolloClient();
  const defaultFilter = {};
  const [dateFilter, setdateFilter] = React.useState({ ...InitialDate });
  const size = useWindowDimensions();
  const backdrop = React.useContext(BackdropContext);
  const [openList, setopenList] = React.useState(false);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [drawer, setDrawer] = React.useState(null);
  const [state, setState] = React.useState([]);
  const [selectedStatement, setSelectedStatement] = React.useState([]);
  const [pdfData, setPdfData] = React.useState([]);
  const [showDrawer, setShowDrawer] = React.useState(false);


  const [Statementdata, setStatementdata] = React.useState([]);
  const alert = React.useContext(AlertContext);
  const [selectedFilter, setSelectedFilter] = React.useState(defaultFilter);
  const [offset, setOffset] = React.useState(0);


  const onClose = () => {
    setShowDrawer(false)
  }
  const updateState = (key, value) => {
    setdateFilter({ ...dateFilter, [key]: value });
  };
  const draweropen = () => {
    setDrawer(true);
  };
  const drawerclose = () => {
    setDrawer(false);
  };
  const goBack = () => {
    window.history.go(-1);
  };
  const validation = () => {
    let isValid = true;
    if (dateFilter?.startDate?.length === 0) {
      isValid = false;

    }
    if (dateFilter?.endDate?.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  const fetchMoreData = () => {
    setOffset(offset + 20);
    generateStatement(false, offset + 20)
  };

  const generateStatement = (filter, offSet) => {
    const payload = {
      account_no: selectedStatement?.account_no,
      start_date: lightFormat(dateFilter?.startDate, 'yyyy-MM-dd'),
      end_date: lightFormat(dateFilter?.endDate, 'yyyy-MM-dd'),
      search: "",
      offset: offSet,
      limit: 10
    };
    NetworkCall(
      `${config.api_url}/account-statement/get-account-statement`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        if (filter === true) {
          setStatementdata(response?.data?.data);
        } else {
          setStatementdata(Statementdata?.concat(response?.data?.data));

        }
        drawerclose()
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });

  }



  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      const tempDecoded = jwt_decode(authToken);
      const userId = tempDecoded.id;
      const payload = {
        userId: userId,
      };
      NetworkCall(
        `${config.api_url}/user-accounts`,
        NetWorkCallMethods.post,
        payload,
        null,
        true,
        false
      )
        .then((response) => {
          const data = response?.data?.data;

          setSelectedStatement(data?.[0])
          const fetch = response?.data?.data.map((val, index) => {
            let _d;
            try {
              _d = {
                id: val.id,
                account_no: val.account_no,
                key: "account_no",
              };
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _d;
          });
          setState(fetch);
          const defaultFilters = response?.data?.data.map((val, index) => {
            let _d = [];
            try {
              _d.push(val.account_no);
            } catch (err) {
              alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
              });
            }
            return _d;
          });
          defaultFilter.account_no = defaultFilters.flat();

        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    // eslint-disable-next-line
  }, []);

  const applyFilter = (data) => {
    if (dateFilter?.startDate > dateFilter?.endDate) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("Enddatemustbegreaterthanstartdate"),
      });
      return false;
    }
    if (validation()) {
      setSelectedFilter(data);
      generateStatement(true, 0)
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "please fill both start date & end date fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center
      })
    }
  };
  const filterData = [
    {
      id: "1",
      title: "Date",
      key: "date",
      filterType: "DATE",
      values: [],
    },
    // {
    //   id: "1",
    //   title: "Account No",
    //   key: "account_no",
    //   filterType: "CHECKBOX",

    //   values: state,
    // },
  ];
  const updateStatement = (data) => {
    setopenList(false);
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: ("switching account"),
    });
    setSelectedStatement(data);
    backdrop.setBackDrop({
      ...backdrop,
      open: false,
      message: ("switching account"),
    });
  };
  const StatementDrawer = () => {
    return (
      <>
        <Box display="flex" alignItems="center" className={classes.titleroot} style={{ padding: '12px' }}>
          <Box flexGrow={1}><Typography className={classes.bottomTitle}>{"Accounts"}</Typography></Box>
          <Box><IconButton size="small" onClick={() => setopenList(false)}><CloseIcon /></IconButton></Box>
        </Box>
        {state?.map((val) => {
          return (
            <Box
              className={classes.list}
              onClick={() => updateStatement(val)}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* account image */}
                <Box className={classes.agreementImg}>
                  <img src="/images/agreementnew.svg" alt="agreement_img" />
                </Box>
                {/* account number */}
                <Box style={{ marginLeft: "10px" }}>
                  <div>
                    <Typography className={classes.Tittle}>
                      {val.account_no}
                    </Typography>

                  </div>
                </Box>
              </Box>
              <Box>
                {val.id ===
                  selectedStatement.id &&
                  <img src="/images/ticknew.svg" alt="tick_img" />
                }
              </Box>
            </Box>
          )
        })}
      </>
    )
  }

  const getPDFDetails = (id) => {
    client
      .query({
        query: GET_INVOICE_DETAILS,
        fetchPolicy: "network-only",
        variables: {
          id: id,
        },
      })
      .then((res) => {
        setPdfData(res?.data?.invoice?.[0] ?? [])
        setShowDrawer(true)
      })
      .catch((err) => {
        console.log(err);

      });
  };

  return (
    <div>
      <Container maxWidth="sm" sx={{ padding: 0 }}>
        <div className={classes.root}>
          <Grid
            className={classes.screen}
            style={{ height: size?.height }}
            item
            xs={12}
          >
            <Grid className={classes.topNavBarStyle}>
              <TitleBar text={t("StatementOfAccount")} goBack={goBack} />
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    className={classes.agreement}
                    onClick={() => setopenList(true)}
                  >
                    <Box flexGrow={1}>
                      <Typography className={classes.statementtotal}>
                        Account No :  {state?.map((val) => {
                          return (
                            <>
                              {val?.id === selectedStatement?.id
                                ? val?.account_no
                                : ""}
                            </>
                          );
                        })}
                      </Typography>
                    </Box>

                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => setopenList(true)}
                        style={{
                          color: "black",
                        }}
                      >
                        <KeyboardArrowDownIcon />
                      </IconButton>
                    </Box>
                  </Box>

                </Grid>
              </Grid>
              <Grid xs={12}>
                <Box display="flex" p={1}>
                  <Box flexGrow={1}>
                    <Typography className={classes.statementtotal}>
                      {Statementdata?.length} {t("Statements")}
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton className={classes.iconbtn}>
                      <img src="/images/downlodestatement.svg" alt="downlode" />
                    </IconButton>
                  </Box>
                  <Box marginLeft={1}>
                    <IconButton
                      className={classes.iconbtn}
                      onClick={draweropen}
                    >
                      <Badge variant="dot" color="primary">
                        <img src="/images/icons8-funnel.svg" alt="filter" />
                      </Badge>
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <InfiniteScroll
              dataLength={Statementdata?.length}
              next={fetchMoreData}
              hasMore={true}
              height={size?.height - 110}
            >
              <Grid Container className={classes.statement}>
                {Statementdata.length > 0 && (
                  <>
                    {Statementdata?.map((item) => {
                      return (
                        <Grid xs={12}>
                          <Statement
                            Statementdata={item}
                            getPDFDetails={getPDFDetails}
                            url={pdfData?.asset_url}
                            currencySymbol={pdfData?.currency_master?.symbol}
                            onClose={onClose}
                            showDrawer={showDrawer}
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </InfiniteScroll>
          </Grid>
        </div>
      </Container>

      {/* filter drawer */}
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={drawer}
          onClose={drawerclose}
          className={classes.dialog}
        >
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
                updateState={updateState}
                data={dateFilter}
              />
            </Grid>
          </Grid>
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer open={drawer} onClose={drawerclose}>
          <Grid container>
            <Grid item xs={12}>
              <Filter
                filterData={filterData}
                onGoBack={drawerclose}
                selectedList={filterData[0]}
                filters={{
                  account_no: selectedFilter.account_no,
                }}
                allFilter={true}
                onApply={applyFilter}
                onClose={drawerclose}
                updateState={updateState}
                data={dateFilter}
              />
            </Grid>
          </Grid>
        </Drawer>
      </Hidden>
      {/* statement Drawer */}
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={openList}
          onClose={() => setopenList(false)}
        >
          <>
            {
              StatementDrawer()
            }
          </>
          {/* <AgreementDrawer
            agreementList={agreementList}
            agreement={agreement}
            updateagrement={updateagrement}
            onclose={() => setopenList(false)}
            expiresOn={props.t("expires_on")}
          /> */}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          open={openList}
          onClose={() => setopenList(false)}
          className={classes.dialog}
        >
          {
            StatementDrawer()
          }
          {/* <AgreementDrawer
            agreementList={agreementList}
            agreement={agreement}
            updateagrement={updateagrement}
            onclose={() => setopenList(false)}
          /> */}
        </Dialog>
      </Hidden>
    </div>
  );
};
export default withNamespaces("statementofaccount")(Statementofaccounts)