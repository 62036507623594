import moment from "moment-timezone";
import React, { createContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { GET_PROPERTY_UNITID } from "../../graphql/queries";
import { AlertProps, NetWorkCallMethods, ValidateEmail, LocalStorageKeys } from "../../utils";
import { useApolloClient } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { BackdropContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
const InitialState = {
  passType: "",
  unit: "",
  stayType: "",
  startData: "",
  endDate: "",
  gate: {
    label:"Any",
    value:"Any"
  },
  profileImg: "",
  name: "",
  mobile: "",
  mail: "",
  idType: "",
  idproof: "",
  imgURL: "",
  gender: "",
  error: {
    passType: "",
    unit: "",
    stayType: "",
    startData: "",
    endDate: "",
    gate: "",
    profileImg: "",
    name: "",
    mobile: "",
    mail: "",
    idType: "",
    idproof: "",
    gender: "",
  },
};
export const PassContext = createContext();
moment.defaultFormat = "DD MMM YY";

const PassContextProvider = (props) => {
  const {t} = props
  const [data, setdata] = useState({ ...InitialState });
  const [step, setStep] = useState(1);
  const client = useApolloClient();
  const [type, setType] = React.useState("");
  const [visitList, setVisitList] = React.useState([]);
  const [url, setUrl] = React.useState("");
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const history = useHistory();
  const searchURL = useLocation().search;
  const passId = new URLSearchParams(searchURL).get("passId");
  const [editVisitList, setEditList] = React.useState([]);
  const [disable, setDisable] = React.useState(null);
  const [agreementList, setAgreementLists] = React.useState([]);
  const [property, setProperty] = React.useState({});
  React.useEffect(() => {
    if (passId) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Loading",
      });
      const data = {
        tenantId: `${config.tenantid}`,
        request_id: passId,
      };
      NetworkCall(
        `${config.api_url}/security/request`,
        NetWorkCallMethods.post,
        data,
        null,
        true,
        false
      )
        .then((response) => {
          const temp = response?.data?.data[0];
          const InitialState = {
            passType: {
              value: temp?.request_type,
              label:
                temp?.request_type === "WGR"
                  ? "Worker Gate Pass"
                  : "Visitor Gate Pass",
            },
            unit: {
              value: temp?.agreement_unit_id ?? "",
              label: temp?.unit?.[0]?.name ?? "",
              unit_id: temp?.unit?.[0]?.id ?? "",
            },
            stayType: temp?.stay_type,
            startData: new Date(temp?.request_from),
            endDate: new Date(temp?.request_to),
            gate: {
              value: temp?.gate_id,
              label: temp?.gate,
            },
            profileImg: "",
            name: "",
            mobile: "",
            mail: "",
            idType: "",
            idproof: "",
            imgURL: "",
            gender: "",
            error: {
              passType: "",
              unit: "",
              stayType: "",
              startData: "",
              endDate: "",
              gate: "",
              profileImg: "",
              name: "",
              mobile: "",
              mail: "",
              idType: "",
              idproof: "",
              gender: "",
            },
          };
          setProperty({
            id: temp?.property_id,
            name: temp?.property_name
          })
          setdata(InitialState);
          setVisitList(temp?.visitors);
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
        })
        .catch((err) => {
          backdrop.setBackDrop({
            ...backdrop,
            open: false,
            message: "",
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    }
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    if (key === 'unit') {
      getpropertyUnitid(value)
    }
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking Pass Type
    if (data.passType.length === 0) {
      isValid = false;
      error.passType = t("PassTypeisRequired");
    }
    //Checking unit
    if (data.unit.length === 0) {
      isValid = false;
      error.unit = t("unitisRequired");
    }
    //Checking Stay Type
    if (data.stayType.length === 0) {
      isValid = false;
      error.stayType = t("StayTypeisRequired");
    }
    //Checking Start Date
    if (data.startData.length === 0) {
      isValid = false;
      error.startData = t("StartDateisRequired");
    }
    //Checking End Date
    if (data.endDate.length === 0) {
      isValid = false;
      error.endDate = t("EndDateisRequired");
    }
    //Checking Gate
    if (data.gate.length === 0) {
      isValid = false;
      error.gate = t("GateisRequired");
    }
    let initialDate = moment(data.startData);
    let endDate = moment(data.endDate);

    if (endDate.diff(initialDate) >= 0) {
    } else {
      isValid = false;
      error.endDate = t("EndDateisMorethenStartDate");
    }
    setdata({ ...data, error });
    return isValid;
  };
  const isIamValideTostep2 = () => {
    let isValid = true;
    let error = data.error;

    if (data.name.length === 0) {
      //Checking Name
      isValid = false;
      error.name = t("NameisRequired");
    }
    if (data.mail.length > 0) {
      if (ValidateEmail(data.mail) === false) {
        isValid = false;
        error.mail = t("InvalidMail");
      }
    }

    if (data.gender.length === 0) {
      //gender
      isValid = false;
      error.gender = t("GenderisRequired");
    }

    setdata({ ...data, error });
    return isValid;
  };
  const previous = () => {
    setStep(step - 1);
  };
  const addPass = () => {
    const payload = {
      tenantId: `${config.tenantid}`,
      pass_type: data?.passType?.value,
      visitor_type: data?.passType?.value === "VGR" ? "Visitor" : "Worker",
      stay_type: data?.stayType,
      start_date: data?.startData,
      end_date: data?.endDate,

      gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
      profiles: visitList.map((data) => {
        return {
          name: data?.name,
          gender: data?.gender,
          mobile_no: data?.mobile_no,
          mobile_country_code: data?.mobile_country_code,
          email_id: data?.email_id,
          visitor_image: data?.visitor_image,
          id_type: data?.id_type,
          identification_no: data?.identification_no,
        };
      }),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
    };
    const payload2 = {
      tenantId: `${config.tenantid}`,
      pass_type: data?.passType?.value,
      visitor_type: data?.passType?.value === "VGR" ? "Visitor" : "Worker",
      stay_type: data?.stayType,
      start_date: data?.startData,
      end_date: data?.endDate,
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      user_profile_id: localStorage.getItem("userProfileId"),
    };
    NetworkCall(
      `${config.api_url}/security/create`,
      NetWorkCallMethods.post,
      visitList.length > 0 ? payload : payload2,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("PassSuccessfullyCreated"),
        });
        history.push(Routes.workerGatePass);
        setDisable(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };
  const update = () => {
    let result2 = visitList.filter((val) => !val.id);
    const payload = {
      tenantId: `${config.tenantid}`,
      pass_type: data?.passType?.value,
      visitor_type: data?.passType?.value === "VGR" ? "Visitor" : "Worker",
      stay_type: data?.stayType,
      start_date: data?.startData,
      end_date: data?.endDate,
      gate_no: data?.gate?.label === "Any" ? undefined : data?.gate?.value,
      request_id: passId,
      user_profile_id: localStorage.getItem("userProfileId"),
      profiles: result2.map((data) => {
        return {
          name: data?.name,
          gender: data?.gender,
          mobile_no: data?.mobile_no,
          mobile_country_code: data?.mobile_country_code,
          email_id: data?.email_id,
          visitor_image: data?.visitor_image,
          id_type: data?.id_type,
          identification_no: data?.identification_no,
        };
      }),
      property_id: property?.id,
      unit_id: data?.unit?.unit_id,
      agreement_unit_id: data?.unit?.value,
      deleteProfiles: editVisitList,
    };

    NetworkCall(
      `${config.api_url}/security/update`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("PassSuccessfullyUpdated"),
        });
        history.push(Routes.workerGatePass);
        setDisable(false);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };
  const next = () => {
    if (step === 1) {
      if (isIamValideTostep1()) {
        setStep(2);
      }
    } else if (step === 2) {
      if (visitList.length > 0) {
        if (passId) {
          update();
        } else {
          addPass();
        }
        setDisable(true);
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("PleaseAddVisitors"),
        });
      }
    }
  };
  const addVisitList = () => {
    if (isIamValideTostep2()) {
      setVisitList([
        ...visitList,
        {
          name: data?.name,
          gender: data?.gender?.value,
          mobile_no: data?.mobile?.mobile,
          mobile_country_code: data?.mobile?.mobile_code,
          email_id: data?.mail,
          visitor_image: url,
          id_type: data?.idType?.value,
          identification_no: data?.idproof,
          id_label: data?.idType?.label,
        },
      ]);
      const InitialState = {
        passType: data?.passType,
        unit: data?.unit,
        stayType: data.stayType,
        startData: data?.startData,
        endDate: data?.endDate,
        gate: data?.gate,
        profileImg: "",
        name: "",
        mobile: "",
        mail: "",
        idType: "",
        idproof: "",
        imgURL: "",
        error: {
          passType: "",
          unit: "",
          stayType: "",
          startData: "",
          endDate: "",
          gate: "",
          profileImg: "",
          name: "",
          mobile: "",
          mail: "",
          idType: "",
          idproof: "",
        },
      };

      setdata(InitialState);
      setUrl("");
    } else {
      return false;
    }
  };
  const getUnitIDS = () => {
    const temp_decoded = jwt_decode(localStorage.getItem(LocalStorageKeys.authToken));
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }
  const getpropertyUnitid = (v, x) => {
    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: "Loading",
    });
    client.query({
      query: GET_PROPERTY_UNITID(),
      fetchPolicy: 'network-only',
      variables: {
        unit_id: [v?.unit_id],
        offset: 0,
        limit: 10,
        search: "",
      }
    }).then((res) => {
      setProperty(res?.data?.unit?.[0]?.property)
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
    }).catch((err) => {
      backdrop.setBackDrop({
        ...backdrop,
        open: false,
        message: "",
      });
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    })
  }
  return (
    <PassContext.Provider
      value={{
        data,
        step,
        previous,
        next,
        updateState,
        type,
        setType,
        visitList,
        addVisitList,
        setVisitList,
        setUrl,
        url,
        setEditList,
        editVisitList,
        disable,
        isIamValideTostep2,
        getUnitIDS,
        agreementList,
        property
      }}
    >
      {props.children}
    </PassContext.Provider>
  );
};

export default  withNamespaces('createPass')(PassContextProvider)
