import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";
export const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: "16px",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.secondary,
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  requsettop: {
    backgroundColor: "#5078E11E",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsettitle: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
  requsetsub: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "8px",
  },
  requsetsubs: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsetsubs2: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    display: "flex",
    alignItems: "center"
  },
  requsetsubs3: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    alignSelf: "end",
  },
  avatar: {
    backgroundColor: "#E4E8EE",
  },
  pa: {
    color: theme.typography.color.primary,
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #5078E1",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  submitbtn: {
    border: "1px solid #5078E1",
    borderRadius: "4px",
    padding: "12px",
  },
  convayTitle: {
    color: theme?.palette?.border?.status,
    fontSize: "15px",
    fontFamily: FontFamilySwitch().bold,
  },
  status: {
    color: `${theme?.typography?.color?.Tertiary} !important`,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  subStatus: {
    color: theme?.palette?.border?.status,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  statusBox: {
    borderRadius: "4px",
    backgroundColor: theme?.palette?.info?.light,
    padding: "16px"
  },
  notesTitle: {
    color: theme?.typography?.color?.primary,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  createnewsectionlog1: {
    padding: "14px 20px 30px 26px",
    height: `calc(100vh - 394px)`,
    overflow: "auto",
    backgroundColor: "white",
  },
  dateTime: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: "#7C8594",
  },
  innerImage: {
    height: "100%",
    width: "100%",
    backgroundColor: theme?.typography?.status?.primary,
  },
  notesTimeLine: {
    color: theme?.palette?.background?.primary,
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold
  },
  noteTimeLine: {
    color: theme?.typography?.color?.secondary,
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
    maxWidth: "624px",
    width: '100%',
    textAlign: "left"
  },
  border: {
    borderLeft: `2px dashed ${theme?.palette?.primary?.main}`,
    padding: "0px 40px",
    position: "relative",
    // "&:last-child": {
    //     borderLeft: "none",
    // },
  },
  avatars1: {
    position: "absolute",
    top: 0,
    left: '-22px',
    height: "45px",
    width: "45px",
    backgroundColor: "#F2F4F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px"
  },
  image: {
    backgroundColor: theme?.palette?.primary?.main,
    borderRadius: "50%",
    padding: "10px 9px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  recentNote: {
    fontSize: "15px",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
  },
  imageStyleView: {
    height: "100%",
    width: "100%",
    margin: '0 auto',
    objectFit: "cover"
  },
  divider: {
    backgroundColor: theme?.palette?.background?.background1,
    height: "6px"
  },
  imageStyle: {
    height: "75px",
    width: "75px",
    borderRadius: "4px",
    margin: "0px 16px 0px 0px",
    objectFit: "cover"
  },
  notes: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  overflow:{
    display:"flex",
    alignItems:"center",
    overflow:"auto !important"
  }
}));