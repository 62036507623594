import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const DetailHeadCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 48,
        width: 48,
        borderRadius: '4px'
    },
    title: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    paid: {
        fontSize: 12,
        color: theme.palette.success.main,
        textAlign: "right",
        fontFamily: FontFamilySwitch().regular,
    }
}))

export const DetailCardStyle = makeStyles((theme) => ({
    title: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    total: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    }
}))

export const BookingCardStyle = makeStyles((theme) => ({
    avatar: {
        height: 42,
        width: 42,
    },
    title: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    content: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    headerTitle: {
        fontSize: 10,
        color: theme.typography.color.Tertiary,
        marginBottom: 4,
        textTransform: "uppercase",
        fontFamily: FontFamilySwitch().semiBold,
    }
}))

export const CancelBookingStyle = makeStyles((theme) => ({
    header: {
        fontSize: 12,
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().semiBold,
    },
    submitBtn: {
        textTransform: "capitalize",
        height: 42,
        borderRadius: '4px'
    }
}))