import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
  folderName: {
    color: theme?.palette?.color?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "12px",
  },
  createdAt: {
    color: theme?.palette?.color?.Tertiary,
    fontFamily: FontFamilySwitch().regular,
    fontSize: "8px",
  },
  folderParent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
  more: {
    fontSize: "20px",
    color: "gray",
    cursor: "pointer",
  },
  menuItem: {
    borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
    margin: "0px 4px",
    fontSize: "14px",
    color: theme?.palette?.secondary?.main,
    fontFamily: FontFamilySwitch().semiBold,
    "&:last-child": {
      border: 0,
    },
  },
  chip: {
    backgroundColor: theme?.palette?.primary?.main,
    padding: "3px 10px",
    borderRadius: "4px",
    color: "white",
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().bold,
  },
  menuList: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 0px 6px #0717411F",
      borderRadius: theme.palette.borderRadius,
      backgroundColor: "white",
    },
  },
  moreBtn: {
    position: (props) => props?.type === 2 ? "absolute" : "relative",
    top: "3px",
    right: "0px",
  },
  listFolderView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  card: {
    padding: "18px",
    background: "white",
    borderRadius: "4px",
    position: "relative",
  },
  flag: {
    backgroundColor: "#5078E1",
    padding: "2px 8px",
    position: "absolute",
    top: "14px",
    left: "-11px",
    borderRadius: "4px",
    boxShadow: '0px 3px 6px #00000029'
  },
  flagChild: {
    height: "10px",
    width: "11px",
    backgroundColor: "#5078E1",
    bottom: "-8px",
    left: "-8px",
    position: "absolute",
    borderBottomLeftRadius: '195px'
  },
  flagName: {
    color: "white",
    fontSize: "10px",
    fontFamily: FontFamilySwitch().bold,
  },
}));
