import * as React from "react"

const PlaceOfInterestIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={17.455} height={24} {...props}>
        <defs>
            <linearGradient
                id="a"
                y1={0.5}
                x2={1}
                y2={0.5}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#33bef0" />
                <stop offset={1} stopColor="#22a5e2" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={0.147}
                y1={0.124}
                x2={0.854}
                y2={0.721}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#d43a02" />
                <stop offset={1} stopColor="#b9360c" />
            </linearGradient>
            <radialGradient
                id="c"
                cx={0.5}
                cy={0.5}
                r={0.5}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0.177} />
                <stop offset={1} stopOpacity={0} />
            </radialGradient>
        </defs>
        <ellipse
            data-name="Ellipse 129403"
            cx={8.145}
            cy={2.7}
            rx={8.145}
            ry={2.7}
            transform="translate(.582 18.6)"
            fill="url(#a)"
        />
        <path
            data-name="Path 11896"
            d="M23.983 19.268A9.111 9.111 0 0 0 26.455 13a8.867 8.867 0 0 0-8.727-9A8.867 8.867 0 0 0 9 13a9.111 9.111 0 0 0 2.472 6.268l5.57 5.749a.949.949 0 0 0 1.371 0Z"
            transform="translate(-9 -4)"
            fill="url(#b)"
        />
        <ellipse
            data-name="Ellipse 129404"
            cx={4.073}
            cy={4.2}
            rx={4.073}
            ry={4.2}
            transform="translate(4.655 4.8)"
            fill="url(#c)"
        />
        <ellipse
            data-name="Ellipse 129405"
            cx={3.491}
            cy={3.6}
            rx={3.491}
            ry={3.6}
            transform="translate(5.236 5.4)"
            fill="#f9f9f9"
        />
    </svg>
)

export default PlaceOfInterestIcon
