import { useApolloClient } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, Box, Container, FormControl, FormControlLabel, Grid, Hidden, IconButton, Radio, RadioGroup, Tab, Tabs, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, AmenitiesCardList, DrawerComponent, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { GET_PROPERTY_AMENITIES, GET_UNIT_DETAILS_BY_UNIT_ID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { AmenitiesCard } from "./amenitiesDetailsCard";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0px",
        backgroundColor: '#F2F5FA',
    },
    // title: {
    //     fontSize: "16px",
    //     fontFamily: FontFamilySwitch().bold,
    //     color: "#071741",
    //     textAlign: "center",
    //     marginTop: "5%",
    // },
    search: {
        [`& fieldset`]: {
            borderRadius: "10px",
            height: "38px",
            display: "flex",
            alignItems: "center",
            border: "1px solid #CED3DD",
            "& :hover": {
                border: "1px solid #5078E1",
            },
        },
        "& .MuiOutlinedInput-input": {
            padding: "6px 0px 10px 10px",
            fontSize: "14px",
        },
    },
    topDrap: {
        backgroundColor: 'white',
        borderRadius: '4px',
        border: "1px solid #E4E8EE",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1px 15px 1px 15px',
        cursor: 'pointer',
        color: theme.palette.secondary.main
    },
    toptitle: {
        color: '#091B29',
        fontSize: '12px',
        fontFamily: FontFamilySwitch().bold,
    },
    rationame: {
        '&.MuiFormControlLabel-label': {
            color: '#091B29',
            fontSize: '15px',
            fontFamily: FontFamilySwitch().bold,
        }
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
        padding: "4px 16px",
        backgroundColor: "white",
        boxShadow: "0px -1px 6px #00000021"

    },
    tabItem: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        padding: "0px",
        minHeight: "auto",
        borderRadius: "4px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "4px",
        },
        minWidth: "auto",
        // border: "1px solid #E4E8EE",
        marginRight: "8px",
        backgroundColor: "white",
    },
    tabItemSelect: {
        textTransform: "capitalize",
        fontWeight: "bold",
        color: theme.typography.color.primary,
        marginRight: "8px",
        padding: "0px",
        borderRadius: "4px",
        minHeight: "auto",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: "0px",
        },
        minWidth: "auto",
    },

    title: {
        border: "1px solid /serviceE4E8EE",
        padding: "8px 14px",
        borderRadius: "4px",
        background: theme.palette.background.paper,
        display: "flex",
        alignItems: "center",
    },
    titleselect: {
        padding: "8px 14px",
        borderRadius: "4px",
        backgroundColor: theme.palette.secondary.main,
        display: "flex",
        alignItems: "center",
        color: "white",
    },
    card: {
        backgroundColor: "white",
        padding: "16px 16px 9px 16px",
    },
    name: {
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px",
        marginTop: "-8px",
    },
    image: {
        objectFit: "cover",
        width: "64px",
        borderRadius: theme.palette.borderRadius,
        height: "60px",
        border: "1px solid #5C86CB2E",
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    subHead: {
        fontSize: "12px",
        color: "#4E5A6B",

    },
    invoice: {
        backgroundColor: "white",
        padding: "14px",
        borderRadius: "4px",
        // marginTop: "14px",
        boxShadow: "0px 3px 30px #5C86CB2E",
        margin: "20px 18px 20px 20px",
        [theme.breakpoints.down('sm')]: {
            margin: "16px 16px 16px 20px"

        }

    },
    noData: {
        color: "#999",
        textAlign: "center",
        marginTop: "20vh",
        fontSize: "14px",
        fontFamily: FontFamilySwitch().bold,
        marginLeft: "12px"
    },
    paddingDiv: {
        padding: "16px",
        [theme.breakpoints.down('sm')]: {
            padding: "16px 16px 16px 20px"

        }
    },
    marginGrid: {
        margin: "20px",
        [theme.breakpoints.down('sm')]: {
            margin: "16px 16px 16px 20px"

        }
    },
    marginGrid1: {
        margin: "20px 18px 20px 20px",
        [theme.breakpoints.down('sm')]: {
            margin: "16px 16px 16px 20px"

        }
    }
}));


const AmenitiesDetails = (props) => {
    const { t } = props
    const classes = useStyles();
    const history = useHistory();
    const { state } = useLocation()
    const size = useWindowDimensions();
    const selectedTab = state?.tab;
    const [value, setValue] = React.useState(selectedTab ?? "1");
    const authToken = localStorage.getItem(LocalStorageKeys.authToken);
    const [propertyAmenities, setPropertyAmenities] = React.useState({
        propertyList: null,
        assets: null,
        loading: true
    });
    const [unitAmenities, setUnitAmenities] = React.useState({
        unitList: null,
        units: [],
        selected: null,
        loading: true
    });
    const [unitDetails, setUnitDetails] = React.useState()
    const [open, setOpen] = React.useState(false);
    const backdrop = React.useContext(BackdropContext);
    const alert = React.useContext(AlertContext);
    const client = useApolloClient();
    const propertyId = state?.propertyId;
    const agreementId = state?.agreementId;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const closerDrawer = () => {
        setOpen(!open)
    }

    //property amenities
    const PropertyAmenities = () => {
        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        client.query({
            query: GET_PROPERTY_AMENITIES(),
            fetchPolicy: 'network-only',
            variables: {
                property_id: propertyId,
                offset: 0,
                limit: 10,
                client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
            }
        }).then((res) => {
            setPropertyAmenities({
                propertyList: res?.data,
                assets: res?.data?.assets,
                loading: false
            })
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        }).catch((err) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        })
    }

    //units amenities
    const getUnitids = () => {
        const tempDecoded = jwt_decode(authToken);
        const data = {
            tenantId: `${config.tenantid}`,
            userId: tempDecoded.id,
        };
        NetworkCall(
            `${config.api_url}/agreement-unit-info/switcher`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                let agreement_units = response.data?.data.map(item => item.agreement_unit).flat(Infinity).filter(i => i.property_id === propertyId)
                setUnitAmenities({
                    units: agreement_units,
                    selected: {
                        label: agreement_units?.[0]?.unit_no,
                        value: agreement_units?.[0]?.unit_id
                    },
                    loading: false
                })
                unitDetailsApi(agreement_units?.[0]?.unit_id, agreement_units?.[0]?.id)

            })
            .catch((err) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    //unit details

    const unitDetailsApi = (id, agreementId) => {

        backdrop.setBackDrop({
            ...backdrop,
            open: true,
            message: "Loading",
        });
        client.query({
            query: GET_UNIT_DETAILS_BY_UNIT_ID(),
            fetchPolicy: 'network-only',
            variables: {
                unitId: id,
                agreement_unit_id: agreementId,
                client: JSON.parse(localStorage.getItem(LocalStorageKeys.client))?.id
            }
        }).then((res) => {
            setUnitDetails(res?.data)
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
        }).catch((err) => {
            backdrop.setBackDrop({
                ...backdrop,
                open: false,
                message: "",
            });
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: t("SomethingWentwrong"),
            });
        })
    }

    React.useEffect(() => {
        if (value === "1") {
            PropertyAmenities()
        }
        if (value === "2") {
            getUnitids()
        }
        //eslint-disable-next-line
    }, [value])
    const SwitchUnit = (val) => {
        setUnitAmenities({
            ...unitAmenities,
            selected: {
                label: val?.unit_no,
                value: val?.unit_id
            },
            loading: false
        })
        unitDetailsApi(val?.unit_id, val?.id)
        // backdrop.setBackDrop({
        //     ...backdrop,
        //     open: true,
        //     message: "Switching unit",
        // });
        setOpen(false)
    }
    return (
        <Container
            className={classes.root}
            maxWidth="sm"
            style={{ height: size?.height }}
        >
            <TitleBar
                text={t("Amenities")}

                goBack={() => selectedTab ? history.push(Routes.amenities + "?Id=" + agreementId) : history.goBack()}
            />
            <Grid container>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="none"
                        textColor="secondary"
                        className={classes.tabroot}
                        variant="scrollable"
                        visibleScrollbar={false}
                        scrollButtons={false}
                    >
                        <Tab
                            className={
                                value === "1" ? classes.tabItemSelect : classes.tabItem
                            }
                            label={
                                <span
                                    className={
                                        value === "1" ? classes.titleselect : classes.title
                                    }
                                >

                                    &nbsp;{t("property")}
                                </span>
                            }
                            value="1"
                        />
                        <Tab
                            className={
                                value === "2" ? classes.tabItemSelect : classes.tabItem
                            }
                            label={
                                <span
                                    className={
                                        value === "2" ? classes.titleselect : classes.title
                                    }
                                >
                                    &nbsp;{t("units")}&nbsp;
                                </span>
                            }
                            value="2"
                        />
                    </Tabs>
                </Grid>
                {value === "1" &&
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.card}
                        >
                            <Box>
                                {propertyAmenities?.propertyList?.property?.[0]?.logo ?
                                    <Avatar
                                        src={propertyAmenities?.propertyList?.property?.[0]?.logo}
                                        alt=""
                                        className={classes.image}
                                    /> : <Avatar className={classes.image} ></Avatar>}

                            </Box>
                            <Box flexGrow={1}>
                                <Typography className={classes.name}>
                                    {propertyAmenities?.propertyList?.property?.[0]?.name ?? "-"}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                }
                {value === "2" &&
                    <>
                        <Grid item xs={12} padding="16px" bgcolor={"white"}>
                            <Box className={classes.topDrap} onClick={closerDrawer}>
                                <Box>
                                    <Typography className={classes.toptitle}>{unitAmenities?.selected?.label ? unitAmenities?.selected?.label : t("nounitsfound")}</Typography>
                                </Box>
                                <Box>
                                    <ExpandMoreIcon style={{ color: '#091B29', marginTop: '5px' }} />
                                </Box>

                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display="flex"
                                alignItems="center"
                                className={classes.card}
                            >
                                <Box>
                                    {unitDetails?.unit?.[0]?.logo ?
                                        <Avatar
                                            src={unitDetails?.unit?.[0]?.logo}
                                            alt=""
                                            className={classes.image}
                                        /> : <Avatar className={classes.image} ></Avatar>}
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography className={classes.name} marginTop={"0px !important"}>
                                        {unitDetails?.unit?.[0]?.unit_no ?? "-"}
                                    </Typography>
                                    <Box display={"flex"} className={classes.name} marginTop={"0px !important"}>
                                        <Typography className={classes.subHead}>
                                            {unitDetails?.unit?.[0]?.property?.name ?? "-"}
                                        </Typography>
                                        <Box className={classes.dot} />
                                        <Typography className={classes.subHead}>
                                            {unitDetails?.unit?.[0]?.block?.name ?? "-"}
                                        </Typography>
                                        <Box className={classes.dot} />
                                        <Typography className={classes.subHead}>
                                            {unitDetails?.unit?.[0]?.floor?.name ?? "-"}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>

                        </Grid>

                    </>
                }
            </Grid>
            {value === "1" &&
                <div
                    style={{
                        height: size?.height - 200,
                        overflow: "auto",
                    }}
                >
                    {propertyAmenities?.loading ? (
                        <LoderSimmer count={10} />
                    ) : (
                        <>
                            <Grid conatiner className={classes.marginGrid}>
                                {propertyAmenities?.propertyList?.property_facilities.length > 0 ? propertyAmenities?.propertyList?.property_facilities.map((item) => {
                                    return (
                                        <>
                                            <Grid item xs={12}>
                                                <AmenitiesCard
                                                    data={item}
                                                    type={"property"}
                                                    companyId={propertyAmenities?.propertyList?.property?.[0]?.companyByID?.id}
                                                    propertyId={propertyAmenities?.propertyList?.property?.[0]?.id}
                                                    agreementId={agreementId}
                                                />
                                                <br />
                                            </Grid>
                                        </>
                                    )

                                }) :
                                    <Typography className={classes.noData}>
                                        {t("Datanotfound")}
                                    </Typography>

                                }
                            </Grid>
                        </>
                    )}

                </div>
            }
            {value === "2" &&
                <div
                    style={{
                        height: size?.height - 270,
                        overflow: "auto",
                    }}
                >

                    {unitDetails?.loading ? (
                        <LoderSimmer count={10} />
                    ) : (
                        <>

                            {unitDetails?.ameneties_usage.length > 0 &&
                                < div className={classes.invoice}>
                                    <Typography style={{ color: "#071741", fontSize: "14px", fontFamily: FontFamilySwitch().bold }}>
                                        {t("Invoice")}
                                    </Typography>
                                    <Box height="14px" />
                                    <AmenitiesCardList data={unitDetails?.ameneties_usage?.[0]} />
                                </div>
                            }
                            <Grid conatiner spacing={3} className={classes.marginGrid1}>
                                {unitDetails?.unit_amenities_breakup?.length > 0 ? unitDetails?.unit_amenities_breakup?.map((item) => {
                                    return (
                                        <>
                                            <Grid item xs={12}>
                                                <AmenitiesCard
                                                    data={item}
                                                    type={"unit"}
                                                    companyId={unitDetails?.unit?.[0]?.property?.companyByID?.id}
                                                    propertyId={unitDetails?.unit?.[0]?.property?.id}
                                                    agreementId={agreementId}
                                                />
                                                <br />
                                            </Grid>
                                        </>
                                    )
                                }) :
                                    <Typography className={classes.noData}>
                                        {t("Datanotfound")}
                                    </Typography>
                                }
                            </Grid>
                        </>
                    )}

                </div>
            }
            {/* drawer */}
            <Hidden smUp>
                <DrawerComponent onClose={closerDrawer} open={open}
                    component={
                        <div>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                <Box>
                                    <Typography className={classes.title}>{t("Unit")}</Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closerDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <FormControl component="fieldset" >
                                <RadioGroup
                                    name="radio-buttons-group"
                                    style={{ padding: "8px" }}
                                >
                                    {unitAmenities?.units?.map((val) => {
                                        return (
                                            <>
                                                <FormControlLabel
                                                    value={val?.unit_id}
                                                    control={
                                                        <Radio
                                                            checked={
                                                                val?.unit_id === unitAmenities?.selected?.value
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    label={val?.unit_no}
                                                    onChange={() => SwitchUnit(val)}
                                                />

                                            </>
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </div>
                    }

                />
            </Hidden>

            {/* dialog */}
            <Hidden smDown>
                <AlertDialog onClose={closerDrawer} open={open}
                    component={
                        <div>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                <Box>
                                    <Typography className={classes.title}>{t("Unit")}</Typography>
                                </Box>
                                <Box>
                                    <IconButton size="small" onClick={closerDrawer}>
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                            <FormControl component="fieldset" >
                                <RadioGroup
                                    style={{ padding: "8px" }}
                                >
                                    {unitAmenities?.units?.map((val) => {
                                        return (
                                            <>

                                                <FormControlLabel
                                                    value={val?.unit_id}
                                                    control={
                                                        <Radio
                                                            checked={
                                                                val?.unit_id === unitAmenities?.selected?.value
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    }
                                                    label={val?.unit_no}
                                                    onChange={() => SwitchUnit(val)}
                                                />
                                            </>
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>

                        </div>
                    }

                />
            </Hidden>
        </Container >
    );
};
export default withNamespaces("amenitiesDetails")(AmenitiesDetails)