import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../../contexts'
import {PreviewCardStyle} from './style'

export const PreviewCard = ({data={}}) => {
    const auth = React.useContext(AuthContext)
    const classes = PreviewCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} spacing={1}>
                    <Avatar className={classes.avatar} src={data?.assets[0]}></Avatar>
                    <Stack>
                        <Typography className={classes.name} noWrap sx={{width:200}}>{data?.amenities_name}</Typography>
                        <Typography className={classes.subtitle}>{data?.description ? data?.description : "No Description Found" }</Typography>
                    </Stack>
                </Stack>
                <Typography className={classes.name}>{data?.symbol} {data?.rate}</Typography>
            </Stack>
        </Box>
    )
}