import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/styles/useTheme';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../router/routes';
import { FontFamilySwitch } from '../../../utils';
import { CarouselSliders } from './carousel';
import { CustomTypography } from './components';

export const Announcement = (props) => {

    const {
        t = {},
        data = {},
        loading = false
    } = props;

    const theme = useTheme();
    const history = useHistory();


    const redirectToAnnouncement = () => {
        history.push({
            pathname: Routes.announcement
        })
    }

    return (
        <Box
            sx={{
                p: 2,
                backgroundColor: theme.palette.background.tertiary1
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={"10px"}
                paddingBottom={theme.spacing(1)}
            >
                <CustomTypography
                    color={theme.palette.secondary.main}
                    fontFamily={FontFamilySwitch().bold}
                >
                    {t("announcements")}
                </CustomTypography>
                {
                    (!loading && data?.count) ? (
                        <CustomTypography
                            color={"#5078E1"}
                            fontFamily={FontFamilySwitch().bold}
                            fontSize={12}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer"
                            }}
                            onClick={redirectToAnnouncement}
                        >
                            {`${data?.count} ${t("unopened")}`}
                            <ChevronRightIcon fontSize='small' />
                        </CustomTypography>
                    ) : (
                        <>
                            {loading && <Skeleton variant="text" width={150} height={20} />}
                        </>
                    )
                }
            </Stack>
            {
                loading ? (
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        padding={2}
                        gap={2}
                        sx={{
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px 3px 10px #5C86CB2E",
                            borderRadius: '12px'
                        }}
                    >
                        <Skeleton variant="rectangular" width={73} height={73} sx={{ borderRadius: '4px' }} />
                        <div style={{ flex: 1 }}>
                            <Skeleton variant="rectangular" width={50} height={10} sx={{ borderRadius: '4px' }} />
                            <Skeleton variant="text" width={"100%"} height={20} sx={{ marginBlock: 1 }} />
                            <Skeleton variant="text" width={150} height={20} />
                        </div>
                    </Stack>
                ) : (
                    <CarouselSliders
                        list={data?.list}
                    />
                )
            }
        </Box>
    )
}