import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Container, FormControl, FormControlLabel, Hidden, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, DrawerComponent, LoderSimmer, TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext, BackdropContext } from "../../contexts";
import { UTILITIES_DROPDOWN_UNITS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { UtilitiesList } from "./detailCard";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
      root: {
            padding: "0px",
            backgroundColor: '#F2F5FA',
      },
      title: {
            fontSize: "16px",
            fontFamily: FontFamilySwitch().bold,
            color: "#071741",
            textAlign: "center",
            marginTop: "5%",
      },
      search: {
            [`& fieldset`]: {
                  borderRadius: "4px",
                  height: "38px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #CED3DD",
                  "& :hover": {
                        border: "1px solid #5078E1",
                  },
            },
            "& .MuiOutlinedInput-input": {
                  padding: "6px 0px 10px 10px",
                  fontSize: "14px",
            },
      },
      topDrap: {
            backgroundColor: theme.palette.background.secondary,
            borderRadius: theme.palette.borderRadius,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1px 15px 1px 15px',
            cursor: 'pointer'
      },
      toptitle: {
            color: theme.typography.color.primary,
            fontSize: '12px',
            fontFamily: FontFamilySwitch().bold,
      },
      rationame: {
            '&.MuiFormControlLabel-label': {
                  color: '#091B29',
                  fontSize: '15px',
                  fontFamily: FontFamilySwitch().bold,
            }
      },
      noData: {
            color: "#999",
            textAlign: "center",
            marginTop: "20vh",
            fontSize: "14px",
            fontFamily: FontFamilySwitch().bold,
            marginLeft: "12px"
      }
}));


const UtilitiesDetails = (props) => {
      const {t} = props
      const classes = useStyles();
      const history = useHistory();
      const size = useWindowDimensions();
      const alert = React.useContext(AlertContext);
      const authToken = localStorage.getItem(LocalStorageKeys.authToken);
      const searchURL = useLocation().search;
      const propertyId = new URLSearchParams(searchURL).get("propertyId");
      const propertyName = new URLSearchParams(searchURL).get("name");
      const [data, setData] = React.useState([]);
      const [loading, setLoading] = React.useState(true);
      const [offset, setOffset] = React.useState(0);
      const backdrop = React.useContext(BackdropContext);

      const [open, setOpen] = React.useState(false);
      const [value, setValue] = React.useState([])
      const [selected, setSelected] = React.useState({})

      const porpertyagainstunit = (id) => {
            const data = {
                  query: UTILITIES_DROPDOWN_UNITS,
                  variables: {
                        property_id: propertyId,
                        unit_id: id
                  },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setValue(response?.data?.data?.unit)
                        setSelected(response?.data?.data?.unit?.[0])
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some thing went wrong",
                        });
                  });
      }

      const getUnitDetails = (unitId, offset) => {
            const payload = {
                  tenantId: `${config.tenantid}`,
                  unit_id: unitId,
                  offset: offset,
                  limit: 20,
            };
            NetworkCall(
                  `${config.api_url}/unit-utils`,
                  NetWorkCallMethods.post,
                  payload,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setData(response?.data?.data)
                        setOpen(false)
                        backdrop.setBackDrop({
                              ...backdrop,
                              open: false,
                              message: "",
                        });
                        setLoading(false);
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Some Thing Went Wrong.",
                        });
                  });
      };
      const getAggreement = () => {
            const temp_decoded = jwt_decode(authToken);
            const userId = temp_decoded.id;
            const data = {
                  tenantId: `${config.tenantid}`,
                  userId: userId,
            };
            NetworkCall(
                  `${config.api_url}/agreement-unit-info/switcher`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            ).then((response) => {
                  const units = response?.data?.data?.map((item) => item.units)
                  const unitId = units?.map((item) => item).flat()
                  porpertyagainstunit(unitId)
                  // getContactDetails(
                  //       res.data.data.agreements.result
                  //             .filter((val) => val.is_primary === true)
                  //             .map((val) => val.id),
                  //       propertyId,
                  //       0,
                  //       "",
                  //       false
                  // );
                  // setAgreement(
                  //       res.data.data.agreements.result
                  //             .filter((val) => val.is_primary === true)
                  //             .map((val) => val.id)
                  // );
            });
      };
      React.useEffect(() => {
            getAggreement();
            // eslint-disable-next-line
      }, []);
      React.useEffect(() => {
            if (selected?.value) {
                  getUnitDetails(selected?.value)
            }
            // eslint-disable-next-line
      }, [selected]);
      const fetchMoreData = () => {
            setOffset(offset + 10);
            getUnitDetails(selected?.value, offset + 10)
      };


      const closerDrawer = () => {
            setOpen(!open)
      }

      const SwitchUnit = (val) => {
            setSelected(val)
            backdrop.setBackDrop({
                  ...backdrop,
                  open: true,
                  message: t("Switchingunit"),
            });
            setOpen(false)
      }
      return (
            <Container
                  className={classes.root}
                  maxWidth="sm"
                  style={{ height: size?.height }}
            >


                  <TitleBar
                        text={propertyName}

                        isUtilities
                        component={
                              <Box className={classes.topDrap} onClick={closerDrawer}>
                                    <Box>
                                          <Typography className={classes.toptitle}>{selected?.label ? selected?.label : t("nounitsfound")}</Typography>
                                    </Box>
                                    <Box>
                                          <ExpandMoreIcon style={{ color: '#091B29', marginTop: '5px' }} />
                                    </Box>

                              </Box>
                        }
                        goBack={() => history.goBack()}
                  />

                  <div>
                        {loading ? (
                              <LoderSimmer count={10} />
                        ) : (
                              <>
                                    {data.length > 0 ? (
                                          <>
                                                <InfiniteScroll
                                                      dataLength={data.length}
                                                      next={fetchMoreData}
                                                      hasMore={true}
                                                      height={size?.height - 120}
                                                >
                                                      {data?.map((val) => {
                                                            return <UtilitiesList data={val} unitId={selected?.value} />;
                                                      })}
                                                </InfiniteScroll>
                                          </>
                                    ) : (
                                          <Typography className={classes.noData}>
                                                {t("Datanotfound")}
                                          </Typography>
                                    )}
                              </>
                        )}



                        {/* drawer */}
                        <Hidden smUp>
                              <DrawerComponent onClose={closerDrawer} open={open}
                                    component={
                                          <div>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                      <Box>
                                                            <Typography className={classes.title}>{t("Unit")}</Typography>
                                                      </Box>
                                                      <Box>
                                                            <IconButton size="small" onClick={closerDrawer}>
                                                                  <CloseIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Box>
                                                <FormControl component="fieldset" >
                                                      <RadioGroup
                                                            aria-label="gender"
                                                            defaultValue="female"
                                                            name="radio-buttons-group"
                                                            style={{ padding: "8px" }}
                                                      >
                                                            {value?.map((val) => {
                                                                  return (
                                                                        <>

                                                                              <FormControlLabel
                                                                                    value={val?.value}
                                                                                    control={
                                                                                          <Radio
                                                                                                checked={
                                                                                                      val?.value === selected?.value
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                          />
                                                                                    }
                                                                                    label={val?.label}
                                                                                    onChange={() => SwitchUnit(val)}
                                                                              />
                                                                              {/* <Divider /> */}
                                                                        </>
                                                                  );
                                                            })}
                                                      </RadioGroup>
                                                </FormControl>
                                          </div>
                                    }

                              />
                        </Hidden>

                        {/* dialog */}
                        <Hidden smDown>
                              <AlertDialog onClose={closerDrawer} open={open}
                                    component={
                                          <div>
                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                      <Box>
                                                            <Typography className={classes.title}>{t("Unit")}</Typography>
                                                      </Box>
                                                      <Box>
                                                            <IconButton size="small" onClick={closerDrawer}>
                                                                  <CloseIcon />
                                                            </IconButton>
                                                      </Box>
                                                </Box>
                                                <FormControl component="fieldset" >
                                                      <RadioGroup
                                                            aria-label="gender"
                                                            defaultValue="female"
                                                            name="radio-buttons-group"
                                                            style={{ padding: "8px" }}
                                                      >
                                                            {value?.map((val) => {
                                                                  return (
                                                                        <>

                                                                              <FormControlLabel
                                                                                    value={val?.value}
                                                                                    control={
                                                                                          <Radio
                                                                                                checked={
                                                                                                      val?.value === selected?.value
                                                                                                            ? true
                                                                                                            : false
                                                                                                }
                                                                                          />
                                                                                    }
                                                                                    label={val?.label}
                                                                                    onChange={() => SwitchUnit(val)}
                                                                              />
                                                                              {/* <Divider /> */}
                                                                        </>
                                                                  );
                                                            })}
                                                      </RadioGroup>
                                                </FormControl>

                                          </div>
                                    }

                              />
                        </Hidden>
                  </div>
            </Container>
      );
};
export default withNamespaces("utilites")(UtilitiesDetails)