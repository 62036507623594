import * as React from "react"

const UnpaidInvoices = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.158} height={18} {...props}>
    <path
      data-name="Path 2698"
      d="M3.158 10h9"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={3}
    />
    <path
      data-name="Path 2699"
      d="M3.158 13h9"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={3}
    />
    <path
      data-name="Path 2697"
      d="M3.046 6.419h9"
      fill="#fff"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
    />
    <path
      data-name="icons8-bill (1)"
      d="M.723 0A.711.711 0 0 0 0 .71v14.684A2.616 2.616 0 0 0 2.605 18h9.947a2.616 2.616 0 0 0 2.605-2.605V.71a.711.711 0 0 0-1-.649L12.3.887 10.21.051a.711.711 0 0 0-.527 0l-2.1.842-2.1-.842a.711.711 0 0 0-.527 0l-2.09.836L1 .061A.711.711 0 0 0 .723 0Zm2.355 5.684h9a.711.711 0 0 1 0 1.421h-9a.711.711 0 0 1 0-1.421Zm0 4.263h5.21a.711.711 0 0 1 0 1.421h-5.21a.711.711 0 0 1 0-1.421Zm8.053 0h.947a.711.711 0 0 1 0 1.421h-.947a.711.711 0 1 1 0-1.421Zm-8.053 2.842h5.21a.711.711 0 0 1 0 1.421h-5.21a.711.711 0 1 1 0-1.421Zm8.053 0h.947a.711.711 0 0 1 0 1.421h-.947a.711.711 0 1 1 0-1.421Z"
      fill={props?.color}
    />
  </svg>
)

export default UnpaidInvoices
