import { Box, Grid, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px 0px 16px 0px",
    borderRadius: "4px",
    boxShadow: "0px 3px 30px #5C86CB2E",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  topCard: {
    padding: "12px",
  },
  bottomCard: {
    padding: "12px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: FontFamilySwitch().bold,
    color: "#091B29",
    textAlign: "left",
  },
  subTitle1: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
  },
  subTitle2: {
    fontSize: "12px",
    color: "#4E5A6B",
    textAlign: "left",
    textTransform: "capitalize",
  },
  periodStyle: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  tagStyle: {
    backgroundColor: "#5078E11E",
    borderRadius: "4px",
    padding: "3px 10px 3px 10px",
  },
  tagTextStyle: {
    fontSize: "12px",
    color: "#091B29",
    fontWeight: "bold",
    textAlign: "left",
  },
  extraDetailsStyle: {
    fontSize: "12px",
    color: "#4E5A6B",
  },
  horizontalDividerStyle: {
    backgroundColor: "#E4E8EE",
    height: "1px",
  },
  verticalDividerStyle: {
    backgroundColor: "#E4E8EE",
    width: "1px",
    height: "20px",
  },
  iconButtonStyle: {
    backgroundColor: "#F2F4F7",
    border: "1px solid #E4E8EE",
    height: "32px",
    width: "32px",
  },
  cardImage: {
    border: "1px solid #5078E138",
    padding: "14px",
    borderRadius: "4px",
  },
  total: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
  filter: {
    borderRadius: "4px",
    border: "1px solid #c1c1c1",
    color: "black",
  },
  filterdata: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
  },
  filterdataChild: {
    paddingLeft: "12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },

  filterleft: {
    borderRight: "1px solid #c1c1c1",
    height: "78vh",
  },
  active: {
    fontSize: "12px",
    padding: "2px 6px",
    color: "#5078E1",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
  },
  btncontainer: {
    padding: "14px",
    backgroundColor: "#F5F7FA",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
  },
  cancelbtn: {
    padding: "12px",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
    borderRadius: "4px",
  },
  filterdataActive: {
    padding: "8px 12px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    cursor: "pointer",
    backgroundColor: theme.palette.background.secondary,
  },
  date: {
    display: "block",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
  },
}));
export const ExperiedAgreements = (props) => {
  const classes = useStyles();

  const downloadURI = () => {
    saveAs(props?.asset[0].url, `Agreement ${props.data.agreement_no}`);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid item xs={12} className={classes.root}>
          <Grid container alignItems="center" className={classes.topCard}>
            <Grid sm={1.8} xs={2.5}>
              <img
                src="/images/icons8-agreement.svg"
                className={classes.cardImage}
                alt="altimage"
              />
            </Grid>
            <Grid sm={0.2} xs={0} />

            <Grid sm={8} xs={7.5}>
              <Typography className={classes.title}>
                {props.data.agreement_no}
              </Typography>
              <Box height="6px" />
              <Grid container alignItems="center">
                <Typography className={classes.date}>Start Date</Typography>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <Typography className={classes.date}>
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;End Date
                </Typography>
              </Grid>
              <Grid container alignItems="center">
                <img src="/images/icons8-calendar (5)-1.svg" alt="start date" />{" "}
                <Typography className={classes.subTitle2}>
                  &nbsp;&nbsp;
                  {moment(props.data.lease_start_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY")}{" "}
                  &nbsp;&nbsp; &nbsp;&nbsp;
                </Typography>
                <img src="/images/icons8-calendar (5)-1.svg" alt="start date" />{" "}
                <Typography className={classes.subTitle2}>
                  &nbsp;&nbsp;
                  {moment(props.data.lease_end_date)
                    .tz(moment.tz.guess())
                    .format("DD MMM YY")}
                </Typography>
              </Grid>
              <Box height="6px" />
            </Grid>
            <Grid
              container
              sm={2}
              xs={1}
              justifyContent="right"
              alignItems="center"
            >
              <IconButton
                onClick={downloadURI}
                className={classes.iconButtonStyle}
              >
                <img src="/images/downlodeblue.svg" alt="download" />
              </IconButton>
            </Grid>
          </Grid>
          <Box className={classes.horizontalDividerStyle} />
          <Grid
            container
            className={classes.bottomCard}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid
              container
              sm={4}
              xs={4}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <img src="/images/unitss.svg" alt="units" />
              <Box width="8px" />
              <Typography className={classes.extraDetailsStyle}>
                {props?.property?.length +
                  (props?.property?.length === 1 ? " Property" : " Properties")}
              </Typography>
            </Grid>
            <Box className={classes.verticalDividerStyle} />
            <Grid
              container
              sm={4}
              xs={4}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <img src="/images/icons8-company.svg" alt="unit" />
              <Box width="8px" />
              <Typography className={classes.extraDetailsStyle}>
                {props?.unit?.length +
                  (props?.unit?.length === 1 ? " Unit" : " Units")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
