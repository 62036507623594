import { Avatar, Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { AuthContext } from '../../../contexts'
import { DetailHeadCardStyle } from './style'

export const DetailHeadCard = ({ open = "", data = {} }) => {
    const auth = React.useContext(AuthContext)
    const classes = DetailHeadCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Box>
            <Stack direction={"row"} p={1} spacing={2}>
                <Avatar className={classes.avatar} src={data?.amenity_assets}></Avatar>
                <Stack direction={"row"} justifyContent={"space-between"} alignItems="center" width={"100%"}>
                    <Stack>
                        <Typography className={classes.title}>{data?.amenities_name}</Typography>
                        <Typography className={classes.content} noWrap>{data?.booking_id}</Typography>
                    </Stack>
                    <Stack>
                        <Typography className={classes.title} sx={{ textAlign: "right" }}>
                            {data?.currency} { (data?.paid_amount !== null && data?.no_of_slots !== null) ? data?.paid_amount / data?.no_of_slots : 0}
                        </Typography>
                        {
                            open ?
                                <Typography className={classes.paid}>Added to unit Invoice</Typography> :
                                <Typography className={classes.paid}>{data?.paid_on ? "Paid" : "Unpaid"}</Typography>
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}