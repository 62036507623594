import { AlertProps, NetWorkCallMethods } from "../utils";
import { NetworkCall } from "../networkcall";
import { config } from "../config";
import moment from 'moment';
import { add } from "date-fns";
import { AuthContext } from "../contexts";
import React from "react";

export const mapResult = (map) => {
      let updateData = {
            latitude: map?.lat,
            longitude: map?.lng
      };
      if (map) {
            let keys = ['doorNo', 'addressLineOne', 'addressLineTwo', 'landmark', 'area', 'district', 'city', 'state', 'country', 'pincode', 'zipcode'];
            let findAddress = map?.result?.address_components;
            keys?.map(_ => {
                  switch (_) {
                        case 'doorNo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["premise"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineOne':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["establishment", "street_number"]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'addressLineTwo':
                              updateData[_] = findAddress?.find(_ => _?.types?.some(type => ["neighborhood", "route",]?.includes(type)))?.long_name ?? ''
                              break;
                        case 'landmark':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('landmark'))?.long_name ?? ''
                              break;
                        case 'area':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes("political", "locality"))?.long_name ?? ''
                              break;
                        case 'district':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'city':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_2'))?.long_name ?? ''
                              break;
                        case 'state':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('administrative_area_level_1'))?.long_name ?? ''
                              break;
                        case 'country':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('country'))?.long_name ?? ''
                              break;
                        case 'pincode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        case 'zipcode':
                              updateData[_] = findAddress?.find(_ => _?.types?.includes('postal_code'))?.long_name ?? ''
                              break;
                        default:
                              return null
                  }
                  return _
            })
      }

      return updateData
}

// sigle images
export const SingleImageUpload = async (data,alert) => {     
      let bytes = data?.size
      let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
      if (finalSize <= 2.00) {
            const formData = new FormData();
            formData.append("files", data);
            formData.append("tenantId", `${config.tenantid}`);
            let image = await NetworkCall(
                  `${config.api_url}/file-upload`,
                  NetWorkCallMethods.post,
                  formData,
                  null,
                  true,
                  false
            )

            return image.data.fileUrls[0].url
      }
      else {
            alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please upload less then 2 MB file",
            });
            return false
      }

}

// multiple images
export const MultiImageUpload = async (data, { companyId, file_meta },alert) => {
      let bytes = data?.size

      let finalSize = Number((bytes / (1024 * 1024)).toFixed(2))
      if (finalSize <= 2.00) {

            const formData = new FormData();
            formData.append("files", data);
            formData.append("tenantId", `${config.tenantid}`);
            formData.append("companyId", `${companyId}`);

            let image = await NetworkCall(
                  `${config.api_url}/file-upload`,
                  NetWorkCallMethods.post,
                  formData,
                  null,
                  true,
                  false
            )
            if (!file_meta) {
                  return {
                        url: image.data.fileUrls[0].url,
                  }
            }
            else {
                  return {
                        url: image.data.fileUrls[0].url,
                        name: data?.name?.split(".")?.[0],
                        size: formatSizeUnits(data?.size ?? 0),
                        type: data?.name?.split(".")?.[1],
                  };
            }
      } else {

            alert.setSnack({
                  ...alert,
                  open: true,
                  severity: AlertProps.severity.error,
                  msg: "Please upload less then 2 MB file",
            });
            return false
      }

};


// access companies
export const companyDetails = JSON.parse(localStorage.getItem('accessType'))


/**
 * Function to get ENUM dynamically
 * @param enumTypes `[String]`
 * @returns `Object`
 */
export const enumSelect = async (enumTypes, fullResponse = false) => {
      let result;

      const payload = { enumName: enumTypes, };

      await NetworkCall(
            `${config.api_url}/enum`,
            NetWorkCallMethods.post,
            payload, null, true, false
      ).then((res) => {
            // if (fullResponse) {
            //       result = res?.data?.data
            // } else {
            //       let list = Object.keys(res?.data?.data)?.map((val) => {
            //             let enumList=res?.data?.data[val];
            //             return {
            //                   data: enumList?.map((i)=>i?.value),
            //                   type:[val]
            //             }
            //       })

            //       const enums = list.reduce((obj, cur) => ({...obj, [cur.type]: cur.data}), {})

            //       result = enums
            // }
            result = res?.data?.data
      }).catch((err) => {
            console.log(err)
      })

      return result;
};


export const enumCatagory = {
      notifications_content_type: 'notifications_content_type',
      security_status_enum_type: 'security_status_enum_type',
      security_request_type: 'security_request_type',
      delivery_status_type: 'delivery_status_type',
      general_maintenance_type: 'general_maintenance_type',
      swift_type: 'swift_type',
      payment_mode: 'payment_mode'
}

// get types
export const keyMapSource = ({ enumType }) => {
      return enumType.map((item, index) => {
            return {
                  [`type${index + 1}`]: item?.value
            }
      }).reduce((old, item) => (
            { ...old, ...item }
      ), {})

}

export const FontFamilySwitch = () => {
      const auth = React.useContext(AuthContext)
      const language = auth?.auth?.auth?.language
      switch (language) {
            case "en":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "zh":
                  return {
                        regular: "'NotoSansSC-Regular'",
                        bold: "'NotoSansSC-Bold'",
                        semiBold: "'NotoSansSC-SemiBold'",
                        extraBold: "'NotoSansSC-ExtraBold'"
                  }
            case "hi":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "es":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "fr":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "ar":
                  return {
                        regular: "'NotoSansArabic-Regular'",
                        bold: "'NotoSansArabic-Bold'",
                        semiBold: "'NotoSansArabic-SemiBold'",
                        extraBold: "'NotoSansArabic-ExtraBold'"
                  }
            case "ru":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "pt":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "it":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "fil":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "id":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            case "ta":
                  return {
                        regular: "'NotoSansTamil-Regular'",
                        bold: "'NotoSansTamil-Bold'",
                        semiBold: "'NotoSansTamil-SemiBold'",
                        extraBold: "'NotoSansTamil-ExtraBold'"
                  }
            case "te":
                  return {
                        regular: "'NotoSansTelugu-Regular'",
                        bold: "'NotoSansTelugu-Bold'",
                        semiBold: "'NotoSansTelugu-SemiBold'",
                        extraBold: "'NotoSansTelugu-ExtraBold'"
                  }
            case "kn":
                  return {
                        regular: "'NotoSansKannada-Regular'",
                        bold: "'NotoSansKannada-Bold'",
                        semiBold: "'NotoSansKannada-SemiBold'",
                        extraBold: "'NotoSansKannada-ExtraBold'"
                  }
            case "ml":
                  return {
                        regular: "'NotoSansMalayalam-Regular'",
                        bold: "'NotoSansMalayalam-Bold'",
                        semiBold: "'NotoSansMalayalam-SemiBold'",
                        extraBold: "'NotoSansMalayalam-ExtraBold'"
                  }
            case "de":
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
            default:
                  return {
                        regular: "'OpenSans-Regular', 'Roboto-Regular', Arial, sans-serif",
                        bold: "'OpenSans-Bold', 'Roboto-Bold', Arial, sans-serif",
                        semiBold: "'OpenSans-SemiBold', 'Roboto-SemiBold', Arial, sans-serif",
                        extraBold: "'OpenSans-ExtraBold', 'Roboto-ExtraBold', Arial, sans-serif"
                  }
      }
}
// get size of image
export function formatSizeUnits(bytes) {
      if (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
      else if (bytes >= 1048576) { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
      else if (bytes >= 1024) { bytes = (bytes / 1024).toFixed(2) + " KB"; }
      else if (bytes > 1) { bytes = bytes + " bytes"; }
      else if (bytes === 1) { bytes = bytes + " byte"; }
      else { bytes = "0 bytes"; }
      return bytes;
}
export const returnMomentFormat = (val) => moment(val).tz(moment.tz.guess()).format("DD MMM YY");

export const getDate = (type, date, value) => {

      let copyDate = new Date(JSON.parse(JSON.stringify(date)))
      switch (type) {
            case "Day":
                  return add(new Date(copyDate), { days: value })
            case "Month":
                  return add(new Date(copyDate), { months: value })
            case "Year":
                  return add(new Date(copyDate), { years: value })
            case "Monthly":
                  return add(new Date(copyDate), { months: value })
            case "Yearly":
                  return add(new Date(copyDate), { years: value })
            default:
                  return "not edited"
      }
}

export const getDateValue = (value, sendEndDate, gracePeriod = 0, subtract) => {
      const { period = null, input = null, date = null } = value ?? {};
      if (period && input && date) {
            let val = getDate(period, date, Number(input));
            if (gracePeriod && gracePeriod > -1) {
                  val = getDate('Day', val, Number(gracePeriod));
            }
            if (sendEndDate) {
                  if (subtract) {
                        return moment(val).subtract(1, "days")?._d
                  } else {
                        return val
                  }

            }
            return `${returnMomentFormat(date)} - ${returnMomentFormat(moment(val).subtract(1, "days"))}`
      }
      return ''
}


export const getTotal = (data) => {
      // get total count
      let totalCountofParkingslot = data.reduce(function (prev, current) {
            return prev + +current.value;
      }, 0);

      return totalCountofParkingslot;
};


// find a difference between two days

export function timeDiffCalc(dateFuture, dateNow, day) {
      let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

      // calculate days
      const days = Math.floor(diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;

      // calculate hours
      const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
      diffInMilliSeconds -= hours * 3600;

      // calculate minutes
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;

      let difference = "";
      if (days > 0) {
            difference += days === 1 ? days : days;
      }

      difference +=
            hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;

      difference +=
            minutes === 0 || hours === 1 ? `${minutes} minutes` : `${minutes} minutes`;
      if (day) {
            return days === 1 ? days ?? 0 : days;
      } else {
            return difference;
      }
}


export function dateDiffInDays(a, b) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

      return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}


export const enumName = {
      post_dated_cheques: "Post Dated Cheques"
}

export const vehicleTypeBGSVG = {
      "FOUR-WHEELER": "/images/parkingSlotCarBGSVG.svg",
      "TWO-WHEELER": "/images/parkingSlotBikeBGSVG.svg",
      "BI-CYCLE": "/images/parkingSlotBicycleBGSVG.svg"
}

export const vehicleTypeSVG = {
      "FOUR-WHEELER": "/images/carSVG.svg",
      "TWO-WHEELER": "/images/bikeSVG.svg",
      "BI-CYCLE": "/images/bicycleSVG.svg"
}