/* eslint-disable array-callback-return */
import { Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React from "react";
import { useHistory } from "react-router-dom";
import { TextBox, TitleBar, DatePickerTimeNew } from "../../components";
// import DateWithTime from "../../components/datewithtime";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_GATES, GET_PROPERTY_UNITID } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, LocalStorageKeys, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useApolloClient } from "@apollo/client";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F5FA",
    padding: "0px",
  },
  input: {
    marginTop: "10px",
  },
  applybtn: {
    padding: "12px",
    borderRadius: "4px",
    textTransform:"capitalize"
  },
}));
const InitialState = {
  unit: "",
  name: "",
  remarks: "",
  startDate: new Date(),
  gate: {
    value: "Any",
    label: "Any",
  },
  error: {
    unit: "",
    name: "",
    remarks: "",
    startDate: "",
    gate: "",
  },
};
const CreateDelivery = ({ t }) => {
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const [data, setdata] = React.useState({ ...InitialState });
  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();
  // const [getunit, setgetUnit] = React.useState(null);
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [disable, setDisable] = React.useState(null);
  const [property, setProperty] = React.useState({});
  const [agreementList, setAgreementLists] = React.useState([]);
  const anyOption = {
    value: "Any",
    label: "Any",
  }
  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "gate":
        if (!data?.unit?.value?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_GATES(),
          variables: {
            property_id: property?.id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: offset === 0 ? [anyOption, ...result?.data?.access_gates_master] : [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };

  const getUnitIDS = () => {
    const temp_decoded = jwt_decode(authToken);
    const userId = temp_decoded.id;
    const data = {
      tenantId: `${config.tenantid}`,
      userId: userId,
    };
    NetworkCall(
      `${config.api_url}/community/community_units`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        let data = response?.data?.data?.map(x => x?.agreement_unit).flat()
        const _tempUnitList = data?.map(
          (_uitem) => {
            let _u;
            _u = {
              label: _uitem?.name ?? "",
              value: _uitem?.id ?? "",
              unit_id: _uitem?.unit_id ?? "",
            };

            return _u;
          }
        );
        setAgreementLists(_tempUnitList);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  }
  const getpropertyUnitid = (v) => {
    client.query({
      query: GET_PROPERTY_UNITID(),
      fetchPolicy: 'network-only',
      variables: {
        unit_id: [v?.unit_id],
        offset: 0,
        limit: 10,
        search: "",
      }
    }).then((res) => {
      setProperty(res?.data?.unit?.[0]?.property)
      // setdata({...data , gate: ""})
    }).catch((err) => {

    })
  }
  React.useEffect(() => {
    getUnitIDS()
    // eslint-disable-next-line
  }, []);
  const updateState = (key, value) => {
    if (key === 'unit') {
      getpropertyUnitid(value)
    }
    let error = data.error;
    error[key] = "";
    setdata({ ...data, [key]: value, error });
  };
  const isIamValideTostep1 = () => {
    let isValid = true;
    let error = data.error;
    //Checking unit
    if (data.unit.length === 0) {
      isValid = false;
      error.unit = t("unitisRequired");
    }
    //Checking name
    if (data.name.length === 0) {
      isValid = false;
      error.name = t("NameisRequired");
    }
    //Checking startDate
    if (data.startDate.length === 0) {
      isValid = false;
      error.startDate = t("StartDateisRequired");
    }
    //Checking remarks
    if (data.remarks.length === 0) {
      isValid = false;
      error.remarks = t("RemarksisRequired");
    }
    //Checking gate
    if (data.gate.length === 0) {
      isValid = false;
      error.gate = t("GateisRequired");
    }
    setdata({ ...data, error });
    return isValid;
  };
  const createPass = () => {
    if (isIamValideTostep1()) {
      setDisable(true);
      const payload2 = {
        tenantId: `${config.tenantid}`,
        pass_type: "DCR",
        start_date: data?.startDate,
        gate_no: data?.gate?.value === "Any" ? undefined : data?.gate?.value,
        user_profile_id: localStorage.getItem("userProfileId"),
        property_id: property?.id,
        unit_id: data?.unit?.unit_id,
        agreement_unit_id: data?.unit?.value,
        remarks: data?.remarks,
        description: data?.name,
        name: data?.name,
      };
      NetworkCall(
        `${config.api_url}/security/create`,
        NetWorkCallMethods.post,
        payload2,
        null,
        true,
        false
      )
        .then((response) => {
          setDisable(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: t("OrderSuccessfullyCreated"),
          });
          history.push(Routes.delivery);
        })
        .catch((err) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });

          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: err,
          });
          setDisable(false);
        });
    } else {
      return false;
    }
  };
  let boxParent = document.querySelector('#createDiv')?.offsetHeight;
  let fullSize = size?.height - 135

  return (
    <Container className={classes.root} maxWidth="sm">
      <TitleBar
        text={t("CreateDeliveryRequirement")}

        goBack={() => history.push(Routes.delivery)}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ height: size?.height - 135, overflow: "auto" }}
        >
          <div style={{ padding: "12px" }} id="createDiv">
            <div className={classes.input}>
              <SelectBox
                isRequired
                label={t("ChooseUnit")}
                placeholder={t("SelectUnit")}
                value={data.unit}
                onChange={(value) => {
                  updateState("unit", value);
                }}
                options={agreementList}
                isError={data?.error?.unit?.length > 0}
                errorMessage={data?.error?.unit}
              />
            </div>

            <div className={classes.input}>
              <TextBox
                isRequired
                color
                label={t("DeliveryDescriptionORName")}
                placeholder={t("DeliveryDescriptionORName")}
                value={data.name}
                onChange={(e) => updateState("name", e.target.value)}
                isError={data?.error?.name?.length > 0}
                errorMessage={data?.error?.name}
              />
            </div>
            <div className={classes.input}>
              <TextBox
                isRequired
                color
                label={t("DeliveryRemarks")}
                placeholder={t("DeliveryRemarks")}
                value={data.remarks}
                onChange={(e) => updateState("remarks", e.target.value)}
                isError={data?.error?.remarks?.length > 0}
                errorMessage={data?.error?.remarks}
                multiline
              />
            </div>

            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item xs={12}>
                <DatePickerTimeNew
                  isRequired
                  isTime
                  label={t("TentativedeliverydateANDtime")}
                  placeholder={t("TentativedeliverydateANDtime")}
                  value={data.startDate}
                  onChange={(value) => updateState("startDate", value)}
                  isError={data?.error?.startDate?.length > 0}
                  errorMessage={data?.error?.startDate}
                  minDate={new Date()}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ marginTop: "4px" }}>
              <Grid item xs={12}>
                <SelectBox
                  isRequired
                  label={t("ChooseGate")}
                  placeholder={t("ChooseGate")}
                  value={data.gate}
                  onChange={(value) => updateState("gate", value)}
                  isError={data?.error?.gate?.length > 0}
                  errorMessage={data?.error?.gate}
                  isPaginate
                  key={JSON.stringify(data.unit)}
                  loadOptions={(search, array) => loadOptions(search, array, "gate")}
                  loading={loading1 === "gate"}
                  debounceTimeout={800}
                  menuPlacement={boxParent + 10 > fullSize ? "top" : "bottom"}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              padding: "12px",
              backgroundColor: "white",
              boxShadow: "0px -1px 6px #00000021",
            }}
          >
            <Button
              fullWidth
              className={classes.applybtn}
              variant="contained"
              onClick={createPass}
              disabled={disable}
            >
              {t("Submit")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withNamespaces("deliveryInfo")(CreateDelivery)