import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Badge, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import FileIcon from "../../assets/fileIcon";
import TranslateIcon from "../../assets/translateIcon";
import { AuthContext } from "../../contexts";
import { FontFamilySwitch, LanguageOptions } from "../../utils";
import { AgreementList } from "../agreementlist";
import { DialogDrawer } from "../dialogDrawer";
const useStyles = makeStyles((theme) => ({
  grow: {},
  appBar: {
    background: theme.palette.background.tertiary1,
    padding: "12px 20px",
    minHeight: "54px",
    boxShadow: "0px 2px 6px #00000014"
  },
  navbarimg: {
    height: "36px",
    marginTop: "4px"

  },
  id: {
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().extraBold,
    fontSize: "14px",
  },
  containers: {
    "&.MuiContainer-root": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    overflow: "hidden",
  },
  agreement: {
    backgroundColor: theme.palette.background.secondary,
    borderRadius: "4px",
    padding: "4px 8px",
    marginTop: "4px",
    cursor: "pointer",
  },
  exporie: {
    backgroundColor: "#5078E114",
    padding: "2px 4px",
    borderRadius: "4px",
    color: theme.palette.primary.main,
    fontSize: "10px",
    fontFamily: FontFamilySwitch().bold,
  },
}));
export const TopNavBars = (props) => {
  const auth = React.useContext(AuthContext)
  const classes = useStyles(props);
  const [openLanguage, setOpenLanguage] = React.useState(false)
  const [selectedLanguage, setSelectedLanguage] = React.useState(LanguageOptions?.[0])
  const clientLogo = localStorage.getItem("clientLogo")
  const logo = JSON.parse(localStorage.getItem("accessType")) ? JSON.parse(localStorage.getItem("accessType")).filter((val) => val.name === "Tenant").find((item) => item?.company_logo !== null) : null
  const updateLanguage = (data) => {
    setSelectedLanguage(data)
    saveLanguage(data)
  }

  const openLanguageSwitcher = () => {
    let getselectedlag = props?.i18n.language
    setSelectedLanguage(LanguageOptions.find((val) => val.value === getselectedlag) ?? LanguageOptions[0])
    setOpenLanguage(true)
  }

  const saveLanguage = (language) => {
    props?.i18n.changeLanguage(language?.value);
    setOpenLanguage(false)
    auth.setAuth({
      ...auth,
      auth: {
        language: language?.value
      }
    })
  };

  return (
    <Grid container className={classes.appBar} alignItems={"center"}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
          <img
                    src="/images/rafallogo.svg"
                    alt="logo"
                className={classes.navbarimg}
              />

          </Box>
          {props?.note && (

            <Stack
              spacing={1}
              alignItems={"center"}
              direction="row"
            >
              <IconButton
                size="small"
                onClick={props?.document}
              >
                <FileIcon />
              </IconButton>

              <IconButton
                size="small"
                onClick={props.handleClicknote}
              >
                {props?.new ? (
                  <Badge color="error" variant="dot">
                    <img src="/images/notificationICON.svg" alt="note" />
                  </Badge>
                ) : (
                  <img src="/images/notificationICON.svg" alt="note" />
                )}
              </IconButton>
              <IconButton
                size="small"
                onClick={openLanguageSwitcher}
              >
                <TranslateIcon />
              </IconButton>

            </Stack>
          )}

        </Box>
      </Grid>

      <Grid item xs={12}>
        {props?.home && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.agreement}
            onClick={props.handleClick}
          >
            <Box flexGrow={1}>
              <Typography className={classes.id}>
                {props?.agreementList?.map((val) => {
                  return (
                    <>
                      {val?.id === props?.agreement?.id
                        ? val?.agreement_no
                        : ""}
                    </>
                  );
                })}
              </Typography>
            </Box>
            <Box>
              {props?.agreementList?.map((val) => {
                return (
                  <>
                    {val?.id === props?.agreement?.id ? (
                      <>
                        {val?.expiry_on && (
                          <Typography className={classes.exporie}>
                            &nbsp;&nbsp;{props?.expire}&nbsp;
                            {moment(val?.expiry_on)
                              .tz(moment.tz.guess())
                              .format("DD MMM YY")}
                            &nbsp;&nbsp;
                          </Typography>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={props.handleClick}
                style={{
                  color: "black",
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Grid>



      {
        props?.isUtilities &&
        <Grid item xs={12}>
          {
            props?.component
          }
        </Grid>
      }

      <DialogDrawer
        height={"400px"}
        open={openLanguage}
        header={"Choose Language"}
        padding={"0px 16px 16px 16px"}
        component={
          <AgreementList
            languageOptions={LanguageOptions}
            selectedLanguage={selectedLanguage}
            updateLanguage={updateLanguage}
          />
        }
        onClose={() => setOpenLanguage(false)} />

    </Grid>
  );
};