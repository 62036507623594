import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {  Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React, { useState } from "react";
import { withNamespaces } from "react-i18next";
import { Document } from "../../../assets";
import { DialogDrawer } from "../../../components";
import {
  TypeOfRenewal,
  CustomForm,
  AsPerAgreement,
  RequestForRenewal,
} from "../renewalComponents";
import { timeDiffCalc , dateDiffInDays, FontFamilySwitch} from "../../../utils";

const useStyles = makeStyles((theme) => ({
  shedule: {
    backgroundColor: "transparent",
  },
  sheduleroot: {
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundColor: "white",
    backgroundImage: `url("/images/renewbg.svg")`,
    cursor: "pointer",
    backgroundRepeatX: "no-repeat",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
    // marginBottom: "16px",
  },
  sheduleroot2: {
    backgroundColor: "white",
    padding: "6px 6px",
    borderRadius: theme.palette.borderRadius,
    color: "black",
    backgroundImage: `url("/images/Mask Group violate 40.svg")`,
    // marginBottom: "16px",
    backgroundRepeatX: "no-repeat",
    fontFamily: FontFamilySwitch().semiBold,
    backgroundPositionX: "100%",
    border: "1px solid #E4E8EE",
  },
  arrow: {
    fontSize: "20px",
    color: "black",
  },
  inprogress: {
    backgroundColor: theme.palette.success.main,
    padding: "0px 12px",
    fontSize: "12px",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  renwelRequest: {
    color: theme?.typography?.color?.primary,
    fontSize: "15px",
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "4px",
  },
  expiry: {
    color: theme?.typography?.color?.secondary,
    fontSize: "12px",
    fontFamily: FontFamilySwitch().regular,
  },
  chip: {
    padding: "3px 6px",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "10px",
  },
  arrowIcon: {
    fontSize: "12px",
  },
  card: {
    backgroundColor: theme.palette.primary.contrastText,
    padding: "12px",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
const RequsetCard = (props) => {
  const { t } = props;
  const classes = useStyles();
  const [state, setState] = useState({
    dialog: false,
    step: 0,
    show:false
  });

  const d = new Date();
  let day = d.getDate();
  //let month = d.getMonth();
  // var year = d.getFullYear();
  // let leasemonth = props?.agreementDatas?.lease_end_date?.slice(6, 7);
  // let leaseyear = props?.agreementDatas?.lease_end_date?.slice(0, 4);
  let differenceDay = dateDiffInDays(new Date() , new Date(props?.agreementDatas?.lease_end_date))

  let difference_btn_date = timeDiffCalc(
    new Date(),
    new Date(props?.agreementDatas?.lease_end_date),
    day
  );


  const updateState = (k, v) => {
    setState({ ...state, [k]: v });
  };
  const cardClick = () => {
    setState({ ...state, step: 0, dialog: true });
  };

  const handleClick = (value) => {
    setState({ ...state, step: value, dialog: true });
  };
  const component = (title, sub, chip, status, show , color) => {
    return (
      <Box
        className={classes.card}
        onClick={() => (show ? cardClick("dialog", true) : false)}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
        <Document />
        <Box width={"10px"} />
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              <Typography className={classes.renwelRequest} noWrap>
                {title}
              </Typography>
              <Typography className={classes.expiry}>
                {sub === true
                  ? `${props.t("expire_in")} ${difference_btn_date} Days`
                  : sub}
              </Typography>
            </Box>
            <Box width={"15px"} />
            {chip && (
              <Box>
                <Typography
                  className={classes.chip}
                  style={color}
                >
                  {chip}
                </Typography>
              </Box>
            )}
          </Box>
         
        </Box>
        <Box ml={1}>
            <ArrowForwardIosIcon className={classes.arrowIcon} />
          </Box>
      </Box>
    );
  };

  const reloadFunction =()=>{
    props?.reload()
    updateState("show" , true)
  }
  const drawerComponent = {
    0: (
      <TypeOfRenewal
        language={props?.language}
        t={props?.t}
        handleClick={handleClick}
      />
    ),
    2: (
      <CustomForm
        language={props?.language}
        t={props?.t}
        agreement_id={props?.agreementDatas?.id}
        agreement_data={props?.agreementDatas}
        reload={reloadFunction}
      />
    ),
    1: (
      <AsPerAgreement
        language={props?.language}
        t={props?.t}
        agreement_id={props?.agreementDatas?.id}
        reload={reloadFunction}
      />
    ),
    3: <RequestForRenewal language={props?.language} t={props?.t}  agreement_id={props?.agreementDatas?.id} requestDetails={props?.agreementData} termination={props?.status === "Termination" ? true : false}/>,
  };
  const drawerTitle = {
    0: t("Request for renewal"),
    2: t("Custom"),
    1: t("As Per Agreement"),
    3: t("Request for renewal"),
  };

  return (
    <div style={{ marginTop: "18px" }}>
      {props?.status === "Termination" && (
        <Box
        // display="flex"
        // alignItems="center"
        // className={classes.sheduleroot2}
        >
          {component(
            props.t("tentative_termination_on"),
            moment(props?.agreementData?.tentative_termination_date)
              .format("DD MMM YY"),
            "",
            "",
            true,
            "",
          )}
        </Box>
      )}
      {/* {props?.status !== "Termination" && (
        <>
          <Grid item xs={12} sm={12}>
            <>
              <Box
                display="flex"
                alignItems="center"
                className={classes.sheduleroot}
                onClick={props?.status !== "Renewal" && props?.openRenwel}
              >
                <Box>
                  <Avatar className={classes.shedule}>
                    <img
                      src="/images/icons8-schedule black.svg"
                      alt="cal"
                      height="20px"
                    />
                  </Avatar>
                </Box>
                <Box flexGrow={1}>
                  <Typography whiteSpace="nowrap" className={classes.experies}>
                    {props?.status === "Renewal" ? (
                      `${props.t("renewal_request_in")}`
                    ) : (
                      <>
                        &nbsp;{" "}
                        {leaseday - parseInt(day) < 31 &&
                        leaseday - parseInt(day) !== 0 &&
                        leaseday - parseInt(day) > 0 ? (
                          <>
                            {props.t("expire_in")}&nbsp;
                            <b>
                              {" "}
                              {leaseday - parseInt(day)} {props.t("days")}
                            </b>
                          </>
                        ) : (
                          ""
                        )}
                        {leaseday - parseInt(day) === 0 && (
                          <>
                            <b>{props.t("expire_today")}</b>
                          </>
                        )}
                        {leaseday - parseInt(day) < 0 && (
                          <>
                            &nbsp;{" "}
                            {leaseday - parseInt(day) < 31 &&
                            leaseday - parseInt(day) !== 0 &&
                            leaseday - parseInt(day) > 0 ? (
                              <>
                                <b style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {props.t("expire_in")}&nbsp;
                                  {leaseday - parseInt(day)} {props.t("day")}
                                </b>
                              </>
                            ) : (
                              ""
                            )}
                            {leaseday - parseInt(day) === 0 && (
                              <>
                                <b>{props.t("expire_today")}</b>
                              </>
                            )}
                            {leaseday - parseInt(day) < 0 && (
                              <>
                                <b>
                                  {" "}
                                  {props.t("agreement")} {props.t("expired")}
                                </b>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>

                {props?.status === "Renewal" ? (
                  " "
                ) : (
                  <Box>
                    <Typography className={classes.experies}>
                      {props.t("renew")}
                    </Typography>
                  </Box>
                )}

                <Box>
                  {props?.status === "Renewal" ? (
                    <Typography className={classes.inprogress}>
                      {props.t("inprogress")}
                    </Typography>
                  ) : (
                    <IconButton size="small">
                      <ArrowForwardIosIcon className={classes.arrow} />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </>
          </Grid>
        </>
      )} */}

      {/* {difference_btn_date > 30 &&
        component(
          props.t("renewal_request_in"),
          difference_btn_date === 0 ? props.t("expire_today") : true,
          difference_btn_date === 0
            ? props.t("expire_today")
            : props.t("Pending"),
          "",
          true
        )} */}

      {(difference_btn_date < 30 && props?.status !== "Termination" )
        ? differenceDay < 0
          ? component(
              `${props.t("agreement")} ${props.t("expired")}`,
              "",
              "Expired",
              "Rejected",
              false,
              {
                backgroundColor: "#FFECEC",
                color: "#FF4B4B",
              }
            )
          : component(
              props.t("renewal_request_in"),
              difference_btn_date === 0 ? props.t("expire_today") : true,
              props?.agreementData?.status === "Pending" ? props.t("inprogress") : difference_btn_date === 0
                ? props.t("expire_today")
                : props.t("Expiring Soon"),
              "",
              difference_btn_date < 0 ? false : true,
              props?.agreementData?.status === "Pending" ? {
                color: "#FF9340",
                backgroundColor: "#FFF4EB",
              } : {
                backgroundColor: "#FFECEC",
                color: "#FF4B4B",
              }
            )
        : ""}

      {/* RENEWAL REQUEST */}
      <DialogDrawer
        height={"auto"}
        open={state?.dialog}
        header={
          state?.show ? props?.status === "Termination" ? t("Tentative Termination") : t("Request for renewal") : drawerTitle[state?.step]
        }
        padding={"0px"}
        component={<>{drawerComponent[(props?.agreementData?.status === "Pending" || props?.status === "Termination") ? 3 : state?.step]}</>}
        onClose={() => updateState("dialog", false)}
      />
    </div>
  );
};
export default withNamespaces("agreement")(RequsetCard);
