import { Box, Container, Grid, Typography, IconButton, Hidden, Dialog, Drawer } from '@mui/material'
import React from 'react'
import { PropertyDetails } from './components'
import { useHistory } from 'react-router-dom'
import { amenitiesBookingStyle } from './style'
import CloseIcon from '@mui/icons-material/Close';
import { NetworkCall } from '../../networkcall'
import { config } from '../../config'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { AlertContext, AuthContext } from "../../contexts";
import { withNamespaces } from "react-i18next";
import { Routes } from '../../router/routes'

const AmenityBookingNew = ({ t, closeServicePopup = () => false }) => {
    const auth = React.useContext(AuthContext)
    const history = useHistory()
    const classes = amenitiesBookingStyle({ language: auth?.auth?.auth?.language })
    const alert = React.useContext(AlertContext);

    // States
    const [data, setData] = React.useState({
        selectedProperty: {},
        unitDetail: [],
        amenities: [],
        pdOpen: true,
        unitPopup: false,
        properties: []
    })

    React.useEffect(() => {
        getproperty()
        // eslint-disable-next-line
    }, [])

    const closeDrawer = () => {
        setData({
            ...data,
            pdOpen: false
        })
        closeServicePopup()
    }

    const closeUnitPopup = () => {
        setData({
            ...data,
            unitPopup: false,
            pdOpen: true,
        })
        getproperty()
    }

    // Get amenities agaist property
    const getAmenitiesBooking = (propertydata) => {
        const data = {
            property_id: propertydata?.property_id
        };
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/get_amenity_categories`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                getUnitids(propertydata?.property_id, propertydata, response?.data?.data)
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // Get unit agaist property
    const getUnitids = (id, propertydata, amenities) => {
        const data = {
            property_id: id,
        };
        NetworkCall(
            `${config.api_url}/resident/access_unit`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        )
            .then((response) => {
                setData({
                    ...data,
                    selectedProperty: propertydata,
                    unitDetail: response?.data?.data,
                    amenities: amenities,
                    unitPopup: true,
                    pdOpen: true,
                })
            })
            .catch((err) => {
                console.log(err)
            });
    };


    // Get property 
    const getproperty = () => {
        NetworkCall(
            `${config.api_url}/agreement-property-info/get_community`,
            NetWorkCallMethods.post,
            {},
            null,
            true,
            false
        )
            .then((response) => {
                setData({
                    ...data,
                    properties: response?.data?.data?.propertyInfo,
                    unitPopup:false
                })
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    // set Property details 
    const setPropertyDetail = (value) => {
        // getUnitids(data?.property_id)
        getAmenitiesBooking(value)
    }

    // select unit 

    const selectedUnitDetail = (value) => {
        history.push({
            pathname: Routes.amenitiesdetailsNew,
            state: { data: data?.amenities, selectedProperty: data?.selectedProperty, unitData: value },
        });
    }


    return (
        <Container maxWidth="sm">
            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={data?.pdOpen}
                    onClose={closeDrawer}
                >
                    <div>{AmenityBookingPopup(closeDrawer, data?.properties, setPropertyDetail)}</div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={data?.pdOpen}
                    onClose={closeDrawer}
                >
                    <div>{AmenityBookingPopup(closeDrawer, data?.properties, setPropertyDetail)}</div>
                </Drawer>
            </Hidden>

            <Hidden smDown>
                <Dialog
                    className={classes.dialog}
                    maxWidth="sm"
                    fullWidth
                    open={data?.unitPopup}
                    onClose={closeUnitPopup}
                >
                    <div>{UnitPopup(closeUnitPopup, data?.unitDetail, selectedUnitDetail)}</div>
                </Dialog>
            </Hidden>
            <Hidden smUp>
                <Drawer
                    anchor="bottom"
                    className={classes.drawer}
                    open={data?.unitPopup}
                    onClose={closeUnitPopup}
                >
                    <div>{UnitPopup(closeUnitPopup, data?.unitDetail, selectedUnitDetail)}</div>
                </Drawer>
            </Hidden>

        </Container>
    )
}

const AmenityBookingPopup = (closeDrawer, properties, setPropertyDetail) => {
    const classes = amenitiesBookingStyle()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            Property Details
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} p={"16px 16px 0px"}>
                <PropertyDetails properties={properties} setPropertyDetail={setPropertyDetail} />
            </Grid>
        </Grid>
    )
}

const UnitPopup = (closeDrawer, properties, setPropertyDetail) => {
    const classes = amenitiesBookingStyle()
    return (
        <Grid container>
            <Grid item xs={12}>
                <Box
                    className={classes.flexWithPadding}
                >
                    <Box flexGrow={1}>
                        <Typography className={classes.modalTitle}>
                            Unit Details
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton
                            onClick={closeDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} p={"16px 16px 0px"}>
                <PropertyDetails properties={properties} setPropertyDetail={setPropertyDetail} />
            </Grid>
        </Grid>
    )
}

export default withNamespaces("amenitiesBooking")(AmenityBookingNew)