import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import { AmenityImageStyle } from "./style";

export const AmenityImage = (props) => {
  const classes = AmenityImageStyle();
  return (
    <div className={classes.main}>
      <Card className={classes.imageCard} elevation={0}>
        <CardMedia
          className={classes.cardImage}
          image={props?.data?.avatar}
          alt={props?.data?.alt}
        />
        <CardContent className={classes.contentText}>
          <Stack className={classes.firstRow} direction="row">
            <Typography className={classes.title}>{props?.data?.title}</Typography>
            <Typography className={classes.dollar}>{props?.data?.dollar}</Typography>
          </Stack>
          <Stack className={classes.secondRow} direction="row">
            <Typography className={classes.subText}>{props?.data?.subText}</Typography>
            <Typography className={classes.time}>{props?.data?.time}</Typography>
          </Stack>
        </CardContent>
      </Card>
    </div>
  );
};
