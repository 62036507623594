import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { TitleBar } from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import { Routes } from "../../router/routes";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },

  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
  },
  requsettop: {
    backgroundColor: "#5078E11E",
    padding: "4px",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsettitle: {
    marginTop: "10px",
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
  },
  requsetsub: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    marginTop: "8px",
  },
  requsetsubs: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  requsetsubs2: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
  },
  requsetsubs3: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    marginTop: "4px",
    alignSelf: "end",
  },
  screen: {
    borderRadius: "4px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
}));
const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};
const Step4 = (props) => {
  const {t} = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const [disable, setDisable] = React.useState(null);
  const goBack = () => {
    history.push(Routes.requestScreen);
  };

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const userProfileId = localStorage.getItem("userProfileId");

  const saveMaintenanceRequest = () => {
    setDisable(true);
    const payload = {
      tenantId: `${config.tenantid}`,
      type: 2,
      subject: props?.request?.problemTitle,
      description: props?.request?.problemDescription,
      category: props?.request?.category?.value,
      sub_category: props?.request?.subCategory?.value,
      problem_since: props?.request?.problemSince,
      preferred_time: props?.request?.preferredTime,
      contact_name: props?.request?.contactName,
      contact_mobile: props?.request?.primaryNumber,
      mobile_country_code: props?.request?.primaryNumberCountryCode,
      contact_alternative_mobile: props?.request?.alternateNumber?.length > 0 ? props?.request?.alternateNumber : undefined,
      alternative_mobile_country_code: props?.request?.alternateNumberCountry?.length > 0 ? props?.request?.alternateNumberCountry : undefined,
      image_urls: props?.images?.map((x) => {
        return {
          url: x.url,
          "file_meta": {
            "file_name": x?.name ?? null,
            "file_size": x?.size ?? null,
            "file_type":x?.type ?? null
          }
        }
      }),
      units: [props?.request?.unit?.value],
      userProfileId,
      agreement_unit_id: props?.agreeementUnitid,
      propertyId: props?.request?.unit?.property?.value,
      companyId: props?.request?.unit?.property?.company?.value,
      is_active:true
    };
    NetworkCall(
      `${config.api_url}/request/create`,
      NetWorkCallMethods.post,
      payload,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("RequestCreatedsuccessfully"),
        });
        setDisable(false);
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
        setDisable(false);
      });
  };

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        <Grid container className={classes.screen}>
          <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
            <TitleBar

              text={t("NewMaintenanceRequest")}
              goBack={goBack}
            />
          </Grid>
          <div style={{ zIndex: 999, width: "100%" }}>
            <Grid
              container
              className={classes.stepperBody}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <div className={classes.circle}>
                  <div className={classes.stepper}>4</div>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.steps}>Step 4 / 4</Typography>
                <Typography className={classes.details}>
                  {t("RequestSummary")}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={100}

              />
            </Box>
          </div>
        </Grid>
        <Grid
          container
          className={classes.screenPadding}
          style={{ height: size?.height - 185 }}
          spacing={1}
        >
          <Grid item xs={12}>
            <div style={{ marginTop: "10px" }}>
              <span className={classes.requsettop}>
                &nbsp;{props?.request?.category?.label}&nbsp;
              </span>{" "}
              <span className={classes.requsettop}>
                &nbsp;{props?.request?.subCategory?.label}&nbsp;
              </span>
            </div>
            <Typography className={classes.requsettitle}>
              {props?.request?.problemTitle}
            </Typography>
            <Typography className={classes.requsetsub}>
              {props?.request?.problemDescription}
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="10px"
              marginBottom="14px"
            >
              <Box>
                <Typography className={classes.requsetsubs}>
                  {t("ProblemSince")}
                </Typography>
                <Typography className={classes.requsetsubs2}>
                  {" "}
                  <img src="/images/icons8_calendar.svg" alt="img" />
                  &nbsp;&nbsp;
                  <span>
                    {moment(props?.request?.problemSince)
                      .tz(moment.tz.guess())
                      .format("DD MMM YY")}
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.requsetsubs}>Unit</Typography>
                <Typography className={classes.requsetsubs2}>
                  {" "}
                  <img src="/images/build.svg" alt="img" />
                  &nbsp;&nbsp;
                  <span>{props?.request?.unit?.label}</span>
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.requsetsubs}>
                  {t("RaisedOn")}
                </Typography>
                <Typography className={classes.requsetsubs2}>
                  {" "}
                  <img src="/images/icons8_calendar.svg" alt="img" />
                  &nbsp;&nbsp;
                  <span>
                    {moment(Date()).tz(moment.tz.guess()).format("DD MMM YY")}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Grid container style={{ marginBottom: "10px", marginTop: "10px" }}>
              <Grid
                item
                xs={12}
                style={{ marginBottom: "8px", marginTop: "8px" }}
              >
                <Typography className={classes.requsetsubs}>Images</Typography>
              </Grid>
              {props?.images.map((val) => {
                return (
                  <Grid item xs={3}>
                    <img
                      height={75}
                      width={75}
                      style={{ borderRadius: "4px" }}
                      src={val?.url}
                      alt="img"
                    />
                  </Grid>
                );
              })}
            </Grid>

            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="10px"
              marginBottom="14px"
            >
              <Box>
                <Typography className={classes.requsetsubs}>
                  {t("PreferredVisitDateAndTime")}
                </Typography>
                <Typography className={classes.requsetsubs2}>
                  {" "}
                  <img src="/images/icons8_calendar.svg" alt="img" />
                  &nbsp;&nbsp;
                  <span>
                    {moment(props?.request?.preferredTime)
                      .tz(moment.tz.guess())
                      .format("DD MMM YYYY hh:mm:ss A")}
                  </span>
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="10px"
              marginBottom="14px"
            >
              <Box>
                <Typography className={classes.requsetsubs}>
                  {t("ContactDetails")}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              marginTop="10px"
              marginBottom="14px"
            >
              <Box>
                <Avatar className={classes.avatar}>
                  <img src="/images/icons8-call.svg" alt="phone" />
                </Avatar>
              </Box>
              <Box flexGrow={1} marginLeft="8px">
                <Typography className={classes.requsetsubs}>
                  {props?.request?.contactName}
                </Typography>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                // spacing={1}
                >
                  <Typography
                    className={classes.requsetsubs2}
                    style={{ paddingRight: "8px" }}
                  >
                    {props?.request?.primaryCountryCode + " " + props?.request?.primaryNumber}
                  </Typography>
                  <Typography
                    className={classes.requsetsubs2}
                    style={{ paddingLeft: "8px" }}
                  >
                    {props?.request?.alternateNumberCountryCode + " " + props?.request?.alternateNumber}
                  </Typography>
                </Stack>
              </Box>
            </Box>
            <Divider />
            <br />
          </Grid>
          <br />
          <br />
          <br />
          <br />
        </Grid>
      </Container>
      {/* button */}

      <Container maxWidth="sm" className={classes.btnRoot}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btnPrevious}
              onClick={() => props.previous(3)}
            >
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={saveMaintenanceRequest}
              disabled={disable}
            >
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
        {/* <br /> */}
      </Container>
    </div>
  );
};
export default withNamespaces("maintance")(Step4)