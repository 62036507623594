import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const MyBookingDetailsStyle = makeStyles((theme) => ({
    topCard: {
        background: "linear-gradient(to right, #F8997B, #E25C54)",
        height: 145
    },
    backbtn: {
        height: 32,
        width: 32,
        background: "#624234",
        color: "#fff",
        position: "relative",
        top: "30px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    detailBox: {
        position: "relative",
        zIndex: 3,
        backgroundColor: "white",
        borderRadius: '4px',
        height: "auto",
        top: "2%",
        left: "50%",
        transform: "translate(-53%,-50%)",
        padding: "0 !important",
        margin: "14px"
    },
    diamond: {
        height: 8,
        transform: " rotate(-45deg)",
        verticalAlign: "middle",
        width: 8,
        background: theme.palette.border.primary
    },
    your_booking: {
        color: theme.palette.border.primary,
        fontSize: 10,
        fontFamily: FontFamilySwitch().bold,
    },
    content_box: {
        marginTop: "-60px"
    },
    your_booking_pass: {
        position: "relative",
        top: "5%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        width: "100%"
    },
    passCard: {
        borderRadius: '4px',
        backgroundColor: "white",
        width: "96%",
        height: "100%",
        padding: "12px 0px",
        boxShadow: "0px 3px 8px #D2D5D938",
        marginTop: 8
    },
    chair_box: {
        background: "#FCF3FF",
        padding: 6,
        borderRadius: '4px'
    },
    chair_img: {
        height: 16,
        width: 16
    },
    chair_content: {
        color: "#771AA6",
        fontSize: 12,
        fontFamily: FontFamilySwitch().bold,
    },
    qrTime: {
        color: theme.palette.success.main,
        fontSize: 12,
        fontFamily: FontFamilySwitch().bold,
    },
    qrDetail: {
        color: theme.palette.success.main,
        fontSize: 10,
        fontFamily: FontFamilySwitch().regular,
    },
    city_box: {
        background: theme.palette.info.light,
        padding: 6,
        borderRadius: '4px'
    },
    city_content: {
        color: theme.palette.info.main,
        fontSize: 12,
        fontFamily: FontFamilySwitch().bold,
    },
    divider: {
        border: `0.5px dashed ${theme.palette.border.secondary}`,
        width: 353
    },
    booked: {
        background: theme.palette.success.main,
        color: "#fff",
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: 10,
        width: 50,
        marginTop: 4,
        fontFamily: FontFamilySwitch().regular,
    },
    avatar: {
        borderRadius: '4px',
        height: 40,
        width: 40
    },
    amenity_name: {
        fontSize: 16,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().bold,
        width: 150
    },
    amenity_content: {
        fontSize: 12,
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    cancel_btn: {
        background: "#fff",
        width: "100%"
    },
    body: {
        height: `calc(100vh - 70px)`,
        overflow: "auto",
        width: "100%"
    },
    canceledBody: {
        height: `calc(100vh - 0px)`,
        overflow: "auto",
        width: "100%"
    },
    pass_card: {
        background: "#EBF3FE",
        borderRadius: '4px',

    },
    pass_title: {
        fontSize: 14,
        color: "#6B78B7",
        fontFamily: FontFamilySwitch().bold,
    },
    pass_content: {
        fontSize: 10,
        color: "#6B78B7",
        fontFamily: FontFamilySwitch().regular,
    },
    flexWithPadding: {
        display: "flex",
        padding: "8px",
        borderBottom: "1px solid #c1c1c1",
        alignItems: "center",
    },
    modalTitle: {
        fontSize: "16px",
        fontFamily: FontFamilySwitch().regular,
        color: theme.typography.color.primary,
    },
    drawer: {
        "& .MuiDrawer-paper": {
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
        },
    },
    cancelButton: {
        height: 48,
        textTransform: "capitalize"
    },
    not_booked: {
        background: theme.palette.border.secondary,
        color: theme.typography.color.tertiary,
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: 10,
        width: 50,
        marginTop: 4,
        fontFamily: FontFamilySwitch().regular,
    },
    company_logo: {
        height: 20,
        width: 105
    }
}))