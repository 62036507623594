import * as React from "react"

const FileIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
        <g data-name="Group 105219" transform="translate(-329 -53)">
            <rect
                data-name="Rectangle 44485"
                width={30}
                height={30}
                rx={6}
                transform="translate(329 53)"
                fill="none"
            />
            <g data-name="Group 838">
                <path
                    data-name="Path 4325"
                    d="M353.98 62.025v.832h-3.792v-.76l1.916-2.064a3.158 3.158 0 0 0 .5-.676 1.345 1.345 0 0 0 .156-.612.736.736 0 0 0-.22-.58.94.94 0 0 0-.644-.2 2.523 2.523 0 0 0-1.532.592l-.344-.756a2.526 2.526 0 0 1 .86-.476 3.22 3.22 0 0 1 1.052-.18 2.026 2.026 0 0 1 1.344.412 1.386 1.386 0 0 1 .5 1.12 2.068 2.068 0 0 1-.208.916 4.059 4.059 0 0 1-.7.956l-1.392 1.476Z"
                    fill="none"
                />
            </g>
            <path
                d="M335.4 59a2.411 2.411 0 0 0-2.4 2.4v12.26a2.411 2.411 0 0 0 2.4 2.4h16.524a2.411 2.411 0 0 0 2.4-2.4v-9.6a2.411 2.411 0 0 0-2.4-2.4h-8.24l-2.384-1.98a2.931 2.931 0 0 0-1.875-.68Zm0 1.6h4.026a1.331 1.331 0 0 1 .853.309l2.605 2.171a.8.8 0 0 0 .512.185h8.529a.788.788 0 0 1 .8.8v9.6a.788.788 0 0 1-.8.8H335.4a.788.788 0 0 1-.8-.8V61.4a.788.788 0 0 1 .8-.8Zm5.331 3.732a.8.8 0 0 0-.8.8v7.463a.8.8 0 0 0 .8.8h5.864a.8.8 0 0 0 .8-.8V67.8a.8.8 0 0 0-.234-.565l-2.665-2.665a.8.8 0 0 0-.565-.234Zm.8 1.6h1.069v2.4a1.335 1.335 0 0 0 1.333 1.333h1.866v2.132h-4.27Z"
                fill="#5078e1"
            />
        </g>
    </svg>
)

export default FileIcon
