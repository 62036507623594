import { Box, Grid } from "@mui/material";
import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AmenitiesInvoiceCrad from "../../screens/amenitiesDetails/amenitiesInvoiceCrad";
export const AmenitiesCardList = (props) => {
  const { data } = props;
  // const history = useHistory();
  // const openSuccess = () => {
  //   history.push({
  //     pathname: Routes.success,
  //     state: {
  //       msg: "Payment Successful",
  //     },
  //   });
  // };

  return (
    <div>
      <ScrollContainer className="scroll-container">
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex">
              {[...new Map(data?.invoice?.map((item, key) => [item[key], item])).values()]?.map((item) => {
                return (
                  <Box marginRight="8px">
                    <AmenitiesInvoiceCrad
                      names={props?.names}
                      cardData={item}
                      type={item?.invoice_type}
                    />
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </ScrollContainer>
    </div>
  );
};
