import { Box, Typography, Grid, Avatar } from "@mui/material";
import moment from "moment";
import { useStyles } from "../discussionDetails";
import React from "react";
import ImageViewer from "react-simple-image-viewer";

export const CommentCard = ({ val, t = () => false ,comment={}}) => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = React.useState(0);
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const openImageViewer = React.useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };


  return (
    <Box className={classes.replaySection}>
      <Box alignItems="center" display="flex">
        <Box>
          <Avatar src={val?.image_url ?? null} />
        </Box>
        <Box flexGrow={1} marginLeft="10px">
          <Box alignItems="center" display="flex">
            <Box>
              <Typography variant="subtitle2" className={classes.name}>
                {val?.first_name}&nbsp;
                {val?.last_name}
              </Typography>
            </Box>
            <Box className={classes.dot} />
                  <Box flexGrow={1} noWrap>
                    <Typography
                      variant="subtitle2"
                      className={classes.unit}
                      noWrap
                    >
                      {comment?.unit?.name}-{comment?.unit?.unit_no}
                    </Typography>
                  </Box>
          </Box>

          <Typography variant="subtitle2" className={classes.time}>
            {t("Postedat")}&nbsp;
            {moment(new Date(val.created_at)).fromNow()}
          </Typography>
        </Box>
      </Box>
      <Typography variant="subtitle2" className={classes.replay}>
        {val?.comment}
      </Typography>
      <Grid container spacing={1} marginTop={0.7}>
        {val?.assets?.length > 0 &&
          val?.assets?.map((item, index) => {
            return (
              <>
                <Grid item xs={3} onClick={() => openImageViewer(index)}>
                  <img src={item?.url} alt="" className={classes.detailImg2} />

                  
                </Grid>
              </>
            );
          })}
      </Grid>
      {isViewerOpen && (
                    <ImageViewer
                      src={val?.assets?.map((i) => i?.url)}
                      currentIndex={currentImage}
                      onClose={closeImageViewer}
                      disableScroll={false}
                      backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                      }}
                      closeOnClickOutside={true}
                    />
                  )}
    </Box>
  );
};
