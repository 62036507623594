import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, FormControl, FormControlLabel, Grid, Hidden, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import moment from 'moment';
import * as React from 'react';
import { withNamespaces } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { AlertDialog, DrawerComponent, LoderSimmer, TitleBar } from "../../components";
import { config } from '../../config';
import { AlertContext } from "../../contexts";
import { METRE_DETAILS, METRE_DROPDOWN } from '../../graphql/queries';
import { NetworkCall } from '../../networkcall';
import { AlertProps, FontFamilySwitch, getMonth, NetWorkCallMethods } from '../../utils';
import useWindowDimensions from "../../utils/useWindowDimensions";
import Payments from './paymentscreen';


const useStyles = makeStyles((theme) => ({
      root: {
            padding: "0px",
            backgroundColor: '#F2F5FA',
      },
      tagStyle: {
            backgroundColor: "#F1E6FE",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: FontFamilySwitch().bold,
            color: "#5D427F",
            textTransform: "uppercase",
            marginLeft: "8px",
      },
      tagStyle1: {
            backgroundColor: "#F1E6FE",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: FontFamilySwitch().bold,
            color: "#5D427F",
            textTransform: "uppercase",
            marginLeft: "8px",
      },
      title: {
            color: '#071741',
            fontSize: '14px',
            fontFamily: FontFamilySwitch().bold,
      },
      sub: {
            color: '#98A0AC',
            fontFamily: FontFamilySwitch().semiBold,
            fontSize: '12px',
      },
      amount: {
            color: '#091B29',
            fontFamily: FontFamilySwitch().extraBold,
            fontSize: '20px',
            fontWeight: 600
      },
      date: {
            color: '#091B29',
            fontFamily: FontFamilySwitch().extraBold,
            fontSize: '14px',
      },
      main: {
            backgroundColor: '#F5F7FE',
            borderRadius: '4px',
            padding: '15px'
      },
      accordion: {
            borderRadius: '4px !important',
      },
      paymentbtn: {
            borderRadius: '4px',
            fontSize: '14px',
            fontFamily: FontFamilySwitch().bold,
            width: '100%',
            padding: '9px',
            '&hover': {
                  backgroundColor: '#5078E1'
            }
      },
      btnParent: {
            padding: '14px',
            margin: "0 auto",
            textAlign: 'center',
            background: '#F2F5FA',
            zIndex: 2
      },
      topDrap: {
            backgroundColor: '#5C86F6',
            borderRadius: '4px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1px 15px 1px 15px',
            cursor: 'pointer'
      },
      toptitle: {
            color: '#fff',
            fontSize: '12px',
            fontFamily: FontFamilySwitch().bold,
      },
      rationame: {
            '&.MuiFormControlLabel-label': {
                  color: '#091B29',
                  fontSize: '15px',
                  fontFamily: FontFamilySwitch().bold,
            }
      },
      noData: {
            color: "#999",
            textAlign: "center",
            marginTop: "20vh",
            fontSize: "14px",
            fontFamily: FontFamilySwitch().bold,
            marginLeft: "12px"
      }
}));


const UtilitiesType = ({t}) => {
      const classes = useStyles();
      const size = useWindowDimensions();
      const alert = React.useContext(AlertContext);
      const history = useHistory();
      const [open, setOpen] = React.useState(false);
      const [value, setValue] = React.useState([])
      const [details, setDetails] = React.useState([])
      const [selected, setSelected] = React.useState({})
      const searchURL = useLocation().search;
      const utilityName = new URLSearchParams(searchURL).get("name");
      const utilityId = new URLSearchParams(searchURL).get("utility_Id");
      const unitId = new URLSearchParams(searchURL).get("unit_id");
      const [paymentDrawer, setPaymentDrawer] = React.useState(false);
      const [alreadypayed, setAlreadypayed] = React.useState(false);
      const [totalBill, setTotalBill] = React.useState("");
      const [companyId, setCompanyId] = React.useState("");
      const [loading, setLoading] = React.useState(true);

      const closerDrawer = () => {
            //      delete setvalue
            setOpen(!open)
      }
      const closePayment = () => {
            setPaymentDrawer(!paymentDrawer)
      }

      const closeAllreadyPayment = () => {
            setAlreadypayed(!alreadypayed)
      }

      const SwitchUnit = (val) => {
            setSelected(val)
            setOpen(false)
      }


      const MetreAgainstUtility = () => {
            const data = {
                  query: METRE_DROPDOWN,
                  variables: {
                        utility_id: utilityId,
                        unit_id: unitId
                  },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setValue(response?.data?.data?.unit_utilties)
                        setSelected(response?.data?.data?.unit_utilties?.[0])

                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                  });
      }

      const MetreDetails = (id) => {
            const data = {
                  query: METRE_DETAILS,
                  variables: {
                        unit_utlities_id: id,
                  },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  data,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setDetails(response?.data?.data?.unit_utilties_readings)
                        setTotalBill(response?.data?.data?.unit_utilties_readings?.[0]?.bill_total)
                        setCompanyId(response?.data?.data?.unit_utilties_readings?.[0]?.invoice?.[0]?.company_masterByID?.company_id)
                        setLoading(false)

                        // setDetails(groupBy(response?.data?.data?.unit_utilties_readings || [], ({ created_at }) => new Date(created_at).getMonth()))
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: t("SomethingWentwrong"),
                        });
                  });
      }


      React.useEffect(() => {
            MetreAgainstUtility()
            // eslint-disable-next-line
      }, [])
      React.useEffect(() => {
            if (selected?.value) {
                  MetreDetails(selected?.value)
            }
            // eslint-disable-next-line
      }, [selected])


      return (
            <Container className={classes.root} maxWidth="sm" style={{ height: size?.height }}>
                  <TitleBar
                        text={utilityName}

                        isUtilities
                        component={
                              <Box className={classes.topDrap} onClick={closerDrawer}>
                                    <Box>
                                          <Typography className={classes.toptitle}>{selected?.label ? selected?.label : t("nounitsfound")}</Typography>
                                    </Box>
                                    <Box>
                                          <ExpandMoreIcon style={{ color: '#fff', marginTop: '5px' }} />
                                    </Box>

                              </Box>
                        }
                        goBack={() => history.goBack()}
                  />
                  {loading ? <LoderSimmer count={20} /> : <>
                        {details.length > 0 ?
                              <>
                                    <div style={{ height: size?.height - 180, overflow: 'auto', paddingBottom: '20px' }}>
                                          {
                                                details?.map((x, index) => {
                                                      return (
                                                            <div style={{ padding: '16px 4px 0px 10px' }}>
                                                                  <AccordionComponent data={x} index={index} setTotalBill={setTotalBill} setCompanyId={setCompanyId} />
                                                            </div>
                                                      )
                                                })
                                          }
                                    </div>
                                    <Box className={classes.btnParent}>
                                          <Button className={classes.paymentbtn} variant='contained' onClick={closePayment}>{`${"Pay Now"} ${totalBill}`}</Button>
                                    </Box>
                              </> :
                              <Typography className={classes.noData}>
                                    {t("Datanotfound")}
                              </Typography>

                        }
                  </>
                  }
                  {/* payment */}
                  <Payments closePayment={closePayment} closeAllreadyPayment={closeAllreadyPayment} alreadypayed={alreadypayed} paymentDrawer={paymentDrawer} companyId={companyId} />



                  {/* drawer */}
                  <Hidden smUp>
                        <DrawerComponent onClose={closerDrawer} open={open}
                              component={
                                    <div>
                                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                <Box>
                                                      <Typography className={classes.title}>{t("Meter")}</Typography>
                                                </Box>
                                                <Box>
                                                      <IconButton size="small" onClick={closerDrawer}>
                                                            <CloseIcon />
                                                      </IconButton>
                                                </Box>
                                          </Box>
                                          <FormControl component="fieldset" >
                                                <RadioGroup
                                                      aria-label="gender"
                                                      defaultValue="female"
                                                      name="radio-buttons-group"
                                                      style={{ padding: "8px" }}
                                                >
                                                      {value?.map((val) => {
                                                            return (
                                                                  <>
                                                                        <FormControlLabel
                                                                              value={val?.value}
                                                                              control={
                                                                                    <Radio
                                                                                          checked={
                                                                                                val?.value === selected?.value
                                                                                                      ? true
                                                                                                      : false
                                                                                          }
                                                                                    />
                                                                              }
                                                                              label={val?.label}
                                                                              onChange={() => SwitchUnit(val)}
                                                                        />
                                                                        {/* <Divider /> */}
                                                                  </>
                                                            );
                                                      })}
                                                </RadioGroup>
                                          </FormControl>

                                    </div>
                              }

                        />
                  </Hidden>

                  {/* dialog */}
                  <Hidden smDown>
                        <AlertDialog onClose={closerDrawer} open={open}
                              component={
                                    <div>
                                          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '12px', borderBottom: '1px solid #E4E8EE' }}>
                                                <Box>
                                                      <Typography className={classes.title}>{t("Meter")}</Typography>
                                                </Box>
                                                <Box>
                                                      <IconButton size="small" onClick={closerDrawer}>
                                                            <CloseIcon />
                                                      </IconButton>
                                                </Box>
                                          </Box>
                                          <FormControl component="fieldset" >
                                                <RadioGroup
                                                      aria-label="gender"
                                                      defaultValue="female"
                                                      name="radio-buttons-group"
                                                      style={{ padding: "8px" }}
                                                >
                                                      {value?.map((val) => {
                                                            return (
                                                                  <>
                                                                        <FormControlLabel
                                                                              value={val?.value}
                                                                              control={
                                                                                    <Radio
                                                                                          checked={
                                                                                                val?.value === selected?.value
                                                                                                      ? true
                                                                                                      : false
                                                                                          }
                                                                                    />
                                                                              }
                                                                              label={val?.label}
                                                                              onChange={() => SwitchUnit(val)}
                                                                        />
                                                                        {/* <Divider /> */}
                                                                  </>
                                                            );
                                                      })}
                                                </RadioGroup>
                                          </FormControl>
                                    </div>
                              }

                        />
                  </Hidden>

            </Container>
      );
}

const AccordionComponent = (props) => {
      const {t} = props
      const classes = useStyles();


      // const dueDate = new Date(props?.data?.[1][props?.data?.[1].length - 1].invoice[0].payment_due_date)
      return (
            <Accordion className={classes.accordion} TransitionProps={{ unmountOnExit: true }} defaultExpanded={props?.index === 0 ? true : false}
                  onChange={() => {
                        props?.setTotalBill(props?.data?.bill_total)
                        props?.setCompanyId(props?.data?.invoice?.[0]?.company_masterByID?.company_id)
                  }
                  }
            >
                  <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#071741' }} />} aria-controls='panel1a-content' id='panel1a-header'>
                        <Typography style={{ display: 'flex', alignItems: 'center' }} className={classes.title}>
                              {
                                    getMonth(new Date(props?.data?.created_at).getMonth())
                              }
                              {/* <span className={props?.data?.is_owner === "overdue" ? classes.tagStyle1 : classes.tagStyle}>
                              {
                                    // calculateDaysBetweenDates(dueDate, new Date(Date.now()))
                              }
                        </span> */}
                        </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                        <Grid container spacing={2} marginBottom="10px">
                              <Grid item xs={12}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.main}>
                                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>
                                                      <img src='/images/utilitiesinvoices.svg' alt='invoice' />
                                                </Box>
                                                <Box style={{ marginLeft: '16px' }}>
                                                      <Typography className={classes.sub}>{t("TotalBill")}</Typography>
                                                      <Typography className={classes.amount}>{props?.data?.bill_total}</Typography>
                                                </Box>
                                          </Box>

                                          <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <Box>
                                                      <img src='/images/meter.svg' alt='invoice' />
                                                </Box>
                                                <Box style={{ marginLeft: '16px' }}>
                                                      <Typography className={classes.sub}>{t("ConsumedUnits")}</Typography>
                                                      <Typography className={classes.amount}>{props?.data?.units_consumed}</Typography>
                                                </Box>
                                          </Box>
                                    </Box>
                              </Grid>
                              {/* 2nd */}
                              <Grid item xs={6}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                                          <Box>
                                                <img src='/images/utilitiescalander.svg' alt='invoice' />
                                          </Box>
                                          <Box style={{ marginLeft: '16px' }}>
                                                <Typography className={classes.sub}>{t("FromDate")}</Typography>
                                                <Typography className={classes.date}>
                                                      {moment(props?.data?.usage_period_from).format("DD MMM YY")}
                                                </Typography>
                                          </Box>
                                    </Box>
                              </Grid>
                              <Grid item xs={6}>
                                    <Box style={{ display: 'flex', alignItems: 'center' }} className={classes.main}>
                                          <Box>
                                                <img src='/images/utilitiescalander.svg' alt='invoice' />
                                          </Box>
                                          <Box style={{ marginLeft: '16px' }}>
                                                <Typography className={classes.sub}>{t("ToDate")}</Typography>
                                                <Typography className={classes.date}>
                                                      {moment(props?.data?.usage_period_to).format("DD MMM YY")}

                                                </Typography>
                                          </Box>
                                    </Box>
                              </Grid>
                              {/* 3rd */}
                              <Grid item xs={12}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.main}>
                                          <Box>
                                                <Typography className={classes.sub}>{t("CurrentMeterReadingValue")}</Typography>
                                                <Typography className={classes.date}>{props?.data?.present_meter_readings}</Typography>
                                          </Box>
                                          <Box>
                                                <img src='/images/ameter.svg' alt='ameter' />
                                          </Box>
                                    </Box>
                              </Grid>
                              {/* 4th */}
                              <Grid item xs={12}>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className={classes.main}>
                                          <Box>
                                                <Typography className={classes.sub}>{t("PreviousMeterReadingDate")}</Typography>
                                                <Typography className={classes.date}>{props?.data?.previous_meter_readings}</Typography>
                                          </Box>
                                          <Box>
                                                <img src='/images/utilitiescalander.svg' alt='calander' />
                                          </Box>
                                    </Box>

                              </Grid>
                        </Grid >
                  </AccordionDetails>
            </Accordion>
      )
}
export default withNamespaces("utilites")(UtilitiesType)



