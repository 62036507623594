import * as React from "react"

const AgeementImg = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={20} {...props}>
    <path
      data-name="icons8-rental-house-contract (2)"
      d="M2.25 0A2.253 2.253 0 0 0 0 2.25v15.5A2.253 2.253 0 0 0 2.25 20h11.5A2.253 2.253 0 0 0 16 17.75V8h-5.75A2.253 2.253 0 0 1 8 5.75V0ZM9.5.439V5.75a.751.751 0 0 0 .75.75h5.311ZM8 9.515a2.159 2.159 0 0 1 1.334.448l2.344 1.846A2.147 2.147 0 0 1 12.5 13.5v2.8a1.7 1.7 0 0 1-1.687 1.7H5.187A1.7 1.7 0 0 1 3.5 16.3v-2.8a2.145 2.145 0 0 1 .822-1.694L6.666 9.96A2.162 2.162 0 0 1 8 9.515ZM7.25 13a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 .75.75h1.5a.75.75 0 0 0 .75-.75v-1.5a.75.75 0 0 0-.75-.75Z"
      fill={props?.color}
    />
  </svg>
)

export default AgeementImg