import { MoreVertOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  more: {
    // backgroundColor: theme.palette.background.paper,
  },

  popper: {
    cursor: "pointer",
    fontSize: "14px",
  },
  root: {
    "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
      boxShadow: "none",
      border: "1px solid #c1c1c1",
    },
    "& .MuiList-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
    backgroundColor: "transparent",
  },
  list: {
    "& .MuiList-root": {
      paddingTop: "4px",
      paddingBottom: "4px",
    },
  },
}));

export const OptionMenu = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlepopover = () => {
    setAnchorEl(null);
    props.onOpen();
  };
  const handlepopoveragree = () => {
    setAnchorEl(null);
    props.onOpenagree();
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={classes.root}>
      <IconButton size="small" className={classes.more} onClick={handleClick}>
        <MoreVertOutlined style={{ color: "#071741" }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={classes.root}
      >
        <Box className={classes.popper}>
          <List className={classes.list}>
            {props.termination && (
              <ListItem disablePadding onClick={handlepopover}>
                <ListItemText className={classes.list}>
                  &nbsp;&nbsp; <img src="/images/leavehouse.svg" alt="" />{" "}
                  &nbsp;{props?.terminationName}&nbsp; &nbsp;
                </ListItemText>
              </ListItem>
            )}

            <ListItem disablePadding onClick={handlepopoveragree}>
              <ListItemText className={classes.list}>
                &nbsp;&nbsp;{" "}
                <img src="/images/downloadagreement.svg" alt="Downlode" />{" "}
                &nbsp;{props?.downlodeName} &nbsp; &nbsp;
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Popover>
    </div>
  );
};
