import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import { propertyStyles } from './style';

export const PropertyListCard = (props) => {
    const classes = propertyStyles()
    return (
        <Box
            display="flex"
            alignItems="center"
            className={classes.card}
            onClick={() => props?.onClick()}
        >
            {props?.data?.asset_url ?
                <img
                    src={props?.data?.asset_url}
                    alt=""
                    className={classes.image}
                />
                :
                <Avatar className={classes.image} />}
            <div className={classes.bottomText}>
                <Typography fontSize={"10px"}>
                    {props?.data?.property_no}
                </Typography>
            </div>
            <Box flexGrow={1}>
                <Typography className={classes.name}>
                    {props?.data?.property_name}
                </Typography>
                <Box className={classes.details}>
                    <Typography className={classes.sub}>{props?.data?.area ?? ""}</Typography>
                    <Box className={classes.dot} />

                    <Typography className={classes.sub}>{props?.data?.city ?? ""}</Typography>
                    {/* <Box className={classes.dot} />

                    <Typography className={classes.sub}>{props?.data?.country ?? ""}</Typography> */}
                </Box>
                <Box className={classes.details}>
                    <Typography className={classes.sub}>{props?.data?.country ?? ""}</Typography>
                </Box>
                {props?.neighbours &&
                    <Box className={classes.details}>
                        <Typography className={classes.sub}>{props?.data?.residents ? "Residents :" + props?.data?.residents : ""}</Typography>
                    </Box>}
                {props?.normal &&
                    <Box className={classes.details}>
                        <Typography className={classes.sub}>{props?.data?.mobile_code ? props?.data?.mobile_code + " - " : ""}{props?.data?.mobile_no ?? ""}</Typography>
                        <Box className={classes.dot} />
                        <Typography className={classes.sub}>{props?.data?.email ?? ""}</Typography>
                    </Box>
                }
                {props?.committee &&
                    <Box className={classes.details}>
                        <Typography className={classes.sub}>{props?.data?.management_committee ? "Committee Members :" + props?.data?.management_committee : ""}</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}
