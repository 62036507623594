import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useRef } from "react";
import { TitleBar } from "../../components";
import { Upload } from "../../components/upload/fileupload";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { FontFamilySwitch, MultiImageUpload } from '../../utils/common';
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "12px 16px",
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
  },
  contact: {
    fontSize: "16px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
  },
  change: {
    fontSize: "16px",
    color: theme.palette.primary.main,
    fontFamily: FontFamilySwitch().bold,
  },
  screen: {
    borderRadius: '4px',
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};

const Step3 = (props) => {
  const {t} = props
  const classes = useStyles();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  // const [uploaddocc, setuploaddocc] = React.useState(
  //   props?.request?.selectedImages ? props.request.selectedImages : []
  // );


  const goBack = () => {
    props.previous(2);
  };
  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const uploadImageS3 = async (file) => {
    try {
      let propsData = { companyId: props?.request?.unit?.property?.company?.value, type: props?.id ,file_meta:true}
      const Images = await MultiImageUpload(file, propsData,alert)

      props.updateFunction(Images)
      props?.request?.uploadedImages?.push(Images);
      props?.setRequest(props.request);

    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }

  }

  const handleUpload = async (e) => {
    if (e.target.files) {
      let files = e.target.files;

      const filesArray = Array.from(e.target.files).map((file) => {
        return URL.createObjectURL(file);
      });

      props.request.selectedImages =
        props.request.selectedImages.concat(filesArray);

      // Array?.from(files)?.map(
      //   (val) =>
      //     new Compressor(val, {
      //       quality: 0.6,
      //       success(result) {
      //         uploadImageS3(result);
      //       },
      //       error(err) {
      //         alert.setSnack({
      //           ...alert,
      //           open: true,
      //           severity: AlertProps.severity.error,
      //           msg: "Something Went Wrong",
      //         });
      //       },
      //     })
      // );
      Array?.from(files)?.map((val) => {
        return uploadImageS3(val)
      })

      props.setRequest(props.request);
      // setuploaddocc((prevImages) => prevImages.concat(filesArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
  };
  // const handleDelete = (item) => {
  //   // props.request.selectedImages = props.request.selectedImages?.filter((index) => index !== i)
  //   // props.request.uploadedImages = props.request.uploadedImages?.filter((index) => index !== i)
  //   // props.setRequest(props.request);
  //   // setuploaddocc(uploaddocc?.filter(( index) => index !== i));
  //   props?.handleDelete(item)
  //   // console.log("sfkhsdfksfhsdkf",uploaddocc?.filter((item,index) => index !== i))
  //   // setuploaddocc(uploaddocc?.filter((item,index) => index !== i))
  // };
  // const onRemoveAll = () => {
  //   // props.request.selectedImages = [];
  //   // props.request.uploadedImages = [];
  //   // props.setRequest(props.request);
  //   props?.onRemoveAll()
  //   // setuploaddocc([]);
  // };
  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        <TitleBar

          text={t("NewMaintenanceRequest")}
          goBack={goBack}
        />
        <Grid container className={classes.screen} xs={12}>
          <div style={{ zIndex: 999, width: "100%" }}>
            <Grid
              container
              className={classes.stepperBody}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid item xs={2}>
                <div className={classes.circle}>
                  <div className={classes.stepper}>3</div>
                </div>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.steps}>Step 3 / 4</Typography>
                <Typography className={classes.details}>
                  {t("UploadImages")}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                variant="determinate"
                value={75}

              />
            </Box>
          </div>
        </Grid>
        <Grid
          container
          className={classes.screenPadding}
          style={{ height: size?.height - 200 }}
        >
          <Grid item xs={12}>
            <br />
            <div className={classes.root} noValidate>
              <Grid>
                <Grid item>
                  <Upload
                    onChange={handleUpload}
                    single={props?.single}
                    state={props?.images}
                  />
                </Grid>

                {props?.images?.length > 0 && (
                  <div>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.Container}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Box display="flex">
                          <Box flexGrow={1}>
                            <Typography
                              style={{
                                color: "#404E61",
                                fontSize: "16px",
                                fontFamily: FontFamilySwitch().semiBold,
                              }}
                            >
                              {t("Uploaded")} {props?.images?.length} {t("images")}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              onClick={() => props?.onRemoveAll()}
                              style={{
                                cursor: "pointer",
                                color: "#5078E1",
                                fontSize: "14px",
                                fontFamily: FontFamilySwitch().semiBold,
                              }}
                            >
                              {t("RemoveAll")}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      {props?.images?.map((item) => {
                        return (
                          item !== false &&
                          <Grid item xs={3} style={{ position: "relative" }}>
                            <img
                              src={item?.url ?? ""}
                              alt="upload"
                              style={{
                                width: "100%",
                                height: "75.6px",
                                borderRadius: '4px',
                              }}
                            ></img>
                            {!props?.isReadonly && (
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "8px",
                                  right: "-8px",
                                }}
                                onClick={() => props?.handleDelete(item)}
                                aria-label="settings"
                                disabled={props?.isReadonly}
                              >
                                <img
                                  src="/images/Group 96059.svg"
                                  alt="gruop"
                                />
                              </IconButton>
                            )}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="sm" className={classes.btnRoot}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btnPrevious}
              onClick={() => {
                props.previous(2);
              }}
            >
              {t("Previous")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              className={classes.btn}
              onClick={() => {
                props.next(4);
              }}
            >
              {t("Next")}
            </Button>
          </Grid>
        </Grid>
        <br />
      </Container>
    </div>
  );
};
export default withNamespaces("maintance")(Step3)
