import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Pie, PieChart, ResponsiveContainer } from "recharts";

const useStyles = makeStyles((theme, props) => ({
  root: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-20px",
    "& svg": {
      borderRadius: "50%",
      width: "100%",
      minWidth: "138px !important",
      height: "138px !important",
      position: "relative",
      [theme.breakpoints.up("sm")]: {
        width: "100% !important",
        margin: "0 auto",
      },
      "& .recharts-wrapper": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
  total: {
    color: "#637381",
    fontSize: "15px",
  },
  des: {
    fontWeight: 600,
    color: "#252F3A",
    fontSize: "25px",
  },
  center: {
    textAlign: "center",
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "36%",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      right: "0px",
      top: "36%",
    },
  },
  chart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
export const PieCharts = (props) => {
  const classes = useStyles(props);
  const totalCalculation = (data) => {
    if (data >= 1000 && data <= 9999) {
      return String(data)[0] + "k";
    } else if (data >= 10000 && data <= 99999) {
      return String(data)[0] + String(data)[1] + "k";
    } else if (data >= 100000 && data <= 999999) {
      return String(data)[0] + "L";
    } else if (data >= 1000000 && data <= 9999999) {
      return String(data)[0] + String(data)[1] + "L";
    } else if (data >= 10000000) {
      return String(data)[0] + "C";
    } else {
      return data;
    }
  };
  return (
    <div style={{ position: "relative" }} className={classes.root}>
      <ResponsiveContainer
        width={props?.width}
        height={200}
        style={{ margin: "0 auto" }}
      >
        <PieChart width={props?.width} height={200} className={classes.chart}>
          <Pie
            data={props?.data}
            cx="50%"
            cy="50%"
            outerRadius={100}
            innerRadius={70}
            dataKey="value"
            isAnimationActive={false}
          />
          {/* <Tooltip/> */}
        </PieChart>
      </ResponsiveContainer>
      <div className={classes.center}>
        <Typography className={classes.des}>
          {totalCalculation(
           props?.total
          )}
          {/* {parseInt(props?.YearDataMain?.amount) +
            parseInt(props?.YearDataGeneral?.amount) +
            parseInt(props?.YearDataRent?.amount)} */}
        </Typography>
        <Typography className={classes.total}>Total</Typography>
      </div>
    </div>
  );
};
