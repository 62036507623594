import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url("/images/payMain.svg")`,
    backgroundRepeat: "no-repeat",
    backgroundColor: "white",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
    borderRadius: theme.palette.borderRadius,
    padding: "12px",
    marginTop: "8px",
  },
  cardsub: {
    color: theme.typography.color.primary,
    fontSize: "14px",
    marginTop: "4px",
    fontFamily: FontFamilySwitch().semiBold,
  },
  cardsub2: {
    color: theme.typography.color.secondary,
    fontSize: "12px",
    marginTop: "2px",
  },
  cardtitle: {
    color: theme.typography.color.primary,
    fontSize: "20px",
    fontFamily: FontFamilySwitch().extraBold,
  },
  paynow: {
    padding: "4px 8px",
    backgroundColor: "#091B29",
    color: "white",
    borderRadius: "4px",
    fontSize: "10px",
    fontFamily: FontFamilySwitch().semiBold,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: "40%",
  },
}));

export const HomepagePayCard = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography className={classes.cardtitle}>
            {props?.currency_symbol ? (
              <>
                {props?.currency_symbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.Outstanding_amount))}
              </>
            ) : (
              props?.Outstanding_amount
            )}
          </Typography>
          <Typography className={classes.cardsub}>
            {props?.t("outstanding_amount")}
          </Typography>
          <Typography className={classes.cardsub2}>
            {props?.t("outstanding_amount_des")}
          </Typography>
          <Box height="12px" />
          <Typography className={classes.cardtitle}>
            {props?.currency_symbol ? (
              <>
                {props?.currency_symbol + " " +
                  Intl.NumberFormat("en-IN", {
                    minimumFractionDigits: 0,
                  }).format(Math.round(props?.amount))}
              </>
            ) : (
              0
            )}
          </Typography>
          <Typography className={classes.cardsub}>
            {" "}
            {props?.t("overdue_amount")}
          </Typography>
          <Typography className={classes.cardsub2}>
            {props?.t("overdue_amount_des")}
          </Typography>
        </Box>
        <Box>
          <Typography
            className={classes.paynow}
            onClick={() => props?.onClick()}
          >
            &nbsp; {props?.t("pay_now")} &nbsp;
            <ArrowForwardIosIcon style={{ fontSize: "10px" }} />
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
export default withNamespaces("invoice")(HomepagePayCard);
