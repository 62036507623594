import * as React from "react"

const ActiveUnitIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19.684} height={17} {...props}>
    <path
      data-name="icons8-company (2)"
      d="M1.118 0a.671.671 0 1 0 0 1.342h.224v8.963A1.568 1.568 0 0 0 0 11.855v4.474A.671.671 0 0 0 .671 17h4.25a.448.448 0 0 0 .447-.447v-3.132a.448.448 0 0 1 .447-.447h1.343a.448.448 0 0 1 .447.447v3.132a.448.448 0 0 0 .447.447H12.3a.671.671 0 0 0 .671-.671v-4.474a1.568 1.568 0 0 0-1.342-1.55V1.342h.224a.671.671 0 1 0 0-1.342Zm3.356 3.131h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.894a.448.448 0 0 1 .447-.448Zm3.132 0H8.5a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.894a.448.448 0 0 1 .447-.448Zm4.921 0v6.533a2.475 2.475 0 0 1 1.342 2.191v4.474a1.55 1.55 0 0 1-.157.671h5.3a.67.67 0 0 0 .671-.671V5.592a2.465 2.465 0 0 0-2.461-2.461ZM4.474 6.263h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447V6.71a.448.448 0 0 1 .447-.447Zm3.132 0H8.5a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447V6.71a.448.448 0 0 1 .447-.447Zm8.053 0h.895A.448.448 0 0 1 17 6.71v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447V6.71a.448.448 0 0 1 .447-.447ZM4.474 9.395h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .447-.447Zm3.132 0H8.5a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .447-.447Zm8.053 0h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .446-.447Zm0 3.132h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .446-.448Zm-13.421.447h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.896a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .447-.447Zm7.605 0h.895a.448.448 0 0 1 .447.447v.895a.448.448 0 0 1-.447.447h-.895a.448.448 0 0 1-.447-.447v-.895a.448.448 0 0 1 .446-.447Z"
      fill={props?.color}
    />
  </svg>
)

export default ActiveUnitIcon
