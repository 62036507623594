import { Box, Button, IconButton, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import ImageViewer from "react-simple-image-viewer";
import { Profile } from "../../assets";
import { FontFamilySwitch } from "../../utils/common";
const useStyles = makeStyles((theme) => ({
  card: {
    cursor: "pointer",

    backgroundColor: theme.palette.background.paper,
    margin: "auto 8px",
  },
  Cardcontent: {
    padding: "8px 12px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  title: {
    fontSize: "16px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().bold,
  },
  progress: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#78B1FE",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  approved: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "#5AC782",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  delete: {
    fontSize: "12px",
    color: "#FFFFFF",
    fontFamily: FontFamilySwitch().bold,
    background: "red",
    padding: "1px 8px",
    borderRadius: "4px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  sub: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  view: {
    fontSize: "12px",
    color: "#5078E1",
    fontFamily: FontFamilySwitch().regular,
    textTransform: "capitalize",
  },
  visitorImg: {
    objectFit: "contain",
    marginTop: "4px",
    height: "47px",
    width: "47px",
    borderRadius: "50%"
  }
}));

export const AddvisiterCard = (props) => {
  const classes = useStyles(props);
  return (
    <>
      <div className={classes.card}>
        <div className={classes.Cardcontent}>
          <Box alignItems="center" display="flex">
            <Box onClick={() => {
              props?.setSelectedImage(props?.data?.visitor_image)
              props?.setOpenviewer(true)
            }
            }>
              {
                props?.data?.visitor_image ?
                  <img
                    src={props?.data?.visitor_image}
                    alt=""
                    className={classes.visitorImg}
                  />
                  : <Profile />
              }

            </Box>
            <Box flexGrow={1} marginLeft="10px">
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.name}
              </Typography>
              <Box alignItems="center" display="flex" marginTop="4px">
                <Box>
                  {props?.data?.id_label && (
                    <Typography variant="subtitle2" className={classes.proof}>
                      {props?.data?.id_label}
                      {props?.data?.identification_no && (
                        <>({props?.data?.identification_no?.slice(1, 10)}...)</>
                      )}
                    </Typography>
                  )}
                </Box>
                {props?.data?.id_label ? <Box className={classes.dot} /> : ""}
                <Box>
                  <Button
                    onClick={() => {
                      props.handleDetails(props?.data);
                    }}
                  >
                    <Typography variant="subtitle2" className={classes.view}>
                      View Details
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  props?.onDelete(props.index, props.data);
                }}
              >
                <img src="/images/deleteIcon.svg" alt="" />
              </IconButton>
            </Box>
          </Box>
        </div>
      </div>

      {
        props?.openViwer &&
        <ImageViewer
          src={[props?.selectedImage]}
          onClose={() => props?.setOpenviewer(false)}
          disableScroll={false}
          currentIndex={0}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}

          closeOnClickOutside={true}
        />
      }

    </>
  );
};
