import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../../utils";
export const useStyles = makeStyles((theme) => ({
  asPerAgreement: {
    color: theme?.typography?.color?.primary,
    fontFamily: FontFamilySwitch().bold,
    fontSize: "14px"
  },
  asPerAgreementSub: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "12px",
    marginTop: "6px"
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    borderRadius: "4px"
  },
  btn: {
    padding: "10px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  btnText: {
    fontFamily: FontFamilySwitch().semiBold,
    fontSize: "12px"
  },
  customerCard: {
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: "16px",
    borderRadius: "4px"
  },
  customerIcon: {
    borderRadius: "4px"
  },
  customerTitle: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    color: theme?.typography?.color?.primary,
  },
  customerSub: {
    fontSize: "12px",
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
  },
  dot: {
    backgroundColor: theme?.palette?.border?.primary,
    width: "6px",
    height: "6px",
    borderRadius: "50%",
    margin: "0px 6px"
  },
  Yes: {
    fontFamily: FontFamilySwitch().regular,
    color: "white",
    backgroundColor: theme?.palette?.primary?.main,
    width: "100%",
    "&:hover": {
      backgroundColor: theme?.palette?.primary?.main,
      color: "white",
    },
    padding: "8px 12px",
  },
  card1: {
    borderRadius: "4px",
    border: `1px solid ${theme?.palette?.border?.secondary}`,
    boxShadow: '0px 3px 30px #5C86CB2E'
  },
  asPerAgreementSub1: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "12px",
    marginTop: "6px",
    maxWidth: "275px",
    width: "100%"
  },
  requestStatusTitle: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.secondary,
    fontSize: "12px"
  },
  requestStatusSubTitle: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.Tertiary,
    fontSize: "12px"
  },
  statusBtn: {
    width: "100%",
    display: "flex",
    fontFamily: FontFamilySwitch().bold,
    fontSize: "12px",
    padding: "6px",
    justifyContent: "center"
  },
  cardFlexBox: {
    display: "flex",
    alignItems: "center",
    padding: "12px",
  },
  treeParent: {
    borderTop: `1px solid ${theme?.palette?.border?.secondary}`,
    padding: "25px 25px 20px 0px"
  },
  sucessTitle: {
    fontFamily: FontFamilySwitch().semiBold,
    color: theme?.typography?.color?.primary,
    fontSize: "26px",
    maxWidth: "300px",
    textAlign: "center",
    margin: '0 auto'
  }
}));
