import {
  Box, Button, Container, Divider, Grid, InputAdornment, Stack, TextField, Typography
} from "@mui/material";
import { saveAs } from 'file-saver';
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useLocation } from "react-router-dom";
import {
  AccessDeniedSVG, DeleteIcon, FileDetails, GridView,
  ListView, PDFImage
} from "../../assets";
import SearchIcon from "../../assets/searchIcon";
import {
  DialogDrawer, FileComponent, FolderComponent, LoderSimmer, TextBox, TitleBar
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import { AlertProps, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useStyles } from "./style";
import { DocumentViewer } from "../../components/pdfviewer";
import { AlertDialog } from "../../components/dialogNew";

const FileManagerView = ({ t }) => {
  const searchURL = useLocation().search;
  const Id = new URLSearchParams(searchURL).get("Id");
  // context
  const alert = React.useContext(AlertContext);
  // usedimension
  const size = useWindowDimensions();
  const classes = useStyles();
  const history = useHistory();
  const [loader, setLoader] = useState(false)
  // const typeData = [
  //   {
  //     name: "General",
  //   },
  //   {
  //     name: "My Documents",
  //   },
  // ];
  const detailsFile = (t, data) => [
    {
      title: t("DATE_CREATED_ON"),
      sub: moment(data?.created_at)
        .tz(moment.tz.guess())
        .format("DD MMM YY"),
      is_active: true
    },
    {
      title: t("CREATED_BY"),
      sub: data?.created_by ?? "-",
      is_active: true
    },
    {
      title: t("NUMBER_OF_FILES"),
      sub: data?.no_of_files ?? "-",
      is_active: data?.data?.is_dir ? true : false
    },
  ];
  const [state, setState] = useState({
    tabValue: 0,
    listType: 2,
    dialog: false,
    folderName: "",
    dialogType: 0,
    order_by: false,
    searchText: "",
    details: {},
    offset: 0,
    fileDialog: false,
    fileName: "",
    fileExt: "",
    filetype: "",
    url: "",
    error: {
      folderName: "",
    },
  });
  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };
  const viewType = (value) => {
    return state?.listType === value ? "#5078E1" : "#98A0AC";
  };
  const renameFile = () => {
    setState({
      ...state,
      dialog: true,
      folderName: "folderName",
      is_rename: true,
      dialogType: 0,
      error: {
        folderName: "",
      },
    });
  };

  const deleteFile = () => {
    setState({
      ...state,
      dialog: true,
      dialogType: 1,
      is_rename: false,
    });
  };
  const viewDetails = (data) => {
    setState({
      ...state,
      dialog: true,
      dialogType: 2,
      is_rename: false,
      details: data
    });
  };
  const dialogTitle = {
    0: t("Create_Folder"),
    1: t("Delete_File"),
    2: state?.details?.data?.is_dir ? t("Folder_Details") : t("File_Details"),
  };
  const getDocuments = (search, order_by = "desc", offset) => {
    if (!offset > 0) {
      setLoader(true)
    }
    const data = {
      "directory_id": Id ?? "",
      "offset": offset,
      "limit": 10,
      "is_private": [false],
      "search": search ?? undefined,
      "order_by": order_by ? "desc" : "asc" ?? "desc",
    };
    NetworkCall(
      `${config.api_url}/asset_management/fetch`,
      NetWorkCallMethods.post,
      data,
      null,
      true,
      false
    )
      .then((response) => {
        if (response?.data?.data?.data?.length > 0) {
          setList({
            data: offset > 0 ? list?.data.concat(response?.data?.data?.data) : response?.data?.data?.data,
            count: response?.data?.data?.count,
          })
        }
        else {
          setList({
            data: offset > 0 ? list?.data.concat(response?.data?.data?.data) : response?.data?.data?.data,
            count: 0,
          })
        }
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Some thing Went wrong.",
        });
      });
  };
  const orderFunction = () => {
    getDocuments(state?.searchText, !state?.order_by, 0)
    updateState("order_by", !state?.order_by)
  }
  const searchFunction = (value) => {
    getDocuments(value, state?.order_by, 0)
    updateState("searchText", !state?.order_by)
  }
  const fetchMoreData = () => {
    getDocuments(state?.searchText, state?.order_by, state?.offset + 15);
    updateState("offset", state?.offset + 15);
  };
  const download = (data) => {
    var ext = data?.url.substring(data?.url?.lastIndexOf(".") + 1);
    saveAs(data?.url, `${data?.name}.${ext}`);
  }
  const [list, setList] = useState({
    data: [],
    count: 0,
    property_id: []
  })
  const onPageChange = (data) => {
    history.push(Routes.fileManagerView + "?Id=" + data?.id);
  }

  const components = {
    0: (
      <Box>
        <TextBox
          label={t("Folder_Name")}
          placeholder={t("Enter_Folder_Name")}
          value={state.folderName}
          onChange={(value) => updateState("folderName", value.target.value)}
          isError={state?.error?.folderName?.length > 0}
          errorMessage={state?.error?.folderName}
          isRequired
        />
        <Box height={"46px"} />
        <Button variant="contained" className={classes.create}>
          {t("Submit")}
        </Button>
      </Box>
    ),
    1: (
      <Box textAlign="center">
        <DeleteIcon />
        <Box height={"20px"} />
        <Typography className={classes.deleteTitle}>
          {t("delete_folder")}
        </Typography>
        <Box height={"20px"} />
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Button className={classes.outLine}>{t("Cancel")}</Button>
          <Box width={"15px"} />
          <Button className={classes.create}>{t("Yes_Delete")}</Button>
        </Box>
      </Box>
    ),
    2: (
      <Box>
        <Box display="flex" alignItems="center" className={classes.detailsBox}>
          {
            state?.details?.data?.is_dir ? <FileDetails /> : <PDFImage />
          }

          <Box width={"16px"} />
          <Box>
            <Typography className={classes.viewFileTilte} noWrap>
              {state?.details?.data?.is_dir ? state?.details?.data?.name ?? '-' : state?.details?.name ?? "-"}
            </Typography>
            <Box height={"8px"} />
            <Typography className={classes.viewFileSub}>
              Last Modified on {moment(state?.details?.updated_at).format('YYYY/MM/DD')}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={1}>
            {detailsFile(t, state?.details)?.map((x) => {
              return (
                <>
                  {
                    x?.is_active &&
                    <Grid item xs={4}>
                      <Box p={2}>
                        <Typography className={classes.viewDetailsTilte} noWrap>
                          {x?.title}
                        </Typography>
                        <Box height={"8px"} />
                        <Typography className={classes.viewDetailsSub} noWrap>
                          {x?.sub}
                        </Typography>
                      </Box>
                    </Grid>
                  }
                </>
              )
            })}
          </Grid>
          <Divider />
        </Box>
      </Box>
    ),
  };
  const goBack = () => {
    history.goBack(-1)
  };
  useEffect(() => {
    getDocuments("", "desc", 0)
    // eslint-disable-next-line 
  }, [Id])
  console.log(state)

  const viewComponents = {
    image: <img
      style={{
        objectFit: "contain",
        height: "600px",
        width: "100%",
      }}
      alt={`${state?.fileName}.${state?.fileExt}`}
      src={state.url} />,
    application: <DocumentViewer url={state?.url} />,
    video: <video
      style={{
        objectFit: "contain",
        height: "600px",
        width: "100%",
      }}
      controls>
      <source src={state.url} type={`video/${state?.fileExt}`} />
    </video>,
  };

  const closeFileDialog = () => {
    setState({
      ...state,
      fileDialog: false,
      fileName: "",
      fileExt: "",
      filetype: "",
      url: "",
    })
  }

  const handleFileDialog = (data) => {
    setState({
      ...state,
      fileDialog: true,
      fileName: data?.data?.file_meta?.file_name,
      fileExt: data?.data?.file_meta?.file_ext,
      filetype: data?.data?.file_meta?.file_type,
      url: data?.data?.url,
    })
  }

  return (
    <div>
      <Container maxWidth="sm" className={classes.root}>
        <TitleBar text={t("Your_Documents")} goBack={goBack} />
        <Box className={classes.main}>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search"
            style={{
              backgroundColor: "white",
              borderRadius: "4px",
            }}
            fullWidth
            className={classes.search}
            onChange={(e) => searchFunction(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box height={"16px"} />
          {/* toggle button */}
          {/* <Box className={classes.navTopBar}>
            <Grid container>
              {typeData?.map((x, i) => {
                return (
                  <Grid
                    item
                    xs={6}
                    className={classes.topBtn}
                    onClick={() => updateState("tabValue", i)}
                    style={{
                      backgroundColor:
                        i === state?.tabValue ? "#5078E1" : "#fff",
                      color: i === state?.tabValue ? "#fff" : "#98A0AC",
                    }}
                  >
                    {x?.name}
                  </Grid>
                );
              })}
            </Grid>
          </Box> */}
          {/* <Box height={"21px"} /> */}
          {/* body */}
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography className={classes.folderName}>
              {t("Files")}
            </Typography>
            <Stack direction="row" spacing={2}>
              {[
                <AccessDeniedSVG color={state?.order_by ? "#5078E1" : "#98A0AC"} onClick={orderFunction} />,
                <ListView color={viewType(1)} onClick={() => updateState("listType", 1)} />,
                <GridView color={viewType(2)} onClick={() => updateState("listType", 2)} />,
              ]?.map((x, i) => {
                return (
                  <Box onClick={x?.onClick}>{x}</Box>
                );
              })}
            </Stack>
          </Box>
          <Box height={"16px"} />
          <InfiniteScroll dataLength={list?.data?.length}
            next={fetchMoreData}
            hasMore={true}
            height={size?.height - 258}
            className={state?.listType === 2 && classes.paddingLeft}
          >
            <Box>
              {state?.listType === 2 ?
                loader ? <LoderSimmer count={10} card size="76px" /> :
                  !list?.data?.length > 0 ?
                    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                      <Typography className={classes.folderName}>No Data Found</Typography>
                    </Box>
                    :

                    <Grid container spacing={3}>
                      {
                        list?.data?.map((x) => {
                          return (
                            <Grid item md={4} sm={4} xs={6}>
                              {
                                x?.is_dir ? <FolderComponent
                                  data={{
                                    fileNmae: x?.name,
                                    createdAt: moment(x?.created_at)
                                      .tz(moment.tz.guess())
                                      .format("DD MMM YY"),
                                    data: x
                                  }
                                  }
                                  t={t}
                                  renameFile={renameFile}
                                  deleteFile={deleteFile}
                                  viewDetails={viewDetails}
                                  onView={onPageChange}
                                /> : <FileComponent
                                  data={{
                                    fileNmae: x?.name,
                                    createdAt: moment(x?.created_at)
                                      .tz(moment.tz.guess())
                                      .format("DD MMM YY"),
                                    data: x
                                  }
                                  }
                                  t={t}
                                  renameFile={renameFile}
                                  deleteFile={deleteFile}
                                  viewDetails={viewDetails}
                                  download={download}
                                  handleFileDialog={handleFileDialog}
                                  name={x?.url?.length > 0 ? x?.url?.substring(x?.url?.lastIndexOf(".") + 1) : "-"}
                                />
                              }

                            </Grid>
                          )
                        })
                      }

                    </Grid>
                :
                loader ? <LoderSimmer count={10} card size="76px" /> :
                  !list?.data?.length > 0 ?
                    <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
                      <Typography className={classes.folderName}>No Data Found</Typography>
                    </Box>
                    :
                    <Box className={list?.data?.length > 1 ? classes.listView : classes.list}>
                      <Grid container spacing={2}>
                        {
                          list?.data?.map((x, index) => {
                            return (
                              <Grid item xs={12} className={classes.paddingTop}>
                                {

                                  x?.is_dir ? <FolderComponent
                                    data={{
                                      fileNmae: x?.name,
                                      createdAt: moment(x?.created_at)
                                        .tz(moment.tz.guess())
                                        .format("DD MMM YY"),
                                      data: x
                                    }
                                    }
                                    t={t}
                                    type={state?.listType}
                                    tabValue={state?.tabValue}
                                    renameFile={renameFile}
                                    deleteFile={deleteFile}
                                    viewDetails={viewDetails}
                                    is_divider={list?.data?.length === index + 1 ? false : true}
                                    onView={onPageChange}
                                  /> :
                                    <Box>
                                      <FileComponent
                                        data={{
                                          fileNmae: x?.name,
                                          createdAt: moment(x?.created_at)
                                            .tz(moment.tz.guess())
                                            .format("DD MMM YY"),
                                          data: x
                                        }
                                        }
                                        t={t}
                                        type={state?.listType}
                                        tabValue={state?.tabValue}
                                        renameFile={renameFile}
                                        deleteFile={deleteFile}
                                        viewDetails={viewDetails}
                                        is_divider={list?.data?.length === index + 1 ? false : true}
                                        download={download}
                                        name={x?.url?.length > 0 ? x?.url?.substring(x?.url?.lastIndexOf(".") + 1) : "-"}
                                      />
                                    </Box>
                                }

                              </Grid>
                            )
                          })
                        }

                      </Grid>
                    </Box>
              }
            </Box>
          </InfiniteScroll>
          <DialogDrawer
            height={"210px"}
            open={state?.dialog}
            header={
              state?.is_rename
                ? t("Rename_Folder")
                : dialogTitle[state?.dialogType]
            }
            padding={state?.dialogType === 2 ? "0px" : "16px"}
            component={<>{components[state?.dialogType]}</>}
            onClose={() => updateState("dialog", false)}
          />

          {/* delete folder */}
          <Box></Box>
          {/* <Fab
            color="primary"
            aria-label="add"
            className={classes.addBtn}
            onClick={() => updateState("dialog", true)}
          >
            <DownLoadIcon />
          </Fab> */}
        </Box>
      </Container>
      <AlertDialog
        isNormal
        header={`${state?.fileName}.${state?.fileExt}`}
        onClose={closeFileDialog}
        open={state?.fileDialog}
        component={viewComponents[state?.filetype]}
      />
    </div>
  );
};
export default withNamespaces("fileManager")(FileManagerView);
