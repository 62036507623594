import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const SlotCardStyles = makeStyles((theme) => ({
  main: {
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "pointer"
  },
  mainslot:{
    border: "1px solid #E4E8EE",
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "pointer",
    background:"#fff"
  },
  slotTime: {
    fontSize: "12px",
    color: "#4E5A6B",
    fontFamily: FontFamilySwitch().extraBold,
    marginBottom: "3px",
    [theme.breakpoints.down(321)]: {
      fontSize: "10px",
      color: "#4E5A6B",
      fontFamily: FontFamilySwitch().extraBold,
      marginBottom: "3px",
    }
  },

  slotPerson: {
    fontSize: "10px",
    color: "#98A0AC",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(321)]: {
      fontSize: "8px",
      color: "#98A0AC",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }
  },

  dot: {
    width: "4px",
    height: "4px",
    backgroundColor: "#E4E8EE",
    borderRadius: "50%",
    marginLeft: "7px",
    marginRight: "7px"
  },

  gridBox: {
    paddingTop: "0 !important",
    textAlign: "center"
  },
  selectedBox:{
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "4px",
    padding: "8px 0px",
    cursor: "pointer",
    background:"#fff"
  }
}));
