import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../../utils";

export const DetailCardStyle = makeStyles((theme) => ({
    box: {
        backgroundColor: "#fff",
        borderRadius: '4px',
        border: `2px solid ${theme.palette.border.secondary}`
    },
    scorllBox: {
        backgroundColor: "#fff",
        border: `2px solid ${theme.palette.border.secondary}`
    },
    name: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    subtitle: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    tabroot: {
        display: "flex",
        alignItems: "center",
        "& .MuiTabs-indicator": {
            display: "none"
        }
    },
    tabItemroot: {

        // "& .MuiButtonBase-root-MuiTab-root": {
        // },

    },
    tabItem: {
        borderRadius: "4px",
        padding: "8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            borderRadius: "4px",
        },

    },
    tabItemSelect: {
        backgroundColor: "#263B85",
        borderRadius: "4px",
        padding: "8px",
        minHeight: "auto",
        minWidth: "auto",
        maxHeight: "68px",
        margin: "0px 14px",
        "& .MuiButtonBase-root-MuiTab-root": {
            minHeight: 0,
            borderRadius: "4px",

        },
    },
    textSelect1: {
        color: "#fff",
        fontFamily: FontFamilySwitch().bold,
        fontSize: "12px"
    },
    textSelect2: {
        color: "#fff",
        fontSize: "18px"
    },
    textNotSelect1: {
        color: theme.typography.color.Tertiary,
        fontFamily: FontFamilySwitch().bold,
        fontSize: "12px"
    },
    textNotSelect2: {
        color: theme.typography.color.secondary,
        fontSize: "18px",
        fontFamily: FontFamilySwitch().regular,
    },
    panel: {
        padding: "12px !important"
    },
    card: {
        backgroundColor: "white",
        marginTop: "6px",
        borderRadius: "4px",
    },
    backbtn1: {
        height: 32,
        width: 32,
        background: "#fff",
        color: theme.typography.color.secondary,
        border: `1px solid ${theme.palette.border.secondary}`,
        marginTop: 6
    },
}))

export const PreviewCardStyle = makeStyles((theme) => ({
    name: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
        whiteSpace: "nowrap"
    },
    subtitle: {
        fontSize: 12,
        color: theme.typography.color.tertiary,
        fontFamily: FontFamilySwitch().regular,
    },
    avatar: {
        height: 48,
        width: 48,
        borderRadius: '4px'
    }
}))

export const BillSummaryStyle = makeStyles((theme) => ({
    title: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    secondary: {
        fontSize: 12,
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    dot_divider: {
        border: `0.5px dashed ${theme.palette.border.primary}`
    },
    primary: {
        fontSize: 14,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    }
}))