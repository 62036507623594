import CloseIcon from "@mui/icons-material/Close";
import {
      Box,
      Button,
      Dialog,
      Divider,
      Drawer,
      Grid,
      Hidden,
      IconButton,
      Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GETBANKDETAILS } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
const useStyles = makeStyles((theme) => ({
      bottomTitle: {
            fontSize: "16px",
            fontFamily: FontFamilySwitch().extraBold,
      },
      titleroot: {
            borderBottom: "1px solid #c1c1c1",
            padding: "6px 8px",
      },
      drawer: {
            "& .MuiDrawer-paper": {
                  borderTopRightRadius: "4px",
                  borderTopLeftRadius: "4px",
            },
            "& .MuiContainer-root": {
                  padding: "0px 8px",
            },
      },
      dialog: {
            "& .MuiDialog-paper": {
                  borderRadius: "4px",
                  padding: "0px",
            },
      },
      titles: {
            fontSize: "16px",
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      totalAmount: {
            fontSize: "14px",
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },

      subTitle: {
            fontSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.secondary,
      },
      subTitlecash: {
            fontSize: "12px",
            fontFamily: FontFamilySwitch().semiBold,
            color: theme.typography.color.primary,
      },
      btn: {
            padding: "10px",
            borderRadius: theme.palette.borderRadius,
            boxShadow: "none",
      },
      btn1: {
            padding: "10px",
            borderRadius: theme.palette.borderRadius,
            backgroundColor: "white",
            border: "1px solid #5078E1",
            color: "#5078E1",
            boxShadow: "none",
            "&:hover": {
                  backgroundColor: "white",
            },
      },
}));


const BookingPaymentScreen = (props) => {
      const classes = useStyles();
      const alert = React.useContext(AlertContext);
      const [details, setDetails] = React.useState();

      const getBankDetails = (val) => {
            const params = {
                  query: GETBANKDETAILS,
                  variables: {
                        compayId: [val],
                  },
            };
            NetworkCall(
                  `${config.graphql_url}`,
                  NetWorkCallMethods.post,
                  params,
                  null,
                  true,
                  false
            )
                  .then((response) => {
                        setDetails(response.data.data.company_master);
                  })
                  .catch((error) => {
                        alert.setSnack({
                              ...alert,
                              open: true,
                              severity: AlertProps.severity.error,
                              msg: "Something Went Wrong",
                        });
                  });
      };
      React.useEffect(() => {
            if (props?.companyId) {
                  getBankDetails(props?.companyId);
            }
            // eslint-disable-next-line
      }, []);
      const renderPayoption = () => {
            return (
                  <div>
                        <Box display="flex" alignItems="center" className={classes.titleroot}>
                              <Box flexGrow={1}>
                                    <Typography className={classes.bottomTitle}>
                                          {props?.t("company")}
                                    </Typography>
                              </Box>
                              <Box>
                                    <IconButton size="small" onClick={props?.closePayment}>
                                          <CloseIcon />
                                    </IconButton>
                              </Box>
                        </Box>

                        {details?.length > 0 ? (
                              <Box>
                                    <Box p={1}>
                                          <Typography className={classes.bottomTitle}>
                                                {props?.t("cash")}
                                          </Typography>
                                          <Typography className={classes.subTitlecash}>
                                                {details?.[0]?.cash_collection_office}
                                          </Typography>
                                    </Box>
                                    <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
                                    <Box p={1}>
                                          <Typography className={classes.bottomTitle}>
                                                {props?.t("cheque")}
                                          </Typography>
                                          <Typography className={classes.subTitlecash}>
                                                {details?.[0]?.cheque_name}
                                          </Typography>
                                    </Box>
                                    <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
                                    <Box p={1}>
                                          <Typography className={classes.bottomTitle}>
                                                {props?.t("bank_transfer")}
                                          </Typography>
                                          <Grid container>
                                                <Grid item xs={6}>
                                                      <Typography className={classes.subTitle}>
                                                            {props?.t("bank_name")}
                                                      </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <div style={{ float: "right" }}>
                                                            <Typography className={classes.subTitle}>
                                                                  {details?.[0]?.primary_bank_name}
                                                            </Typography>
                                                      </div>
                                                </Grid>

                                                <Grid item xs={6}>
                                                      <Box height="4px" />
                                                      <Typography className={classes.subTitle}>
                                                            {props?.t("iban_number")}
                                                      </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                      <Box height="4px" />
                                                      <div style={{ float: "right" }}>
                                                            <Typography className={classes.subTitle}>
                                                                  {details?.[0]?.iban}
                                                            </Typography>
                                                      </div>
                                                </Grid>

                                          </Grid>
                                    </Box>
                                    <Divider sx={{ margin: "4px 0px", border: "2px solid #F2F4F7" }} />
                                    <Box p={1}>
                                          <Typography className={classes.bottomTitle}>
                                                {" "}
                                                {props?.t("pay_now")}
                                          </Typography>
                                          <Typography className={classes.subTitlecash}>
                                                {props?.t("pay_now_des")}
                                          </Typography>
                                    </Box>
                                    <Divider sx={{ margin: "4px 0px", border: "1px solid #F2F4F7" }} />
                                    <Box p={1}>
                                          <Button
                                                className={classes.btn}
                                                variant="contained"
                                                fullWidth
                                                onClick={() => props?.onClick()}
                                          >
                                                {props?.t("pay_now")}

                                          </Button>
                                    </Box>
                              </Box>
                        ) : (
                              <>
                                    <br />
                                    <br />
                              </>
                        )}
                  </div>
            );
      };

      return (
            <>
                  <Hidden smDown>
                        <Dialog
                              className={classes.dialog}
                              maxWidth="sm"
                              fullWidth
                              open={props?.paymentDrawer}
                              onClose={props?.closePayment}
                        >
                              <div>{renderPayoption()}</div>
                        </Dialog>
                  </Hidden>
                  <Hidden smUp>
                        <Drawer
                              anchor="bottom"
                              className={classes.drawer}
                              open={props?.paymentDrawer}
                              onClose={props?.closePayment}
                        >
                              <div>{renderPayoption()}</div>
                        </Drawer>
                  </Hidden>
            </>
      );
};
export default withNamespaces("invoice")(BookingPaymentScreen);
