import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  MobileNumberInputComponent,
  TextBox,
  TitleBar
} from "../../components";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import {
  // INSERT_PROFILE_IMAGE_BY_CONTACT_ID as mutationOne,
  UPDATE as mutationThree
} from "../../graphql/mutations";
import { NetworkCall } from "../../networkcall";
import { Routes } from "../../router/routes";
import {
  AlertProps,
  FontFamilySwitch,
  LocalStorageKeys,
  Message,
  NetWorkCallMethods, SingleImageUpload
} from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.tertiary1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  screen: {
    borderRadius: "4px",
    border: "1px solid #F5F7FA",
    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  profilePicturePadding: {
    justifyContent: "center",
    padding: "24px 16px 24px 16px",
  },
  iconButtonStyle: {
    height: "44px",

    padding: "12px 16px 12px 16px",
    borderRadius: "4px",
    color: "#5078E1",
    background: "#5078E1",
    backgroundColor: "#5078E1",
    boxShadow: "0px 2px 6px #00000021",
  },
  saveNUpdateButtonTextStyle: {
    fontSize: "14px",
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  saveNUpdateButtonContainerStyle: {
    padding: "16px",
    background: "white",
    border: "1px solid #E4E8EE",
    boxShadow: "0px -7px 20px #0717410D",
    // position: "fixed",
    width: "-webkit-fill-available",
  },
  addressBoxStyle: {
    borderRadius: "4px",
    border: "1px solid #E4E8EE",
    padding: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "100%",
    backgroundPositionY: "100%",
  },
}));

const ProfileEdit = ({t}) => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowDimensions();
  const alert = React.useContext(AlertContext);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const [initialData, setInitialData] = React.useState({
    userProfileID: "",
    contactID: "",
    firstName: "",
    lastName: "",
    profileImageID: "",
    profileImage: "",
    userType: "Tenant",
    clientRoleNo: "",
    primaryMobileNo: "",
    primaryCountryCode: "",
    alternativeMobileNo: "",
    altCountryCode: "",
    primaryEmailID: "",
    alternativeEmailID: "",
    address: "",
    addressLineOne: "",
    addressLineTwo: "",
    addressLineThree: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    country: "",
    latitude: "",
    longitude: "",
    error: {
      primaryMobileNo: "",
      alternativeMobileNo: "",
    },
  });

  const [loading, setLoading] = React.useState(true);
  const [disable, setDisable] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState("");

  const goBack = () => {
    window.history.go(-1);
  };

  React.useEffect(() => {
    if (authToken !== null && authToken !== "") {
      NetworkCall(
        `${config.api_url}/user-info`,
        NetWorkCallMethods.get,
        null, null, true,
        false)
        .then((response) => {
          const userProfile =
            response?.data?.data?.user_profiles?.[0] !== null
              ? response?.data?.data?.user_profiles?.[0]
              : null;
          if (userProfile !== null) {
            initialData.userProfileID = userProfile?.id !== null ? userProfile?.id : "";
            initialData.contactID = userProfile?.contact_id !== null ? userProfile?.contact_id : "";
            initialData.firstName = userProfile?.first_name !== null ? userProfile?.first_name : "";
            initialData.lastName = userProfile?.last_name !== null ? userProfile?.last_name : "";
            initialData.primaryMobileNo = userProfile?.mobile_no !== null ? userProfile?.mobile_no : "";
            initialData.primaryCountryCode = userProfile?.mobile_no_country_code !== null ? userProfile?.mobile_no_country_code : "+91";
            initialData.alternativeMobileNo = userProfile?.alternative_mobile_no !== null ? userProfile?.alternative_mobile_no : "";
            initialData.altCountryCode = userProfile?.alternative_mobile_no_country_code !== null ? userProfile?.alternative_mobile_no_country_code : "+91";
            initialData.primaryEmailID = userProfile?.email_id !== null ? userProfile?.email_id : "";
            initialData.alternativeEmailID = userProfile?.alternative_email_id !== null ? userProfile?.alternative_email_id : "";
            initialData.profileImage = userProfile?.image_url ?? null;
            if (userProfile?.contact) {
              initialData.addressLineOne =
                userProfile?.contact?.street_1 !== null
                  ? userProfile?.contact?.street_1
                  : "";
              initialData.addressLineTwo =
                userProfile?.contact?.street_2 !== null
                  ? userProfile?.contact?.street_2
                  : "";
              initialData.addressLineThree =
                userProfile?.contact?.street_3 !== null
                  ? userProfile?.contact?.street_3
                  : "";
              initialData.district =
                userProfile?.contact?.district !== null
                  ? userProfile?.contact?.district
                  : "";
              initialData.city =
                userProfile?.contact?.city !== null
                  ? userProfile?.contact?.city
                  : "";
              initialData.state =
                userProfile?.contact?.state !== null
                  ? userProfile?.contact?.state
                  : "";
              initialData.zipCode =
                userProfile?.contact?.zipcode !== null
                  ? userProfile?.contact?.zipcode
                  : "";
              initialData.country =
                userProfile?.contact?.country !== null
                  ? userProfile?.contact?.country
                  : "";
              initialData.latitude =
                userProfile?.contact?.latitude !== null
                  ? userProfile?.contact?.latitude
                  : "";
              initialData.longitude =
                userProfile?.contact?.longitude !== null
                  ? userProfile?.contact?.longitude
                  : "";
              initialData.address =
                (userProfile?.contact?.street_1 !== null &&
                  userProfile?.contact?.street_1 !== ""
                  ? userProfile?.contact?.street_1
                  : "") +
                (userProfile?.contact?.street_2 !== null &&
                  userProfile?.contact?.street_2 !== ""
                  ? ", " + userProfile?.contact?.street_2
                  : "") +
                (userProfile?.contact?.street_3 !== null &&
                  userProfile?.contact?.street_3 !== ""
                  ? ", " + userProfile?.contact?.street_3
                  : "") +
                (userProfile?.contact?.district !== null &&
                  userProfile?.contact?.district !== ""
                  ? ", " + userProfile?.contact?.district
                  : "") +
                (userProfile?.contact?.city !== null &&
                  userProfile?.contact?.city !== ""
                  ? ", " + userProfile?.contact?.city
                  : "") +
                (userProfile?.contact?.state !== null &&
                  userProfile?.contact?.state !== ""
                  ? ", " + userProfile?.contact?.state
                  : "") +
                (userProfile?.contact?.country !== null &&
                  userProfile?.contact?.country !== ""
                  ? ", " + userProfile?.contact?.country
                  : "") +
                (userProfile?.contact?.zipcode !== null &&
                  userProfile?.contact?.zipcode !== ""
                  ? ", " + userProfile?.contact?.zipcode
                  : "");
            }

            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: t("SomethingWentwrong"),
          });
        });
    } else {
      localStorage.clear();
      history.push(Routes.login);
    }
    // eslint-disable-next-line
  }, []);

  const isFormValid = () => {

    let isValid = true;
    let error = initialData.error;
    if (initialData.primaryMobileNo.length === 0) {
      isValid = false;
      error.primaryMobileNo = Message.English.requiredMessage("Mobile Number");
    }

    setInitialData({ ...initialData, error });
    return isValid;
  };

  const saveProfileImageDetails = async () => {
    setDisable(true);
    try {
      const tumbil = await SingleImageUpload(profileImage,alert)
      if(tumbil !== false){
        saveProfileTextDetails(tumbil);
      }
    }
    catch (err) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: t("SomethingWentwrong"),
      });
    }

  };

  const saveProfileTextDetails = (v) => {
    const update_data = {
      query: mutationThree,
      variables: {
        update: {
          email_id: initialData?.primaryEmailID,
          mobile_no: initialData?.primaryMobileNo,
          mobile_no_country_code: initialData?.primaryCountryCode,
          alternative_mobile_no: initialData?.alternativeMobileNo,
          alternative_mobile_no_country_code: initialData?.altCountryCode,
          image_url: v,
        },
        userId: localStorage.getItem(LocalStorageKeys.userProfileId),
      },
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      update_data,
      null,
      true,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: t("ProfileUpdatedSuccessfully"),
        });
        goBack();
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };

  const saveProfileData = () => {
    if (isFormValid()) {
      if (profileImage !== "") {
        saveProfileImageDetails();
      } else {
        saveProfileTextDetails();
      }
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" style={{ padding: "0px" }}>
        {loading ? (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <Grid
              className={classes.screen}
              style={{ height: size?.height }}
              item
              xs={12}
            >
              <TitleBar text={t("ProfileEdit")} goBack={goBack} />
              <Grid
                className={classes.screenPadding}
                style={{ height: size?.height - 135 }}
              >
                <Grid container className={classes.profilePicturePadding}>
                  <Avatar
                    variant="circular"
                    style={{
                      height: "128px",
                      width: "128px",
                      border: "2px solid #ffffff",
                    }}
                  >
                    <img
                      height={128}
                      width={128}
                      src={
                        initialData?.profileImage !== ""
                          ? initialData?.profileImage
                          : "/images/profile_image.png"
                      }
                      alt="profile_image"
                    />
                  </Avatar>
                  <label htmlFor="uplodebtn">
                    <Avatar
                      variant="circular"
                      style={{
                        backgroundColor: "#E4E8EE",
                        border: "3px solid #ffffff",
                        marginTop: "90px",
                        marginLeft: "-25px",
                        height: "30px",
                        width: "30px",
                      }}
                    >
                      <img
                        style={{
                          height: "12px",
                        }}
                        src="/images/icons8-camera.svg"
                        alt=""
                      />
                    </Avatar>
                  </label>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="uplodebtn"
                    onChange={(e) => {
                      setDisable(false)
                      setProfileImage(e.target.files[0]);
                      initialData.profileImage = URL.createObjectURL(
                        e.target.files[0]
                      );
                      setInitialData({ ...initialData });
                    }}
                  />
                </Grid>
                <Divider />
                <Box height="16px" />
                <TextBox
                  label="Name"
                  disabled
                  value={initialData?.firstName + " " + initialData?.lastName}
                />
                <Box height="16px" />
                <MobileNumberInputComponent
                  label={t("PrimaryContact")}
                  placeholder=" 9876543210"
                  value={{
                    mobile: initialData?.primaryMobileNo,
                    mobile_code: initialData?.primaryCountryCode,
                  }}
                  handleChange={(value) => {
                    initialData.primaryMobileNo = value.mobile;
                    initialData.primaryCountryCode = value.mobile_code;
                    setInitialData({ ...initialData });
                  }}
                />
                <Box height="16px" />
                <MobileNumberInputComponent
                  label={t("AlternativeContact")}
                  placeholder=" 9876543210"
                  value={{
                    mobile: initialData?.alternativeMobileNo,
                    mobile_code: initialData?.altCountryCode,
                  }}
                  handleChange={(value) => {
                    initialData.alternativeMobileNo = value.mobile;
                    initialData.altCountryCode = value.mobile_code;
                    setInitialData({ ...initialData });
                  }}
                />
                <Box height="16px" />
                <TextBox
                  label={t("EmailAddress")}
                  value={initialData.primaryEmailID}
                  disabled
                />
                <Box height="16px" />
                <Grid
                  container
                  direction={"row"}
                  style={{ justifyContent: "space-between" }}
                >
                  <Typography style={{ fontFamily: FontFamilySwitch().bold }}>
                    {t("Address")}
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: FontFamilySwitch().semiBold,
                      fontSize: "14px",
                      color: "#5078E1",
                      cursor: "pointer",
                    }}
                  >

                  </Typography>
                </Grid>
                <Box height="16px" />
                <Grid container direction={"column"}>
                  <Grid
                    container
                    display="inline"
                    direction={"column"}
                    className={classes.addressBoxStyle}
                  >
                    <Typography
                      onClick={() => history.push(Routes.addAddress)}
                      style={{
                        textAlign: "right",
                        fontFamily: FontFamilySwitch().semiBold,
                        fontSize: "12px",
                        color: "#5078E1",
                        cursor: "pointer",
                      }}
                    >
                      {t("edit")}
                    </Typography>
                    <Grid container direction={"row"} alignItems={"center"}>
                      <img
                        height={"16px"}
                        width={"14px"}
                        src="/images/icons8-location.svg"
                        alt=""
                      />
                      <Box width="16px" />
                      <Grid container xs={10} direction={"column"}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#091B29",
                            fontFamily: FontFamilySwitch().bold,
                            wordWrap: "break-word",
                          }}
                        >
                          {"Address " /*+ (index + 1)*/}
                        </Typography>
                        <Box height={"4px"} />
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#98A0AC",
                            wordWrap: "break-word",
                          }}
                        >
                          {initialData.address}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box height="16px" />
              </Grid>
              <Grid xs={12} className={classes.saveNUpdateButtonContainerStyle}>
                <Button
                  variant="contained"
                  fullWidth
                  className={classes.iconButtonStyle}
                  onClick={saveProfileData}
                  disabled={disable}
                >
                  <Typography className={classes.saveNUpdateButtonTextStyle}>
                    {t("Save")} & {t("Update")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </div>
  );
};
export default withNamespaces("profile")(ProfileEdit)