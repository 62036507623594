import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { PDFImage } from "../../assets";
import { useStyles } from "./style";

export const FileComponent = ({ data = {}, t, type = 2, handleFileDialog = () => false, renameFile = () => false, deleteFile = () => false, viewDetails = () => false, is_divider = true, download = () => false, name = "" }) => {
  const classes = useStyles({ type });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {" "}
      {console.log(data)}
      {type === 2 ? (
        <>
          <Box className={classes.card}>
            <Box className={classes.folderParent}>
              <IconButton
                className={classes.moreBtn}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>
              <PDFImage onClick={() => handleFileDialog(data)} />
            </Box>
            <Box height={"6px"} />
            <Typography className={classes.folderName} noWrap>
              {data?.data?.file_meta?.file_name}
            </Typography>
            <Box height={"2px"} />
            <Typography className={classes.createdAt} noWrap>
              {data?.createdAt}
            </Typography>
            {
              data?.data?.url?.length > 0 &&
              <Box className={classes.flag}>
                <Box position={"relative"}>
                  <Typography className={classes.flagName} noWrap>{data?.data?.url?.length > 0 ? name : "-"}</Typography>
                  <Box className={classes.flagChild} />
                </Box>
              </Box>
            }

          </Box>
        </>
      ) : (
        <>
          {/* list view */}
          {
            !is_divider && <Box height={'7px'} />
          }
          <Box className={classes.listFolderView}>
            <Box display="flex" alignItems={"center"}>
              <Box>
                <PDFImage />
              </Box>
              <Box width={"12px"} />
              <Box>
                <Typography className={classes.folderName} noWrap>
                  {data?.fileNmae}
                </Typography>
                <Box height={"2px"} />
                <Typography className={classes.createdAt} noWrap>
                  {data?.createdAt}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                className={classes.moreBtn}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              >
                <MoreVertIcon className={classes.more} />
              </IconButton>
            </Box>
          </Box>

          {
            is_divider &&
            <>
              <Box height={'6px'} />
              <Divider />
            </>
          }

        </>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menuList}
      >
        {/* <MenuItem
          onClick={() => {
            handleClose();
            renameFile()
          }}
          className={classes.menuItem}
        >
          {t("Rename")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            deleteFile()
          }}
          className={classes.menuItem}
        >
          {t("Delete")}
        </MenuItem> */}

        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            handleClose();
            viewDetails(data?.data)
          }}
          className={classes.menuItem}
        >
          {t("Details")}
        </MenuItem>
        {
          data?.data?.url?.length > 0 && <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              handleClose();
              download(data?.data)
            }}
            className={classes.menuItem}
          >
            {t("Download")}
          </MenuItem>
        }

      </Menu>
    </>
  );
};
