import * as React from "react"

const CommitteeIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={27.789}
        height={24}
        {...props}
    >
        <defs>
            <linearGradient
                id="a"
                x1={0.161}
                y1={0.161}
                x2={0.858}
                y2={0.858}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#199ae0" />
                <stop offset={1} stopColor="#0782d8" />
            </linearGradient>
            <linearGradient
                id="d"
                x1={0.079}
                y1={0.224}
                x2={0.944}
                y2={1.002}
                xlinkHref="#a"
            />
            <linearGradient
                id="b"
                x1={0.161}
                y1={0.161}
                x2={0.858}
                y2={0.858}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#0d61a9" />
                <stop offset={1} stopColor="#16528c" />
            </linearGradient>
            <linearGradient
                id="e"
                x1={0.079}
                y1={0.224}
                x2={0.944}
                y2={1.002}
                xlinkHref="#b"
            />
            <linearGradient
                id="c"
                x1={0.161}
                y1={0.161}
                x2={0.858}
                y2={0.858}
                gradientUnits="objectBoundingBox"
            >
                <stop offset={0} stopColor="#32bdef" />
                <stop offset={1} stopColor="#1ea2e4" />
            </linearGradient>
            <linearGradient
                id="f"
                x1={0.343}
                y1={0.044}
                x2={0.622}
                y2={1.09}
                xlinkHref="#c"
            />
        </defs>
        <ellipse
            data-name="Ellipse 129408"
            cx={3.308}
            cy={3.333}
            rx={3.308}
            ry={3.333}
            transform="translate(2.647 2.667)"
            fill="url(#a)"
        />
        <path
            data-name="Path 11913"
            d="M8.955 22A5.977 5.977 0 0 0 3 28v6a1.328 1.328 0 0 0 1.323 1.333h9.263A1.328 1.328 0 0 0 14.91 34v-6a5.977 5.977 0 0 0-5.955-6Z"
            transform="translate(-3 -11.333)"
            fill="url(#d)"
        />
        <ellipse
            data-name="Ellipse 129409"
            cx={3.308}
            cy={3.333}
            rx={3.308}
            ry={3.333}
            transform="translate(18.526 2.667)"
            fill="url(#b)"
        />
        <path
            data-name="Path 11914"
            d="M32.955 22A5.977 5.977 0 0 0 27 28v6a1.328 1.328 0 0 0 1.323 1.333h9.263A1.328 1.328 0 0 0 38.91 34v-6a5.977 5.977 0 0 0-5.955-6Z"
            transform="translate(-11.12 -11.333)"
            fill="url(#e)"
        />
        <ellipse
            data-name="Ellipse 129410"
            cx={3.308}
            cy={3.333}
            rx={3.308}
            ry={3.333}
            transform="translate(10.586)"
            fill="url(#c)"
        />
        <path
            data-name="Path 11915"
            d="M21.173 22.667V14a7.315 7.315 0 0 0-.723-3.164 5.989 5.989 0 0 0-4.57 5.831v6A1.328 1.328 0 0 0 17.203 24h3.6a2.654 2.654 0 0 0 .37-1.333Z"
            opacity={0.05}
        />
        <path
            data-name="Path 11916"
            d="M20.732 22.667V14a6.879 6.879 0 0 0-.7-3.011 5.937 5.937 0 0 0-4.152 5.678v6A1.328 1.328 0 0 0 17.203 24h2.842a1.611 1.611 0 0 0 .687-1.333Z"
            opacity={0.067}
        />
        <path
            data-name="Path 11917"
            d="M20.291 22.666V14a6.442 6.442 0 0 0-.671-2.859 5.932 5.932 0 0 0-3.74 5.525v6a1.328 1.328 0 0 0 1.323 1.333h2.083a1.359 1.359 0 0 0 1.005-1.333Z"
            opacity={0.09}
        />
        <path
            data-name="Path 11918"
            d="M6.617 22.667V14a7.315 7.315 0 0 1 .723-3.164 5.989 5.989 0 0 1 4.571 5.831v6A1.328 1.328 0 0 1 10.588 24h-3.6a2.654 2.654 0 0 1-.371-1.333Z"
            opacity={0.05}
        />
        <path
            data-name="Path 11919"
            d="M7.058 22.667V14a6.879 6.879 0 0 1 .7-3.011 5.937 5.937 0 0 1 4.155 5.678v6A1.328 1.328 0 0 1 10.591 24H7.744a1.611 1.611 0 0 1-.686-1.333Z"
            opacity={0.067}
        />
        <path
            data-name="Path 11920"
            d="M7.499 22.666V14a6.442 6.442 0 0 1 .667-2.859 5.932 5.932 0 0 1 3.74 5.525v6a1.328 1.328 0 0 1-1.323 1.333H8.504a1.359 1.359 0 0 1-1.005-1.333Z"
            opacity={0.09}
        />
        <path
            data-name="Path 11921"
            d="M20.955 18A5.977 5.977 0 0 0 15 24v8.667A1.328 1.328 0 0 0 16.323 34h9.263a1.328 1.328 0 0 0 1.323-1.333V24a5.977 5.977 0 0 0-5.954-6Z"
            transform="translate(-7.06 -10)"
            fill="url(#f)"
        />
    </svg>
)

export default CommitteeIcon
