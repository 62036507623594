import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import { FontFamilySwitch } from "../../utils";
const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 2px 6px #53668523",
    borderRadius: theme.palette.borderRadius,
    cursor: "pointer",
    border: `1px solid ${theme.palette.border.secondary}`,
    backgroundColor: theme.palette.background.paper,
  },
  Cardcontent: {
    padding: "12px",
  },
  dot: {
    height: "6px",
    width: "6px",
    borderRadius: "50%",
    backgroundColor: "#CED3DD",
    margin: "6px",
  },
  title: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().bold,
    marginTop: "8px",
  },
  name: {
    fontSize: "14px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  unit: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular,
  },
  time: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().regular,
  },
  dis: {
    fontSize: "12px",
    color: theme.typography.color.secondary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "4px",
    overflow: "hidden",
  },
  titlecontainer: {
    borderBottom: "1px solid #E4E8EE",
    paddingBottom: "12px",
  },
  like: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  property: {
    fontSize: "10px",
    color: "#5D427F",
    backgroundColor: "#F1E6FE",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().bold,
    display: "inline",
  },
  new: {
    fontSize: "10px",
    color: "#FFFFFF",
    backgroundColor: "#FF4B4B",
    padding: "2px",
    borderRadius: "4px",
    fontFamily: FontFamilySwitch().bold,
  },
  communityBox: {
    flexFlow: 'wrap !important'
  }
}));

export const CommunityCard = (props) => {
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <>
      <div className={classes.card}>
        <div className={classes.Cardcontent}>
          <div
            onClick={() => {
              history.push(
                Routes.discussions + "?discussionId=" + props?.data?.id
              );
            }}
          >
            <Box alignItems="center" display="flex">
              <Box>
                <Avatar src={props?.data?.url} />
              </Box>
              <Box flexGrow={1} marginLeft="10px">
                <Box className={classes.communityBox} alignItems="center" display="flex">
                  <Box>
                    <Typography variant="subtitle2" className={classes.name}>
                      {props?.data?.first_name}&nbsp;
                      {props?.data?.last_name}
                    </Typography>
                  </Box>
                  <Box className={classes.dot} />
                  <Box flexGrow={1} noWrap>
                    <Typography
                      variant="subtitle2"
                      className={classes.unit}
                      noWrap
                    >
                      {props?.data?.unit_name}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.time}
                    noWrap
                  >
                    &nbsp; {moment(new Date(props?.data?.created_at)).fromNow()}
                  </Typography>
                  {props?.data?.is_new && (
                    <Box marginLeft="4px">
                      <Typography className={classes.new}>
                        &nbsp;NEW&nbsp;
                      </Typography>
                    </Box>
                  )}
                </Box>

                {props?.data?.property_name && (
                  <Typography className={classes.property} noWrap>
                    &nbsp;#{props?.data?.property_name}&nbsp;
                  </Typography>
                )}
              </Box>
            </Box>
            <div className={classes.titlecontainer}>
              <Typography variant="subtitle2" className={classes.title}>
                {props?.data?.title}
              </Typography>
              <Typography variant="subtitle2" className={classes.dis}>
                {props?.data?.description}
              </Typography>
            </div>
          </div>

          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}>
              <div onClick={() => props?.handleClick(props?.data)}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginTop="10px"
                >
                  <Box>
                    {props?.data?.liked_by?.some(
                      (val) => val === localStorage.getItem("userProfileId")
                    ) ? (
                      <img src="images/icons8-facebook-like (1).svg" alt="" />
                    ) : (
                      <img src="/images/icons8-facebook-like.svg" alt="" />
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" className={classes.like}>
                      {props?.data?.liked_by?.some(
                        (val) => val === localStorage.getItem("userProfileId")
                      ) ? (
                        <span style={{ color: "#5078E1" }}>
                          &nbsp;{props?.data?.reactions} Likes
                        </span>
                      ) : (
                        <>&nbsp;{props?.data?.reactions} Likes</>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="10px"
              >
                <Box>
                  <img src="/images/commment.svg" alt="" />
                </Box>
                <Box>
                  <Typography variant="subtitle2" className={classes.like}>
                    &nbsp;{props?.data?.comments} Comments
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};
