import { useApolloClient } from "@apollo/client";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import makeStyles from "@mui/styles/makeStyles";
import jwt_decode from "jwt-decode";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DatePickerNew, DatePickerTimeNew, TextBox, TitleBar } from "../../components";
import { SelectBox } from "../../components/dropDown";
import {
  GETMAINTANANCECATEGORY, GETMAINTANANCESUBCATEGORYMASTERFROMGENERALCATEGORYMASTERID, GET_UNITS_BY_ID
} from "../../graphql/queries";
import { Routes } from "../../router/routes";
import { FontFamilySwitch, LocalStorageKeys } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { withNamespaces } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  content: {
    padding: "16px",
  },
  btnRoot: {
    backgroundColor: "#F5F7FA",
    padding: "16px",
    border: "1px solid #F9FAFE",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  btn: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
  },
  stepperBody: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: "url(/images/stepperBackground.svg)",
    padding: "8px 12px",
    backgroundPositionX: "right",
    backgroundRepeat: "no-repeat",
  },
  stepper: {
    width: "26px",
    height: "26px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  circle: {
    backgroundColor: theme.palette.primary.main,
    border: "2px solid white",
    width: "36px",
    height: "36px",
    borderRadius: "100%",
    display: "grid",
    placeItems: "center",
  },
  steps: {
    fontSize: "12px",
    color: theme.typography.color.primary,
    fontFamily: FontFamilySwitch().semiBold,
  },
  details: {
    fontSize: "16px",
    color: "white",
    fontFamily: FontFamilySwitch().bold,
  },
  btnPrevious: {
    borderRadius: theme.palette.borderRadius,
    padding: "8px",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  screen: {
    borderRadius: "4px",

    background: theme.palette.background.tertiary1,
  },
  screenPadding: {
    marginTop: "5px",
    padding: "0px 16px 0px 16px",
    overflow: "overlay",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const useUnload = (fn) => {
  const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener("beforeunload", onUnload);

    return () => window.removeEventListener("beforeunload", onUnload);
  }, []);
};
const Step1 = (props) => {
  const {t} = props
  const history = useHistory();
  const classes = useStyles();
  const size = useWindowDimensions();
  const client = useApolloClient();
  const [loading1, setLoading1] = React.useState(null);
  const authToken = localStorage.getItem(LocalStorageKeys.authToken);
  const clinet = JSON.parse(localStorage.getItem(LocalStorageKeys.client));
  const goBack = () => {
    history.push(Routes.requestScreen);
  };

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = "";
  });
  const handleCategotyList = (value) => {
    props?.getSubCategorys(value);
    props.request.category = value;
    props.request.subCategory = [];
    props.setRequest(props?.request);
  };

  // const handleAgreementList = (value) => {
  //   // props?.getUnit(value);
  //   props.request.agreement = value;
  //   props.request.unit = [];
  //   props.setRequest(props?.request);
  // };

  const loadOptions = async (search = "", array, type) => {
    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "category":
        const temp_decoded = jwt_decode(authToken);
        const userId = temp_decoded.id;
        if (!userId?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GETMAINTANANCECATEGORY(clinet?.id),
          variables: {
            userID: temp_decoded.id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.maintenance_category_master],
          hasMore:
            array?.length + result?.data?.maintenance_category_master?.length <
            result?.data?.count[0]?.count,
        };

      case "subCategory":
        if (!props?.request?.category?.value) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GETMAINTANANCESUBCATEGORYMASTERFROMGENERALCATEGORYMASTERID(clinet?.id),
          variables: {
            maintanace_category_id: props?.request?.category?.value,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.maintenance_sub_category_master],
          hasMore:
            array?.length + result?.data?.maintenance_sub_category_master?.length <
            result?.data?.count[0]?.count,
        };

      case "unit":
        let id = props?.request?.agreement?.units;
        if (!id?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_UNITS_BY_ID(),
          variables: {
            id: id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: [...result?.data?.unit],
          hasMore:
            array?.length + result?.data?.unit?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };


  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid
        container
        className={classes.screen}
        style={{ height: size?.height }}
      >
        <Grid item xs={12} sx={{ position: "sticky", top: 0 }}>
          <TitleBar

            text={t("NewMaintenanceRequest")}
            goBack={goBack}
          />
        </Grid>
        <div style={{ zIndex: 999, width: "100%" }}>
          <Grid
            container
            className={classes.stepperBody}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={2}>
              <div className={classes.circle}>
                <div className={classes.stepper}>1</div>
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography className={classes.steps}>Step 1 / 4</Typography>
              <Typography className={classes.details}>
                {t("ServiceDetails")}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <LinearProgress
              variant="determinate"
              value={25}

            />
          </Box>
        </div>

        <Grid
          container
          className={classes.screenPadding}
          style={{ height: size?.height - 200 }}
          spacing={1}
        >
          <Grid item xs={12}>
            <SelectBox
              isRequired
              label={t("SelectCategory")}
              placeholder={t("SelectCategory")}
              value={props?.request?.category}
              onChange={(value) => handleCategotyList(value)}
              isError={props?.request?.error?.category?.length > 0}
              errorMessage={props?.request?.error?.category}
              isPaginate
              loadOptions={(search, array) =>
                loadOptions(search, array, "category")
              }
              loading={loading1 === "category"}
              debounceTimeout={800}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectBox
              isRequired
              label={t("SelectSubCategory")}
              placeholder={t("SelectSubCategory")}
              value={props?.request?.subCategory}
              isPaginate
              key={JSON.stringify(props?.request?.category)}
              loadOptions={(search, array) =>
                loadOptions(search, array, "subCategory")
              }
              loading={loading1 === "subCategory"}
              onChange={(value) =>
                props?.updateRequestState("subCategory", value)
              }
              isError={props?.request?.error?.subCategory?.length > 0}
              errorMessage={props?.request?.error?.subCategory}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectBox
              isRequired
              label={t("SelectAgreement")}
              placeholder={t("SelectAgreement")}
              value={props?.request?.agreement}
              options={props?.agreementList}
              onChange={(value) => props?.onChangeState("agreement", value)}
              isError={props?.request?.error?.agreement?.length > 0}
              errorMessage={props?.request?.error?.agreement}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectBox
              isRequired
              label={t("SelectUnit")}
              placeholder={t("SelectUnit")}
              value={props?.request?.unit}
              options={props?.unitList}
              onChange={(value) => props?.updateRequestState("unit", value)}
              isError={props?.request?.error?.unit?.length > 0}
              errorMessage={props?.request?.error?.unit}
              isPaginate
              key={JSON.stringify(props?.request?.agreement)}
              loadOptions={(search, array) =>
                loadOptions(search, array, "unit")
              }
              loading={loading1 === "unit"}
              debounceTimeout={800}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBox
              isRequired
              label={t("ProblemTitle")}
              placeholder={t("EnterProblemTitle")}
              value={props?.request?.problemTitle}
              onChange={(value) =>
                props?.updateRequestState("problemTitle", value.target.value)
              }
              isError={props?.request?.error?.problemTitle?.length > 0}
              errorMessage={props?.request?.error?.problemTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBox
              isRequired
              multiline
              label={t("ProblemDescription")}
              value={props?.request?.problemDescription}
              placeholder={t("Describeprobleminbriefifany")}
              onChange={(value) =>
                props?.updateRequestState(
                  "problemDescription",
                  value.target.value
                )
              }
              isError={props?.request?.error?.problemDescription?.length > 0}
              errorMessage={props?.request?.error?.problemDescription}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePickerNew
              isRequired
              disableFuture={true}
              disablePast={false}
              label={t("ProblemSince")}
              maxDate={new Date()}
              placeholder={t("ProblemSince")}
              value={props?.request?.problemSince}
              onChange={(value) =>
                props?.updateRequestState("problemSince", value)
              }
              isError={props?.request?.error?.problemSince?.length > 0}
              errorMessage={props?.request?.error?.problemSince}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePickerTimeNew
              isRequired
              isTime
              disableFuture={true}
              disablePast={false}
              minDate={new Date()}
              label={t("PreferredVisitDateandTime")}
              placeholder={t("PreferredVisitDateandTime")}
              value={props?.request?.preferredTime}
              onChange={(value) =>
                props?.updateRequestState("preferredTime", value)
              }
              isError={props?.request?.error?.preferredTime?.length > 0}
              errorMessage={props?.request?.error?.preferredTime}
            />
          </Grid>
        </Grid>
        <Container maxWidth="sm" className={classes.btnRoot}>
          <Grid
            container
            spacing={2}
            className={classes.saveNUpdateButtonContainerStyle}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                disabled
                className={classes.btnPrevious}
              >
                {t("Previous")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                className={classes.btn}
                onClick={() => {
                  props.next(2);
                }}
              >
                {t("Next")}
              </Button>
            </Grid>
          </Grid>
          {/* <br /> */}
        </Container>
      </Grid>
    </Container>
  );
};

export default withNamespaces("maintance")(Step1)