import { Box, Stack, Divider, Typography, Button, Container } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { withNamespaces } from 'react-i18next'
import { config } from '../../config'
import { AlertContext, AuthContext } from '../../contexts'
import { NetworkCall } from '../../networkcall'
import { AlertProps, NetWorkCallMethods } from '../../utils'
import { PreviewCard, BillSummary } from './components'
import { BookingPreviewStyle } from './style'

const BookingPreview = (
    {
        navigate = () => false,
        slots = [],
        selectedDate = {},
        stateData = {},
        t,
        onCLoseDrw = () => false
    }) => {
    const auth = React.useContext(AuthContext)
    const classes = BookingPreviewStyle({ language: auth?.auth?.auth?.language })
    const companyId = JSON.parse(localStorage.getItem("accessType"));
    const alert = React.useContext(AlertContext);

    const bookingSlots = slots?.map((slot) => {
        return [
            slot?.value?.check_in_value,
            slot?.value?.check_out_value
        ]
    })

    // Paynow function (book amenities)
    const bookAmenities = () => {
        const payload = {
            property_id: stateData?.propertyId,
            property_facility_id: stateData?.amenity_data?.facility_id,
            period: stateData?.amenity_data?.period,
            start_date: stateData?.amenity_data?.period === "Hourly" ? moment(selectedDate).format("YYYY-MM-DD ")+ slots[0]?.value?.check_in_value : moment(slots[0]?.value?.check_in_date).format("YYYY-MM-DD") + " 00:00:00",
            end_date: stateData?.amenity_data?.period === "Hourly" ? moment(selectedDate).format("YYYY-MM-DD ")+  slots[slots?.length - 1]?.value?.check_out_value : moment(slots[slots?.length - 1]?.value?.check_out_date).format("YYYY-MM-DD") + " 23:59:59",
            // start_date: moment(selectedDate).format("YYYY-MM-DD") + " 00:00:00",
            // end_date: moment(selectedDate).format("YYYY-MM-DD") + " 23:59:59",
            booked_slots: stateData?.amenity_data?.period === "Hourly" ? bookingSlots : [],
            rate: stateData?.amenity_data?.rate,
            total_rate: stateData?.amenity_data?.rate * bookingSlots.length,
            no_of_slots: bookingSlots.length ?? null,
            total_days: stateData?.amenity_data?.period === "Hourly" ? null : 1,
            agreement_unit_id: stateData?.unitData?.agreement_unit_id,
            currency: stateData?.amenity_data?.symbol,
            paid_on: new Date(),
            paid_amount: stateData?.amenity_data?.rate * bookingSlots.length,
            company_id: companyId[0]?.company_id
        }
        NetworkCall(
            `${config.api_url}/amenities_booking_v2/book_amenity`,
            NetWorkCallMethods.post,
            payload,
            null,
            true,
            false
        )
            .then((response) => {
                if (response?.data?.type === 'success') {
                    navigate()
                }
            })
            .catch((error) => {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: t("SomethingWentwrong"),
                });
            });
    };

    return (
        <Container maxWidth={"sm"}>
            <Box>
                <Box className={classes.previewBox}>
                    <Stack className={classes.box} spacing={2} p={1}>
                        <PreviewCard data={stateData?.amenity_data} />
                        <Divider />
                        <Stack spacing={1}>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography className={classes.title}>{t("CourtID")}</Typography>
                                <Typography className={classes.subtitle}>{stateData?.amenity_data?.amenities_name}</Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography className={classes.title}>{t("BookingDate")}</Typography>
                                <Typography className={classes.subtitle}> {moment(selectedDate).format("DD MMM YYYY")} , {moment(selectedDate).format("MMMM")}</Typography>
                            </Stack>

                            <Stack direction={"row"} justifyContent={"space-between"}>
                                <Typography className={classes.title}>{t("BookingSlot")}</Typography>
                                <Stack>
                                    {
                                        slots?.map((slot, index, length) => {
                                            return (
                                                <Typography className={classes.subtitle}>
                                                    {stateData?.amenity_data?.period === "Daily" ? moment(slot?.value.check_out_date).format("Do MMM YY") : moment(slot?.value.check_in_time, "HH:mm").format("hh:mm A")} - {stateData?.amenity_data?.period === "Daily" ? moment(slot?.value.check_in_date).format("Do MMM YY") : moment(slot?.value?.check_out_time, "HH:mm").format("hh:mm A")}
                                                    {length.length - 1 !== index && <span>,</span>}
                                                </Typography>
                                            )
                                        })
                                    }
                                </Stack>
                            </Stack>

                        </Stack>
                    </Stack>
                    <Box mt={1}>
                        <BillSummary data={stateData?.amenity_data} bookingSlots={bookingSlots} />
                    </Box>
                </Box>
                <Stack direction={"row"} spacing={2} mt={2} mb={1}>
                    <Button fullWidth variant='outlined' className={classes.addUnitBtn}
                        onClick={onCLoseDrw}>{t("AddToUnitInvoice")}</Button>
                    <Button fullWidth variant='contained'
                        onClick={bookAmenities}
                        className={classes.paybtn}>
                        {t("PayNow")}
                        ( {stateData?.amenity_data?.symbol} {stateData?.amenity_data?.rate * bookingSlots.length} )
                    </Button>
                </Stack>
            </Box>
        </Container>
    )
}

export default withNamespaces("amenitiesBooking")(BookingPreview)