import { Box, Typography, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment-timezone";
import React, { useEffect, useState, useContext } from "react";
import { FontFamilySwitch, NetWorkCallMethods ,AlertProps} from "../../utils";
import { withBottombar } from "../../HOCs";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { NetworkCall } from "../../networkcall/index";
import InfiniteScroll from "react-infinite-scroll-component";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { TitleBar,LoderSimmer } from "../../components"
import { useHistory } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    list: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
        padding: '12px',
        cursor: "pointer",
        backgroundColor: theme?.palette?.primary?.contrastText
    },
    list2: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: `1px solid ${theme?.palette?.border?.secondary}`,
        cursor: "pointer",
        padding: '12px',

    },
    Tittle: {
        fontSize: "16px",
        fontFamily: FontFamilySwitch().bold,
        color: theme?.palette?.secondary?.main,
    },
    contant: {
        fontSize: "12px",
        fontFamily: FontFamilySwitch().regular,
        color: theme?.typography?.color?.secondary,
    },
    expires: {
        fontSize: "10px",
        fontFamily: FontFamilySwitch().bold,
        color: theme?.typography?.color?.secondary,
        backgroundColor: theme?.palette?.background?.tertiary,
        padding: "5px",
        marginLeft: "10px",
        borderRadius: theme.palette.borderRadius
    },
    lease: {
        fontSize: "12px",
        color: theme.typography.color.secondary,
        fontFamily: FontFamilySwitch().regular,
    },
    dot: {
        height: "6px",
        width: "6px",
        borderRadius: "50%",
        backgroundColor: "#CED3DD",
        margin: "6px",
    },
    Appartment: {
        fontSize: "10px",
        fontFamily: FontFamilySwitch().bold,
        color: theme?.typography?.color?.secondary,
    },
    root: {
        padding: "0px",
        backgroundColor: "#F2F5FA",
    },
    box: {
        display: "flex", marginTop: "5px"
    },
    agreementImg: {
        border: `1px solid ${theme?.palette?.border?.secondary}`,
        borderRadius: "4px",
        padding: "6px 6px 0px 6px"
    },
    main: {
        padding: "16px"
    },
    upcoming: {
        fontSize: "10px",
        fontFamily: FontFamilySwitch().bold,
        color: theme?.palette?.primary?.contrastText,
        backgroundColor: theme?.palette?.success?.dark,
        padding: "5px",
        marginLeft: "10px",
        borderRadius: theme.palette.borderRadius
    },
    expired: {
        fontSize: "10px",
        fontFamily: FontFamilySwitch().bold,
        color: theme?.palette?.primary?.contrastText,
        backgroundColor: theme?.palette?.error?.dark,
        padding: "5px",
        marginLeft: "10px",
        borderRadius: theme.palette.borderRadius
    },
}));

const Allagreement = (props) => {
    const alert = useContext(AlertContext);

    const classes = useStyles()
    const [state, setState] = useState([])
    const [offset, setOffset] = useState(0);
    const [loader , setLoader] = useState(false)

    const size = useWindowDimensions();
    const history = useHistory();

    const getAgreementData = (offset, filter) => {
        setLoader(true)
        const data = {
            start: offset ?? 0,
            end: 20
        }
        NetworkCall(
            `${config.api_url}/agreement/get_all_agreements`,
            NetWorkCallMethods.post,
            data,
            null,
            true,
            false
        ).then((response) => {
            if (!response.data?.data?.length > 0) {
                return false
            }
            if (filter) {
                setState(state.concat(response?.data?.data));
            }
            else {
                setState(response?.data?.data)
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Some thing Went wrong.",
              });
        });
    };

    const fetchMoreData = () => {
        setOffset(offset + 10);
        getAgreementData(offset + 10, true);
    };

    const statusComponent = (val) => {
        switch (val) {
            case "upcoming":
                return (
                    <span className={classes.upcoming}>
                        {val}&nbsp;
                    </span>
                )
            case "expired":
                return (
                    <span className={classes.expired}>
                        {val}&nbsp;
                    </span>
                )
            default:
                return null;
        }
    }

    useEffect(() => {
        getAgreementData()
        // eslint-disable-next-line
    }, [])
    return (
        <Container className={classes.root} maxWidth="sm">
            <TitleBar
                text={"All Agreements"}

                goBack={() => history.goBack(-1)}
            />
            <InfiniteScroll
                dataLength={state?.length}
                next={fetchMoreData}
                hasMore={true}
                height={size?.height - 120}
            >
                <div className={classes.main}>
                    {
                        loader ?  <LoderSimmer card count={10} size={100} /> : 
                        state?.length > 0 &&
                        state?.map((val) => {
                            return (
                                <>
                                    <Box
                                        className={classes.list}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            {/* agreement image */}
                                            <Box className={classes.agreementImg}>
                                                <img src="/images/agreementnew.svg" alt="agreement_img" />
                                            </Box>
                                            {/* Agreement number */}
                                            <Box style={{ marginLeft: "10px" }}>
                                                <div>
                                                    <Typography className={classes.Tittle}>
                                                        {val?.agreement_no ?? ""}
                                                        {statusComponent(val?.status)}
                                                    </Typography>

                                                    <Box className={classes.box}>
                                                        <Typography className={classes.lease}>
                                                            {"Lease Start :"}&nbsp;
                                                            {val?.lease_start_date &&
                                                                moment(val?.lease_start_date)
                                                                    .format("DD MMM YY")
                                                            }
                                                        </Typography>
                                                        <Box className={classes.dot} />
                                                        <Typography className={classes.lease}>
                                                            {"Lease End :"}&nbsp;
                                                            {moment(val?.lease_end_date)
                                                                .format("DD MMM YY")}
                                                        </Typography>
                                                    </Box>

                                                </div>
                                            </Box>
                                        </Box>
                                    </Box>
                                </>
                            );
                        })

                    }
                </div>
            </InfiniteScroll>
        </Container>
    )
}
export default withBottombar(Allagreement)