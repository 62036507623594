import { makeStyles } from "@mui/styles";
import { FontFamilySwitch } from "../../utils";

export const MyBookingStyle = makeStyles((theme) => ({
    container: {
        background: "#F5F7FA",
        padding: "0 !important",
        height: 'calc(100vh - 10px)'
    },
    topCard: {
        background: "linear-gradient(to right, #F8997B, #E25C54)",
        height: 160
    },
    topImgae: {
        height: 160,
        width: "100%",
        objectFit: "cover"
    },
    searchBox: {
        position: "relative",
        zIndex: 3,
        backgroundColor: "white",
        borderRadius: '4px',
        width: 330,
        top: "-3%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        padding: "0 !important"
    },
    bookingTitle: {
        fontSize: 16,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    avatar: {
        borderRadius: '4px',
        height: 40,
        width: 40
    },
    title: {
        fontSize: 12,
        color: theme.typography.color.primary,
        fontFamily: FontFamilySwitch().bold,
    },
    content: {
        fontSize: 10,
        color: theme.typography.color.secondary,
        whiteSpace: "nowrap",
        fontFamily: FontFamilySwitch().regular,
    },
    divider: {
        border: `0.5px dashed ${theme.palette.border.secondary}`,
        height: 50
    },
    statusCard: {
        background: "#fff",
        boxShadow: "0px 3px 8px #D2D5D938",
        borderRadius: '4px'
    },
    booked: {
        background: theme.palette.success.main,
        color: "#fff",
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: 10,
        fontFamily: FontFamilySwitch().regular,
    },
    not_booked: {
        background: theme.palette.border.secondary,
        color: theme.typography.color.tertiary,
        padding: "2px 8px",
        borderRadius: '4px',
        fontSize: 10,
        fontFamily: FontFamilySwitch().regular,
    },
    backbtn: {
        height: 32,
        width: 32,
        background: "#624234",
        color: "#fff",
        position: "relative",
        top: "30px",
        left: "30px",
        transform: "translate(-50%,-50%)",
        zIndex: 3
    },
    navigate: {
        background: "transparent",
        height: 0
    },
    scroll:{
        "&::-webkit-scrollbar": {
            display: "none",
          },
    }
}))