import makeStyles from "@mui/styles/makeStyles";
import { FontFamilySwitch } from "../../utils";

export const useStyles = makeStyles((theme) => ({
      backgroundImage: {
            backgroundColor: "#F2F4F7",
            height: "213px",
            backgroundImage: `url("/images/Select house-cuate.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "100%"
      },
      content: {
            width: "inherit",
      },
      text: {
            fontSize: "24px",
            fontFamily: FontFamilySwitch().bold,
            color: theme.typography.color.primary,
      },
      btn: {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 6px 10px #00000014",
            padding: "16px",
            "&:hover": {
                  color: "#FFFFFF",
                  backgroundColor: "#5078E1",
            },
      },
      buttonTextStyle: {
            fontSize: "14px",
            color: "#FFFFFF",
            fontFamily: FontFamilySwitch().bold,
      },
      img: {
            height: "8%",
            width: "28%",
      },
      contentBottom: {
            padding: "12px",
      },

      companyName: {
            color: theme.typography.color.secondary,
            fontFamily: FontFamilySwitch().bold,
            fontSize: "14px",
            marginLeft: '12px'
      },
      companySelect: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
      },
      comapnyImage: {
            width: 50,
            height: 50,
            borderRadius: '0px'
      },
      selectCompany: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius:  '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer'
      },
      selectCompanyBorder: {
            padding: '14px',
            boxShadow: '0px 3px 30px #5C86CB2E',
            margin: '8px 0px',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            border: `1px solid ${theme.palette.primary.main}`,
            cursor: 'pointer'
      },
      goBackStyle: {
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer',
            color: "#5078E1",
            fontSize: '14px',
            fontFamily: FontFamilySwitch().bold,
            marginTop: '14px'
      },
      selectMain: {
            padding: '16px'
      },
      paddingCompany: {
            padding: "16px 16px 25px 16px"
      },
      version: {
            fontFamily: FontFamilySwitch().semiBold,
            fontSize: "12px",
            color: "#091B29"
      }
}));