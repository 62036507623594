import React from 'react'
import { Stack, Avatar, Typography } from '@mui/material'
import { BookingCardStyle } from './style'
import { AuthContext } from '../../../contexts'
export const BookingCard = ({ header="", url="", name="", content="" }) => {
    const auth = React.useContext(AuthContext)
    const classes = BookingCardStyle({ language: auth?.auth?.auth?.language })
    return (
        <Stack alignItems={"left"} width={"100%"} ml={1}>
            <Typography className={classes.headerTitle}> {header} </Typography>
            <Stack width="100%" p={1}>
                <Stack direction="row" justifyContent={"space-between"}>
                    <Stack direction={"row"} spacing={1}>
                        <Avatar className={classes.avatar} src={url}></Avatar>
                        <Stack>
                            <Typography className={classes.title}> {name} </Typography>
                            <Typography className={classes.content}> {content} </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )
}