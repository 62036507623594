/* eslint-disable array-callback-return */
import { useApolloClient } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
// import jwt_decode from "jwt-decode";
import React from "react";
import { DatePickerNew } from '../../components';
import { SelectBox } from "../../components/dropDown";
import { GET_GATES } from "../../graphql/queries";
import { enumSelect } from "../../utils";
import { PassContext } from "./passContext";
import { withNamespaces } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },

  input: {
    marginTop: "10px",
  },
  unactive: {
    padding: "10px",
    marginTop: "24px",
    textAlign: "center",
    borderRadius: "4px",
    fontSize: "14px",
    cursor: "pointer",
    border: "1px solid #E4E8EE",
    backgroundColor: "white",
  },
  active: {
    padding: "10px",
    textAlign: "center",
    borderRadius: "4px",
    color: "white",
    marginTop: "24px",
    fontSize: "14px",
    backgroundColor: "#5078E1",
    cursor: "pointer",
  },
}));

const Step1 = ({ t, menuPlacement = "bottom" }) => {
  const classes = useStyles();
  const { data, updateState, getUnitIDS, agreementList, property } = React.useContext(PassContext);
  const [loading1, setLoading1] = React.useState(null);
  const client = useApolloClient();
  const [stayTypeEnum, setStayTypeEnum] = React.useState([]);

  const option3 = [
    { value: "VGR", label: "Visitor Gate Pass" },
    { value: "WGR", label: "Worker Gate Pass" },
  ];
  const anyOption = {
    value: "Any",
    label: "Any",
  }




  const loadOptions = async (search = "", array, type) => {

    setLoading1(type);
    let result,
      offset = 0;

    if (search && !Boolean(array?.length)) {
      offset = 0;
    } else {
      offset = array?.length;
    }

    switch (type) {
      case "gate":
        if (!data?.unit?.value?.length) {
          setLoading1(null);
          return { options: [] };
        }
        result = await client.query({
          query: GET_GATES(),
          variables: {
            property_id: property?.id,
            offset,
            limit: 10,
            search,
          },
        });
        setLoading1(null);
        return {
          options: offset === 0 ? [anyOption, ...result?.data?.access_gates_master] : [...result?.data?.access_gates_master],
          hasMore:
            array?.length + result?.data?.access_gates_master?.length <
            result?.data?.count[0]?.count,
        };

      default:
        return { options: [] };
    }
  };
  const getEnum = async () => {
    const stayType = await enumSelect(['security_stay_type'])
    setStayTypeEnum(stayType?.security_stay_type)
  }

  React.useEffect(() => {
    getUnitIDS()
    getEnum()
    // eslint-disable-next-line
  }, [])


  return (
    <div style={{ padding: "12px 12px 8px 12px" }}>
      <div className={classes.input}>
        <SelectBox
          isRequired
          label={t("ChoosePassType")}
          placeholder={t("ChoosePassType")}
          value={data.passType}
          onChange={(e) => updateState("passType", e)}
          options={option3}
          isError={data?.error?.passType?.length > 0}
          errorMessage={data?.error?.passType}
        />
      </div>
      <div className={classes.input}>
        <SelectBox
          isRequired
          label={t("ChooseUnit")}
          placeholder={t("SelectUnit")}
          value={data.unit}
          // isPaginate
          // loadOptions={(search, array) => loadOptions(search, array, "unit")}
          // loading={loading1 === "unit"}
          // debounceTimeout={800}
          options={agreementList}
          onChange={(value) => {
            updateState("unit", value);
          }}
          isError={data?.error?.unit?.length > 0}
          errorMessage={data?.error?.unit}
        />
      </div>
      <Typography
        style={{ fontSize: "12px", color: "#98A0AC", marginTop: "10px" }}
      >
        Visitor Stay Type
      </Typography>
      <Grid container spacing={1} sx={{ marginTop: "-26px" }}>
        {
          stayTypeEnum?.map((value) => {
            return (
              <Grid item>
                <Typography
                  onClick={() => updateState("stayType", value?.value)}
                  className={
                    data?.stayType === value?.value ? classes.active : classes.unactive
                  }
                >
                  {value?.label}
                </Typography>
              </Grid>
            )
          })
        }
      </Grid>
      {data?.error?.stayType?.length > 0 && (
        <Typography
          style={{ marginTop: "6px" }}
          color="error"
          variant="caption"
        >
          {data?.error?.stayType}
        </Typography>
      )}

      <Grid container spacing={1} sx={{ marginTop: "4px" }}>
        <Grid item xs={6}>
          {/* <DateWithTime
            isRequired
            color
            title="Visit Start Date"
            value={data.startData}
            onChange={(value) => updateState("startData", value)}
          />
          {data?.error?.startData?.length > 0 && (
            <Typography
              style={{ marginTop: "6px" }}
              color="error"
              variant="caption"
            >
              {data?.error?.startData}
            </Typography>
          )} */}
          <DatePickerNew
            isRequired
            label={t("VisitStartDate")}
            placeholder={t("VisitStartDate")}
            value={data?.startData ?? ""}
            onChange={(value) => updateState("startData", value)}
            isError={data?.error?.startData?.length > 0}
            errorMessage={data?.error?.startData}
          minDate={new Date()}
          />
        </Grid>
        <Grid item xs={6}>
          {/* <DateWithTime
            isRequired
            color
            title="Visit End Date"
            value={data.endDate}
            onChange={(value) => updateState("endDate", value)}
          />
          {data?.error?.endDate?.length > 0 && (
            <Typography
              style={{ marginTop: "6px" }}
              color="error"
              variant="caption"
            >
              {data?.error?.endDate}
            </Typography>
          )} */}
          <DatePickerNew
            isRequired
            label={t("VisitEndDate")}
            placeholder={t("VisitEndDate")}
            value={data.endDate ?? ""}
            minDate={new Date(data?.startData)}
            onChange={(value) => updateState("endDate", value)}
          isError={data?.error?.endDate?.length>0}
          errorMessage={data?.error?.endDate}
          />
          {/* <Typography
            style={{
              fontSize: "12px",
              color: "red",
              whiteSpace: "nowrap",
              marginTop: "8px",
            }}
          >
            {data?.error?.endDate}
          </Typography> */}
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ marginTop: "4px" }}>
        <Grid item xs={12}>
          <SelectBox
            isRequired
            label={t("ChooseGateEntry")}
            placeholder={t("ChooseGateEntry")}
            value={data.gate}
            isPaginate
            key={JSON.stringify(data.unit)}
            loadOptions={(search, array) => loadOptions(search, array, "gate")}
            loading={loading1 === "gate"}
            debounceTimeout={800}
            onChange={(value) => updateState("gate", value)}
            isError={data?.error?.gate?.length > 0}
            errorMessage={data?.error?.gate}
            menuPlacement={menuPlacement}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default withNamespaces("createPass")(Step1)