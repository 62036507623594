/* eslint-disable array-callback-return */
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Hidden,
  IconButton,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import {
  AddvisiterCard,
  MobileNumberInputComponent,
  TextBox
} from "../../components";
import { SelectBox } from "../../components/dropDown";
import { config } from "../../config";
import { AlertContext } from "../../contexts";
import { GET_PROOF } from "../../graphql/queries";
import { NetworkCall } from "../../networkcall/index";
import { AlertProps, FontFamilySwitch, NetWorkCallMethods } from "../../utils";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { PassContext } from "./passContext";
import { withNamespaces } from "react-i18next";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F2F2F2",
    padding: "0px",
  },

  input: {
    marginTop: "10px",
  },
  title: {
    fontSize: "16px",
    fontFamily: FontFamilySwitch().bold,
    color: theme.typography.color.primary,
  },
  imgdiv: {
    position: "relative",
    display: "inline-block",
    borderRadius: "50%",
  },
  add: {
    fontSize: "14px",
    fontFamily: FontFamilySwitch().bold,
    color: "#5078E1",
    cursor: "pointer",
  },
  bottomBarTitle: {
    fontSize: "18px",
    fontFamily: FontFamilySwitch().extraBold,
    color: theme.typography.color.primary,
  },
  drawer: {
    "& .MuiDrawer-paper": {
      borderTopRightRadius: "4px",
      borderTopLeftRadius: "4px",
    },
    "& .MuiContainer-root": {
      padding: "0px 8px",
    },
  },
  dialog: {
    "& .MuiDialog-paper": {
      borderRadius: "4px",
      padding: "0px",
    },
  },
  titleroot: {
    borderBottom: "1px solid #c1c1c1",
    padding: "4px 8px 8px 8px",
  },
  profileimg: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "relative",
  },
  profileicon: {
    fontSize: "80px",
    padding: "14px",
  },
  profileimgs: {
    backgroundColor: theme.palette.background.tertiary,
    color: "#E4E8EE",
    position: "absolute",
    bottom: "0px",
    right: "0px",
    border: "2px solid white",
    borderRadius: "50%",
  },

  profilesection: {
    marginTop: "14px",
  },
  submitbtn: {
    borderRadius: "4px",
    padding: "12px 8px",
    textTransform: "capitalize"
  },
  type: {
    borderRadius: "4px",
    padding: "11px 14px",
    height: 46,
    border: "1px solid #CDCDCD",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    color: theme.typography.color.secondary,
  },
  heading: {
    fontSize: "12px",
    color: theme.typography.color.Tertiary,
    fontFamily: FontFamilySwitch().semiBold,
    marginTop: "10px",
  },
  close: {
    position: "absolute",
    right: "0px",
    top: "0px",
    backgroundColor: "red",
    borderRadius: "50%",
    color: "white",
    fontSize: "20px",
  },
}));

const Step2 = ({ t }) => {
  const classes = useStyles();
  const {
    data,
    updateState,
    addVisitList,
    visitList,
    setVisitList,
    setUrl,
    url,
    setEditList,
    isIamValideTostep2,
    editVisitList,
  } = React.useContext(PassContext);
  const [drawer, setDrawer] = React.useState(false);
  const [detail, setDetails] = React.useState(false);
  const [visiterDetails, setVisiterDetails] = React.useState([]);
  const [proofData, setProofData] = React.useState([]);
  const [proof, setProof] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(0);
  const [openViwer, setOpenviewer] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const size = useWindowDimensions();
  const viewDetails = (data) => {
    setDetails(true);
    setVisiterDetails(data);
  };
  const genderOption = [
    { value: "male", label: "Male" },
    { value: "Female", label: "female" },
    { value: "Others", label: "others" },
  ];
  const deleteData = (indexs, data) => {
    setEditList([...editVisitList, data.id]);

    setVisitList(visitList.filter((val, index) => index !== indexs));
  };
  const uploadS3 = (data) => {
    const formData = new FormData();
    formData.append("files", data);
    formData.append("tenantId", `${config.tenantid}`);

    NetworkCall(
      `${config.api_url}/file-upload`,
      NetWorkCallMethods.post,
      formData,
      null,
      true,
      false
    )
      .then((response) => {
        setUrl(response.data?.fileUrls?.[0]?.url);
      })
      .catch((err) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  const getProofData = () => {
    const params = {
      query: GET_PROOF,
    };
    NetworkCall(
      `${config.graphql_url}`,
      NetWorkCallMethods.post,
      params,
      null,
      true,
      false
    )
      .then((response) => {
        setProofData(response.data.data.Identification_master);
      })
      .catch((error) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: t("SomethingWentwrong"),
        });
      });
  };
  React.useState(() => {
    getProofData();
  }, []);
  const submit = () => {
    if (isIamValideTostep2()) {
      setDrawer(false);
      addVisitList();
    }
  };
  const renderFrom = () => {
    return (
      <div style={{ padding: "12px 12px 8px 12px" }}>
        <Box display="flex" alignItems="center" className={classes.titleroot}>
          <Box flexGrow={1}>
            <Typography variant="subtitle2" className={classes.bottomBarTitle}>
              Add {data?.passType?.value === "VGR" && "Visitor"}
              {data?.passType?.value === "WGR" && "Worker"}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={() => setDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <div style={{ height: size.height - 200, overflow: "scroll" }}>
          <div className={classes.input}>
            <center>
              {url ? (
                <div className={classes.imgdiv}>
                  <img
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                    alt=" "
                    src={url}
                    className={classes.profileicons}
                  />

                  <CloseIcon
                    className={classes.close}
                    onClick={() => {
                      setUrl("");
                    }}
                  />
                </div>
              ) : (
                <IconButton className={classes.profileimg}>
                  <PersonOutlineIcon className={classes.profileicon} />
                  <label htmlFor="uplodebtn">
                    <Avatar
                      sx={{ width: 24, height: 24 }}
                      className={classes.profileimgs}
                      type="file"
                    >
                      <img
                        src="/images/icons8-camera (1).svg"
                        alt=" "
                        className={classes.profileicons}
                      />
                    </Avatar>
                  </label>
                  <input
                    type="file"
                    name="img"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={(value) => {
                      uploadS3(value.target.files[0]);
                    }}
                    id="uplodebtn"
                  />
                </IconButton>
              )}
            </center>
          </div>
          <Typography className={classes.title}>
            {data?.passType?.value === "VGR" && "VISTOR"}
            {data?.passType?.value === "WGR" && "WORKER"} DETAIL
          </Typography>
          <div className={classes.input}>
            <TextBox
              isRequired
              color
              label={
                data?.passType?.value === "VGR" ? t("VisitorName") : t("WorkerName")
              }
              placeholder="Enter Visitor Name"
              value={data.name}
              onChange={(value) => updateState("name", value.target.value)}
              isError={data?.error?.name?.length > 0}
              errorMessage={data?.error?.name}
            />
          </div>
          <div className={classes.input}>
            <SelectBox
              isRequired
              label={t("ChooseGender")}
              placeholder={t("ChooseGender")}
              value={data.gender}
              onChange={(e) => updateState("gender", e)}
              options={genderOption}
              isError={data?.error?.gender?.length > 0}
              errorMessage={data?.error?.gender}
            />
          </div>
          <div className={classes.input}>
            <MobileNumberInputComponent
              // isRequired
              label={t("PhoneNumber")}
              placeholder={t("PhoneNumber")}
              value={data.mobile}
              handleChange={(value) => updateState("mobile", value)}
              isError={data?.error?.mobile?.length > 0}
              errorMessage={data?.error?.mobile}
            />
          </div>
          <div className={classes.input}>
            <TextBox
              // isRequired
              color
              label={t("EnterMailID")}
              placeholder={t("EnterMailID")}
              value={data.mail}
              onChange={(value) => updateState("mail", value.target.value)}
              isError={data?.error?.mail?.length > 0}
              errorMessage={data?.error?.mail}
            />
          </div>
          <Box style={{ marginTop: "14px", border: "2px solid #E4E8EE" }} />
          <div className={classes.input}>
            <Typography className={classes.title}>
              {data?.passType?.value === "VGR" && "VISTER"}
              {data?.passType?.value === "WGR" && "WORKER"} PROOF
            </Typography>
          </div>
          <div className={classes.input}>
            <Box onClick={() => setProof(true)} className={classes.type} color={data?.idType?.label ? "#383c41" : "#CDCDCD"}>
              <Typography>
                {data.idType?.label ? data?.idType?.label : t("SelectProofType")}
              </Typography>
              <KeyboardArrowDownIcon sx={{ right: 0, alignItems: "right" }} />
            </Box>
            {data?.error?.idType?.length > 0 && (
              <Typography
                style={{ marginTop: "6px" }}
                color="error"
                variant="caption"
              >
                {data?.error?.idType}
              </Typography>
            )}
          </div>
          <div className={classes.input}>
            <TextBox
              // isRequired
              color
              label={t("EnterIDNumber")}
              placeholder={t("EnterIDNumber")}
              value={data.idproof}
              onChange={(value) => updateState("idproof", value.target.value)}
              isError={data?.error?.idproof?.length > 0}
              errorMessage={data?.error?.idproof}
            />
          </div>
        </div>
        <Button
          fullWidth
          className={classes.submitbtn}
          variant="contained"
          onClick={submit}
        >
          {t("Submit")}
        </Button>
      </div>
    );
  };
  const getProof = () => {
    return (
      <>
        <div style={{ padding: "12px" }}>
          <Box display="flex" alignItems="center" className={classes.titleroot}>
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                className={classes.bottomBarTitle}
              >
                {t("IDProof")}
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setProof(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <div style={{ padding: "12px", overflow: "hidden" }}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {proofData.map((val) => {
                  return (
                    <>
                      <FormControlLabel
                        value={data.idType}
                        control={
                          <Radio
                            checked={
                              data.idType?.value === val.value ? true : false
                            }
                          />
                        }
                        label={val.label}
                        onChange={(value) => {
                          updateState("idType", val);
                          setProof(false);
                        }}
                      />
                      <Divider style={{ width: "100vh" }} />
                    </>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </>
    );
  };
  const renderDetails = () => {
    return (
      <>
        <div style={{ padding: "12px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                className={classes.titleroot}
              >
                <Box flexGrow={1}>
                  <Typography
                    variant="subtitle2"
                    className={classes.bottomBarTitle}
                  >
                    {t("ViewVisitorDetail")}
                  </Typography>
                </Box>
                <Box>
                  <IconButton size="small" onClick={() => setDetails(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" p={2}>
                <Box>

                  <img
                    src={
                      visiterDetails?.visitor_image
                        ? visiterDetails?.visitor_image
                        : "/images/icons8_person.svg"
                    }
                    alt=""
                    style={{ objectFit: "contain", marginTop: "4px", height: "47px", width: "47px", borderRadius: "50%" }}
                  />

                </Box>
                <Box marginLeft="10px">
                  <Typography variant="subtitle2" className={classes.name}>
                    {visiterDetails?.name}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              {visiterDetails?.id_label && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {("IDPROOF")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {visiterDetails?.id_label}
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" className={classes.heading}>
                {t("PHONENUMBER")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#091B29" }}
              >
                {visiterDetails?.mobile_country_code}
                &nbsp;{visiterDetails?.mobile_no}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {visiterDetails?.identification_no && (
                <>
                  <Typography variant="subtitle2" className={classes.heading}>
                    {t("IDNUMBER")}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#091B29" }}
                  >
                    {visiterDetails?.identification_no}
                  </Typography>
                </>
              )}
              <Typography variant="subtitle2" className={classes.heading}>
                {t("MAILID")}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "#091B29" }}
              >
                {visiterDetails?.email_id}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </>
    );
  };

  return (
    <div>
      <Box display="flex" alignItems="center" p={1}>
        <Box>
          <Typography className={classes.title}>
            {" "}
            {data?.passType?.value === "VGR" && "Visitors"}
            {data?.passType?.value === "WGR" && "Workers"}
          </Typography>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Typography className={classes.add} onClick={() => setDrawer(true)}>
            {t("Add")} {data?.passType?.value === "VGR" && "Visitor"}
            {data?.passType?.value === "WGR" && "Worker"}
          </Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          {visitList.map((val, index) => {
            return (
              <React.Fragment key={index}>
                <AddvisiterCard
                  data={val}
                  onDelete={deleteData}
                  handleDetails={viewDetails}
                  index={index}
                  setSelectedImage={setSelectedImage}
                  selectedImage={selectedImage}
                  setOpenviewer={setOpenviewer}
                  openViwer={openViwer}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderFrom()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={drawer}
          onClose={() => setDrawer(false)}
        >
          {renderFrom()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={proof}
          onClose={() => setProof(false)}
        >
          {getProof()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer}
          anchor="bottom"
          open={proof}
          onClose={() => setProof(false)}
        >
          {getProof()}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Dialog
          className={classes.dialog}
          maxWidth="sm"
          fullWidth
          open={detail}
          onClose={() => setDetails(false)}
        >
          {renderDetails()}
        </Dialog>
      </Hidden>
      <Hidden smUp>
        <Drawer
          className={classes.drawer1}
          anchor="bottom"
          open={detail}
          onClose={() => setDetails(false)}
        >
          {renderDetails()}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default withNamespaces("createPass")(Step2)