import { Avatar, Box, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { withNamespaces } from "react-i18next";
import { FontFamilySwitch } from "../../utils";

const useStyles = makeStyles((theme) => ({
      root: {
            padding: "8px",
            margin: "0px 4px 0px 8px",
            [theme.breakpoints.down("xs")]: {
                  padding: "5px",
                  margin: "0px 0px 0px 5px",
  
              },
      },
      roots: {
            borderRadius: theme.palette.borderRadius,
            boxShadow: "0px 3px 30px #5C86CB2E",
            textAlign: "center",
            backgroundColor: "#FFFFFF",
      },
      periodStyle: {
            height: "6px",
            width: "6px",
            borderRadius: "50%",
            backgroundColor: "#CED3DD",
            margin: "6px",
      },
      name: {
            fontSize: "14px",
            fontFamily: FontFamilySwitch().bold,
      },
      des: {
            color: '#4E5A6B',
            fontSize: '12px',
            fontFamily: FontFamilySwitch().semiBold,
            marginLeft: '4px'
      },
      call: {
            fontSize: "12px",
            color: theme.typography.color.secondary,
      },
      mail: {
            fontSize: "12px",
            color: theme.typography.color.secondary,
      },
      tagStyle: {
            backgroundColor: "#F1E6FE",
            borderRadius: "4px",
            padding: "4px 6px 4px 6px",
            fontSize: "8px",
            fontFamily: FontFamilySwitch().bold,
            color: "#5D427F",
            textTransform: "uppercase",
            marginLeft: "8px",
      },

      avatar: {
            backgroundColor: "#F0F4FF",
            width: "48px",
            height: "48px",
      },
      horizontalDividerStyle: {
            backgroundColor: "#E4E8EE",
            height: "1px",
      },
      bottomCard: {
            padding: "12px",
      },
      extraDetailsStyle: {
            fontSize: "12px",
            color: "#4E5A6B",
      },
      verticalDividerStyle: {
            backgroundColor: "#E4E8EE",
            width: "1px",
            height: "20px",
      },
      img:{
            objectFit:"cover"
      },
      flexBox:{
            display:"flex",
            alignItems:"center",
            marginBottom:"2px"
      }

}));

const ManagementList = (props) => {
      const {t} = props
      const classes = useStyles();
      return (
        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12} className={classes.roots}>
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" className={classes.bottomCard}>
                    <Box>
                      <Avatar
                        className={classes.avatar}
                        src={props?.data?.resident_profileByID?.image_url ?? ""}
                      />
                    </Box>
                    <Box flexGrow={1} marginTop="6px" marginLeft="8px">
                      <div className={classes.flexBox}>
                        {props?.data?.committee_role?.role_name?.length > 0 && (
                          <>
                            <Typography className={classes.name} noWrap>
                              {props?.data?.committee_role?.role_name}
                            </Typography>
                            <Typography className={classes.des}>
                              ({props?.data?.resident_profileByID?.first_name})
                            </Typography>
                          </>
                        )}
                      </div>

                      <Box display="flex" style={{ flexFlow: "wrap" }}>
                        {props?.data?.resident_profileByID
                          ?.mobile_no_country_code?.length > 0 && (
                          <>
                            <Box display="flex" alignItems={"center"}>
                              <Box className={classes.periodStyle} />
                              <Typography className={classes.mail} noWrap>
                                {
                                  props?.data?.resident_profileByID
                                    ?.mobile_no_country_code
                                }{" "}
                                {props?.data?.resident_profileByID?.mobile_no}
                              </Typography>
                            </Box>
                          </>
                        )}

                        {props?.data?.resident_profileByID?.email_id?.length >
                          0 && (
                          <>
                            <Box display="flex" alignItems={"center"}>
                              <Box className={classes.periodStyle} />
                              <Typography className={classes.mail} noWrap>
                                {props?.data?.resident_profileByID?.email_id}
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.horizontalDividerStyle} />
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={classes.bottomCard}
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Grid
                      container
                      sm={4}
                      xs={4}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <a
                        href={`mailto:${props?.data?.email_id}`}
                        style={{ textDecorationLine: "none" }}
                      >
                        <Box display="flex" alignItems="center">
                          <img
                            sx={{ padding: "10px", cursor: "pointer" }}
                            src="/images/maintancemail.svg"
                            alt="altimage"
                          />

                          <Box width="8px" />

                          <Typography className={classes.extraDetailsStyle}>
                            {t("Email")}
                          </Typography>
                        </Box>
                      </a>
                    </Grid>
                    <Box className={classes.verticalDividerStyle} />
                    <Grid
                      container
                      sm={4}
                      xs={4}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <a
                        href={`tel:${props?.data?.country_code}+${props?.data?.contact_no}`}
                        style={{ textDecorationLine: "none", color: "#071741" }}
                      >
                        <Box display="flex" alignItems="center">
                          <img
                            sx={{ cursor: "pointer" }}
                            src="/images/maintancecall.svg"
                            alt="altimage"
                          />

                          <Box width="8px" />
                          <Typography className={classes.extraDetailsStyle}>
                            {t("Contact")}
                          </Typography>
                        </Box>
                      </a>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
};
export default withNamespaces("management")(ManagementList)